import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { FormControl } from "@mui/material";
import React from "react";
import HorizontalBarGraph from "../../components/analytics/HorizontalBarGraph";
import QueryComponent from "../../components/analytics/QueryComponent";
import ReactTable from "../../components/analytics/ReactTable";
import { ViewersFilter } from "../../components/analytics/filters";
import { lt_analytics_client_main_v2, quote_string, tableToObjects } from "../../components/analytics/lt_analytics_client";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import { OrgContext } from "../../context/OrgContext";
import GroupVerticalBarGraph from "../../components/analytics/GroupVerticalBarGraph";

const schema = 'VIEWER_INFORMATION';

const ViewerAuditTab: React.FC = () => {
	const { org, api } = React.useContext(OrgContext);

	// Allocate the DuckDB connection
	const [conn, setConn] = React.useState<AsyncDuckDBConnection>();
	// Filter states
	const [dateRange, setDateRange] = React.useState(DateRange.Last_14_Days);

	React.useEffect(() => {
		if (!org) return;
		(async () => {
			try {
				// Clear the old connection
				setConn(undefined);

				// Calculate the filters
				const [start_time, end_time] = range_to_dates(dateRange);

				// Time and execute the query
				const start = performance.now();
				const conn = await lt_analytics_client_main_v2([org.id], null, schema, null, start_time?.toISOString(), end_time?.toISOString());
				const end = performance.now();
				if (end - start > 1000) api?.report_error({
					module: "analytics",
					message: `Slow Analytics Query: ${schema}`,
					duration_ms: end - start,
					level: "warning",
				});

				// Make the data available to components
				setConn(conn);

			} catch (error) {
				api?.report_error({
					module: "analytics",
					user: org.email,
					message: `Error calculating analytics: ${error}`,
					stack: (error as any).stack,
				});
				throw error;
			}
		})();
	}, [org, dateRange]);

	return <>
		<h3>
			Filters:
		</h3>
		<FormControl>
			<DateRangeSelect value={dateRange} onChange={setDateRange} />
		</FormControl>
		{conn && <div>
			<h1>VIEWER INFORMATION</h1>
			<QueryComponent
				conn={conn}
				query={`
				with cte_main as (
					select distinct viewer,
						   substr(first_accessed::text,1,7) as dt
					  from ${schema}.RPT_VIEWER_INFORMATION_VW
				)
				SELECT '# UNIQUE VIEWER' as "group", dt as x, count(distinct(viewer))::int as y
				  from cte_main
				  group by x
				  order by x
				`}
				render={(data) => {
					return <GroupVerticalBarGraph data={data as any} layout={{ xaxis: { title: 'MONTH', type: 'category' }, yaxis: { title: 'COUNT' } }} />
				}}
			/>

			<QueryComponent
				conn={conn}
				query={`
				SELECT
						viewer as "VIEWER"
						,ip as "IP ADDRESS"
						,city as "CITY"
						,state as "STATE"
						,country as "COUNTRY"
					--v.object as "CONTENT FILE",v.sent_date as "DATE SENT",sum(v.cnt) as "OPENS",count(v.page) as "PAGES VIEWED"
					--round(sum(v.duration_seconds)/60.0,2) as "DURATION(MIN)"
					,first_accessed as "FIRST ACCESSED"
					,last_accessed as "LAST ACCESSED"
					from ${schema}.RPT_VIEWER_INFORMATION_VW
				  order by last_accessed desc,viewer
				`}
				render={(data) => {
					return <ReactTable data={data} />;
				}}
			/>
		</div>}
	</>
};

export default ViewerAuditTab;
