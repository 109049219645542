import * as React from 'react';
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import { Page, get_id, get_url } from '../site_map';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	container?: boolean;
}

function CustomTabPanel (props: TabPanelProps) {
	const { children, value, index, container, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			{...other}
		>
			{value === index && (
				// <Box sx={{ p: 3 }}>
				// 	<Typography>{children}</Typography>
				// </Box>
				container
					? <Container sx={{paddingTop: 1}}>{children}</Container>
					: children
			)}
		</div>
	);
}

export default function TabsPage (props: { page: Page, tab?: string, container?: boolean }) {
	const value = Math.max(props.page.tabs!.map(tab => get_id(tab)).indexOf(props.tab ?? get_id(props.page.tabs![0])));

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value}>
					{props.page.tabs!.map((tab, index) => (
						<Tab key={tab.name} icon={tab.icon()} label={tab.name} href={`#${get_url(props.page)}${get_url(tab)}`} />
					))}
				</Tabs>
			</Box>
			{props.page.tabs!.map((tab, index) => (
				<CustomTabPanel key={tab.name} value={value} index={index} container={props.container}>
					{tab.body()}
				</CustomTabPanel>
			))}
		</Box>
	)
}
