import * as react from "react"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"

export enum DateRange {
    All,
    Today,
    Yesterday,
    Last_7_Days,
    Last_14_Days,
    Last_30_Days,
    Last_60_Days,
    Last_90_Days
}

export function range_to_dates(range: DateRange): [Date, Date] {
    const ms_per_day = 1000 * 60 * 60 * 24

    switch (range) {
        case DateRange.All:
            return [new Date(0), new Date()]
        case DateRange.Yesterday:
            const end = new Date
            end.setHours(0, 0, 0, 0)
            const start = new Date(end.getTime() - ms_per_day)
            return [start, end]
    }

    const end = new Date
    let start: Date

    switch (range) {
        case DateRange.Today:
            start = new Date(end.getTime() - ms_per_day)
            break
        case DateRange.Last_7_Days:
            start = new Date(end.getTime() - ms_per_day * 7)
            break
        case DateRange.Last_14_Days:
            start = new Date(end.getTime() - ms_per_day * 14)
            break
        case DateRange.Last_30_Days:
            start = new Date(end.getTime() - ms_per_day * 30)
            break
        case DateRange.Last_60_Days:
            start = new Date(end.getTime() - ms_per_day * 60)
            break
        case DateRange.Last_90_Days:
            start = new Date(end.getTime() - ms_per_day * 90)
            break

    }

    start.setHours(0, 0, 0, 0)

    return [start, end];
}

export type Props = {
    value?: DateRange;
    onChange?: (value: DateRange) => void;
}

export default function DateRangeSelect(props: Props) {
    const { value, onChange } = props
    const options: JSX.Element[] = []
    for (let i = DateRange.All; i <= DateRange.Last_90_Days; i++) {
        options.push(<MenuItem key={i} value={i}>{DateRange[i].replace(/_/g, " ")}</MenuItem>)
    }
    return <Select value={value ?? DateRange.All} onChange={(e) => { onChange?.(typeof e.target.value === "string" ? (DateRange[e.target.value as any] as any) : e.target.value) }}>{options}</Select>
}
