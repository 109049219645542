import { Fragment, useState, useMemo, useEffect, useContext } from "react";
import {
  Button,
  Box,
  TextField,
  Typography,
  Card,
  Modal,
  CircularProgress,
  InputAdornment,
  IconButton,
  InputLabel,
  Stack,
  Autocomplete,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tabs,
  Tab,
  unstable_useId,
  FormGroup,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  JSONFormsContext,
  createBlankForm,
} from "../../context/JSONFormsContext";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";

import ClearFormDialog from "../general/ClearFormDialog";

import CustomSnackbar, { Severity } from "../general/snackbar";

import { FilesContext } from "../../context/FilesContext";
import { IFile, FileContextType } from "../../context/@types/filesContext";

import { DownloadableContext } from "../../context/DownloadableContext";
import { DownloadableContextType } from "../../context/@types/downloadableContext";

import { QueueContext } from "../../context/QueueContext";
import { QueueContextType } from "../../context/@types/queueContext";

import SecurityUISchema from "../../schemas/securityuischema.json";
import schema from "../../schemata/intermediate.schema.json";

import {
  copyToClipboard,
  copy_html_to_clipboard,
  copy_request_html_to_clipboard,
  get_email_html,
  get_email_text,
  get_mailto_link,
  get_request_mailto_link,
  get_upload_link,
  get_view_link,
} from "../../utils/clipBoard";

import AdvancedForm from "./AdvancedForm";
import Downloadable from "../../components/downloadable/Downloadable";
import DateRules from "../daterules";
import LocationRules from "../locationrules/LocationRules";
import Paper from "@mui/material/Paper";
// @ts-ignore
import Grid2 from "@mui/material/Unstable_Grid2";

import { intermediateToEncoder } from "../../utils/manifestTransformers";
import Recipients from "../Recipients";
import Uploaders from "../Uploaders";
import Files from "../files/Files";
import CardContent from "../general/CardContentNoPadding";
import CardHeader from "../general/CardHeaderNoPadding";

import TeraEncoder from "../../encoder/TeraEncoder";
import downloadBlob from "../../utils/downloadBlob";
import Input from "@mui/material/Input";
import {
  Code,
  CompareSharp,
  Drafts,
  Link,
  Rule,
  Send,
  Textsms,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { OrgContext } from "../../context/OrgContext";
import EncoderContext from "../../context/EncoderContext";
import KeyValues from "../keyvalue";
import debounce from "../../utils/debounce";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { call, isOutlook } from "../../utils/office";
import mime from "mime";
import { decode as base64Decode } from "../../utils/base64";
import { idID } from "@mui/material/locale";
import IPAddressRules from "../ipaddressrules/ipaddressrules";
import { v4 as uuid } from "uuid";
import PasswordField from "../password/PasswordField";
import zxcvbn from "../../utils/zxcvbn";
import Manifest from "../../../lib/tera-codec-ts/src/Manifest";
import { useAsyncEffect } from "@react-hook/async";
// import AdvancedSearch from "./AdvancedSearch";

const useStyles = makeStyles({
  Main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    // height: '100%',
    marginBottom: "400px",
  },
  Main2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  FormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  FormContainerDownloadable: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    //margin: "10px 0px",
    //padding: "10px",
  },
  ButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1200px",
    width: "100%",
    marginTop: "8px",
  },
  Center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "200px",
  },
  dataContent: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "0.25em",
    backgroundColor: "#cecece",
    marginBottom: "1rem",
    width: "800px",
  },
  FixedContainer: {
    width: "100%",
    height: "100%",
    position: "fixed",
    // backgroundColor: 'grey',
  },
  ButtonContainer2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1200px",
    width: "100%",
    marginTop: "8px",
    marginBottom: "400px",
  },
  link: {
    width: "75%",
  },
  linkButton: {
    width: "30%",
    height: "56px",
  },
  SwitchContainer: {
    width: "100%",
    padding: "2px 10px",
    // margin: "40px 0px 0px",
    background: "rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export type Props = {
  mode: 'default' | 'large_file' | 'request' | 'fulfill'
}

const EncoderStep = (props: Props) => {
  const { mode = 'default' } = props;

  const classes = useStyles();
  const { org, api } = useContext(OrgContext);

  const { value: orgLimits } = useAsyncEffect(async () => {
    if (!org || !api) return null;
    return await api.get_org_limits(org.root_org_id);
  }, [org, api]);

  const { value: orgConsumption } = useAsyncEffect(async () => {
    if (!org || !api) return null;
    return await api.get_org_consumption(org.root_org_id);
  }, [org, api]);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState<Severity>("success");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [formErrors, setFormErrors] = useState<any>([]);

  const [container_name_error, set_container_name_error] = useState("");
  const [loading, setLoading] = useState(false);

  const [modal_open, set_modal_open] = useState(false);
  const [modal_body, set_modal_text] = useState("");
  const [progress, set_progress] = useState<number | undefined>();
  const [advancedDisabled, setAdvancedDisabled] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  //if uuid for fulfill mode is passed, then it will toggle fulfill mode
  const [fulfillMode, setFulfillMode] = useState(null);
  //const [resultRequest, setResultRequest] = useState<any>();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const {
    form: { form, updateForm },
    tags: { tags, set_tags },
    files: { files, updateFiles },
    downloadable: { downloadable, setDownloadable },
    result,
    set_result,
    request_result,
    set_request_result,
  } = useContext(EncoderContext)!;

  const [showPassword, setShowPassword] = useState(false);

  function getDefaultContainerName(files: { name: string }[]) {
    // toLocaleString('sv') is basically toISOString() but local
    const currentDate = new Date()
      .toLocaleString("sv")
      .replace(" ", "T")
      .slice(0, 16);

    // Strip extensions
    const string = Array.from(
      new Set(files.map((file) => file.name.replace(/\..*?$/, ""))).values()
    ).join(", ");

    return currentDate + " " + string;
  }

  const concatenatedFiles = useMemo(
    () => getDefaultContainerName(files),
    [files]
  );

  useEffect(() => {
    if (!form.recipients) {
      const match = window.location.hash.match(/recipients=([^&]*)/);
      if (match) {
        const recipients = decodeURIComponent(match[1]);
        console.log("found recipients:", recipients);
        updateForm({ ...form, recipients });
      }
    }
  }, []);

  // console.log('form encoder', form);

  // console.log('form:', form, 'files:', files, 'downloadable:', downloadable);

  const { queue, updateQueue } = useContext(QueueContext) as QueueContextType;

  const showSnackbar = (
    severity: Severity,
    message: string,
    ms = severity === "success" ? 3000 : 15000
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);
    setSnackBarOpen(true);
    if (ms > 0) setTimeout(() => setSnackBarOpen(false), ms);
  };

  useEffect(
    debounce(async () => {
      const name = form?.metadata?.fileName;
      if (!name) {
        set_container_name_error("");
        return;
      }

      const containers = await api?.get_containers_by_name(name);
      if (containers.length > 0) {
        set_container_name_error("Container already exists");
      } else {
        set_container_name_error("");
      }
    }, 200),
    [form?.metadata?.fileName, api]
  );

  const zxcvbn_result = useMemo(() => {
    const password = form?.crypto?.passphrase;
    return password ? zxcvbn(password) : null;
  }, [form?.crypto?.passphrase]);

  const handleTagChange = (event: React.ChangeEvent<{}>, value: string[]) => {
    set_tags(value);
  };

  const getRecipients = async () => {
    if (!isOutlook()) {
      return form.recipients;
    } else {
      const to = await call(Office.context.mailbox.item!.to, "getAsync");
      const cc = await call(Office.context.mailbox.item!.cc, "getAsync");
      const bcc = await call(Office.context.mailbox.item!.bcc, "getAsync");
      // console.log('form:', form.recipients, 'to:', to, 'cc:', cc, 'bcc:', bcc);

      return [
        ...(form.recipients ?? []),
        ...[...to, ...cc, ...bcc].map((r) => ({
          name: r.displayName,
          email: r.emailAddress,
        })),
      ];
    }
  };

  const getUploaders = async () => {
    if (!isOutlook()) {
      return form.uploaders;
    } else {
      const to = await call(Office.context.mailbox.item!.to, "getAsync");
      const cc = await call(Office.context.mailbox.item!.cc, "getAsync");
      const bcc = await call(Office.context.mailbox.item!.bcc, "getAsync");
      // console.log('form:', form.recipients, 'to:', to, 'cc:', cc, 'bcc:', bcc);

      return [
        ...(form.uploaders ?? []),
        ...[...to, ...cc, ...bcc].map((r) => ({
          name: r.displayName,
          email: r.emailAddress,
        })),
      ];
    }
  };

  const getFiles = async () => {
    if (!isOutlook()) {
      return files;
    } else {
      const attachmentInfos = await call(
        Office.context.mailbox.item!,
        "getAttachmentsAsync"
      );

      const attachments = await Promise.all(
        attachmentInfos
          .filter((info) => info.attachmentType === "file" && !info.isInline)
          .map(async (info) => {
            const type = mime.getType(info.name) ?? "application/octet-stream";
            const contents = await call(
              Office.context.mailbox.item!,
              "getAttachmentContentAsync",
              info.id
            );
            return {
              name: info.name,
              fileName: info.name,
              type,
              file: new Blob([base64Decode(contents.content)], { type }),
            };
          })
      );

      return [...attachments, ...files];
    }
  };

  const finishOffice = async (metadata: any) => {
    // Insert the HTML into the message body
    const coercionType = await call(
      Office.context.mailbox.item!.body,
      "getTypeAsync"
    );
    const content =
      coercionType === Office.CoercionType.Html
        ? get_email_html(org, metadata)
        : get_email_text(org, metadata);

    await call(
      Office.context.mailbox.item!.body,
      "setSelectedDataAsync",
      content,
      { coercionType }
    );

    // Remove the old attachments
    const attachmentInfos = await call(
      Office.context.mailbox.item!,
      "getAttachmentsAsync"
    );

    await Promise.all(
      attachmentInfos
        .filter((info) => info.attachmentType === "file" && !info.isInline)
        .map(async (info) =>
          call(Office.context.mailbox.item!, "removeAttachmentAsync", info.id)
        )
    );
  };

  const createTera = async () => {
    try {
      if (container_name_error !== "") throw new Error(container_name_error);

      // console.log("createTera form",form);
      setLoading(true);

      const recipients = await getRecipients();
      const files = await getFiles();

      const contentSize = files.reduce((sum, file) => sum + file.file.size, 0);

      if (
        mode !== 'large_file' &&
        orgConsumption.totalContainerSize + contentSize > orgLimits.azure_storage_limit
      ) {
        throw new Error("Total container size limit exceeded");
      }

      if (mode !== 'large_file' && contentSize > orgLimits.container_size_limit) {
        throw new Error("Container size limit exceeded");
      }

      // console.log('recipients:', recipients);
      // console.log('files:', files);

      if (!files.length) {
        throw new Error("Required Field. Please add Files to secure.");
      }

      // if (zxcvbn_result != null && zxcvbn_result.score < 1) {
      //   throw new Error('Please use a more secure encryption password.');
      // }

      // convert manifest
      const formCopy = structuredClone(form);
      (formCopy.metadata ||= {}).orgId = org?.id;
      formCopy.metadata.auth = `${process.env.REACT_APP_AUTH_URL}/auth`;
      formCopy.metadata.uuid = uuid();
      formCopy.metadata.fileName ||= getDefaultContainerName(files);
      formCopy.sender = org?.email;
      formCopy.recipients = recipients;
      formCopy.downloadable = downloadable;
      (formCopy.files ||= {}).parts = files;
      console.log("form:", formCopy);

      let checkedForm = intermediateToEncoder(formCopy);
      console.log("manifest:", checkedForm);
      const newRecipients = checkedForm.container.recipients;

      if (!newRecipients?.length) {
        throw new Error("Required field. Please add Authorized viewers.");
      }

      const customizations = await api!.get_org_customizations();
      if (
        customizations?.user?.add_receipients_to_address_book ??
        customizations?.defaults?.add_receipients_to_address_book
      ) {
        const save_promises =
          recipients
            .filter((r: any) => r.email && r.email != "*@*")
            .map((r: any) => api!.save_address(r.email)) ?? [];
        await Promise.all(save_promises);
      }

      const playableRegex =
        /image|audio|video|sqlite|html|text|pdf|openxmlformats/;
      for (const [name, part] of Object.entries(checkedForm.files)) {
        if (!playableRegex.test((part as any).type) && !downloadable) {
          throw new Error(
            `This file type is not supported for viewing. Please make files downloadable.`
          );
        }
      }

      if (
        form.keyValues?.some(
          (kv: any) => (!kv.key && kv.value) || (kv.required && !kv.value)
        )
      ) {
        throw new Error("Please check the references");
      }

      console.log("manifest:", checkedForm);

      set_modal_text("Creating attachment");
      set_modal_open(true);

      // encode file
      const encoder = new TeraEncoder();
      (encoder as any).on_message = (message: string) =>
        set_modal_text(message);
      (encoder as any).on_progress = ({
        loaded,
        total,
      }: {
        loaded: number;
        total: number;
      }) =>
        set_progress(
          !total || loaded == null ? undefined : (100 * loaded) / total
        );

      const metadata = (await (encoder as any).encode(checkedForm)) as Manifest;
      console.log("encode result:", metadata);

      const encodedFiles = Object.values(metadata.files);
      await api?.addEvent(metadata.container.uuid, "container.create", {
        name: metadata.container.name,
        recipients: metadata.container.recipients,
        objects: encodedFiles.map((file) => file.name),
        contentTypes: encodedFiles.map((file) => file.type),
        durations: encodedFiles.map((file) => file.duration ?? 0),
        pages: encodedFiles.map((file) => file.pages ?? 1),
        digests: encodedFiles.map((file) => file.digest),
        downloadable,
        timeRange: metadata.container?.drm?.time,
        opens: metadata.container?.drm?.opens,
      });

      if (tags) {
        set_modal_text("Adding tags");
        await api?.add_container_tags(metadata.container.uuid, tags);
      }

      if (isOutlook()) {
        await finishOffice(metadata);
      }
      set_result(metadata);

      set_modal_open(false);
      if (isOutlook()) {
        setSnackBarOpen(false);
      } else {
        showSnackbar("success", "Attachment created");
      }

      updateForm(createBlankForm());
      updateFiles([]);
    } catch (error) {
      set_modal_open(false);
      showSnackbar("error", `Tera File Creation Failed: ${error}`);
      console.log("createTera error:", error);
      api?.report_error({
        module: "encoder",
        message: `Error encoding: ${error}`,
        stack: (error as any).stack,
      });
    } finally {
      setLoading(false);
    }
  };

  const createRequest = async () => {
    // try {
    //   setLoading(true);
    //   // const recipients = await getRecipients();
    //   // const uploaders = await getUploaders();
    //   const formCopy = copy(form);
    //   //(formCopy.metadata ||= {}).orgId = org?.id;
    //   console.log("FORM: ", formCopy);
    //   const request_data = {
    //     uuid: uuid(),
    //     org_id: org.id,
    //     fileName: formCopy.metadata.fileName,
    //     recipients: formCopy.recipients?.filter((rule: any) => rule.email),
    //     tags,
    //     downloadable,
    //     password: formCopy.crypto?.passphrase,
    //     ipAddressList: formCopy.drmRules?.ip?.filter((rule: any) => rule.value),
    //     locationRules: formCopy.drmRules?.geo?.filter((rule: any) => rule.value?.country || rule.value?.subdivision || rule.value?.city),
    //     timeRange: formCopy.drmRules?.time,
    //     references: formCopy.keyValues,
    //     uploaders: formCopy.uploaders?.filter((rule: any) => rule.email),
    //   };
    //   api?.create_request(request_data);
    //   set_request_result(request_data);
    // } catch (error) {
    //   set_modal_open(false);
    //   showSnackbar("error", `Tera File Request Failed: ${error}`);
    //   console.log("createRequest error:", error);
    //   api?.report_error({
    //     module: "CreateRequest",
    //     message: `Error creating request: ${error}`,
    //     stack: (error as any).stack,
    //   });
    // } finally {
    //   clean();
    //   setLoading(false);
    // }
  };

  const [allTags, setAllTags] = useState<string[]>([]);

  const fetchTags = async () => {
    try {
      const tagsData = await api!.get_all_container_tags();
      setAllTags(tagsData);
      //console.log("ALL TAGS", tagsData);
    } catch (error) {
      //TODO add snack bar
      console.error("Error fetching tags:", error);
    }
  };

  // useEffect(() => {
  //   if (!api) return;
  //   (async () => {
  //     const requestData = await api.get_request("40c13015-8fee-4d03-b160-e91d8813300e", "1a62bf72-6537-40ba-b4f4-e93ab1b2218")
  //     console.log("Form: ", form);
  //     console.log("RequestData: ", requestData);

  //     const formCopy  = copy(form);
  //     formCopy.metadata.fileName = requestData.fileName;
  //     formCopy.recipients = requestData.recipients;
  //     (formCopy.crypto ||= {}).passphrase = requestData.password;
  //     (formCopy.drmRules ||= {}).ip = requestData.ipAddressList;
  //     formCopy.drmRules.geo = requestData.locationRules;
  //     formCopy.drmRules.time = requestData.timeRange;
  //     formCopy.keyValues = requestData.references;
  //     formCopy.uploaders = requestData.uploaders;
  //     updateForm(formCopy);
  //     set_tags(requestData.tags);
  //     setDownloadable(requestData.downloadable);

  //     console.log("FormCopy", formCopy);

  //     setFulfillMode(requestData);
  //   })();
  //   fetchTags();
  // }, [api]);

  const [hideRequestTab, setHideRequestTab] = useState(true);

  return (
    <>
      {!result && !request_result && (
        <Grid2
          container
          spacing={1}
          sx={{ maxWidth: "1080px", margin: "auto" }}
        >
          {/* {!fulfillMode && (
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="icon label tabs example"
            >
              <Tab label="Secure" />
              <Tab
                label="Request"
                sx={{ display: hideRequestTab ? "none" : "block" }}
              />
              <Tab label="Large File" />
            </Tabs>
          )} */}

          <Grid2 xs={12}>
            <Paper
              sx={{
                bgcolor: "#444",
                color: "#fff",
                width: "100%",
                padding: "0.25em 1em",
                fontSize: "1.4em",
              }}
              elevation={2}
            >
              ADD CONTENT FILES
            </Paper>
          </Grid2>
          {mode === 'request' ? (
            <></>
          ) : (
            <Grid2 xs={12}>
              {!result && !isOutlook() ? (
                <Files limit={orgLimits?.container_size_limit}/>
              ) : (
                <Accordion sx={{ width: "100%", backgroundColor: "#E0E0E0" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    // aria-controls="panel1a-content"
                    // id="panel1a-header"
                  >
                    <Typography variant="h5">Oversize Files</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Files limit={orgLimits?.container_size_limit}/>
                  </AccordionDetails>
                </Accordion>
              )}
            </Grid2>
          )}

          {fulfillMode ? (
            <></>
          ) : (
            <>
              <Grid2 xs={12} sm={6}>
                <Downloadable />
              </Grid2>
              <Grid2 xs={12} sm={6}>
                {/* <Card
                  sx={{
                    borderColor: "#2B5592",
                    borderWidth: "2px",
                    borderStyle: "solid",
                  }}
                > */}
                {/* <CardHeader title="Encryption Password" /> */}
                {/* <CardContent className={classes.FormContainerDownloadable}> */}
                  <FormGroup className={classes.SwitchContainer}>
                    {/* <InputLabel htmlFor="standard-adornment-password">
                      Password
                    </InputLabel> */}
                    <PasswordField
                      id="standard-adornment-password"
                      variant="standard"
                      placeholder="Password"
                      style={{ width: "100%" }}
                      value={form.crypto?.passphrase ?? ""}
                      onInput={(e) =>
                        updateForm({
                          ...form,
                          crypto: {
                            ...form.crypto,
                            passphrase: (e.target as HTMLInputElement).value,
                          },
                        })
                      }
                      error={zxcvbn_result != null && zxcvbn_result.score < 3}
                      helperText={
                        zxcvbn_result
                          ? `Strength: ${
                              zxcvbn_result.score * 25
                            }%, time to crack: ${
                              zxcvbn_result.crackTimesDisplay
                                .offlineSlowHashing1e4PerSecond
                            }${
                              zxcvbn_result.feedback.warning
                                ? `. ${zxcvbn_result.feedback.warning}`
                                : ""
                            }`
                          : null
                      }
                    />
                  </FormGroup>
                {/* </CardContent> */}
                {/* </Card> */}
              </Grid2>
              {/* <Grid2 xs={12}>
                <Accordion
                  sx={{ width: "100%", backgroundColor: "#E0E0E0" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h5">References</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <KeyValues />
                  </AccordionDetails>
                </Accordion>
              </Grid2> */}
              {/* <Grid2 xs={12}>
                <Card
                  style={{
                    width: "100%",
                    marginBottom: "8px",
                    backgroundColor: "#E0E0E0",
                  }}
                >
                  <CardHeader title="Secure Container Name" />
                  <CardContent>
                    <TextField
                      variant="standard"
                      style={{ width: "100%", color: "#64BBE3" }}
                      value={form.metadata?.fileName ?? ""}
                      error={container_name_error !== ""}
                      helperText={container_name_error}
                      placeholder={concatenatedFiles}
                      onInput={(e) =>
                        updateForm({
                        ...form,
                        metadata: {
                          ...form.metadata,
                          fileName: (e.target as HTMLInputElement).value,
                        },
                        })
                      }
                    ></TextField>
                  </CardContent>
                </Card>
                <Card
                  style={{
                    width: "100%",
                    marginBottom: "8px",
                    backgroundColor: "#E0E0E0",
                  }}
                >
                  <Stack>
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={allTags}
                      value={tags}
                      onChange={handleTagChange}
                      freeSolo
                      sx={{ padding: "0 16px" }}
                      renderTags={(value: readonly string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                        <Chip
                          variant="outlined"
                          label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          style={{ width: "100%", color: "#64BBE3" }}
                          {...params}
                          variant="standard"
                          label="Tags"
                          placeholder="Tags"
                        />
                      )}
                    />
                  </Stack>
                </Card>
              </Grid2> */}
              {/* </Box> */}
              <Grid2 xs={12}>
                <Paper
                  sx={{
                    bgcolor: "#444",
                    color: "#fff",
                    width: "100%",
                    padding: "0.25em 1em",
                    fontSize: "1.4em",
                  }}
                  elevation={2}
                >
                  VIEWER RULES
                </Paper>
              </Grid2>
              <Grid2 xs={12}>
                {!result && (
                  <Recipients
                    backgroundColor={mode === 'large_file' ? "#6CC04A" : "#C4D6ED"}
                  />
                )}
              </Grid2>
              {mode === 'request' ? (
                <Grid2 xs={12}>{!result && <Uploaders />}</Grid2>
              ) : (
                <></>
              )}
              <Grid2 xs={12}>
                <DateRules />
              </Grid2>
              {/* <LocationRules /> */}
              <AdvancedForm />
              {/* <AdvancedSearch/> */}
              <Accordion
                disabled={advancedDisabled}
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  marginTop: "10px",
                  backgroundColor: "#E0E0E0",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h5">Advanced Search</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid2 xs={12}>
                    <Accordion
                      sx={{
                        width: "100%",
                        borderColor: "#2B5592",
                        borderWidth: "2px",
                        borderStyle: "solid",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">References</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <KeyValues />
                      </AccordionDetails>
                    </Accordion>
                  </Grid2>
                  <Grid2 xs={12}>
                    <Card
                      style={{
                        width: "100%",
                        marginBottom: "8px",
                        borderColor: "#2B5592",
                        borderWidth: "2px",
                        borderStyle: "solid",
                      }}
                    >
                      <CardHeader title="Secure Container Name" />
                      <CardContent>
                        <TextField
                          variant="standard"
                          style={{ width: "100%", color: "#64BBE3" }}
                          value={form.metadata?.fileName ?? ""}
                          error={container_name_error !== ""}
                          helperText={container_name_error}
                          placeholder={concatenatedFiles}
                          onInput={(e) =>
                            updateForm({
                              ...form,
                              metadata: {
                                ...form.metadata,
                                fileName: (e.target as HTMLInputElement).value,
                              },
                            })
                          }
                        ></TextField>
                      </CardContent>
                    </Card>
                    <Card
                      style={{
                        width: "100%",
                        marginBottom: "8px",
                        borderColor: "#2B5592",
                        borderWidth: "2px",
                        borderStyle: "solid",
                      }}
                    >
                      <Stack>
                        <Autocomplete
                          multiple
                          id="tags-filled"
                          options={allTags}
                          value={tags}
                          onChange={handleTagChange}
                          freeSolo
                          sx={{ padding: "0 16px" }}
                          renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                              <Chip
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              style={{ width: "100%", color: "#64BBE3" }}
                              {...params}
                              variant="standard"
                              label="Tags"
                              placeholder="Tags"
                            />
                          )}
                        />
                      </Stack>
                    </Card>
                  </Grid2>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </Grid2>
      )}
      <Grid2 container spacing={1} sx={{ maxWidth: "1080px", margin: "auto" }}>
        <Box className={classes.Main}>
          {result && (
            <>
              <Typography variant="h6">
                Your attachments have been secured.
              </Typography>

              {!isOutlook() && (
                <Box className={classes.ButtonContainer}>
                  <Button
                    variant="contained"
                    className={classes.linkButton}
                    href={get_mailto_link(org, result)}
                    endIcon={<Send />}
                  >
                    SEND BY DEFAULT EMAIL
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.linkButton}
                    onClick={(event) => {
                      event.preventDefault();
                      try {
                        copy_html_to_clipboard(org, result);
                        showSnackbar("success", "Copied HTML to clipboard.");
                      } catch (err) {
                        showSnackbar(
                          "error",
                          `Error copying HTML to clipboard: ${err}`
                        );
                      }
                    }}
                    endIcon={<Drafts />}
                  >
                    SECURE FILES COPY/PASTE IN EMAIL
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.linkButton}
                    onClick={(event) => {
                      event.preventDefault();
                      try {
                        copyToClipboard(get_view_link(result));
                        showSnackbar("success", "Copied link to clipboard.");
                      } catch (err) {
                        showSnackbar(
                          "error",
                          `Error copying link to clipboard: ${err}`
                        );
                      }
                    }}
                    endIcon={<Textsms />}
                  >
                    SECURE MESSAGE COPY/PASTE
                  </Button>
                </Box>
              )}
            </>
          )}
          {request_result && (
            <>
              <Typography variant="h6">
                Your request has been secured.
              </Typography>

              {!isOutlook() && (
                <Box className={classes.ButtonContainer}>
                  <Button
                    variant="contained"
                    className={classes.linkButton}
                    href={get_request_mailto_link(org, request_result)}
                    endIcon={<Send />}
                  >
                    SEND BY DEFAULT EMAIL
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.linkButton}
                    onClick={(event) => {
                      event.preventDefault();
                      try {
                        copy_request_html_to_clipboard(org, request_result);
                        showSnackbar("success", "Copied HTML to clipboard.");
                      } catch (err) {
                        showSnackbar(
                          "error",
                          `Error copying HTML to clipboard: ${err}`
                        );
                      }
                    }}
                    endIcon={<Drafts />}
                  >
                    SECURE FILES COPY/PASTE IN EMAIL
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.linkButton}
                    onClick={(event) => {
                      event.preventDefault();
                      try {
                        copyToClipboard(get_upload_link(request_result));
                        showSnackbar("success", "Copied link to clipboard.");
                      } catch (err) {
                        showSnackbar(
                          "error",
                          `Error copying link to clipboard: ${err}`
                        );
                      }
                    }}
                    endIcon={<Textsms />}
                  >
                    SECURE MESSAGE COPY/PASTE
                  </Button>
                </Box>
              )}
            </>
          )}

          {mode === 'request' ? (
            <Box className={classes.ButtonContainer}>
              {((!result && !request_result) || !isOutlook()) && (
                <ClearFormDialog />
              )}
              {!result && !request_result && (
                <Button
                  variant="contained"
                  disabled={
                    form &&
                    form.metadata &&
                    // form.metadata.fileName &&
                    formErrors.length === 0 &&
                    loading === false
                      ? false
                      : true
                  }
                  sx={{
                    padding: "14px",
                    width: "100%",
                    // borderRadius: "10px",
                    backgroundColor: "#2B5592",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    createRequest();
                  }}
                >
                  {loading === false
                    ? "CREATE REQUEST LINK"
                    : "CREATING REQUEST LINK..."}
                </Button>
              )}
            </Box>
          ) : mode === 'large_file' ? (
            <Box className={classes.ButtonContainer}>
              {(!result || !isOutlook()) && <ClearFormDialog />}
              {!result && (
                <Button
                  variant="contained"
                  disabled={
                    form &&
                    form.metadata &&
                    // form.metadata.fileName &&
                    (isOutlook() ||
                      (form.files &&
                        form.files.parts &&
                        form.files.parts.length > 0 &&
                        form.files.parts[0].name)) &&
                    formErrors.length === 0 &&
                    loading === false
                      ? false
                      : true
                  }
                  sx={{
                    padding: "14px",
                    width: "100%",
                    // borderRadius: "10px",
                    backgroundColor: "#2B5592",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    createTera();
                  }}
                >
                  {loading === false
                    ? "CREATE SECURE LINK"
                    : "CREATING SECURE LINK..."}
                </Button>
              )}
            </Box>
          ) : (
            <Box className={classes.ButtonContainer}>
              {(!result || !isOutlook()) && <ClearFormDialog />}
              {!result && (
                <Button
                  variant="contained"
                  disabled={
                    form &&
                    form.metadata &&
                    // form.metadata.fileName &&
                    (isOutlook() ||
                      (form.files &&
                        form.files.parts &&
                        form.files.parts.length > 0 &&
                        form.files.parts[0].name)) &&
                    formErrors.length === 0 &&
                    loading === false
                      ? false
                      : true
                  }
                  sx={{
                    padding: "14px",
                    width: "100%",
                    // borderRadius: "10px",
                    backgroundColor: "#2B5592",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    createTera();
                  }}
                >
                  {loading === false
                    ? "SECURE FILES"
                    : "CREATING SECURE FILES..."}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Grid2>
      <CustomSnackbar
        severity={snackBarSeverity}
        message={snackBarMessage}
        state={snackBarOpen}
        changeState={setSnackBarOpen}
      />
      <Modal open={modal_open}>
        <Box sx={modalStyle}>
          <h2>Creating Secure Attachment</h2>
          <p>{modal_body}</p>
          <div>
            {progress == null ? (
              <CircularProgress variant="indeterminate" />
            ) : (
              <CircularProgressWithLabel value={progress} />
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default EncoderStep;
