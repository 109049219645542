import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommon from '@zxcvbn-ts/language-common';
import * as zxcvbnEn from '@zxcvbn-ts/language-en';

zxcvbnOptions.setOptions({
	translations: zxcvbnEn.translations,
	graphs: zxcvbnCommon.adjacencyGraphs,
	dictionary: {
		...zxcvbnCommon.dictionary,
		...zxcvbnEn.dictionary,
	}
});

export default zxcvbn;
