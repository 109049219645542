import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  deleteFile: () => void;
}

export default function ClearFormDialog(props: Props) {
  const { deleteFile } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: '100%', marginRight: '8px' }}>
      <MenuItem key='unblock' onClick={handleClickOpen}>
        Delete
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='clear-all-fields-title'
        aria-describedby='clear-all-fields-description'>
        <DialogTitle id='clear-all-fields-title'>Delete File</DialogTitle>
        <DialogContent>
          <DialogContentText id='clear-all-fields-description'>
            Are you sure you want to delete this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            sx={{ width: '100%' }}
            onClick={handleClose}
            autoFocus>
            Close
          </Button>
          <Button
            variant='contained'
            color='error'
            sx={{ width: '100%' }}
            onClick={(event) => {
              event.preventDefault();
              deleteFile();
              handleClose();
            }}
            autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
