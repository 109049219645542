import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Switch } from "@mui/material";

export type MultiSelectProps = {
	label: string,
	options: string[],
	values: string[],
	set_values: (values: string[]) => void,
}

export const MultiSelect: React.FC<MultiSelectProps> = ({ label, options, values, set_values }) => {
	function renderValue (values: string[]) {
		return values.length === 1
			? values[0]
			: values.length === options.length
				? 'All'
				: `${values[0]} + ${values.length - 1} more`;
	}

	function onChange (e: SelectChangeEvent<string[]>) {
		const new_values = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
		if (new_values.includes('all')) {
			if (values.length !== options.length) {
				set_values(options);
			} else {
				set_values([]);
			}
		} else {
			set_values(new_values);
		}
	}

	return (
		<FormControl>
			<InputLabel>{label}:</InputLabel>
			<Select
				multiple
				value={values}
				label={label}
				sx={{ width: 200 }}
				renderValue={renderValue}
				onChange={onChange}
			>
				<MenuItem key='all' value='all'>
					<Checkbox checked={values.length === options.length} />
					<ListItemText primary='All' />
				</MenuItem>
				{options.map(value => <MenuItem key={value} value={value}>
					<Checkbox checked={values.includes(value)} />
					<ListItemText primary={value} />
				</MenuItem>)}
			</Select>
		</FormControl>
	);
}

export const ContentFilesFilter: React.FC<Omit<MultiSelectProps, 'label'>> = (props) => {
	return (
		<MultiSelect label='Content Files' {...props} />
	);
}

export const ViewersFilter: React.FC<Omit<MultiSelectProps, 'label'>> = (props) => {
	return (
		<MultiSelect label='Viewers' {...props} />
	)
}

export const UnopenedSwitch: React.FC<{ value: boolean, set_value: (value: boolean) => void }> = ({ value, set_value }) => {
	const label = value ? 'Unopened' : 'All';

	return (
		<FormGroup>
			<FormControlLabel control={<Switch onChange={(e) => set_value(e.target.checked)} />} label={label} />
		</FormGroup>
	)
}