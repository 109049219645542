import * as React from 'react';

import { FileContextType, IFile } from './@types/filesContext';

export const FilesContext = React.createContext<FileContextType | null>(null);

const FilesProvider: React.FC<any> = ({ children }) => {
  const [files, setFiles] = React.useState<IFile[]>([]);

  const updateFiles = (files: IFile[]) => {
    setFiles(files);
  };

  return (
    <FilesContext.Provider value={{ files, updateFiles }}>
      {children}
    </FilesContext.Provider>
  );
};

export default FilesProvider;
