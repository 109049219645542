import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

export default (props: TextFieldProps) => {
	const [show_password, set_show_password] = React.useState(false);

	return <TextField
		{...props}
		type={show_password ? 'text' : 'password'}
		InputProps={{
			endAdornment: <InputAdornment position='end'>
				<IconButton onClick={() => set_show_password(!show_password)}>
					{show_password ? <VisibilityOff /> : <Visibility />}
				</IconButton>
			</InputAdornment>
		}}
	/>
};
