import { Card, CardContent, CardHeader, FormControlLabel, FormGroup, Switch, Checkbox, TextField, Button, MenuItem, Select, Container } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import React, { Fragment } from "react";
import { OrgContext } from "../../context/OrgContext";
import CustomSnackbar, { Severity } from "../../components/general/snackbar";
import EnableDialog from "../../components/2FA/EnableDialog";
import DisableDialog from "../../components/2FA/DisableDialog";

const AddressBookUpdates: React.FC = () => {
    const { org, api } = React.useContext(OrgContext);


    const [customizations, set_customizations] = React.useState<any>(null)

    async function load_customizations() {
        if (!api) return;
        set_customizations(await api.get_org_customizations())
    }

    async function save_customizations() {
        try {
            if (customizations.root) await api?.set_org_customizations(customizations.root, org.root_org_id)
            if (customizations.user) await api?.set_org_customizations(customizations.user, org.id)

            show_snack_bar("success", "Configuration Saved")
        } catch (error) {
            show_snack_bar("error", `Error Saving Configuration: ${error}`)
        }
    }

    function change_customizations(level: string, property: string, value: any) {
        const new_customizations = structuredClone(customizations)
        if (!new_customizations[level]) new_customizations[level] = {}
        new_customizations[level][property] = value
        set_customizations(new_customizations)
        return new_customizations
    }

    React.useEffect(() => {
        load_customizations()
    }, [org])

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarSeverity, setSnackBarSeverity] =
        React.useState<Severity>("success");
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    function show_snack_bar(severity: Severity, message: string) {
        setSnackBarSeverity(severity);
        setSnackBarMessage(message);
        setSnackBarOpen(true);
    }

    return <>
    <Container disableGutters>

        <h2 style={{ color: "#2B5592" }}>Address Book Updates</h2>

        <h3 style={{ color: "black" }}><InfoIcon style={{ color: "#64BBE3" }} /> &nbsp; Automatically add all Authorized Viewer’s email address to Address Book.​</h3>

        <Card
            sx={{
                borderColor: "#2B5592",
                borderWidth: "2px",
                borderStyle: "solid",
                width: "100%",
                marginBottom: 1,
            }}
        >
            <CardHeader title="Address Book" />
            <CardContent>
                <FormGroup >
                    <FormControlLabel
                        control={
                            <Switch
                                inputProps={{ "aria-label": "controlled" }}
                                checked={!!(customizations?.user?.add_receipients_to_address_book ?? customizations?.defaults?.add_receipients_to_address_book)}
                                onChange={(e) => {
                                    //update_customizations({ ...customizations, enable_ms_auth: e.target.checked }, org!.root_org_id)
                                    change_customizations("user", "add_receipients_to_address_book", e.target.checked)
                                }}
                            />
                        }
                        label="Add Authorized Viewer's Email Address To Address Book"
                    />
                </FormGroup>
            </CardContent>
        </Card>
        <Button variant="outlined" onClick={(e) => { load_customizations() }} sx={{ width: "10%", marginRight: 2 }}>
            Cancel
        </Button>
        <Button variant="contained" onClick={(e) => { save_customizations() }} sx={{ width: "10%", marginRight: 2 }}>
            Save
        </Button>


        <CustomSnackbar
            severity={snackBarSeverity}
            message={snackBarMessage}
            state={snackBarOpen}
            changeState={setSnackBarOpen}
        />
        </Container>

        <p style={{fontSize: '16px'}}>To Enable The Automatic Addition of Authorized Viewer's to Address Books:</p>
        <ol style={{fontSize: '16px'}}>
        <li>Toggle switch on.</li>
        <li>Click “Save”.</li>
        </ol>

        <p style={{fontSize: '16px'}}>Email addresses added as authorized viewers will be automatically added to the address book</p>

        <p style={{fontSize: '16px'}}>To Disable The Automatic Addition of Authorized Viewer's to Address Books:</p>
        <ol style={{fontSize: '16px'}}>
        <li>Click “DISABLE 2FA” above.</li>
        <li>Toggle switch off.</li>
        <li>Click “Save”.</li>
        </ol>

    </>
}

export default AddressBookUpdates
