import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { FormControl } from "@mui/material";
import React from "react";
import QueryComponent from "../../components/analytics/QueryComponent";
import ReactTable, { valueFormatter } from "../../components/analytics/ReactTable";
import { lt_analytics_client_main_v2 } from "../../components/analytics/lt_analytics_client";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import { OrgContext } from "../../context/OrgContext";
import GroupVerticalBarGraph from "../../components/analytics/GroupVerticalBarGraph";
import DataGrid from "../../components/DataGrid";
import { GridColDef } from "@mui/x-data-grid";
import AnalyticsButton from "../../components/FilesList/AnalyticsButton";

const schema = 'SENT_NOT_ACCESSED';

const ViewerAuditTab: React.FC = () => {
	const { org, api } = React.useContext(OrgContext);

	// Allocate the DuckDB connection
	const [conn, setConn] = React.useState<AsyncDuckDBConnection>();
	// Filter states
	const [dateRange, setDateRange] = React.useState(DateRange.Last_14_Days);

	React.useEffect(() => {
		if (!org) return;
		(async () => {
			try {
				// Clear the old connection
				setConn(undefined);

				// Calculate the filters
				const [start_time, end_time] = range_to_dates(dateRange);

				// Time and execute the query
				const start = performance.now();
				const conn = await lt_analytics_client_main_v2([org.id], null, schema, null, start_time?.toISOString(), end_time?.toISOString());
				const end = performance.now();
				if (end - start > 1000) api?.report_error({
					module: "analytics",
					message: `Slow Analytics Query: ${schema}`,
					duration_ms: end - start,
					level: "warning",
				});

				// Make the data available to components
				setConn(conn);

			} catch (error) {
				api?.report_error({
					module: "analytics",
					user: org.email,
					message: `Error calculating analytics: ${error}`,
					stack: (error as any).stack,
				});
				throw error;
			}
		})();
	}, [org, dateRange]);

	return <>
		<h3>
			Filters:
		</h3>
		<FormControl>
			<DateRangeSelect value={dateRange} onChange={setDateRange} />
		</FormControl>
		{conn && <div>
			<h1>SENT NOT ACCESSED</h1>
			<QueryComponent
				conn={conn}
				query={`
					with cte_main as (
					   select
						substr(q.sent_date::text,1,7) as dt
					   ,count(distinct(q.viewer)) as viewer
					   ,count(distinct(q.uuid)) as containers
					   from ${schema}.RPT_SENT_NOT_ACCESSED_VW q
					  group by substr(q.sent_date::text,1,7)
					  ), cte_final as (
					  select '# Authorized viewers did not access' as "LABEL", dt as "DATE", viewer as "VALUE" from cte_main
					  union all
					  select '# Containers not accessed​' as "LABEL", dt as "DATE", containers as "VALUE" from cte_main
					  )
					  select "LABEL" as "group", "DATE" as x, "VALUE"::int as y from cte_final order by "LABEL", "DATE"
					  `}
				render={(data) => {
					return <GroupVerticalBarGraph data={data as any} layout={{ xaxis: { title: 'MONTH', type: 'category' }, yaxis: { title: 'COUNT' } }} />
				}}
			/>

			<QueryComponent
				conn={conn}
				query={`
				select
				q.viewer as "VIEWER"
			   ,q.container_name as "CONTAINER NAME"
			   ,q.object as "CONTENT FILE"
			   ,q.status as "STATUS"
			   ,q.sent_date as "DATE SENT"
				 ,q.uuid
			   from ${schema}.RPT_SENT_NOT_ACCESSED_VW q
			  	order by q.sent_date desc, q.container_name, q.object, q.viewer
				`}
				render={(data) => {
					const columns: GridColDef[] = [
						{
							headerName: 'VIEWER',
							field: 'VIEWER',
							valueFormatter,
							width: 150,
						},
						{
							headerName: 'CONTAINER NAME',
							field: 'CONTAINER NAME',
							valueFormatter,
							width: 150,
						},
						{
							headerName: 'CONTENT FILE',
							field: 'CONTENT FILE',
							valueFormatter,
							width: 150,
						},
						{
							headerName: 'STATUS',
							field: 'STATUS',
							valueFormatter,
							width: 150,
						},
						{
							headerName: 'DATE SENT',
							field: 'DATE SENT',
							valueFormatter,
							width: 150,
						},
						{
							field: "analytics",
							headerName: "ANALYTICS",
							width: 100,
							disableExport: true,
							renderCell: (cellValues: any) => {
								return <AnalyticsButton file={cellValues.row} />;
							},
						},
					];

					const rows = data.map((item, index) => ({
						id: index + 1,
						...item,
					}));

					return <DataGrid columns={columns} rows={rows} />
				}}
			/>
		</div>}
	</>
};

export default ViewerAuditTab;
