import * as React from "react";
import { Fragment, useState, useEffect, useContext, useMemo } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import FilesList from "../components/FilesList";
import SearchPanel from "../components/SearchPanel";
import { OrgContext } from "../context/OrgContext";
import Grid2 from "@mui/material/Unstable_Grid2";
import RecoverFiles from "../components/RecoverFiles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
} from "@mui/material";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
});

interface Props {}

const FilesPage = (props: Props) => {
  const classes = useStyles();
  const { api } = useContext(OrgContext);
  const [files, setFiles] = useState([]);
  const [fileFilter, setFileFilter] = useState<(file: any) => boolean>(
    () => (file: any) => true
  );
  const filteredFiles = useMemo(() => {
    // console.log("Files Filter: ", fileFilter);
    return files.filter(fileFilter);
  }, [files, fileFilter]);

  function loadFiles() {
    (async () => {
      const files = await api?.getFiles();
      files?.sort((a: any, b: any) =>
        b.created_date.localeCompare(a.created_date)
      );
      setFiles(files ?? []);
    })();
  }

  const [allTags, setAllTags] = React.useState<string[]>([]);
  const [allKeys, setAllKeys] = React.useState<string[]>([]);

  const fetchTags = async () => {
    try {
      const tagsData = await api!.get_all_container_tags();
      setAllTags(tagsData);
      //console.log("ALL TAGS", tagsData);
    } catch (error) {
      //TODO add snack bar
      console.error("Error fetching tags:", error);
    }
  };

  const fetchKeys = async () => {
    try {
      const keysData = await api!.get_all_container_keys();
      setAllKeys(keysData);
      //console.log("ALL KEYS", keysData);
    } catch (error) {
      //TODO add snack bar
      console.error("Error fetching keys:", error);
    }
  };

  const refreshUI = async () => {
    await Promise.all([loadFiles(), fetchTags(), fetchKeys()]);
  };

  React.useEffect(() => {
    if (!api) return;
    refreshUI();
  }, [api]);

  return (
    <Container disableGutters>
    <Box className={classes.main}>

          <RecoverFiles refreshUI={refreshUI}/>
        
        {/* {display: "flex", flexDirection: "column", justifyContent: "center"} */}
        
          {/* <SearchPanel
            setFileFilter={setFileFilter}
            tags={allTags}
            keys={allKeys}
          /> */}

      <FilesList files={filteredFiles} refreshUI={refreshUI} tags={allTags} />
    </Box>
    </Container>
  );
};

export default FilesPage;
