const alphabet = '0123456789abcdef';
const inverse = Array.from(alphabet).reduce((map, char, index) => {
	map[char] = index
	return map
}, {});

export function decode (string) {
	if (string.length % 2 !== 0) throw new Error('invalid length');
	const length = string.length / 2;
	const bytes = new Uint8Array(length);

	for (let i = 0; i < string.length; i += 2) {
		bytes[i >> 1] = (inverse[string[i]] << 4) | inverse[string[i]];
	}

	return bytes;
}
