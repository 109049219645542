import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
import { Fragment, useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { JSONFormsContext } from "../../context/JSONFormsContext";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";
import CardContent from "../general/CardContentNoPadding";
import CardHeader from "../general/CardHeaderNoPadding";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { OrgContext } from "../../context/OrgContext";
import Grid2 from "@mui/material/Unstable_Grid2";

const useStyles = makeStyles({
  FormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
    marginTop: "10px",
  },
  Main2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
});

type KeyValue = {
  id: string;
  key?: string;
  value?: string;
  required?: string;
};

const KeyValues = () => {
  const { org, api } = useContext(OrgContext);
  const [allKeys, setAllKeys] = useState<string[]>([]);
  const [customizations, setCustomizations] = useState<any>();

  const { form, updateForm } = useContext(
    JSONFormsContext
  ) as JSONFormsContextType;

  async function get_default_rows(){
    if(form.keyValues) return
    console.log(customizations)
    const rows = []
    //Where we generate the rows if required
    if (customizations.root?.custom_reference_1_required) rows.push({ id: crypto.randomUUID(), key: customizations.root.custom_reference_1, value: null, required: true})
    if (customizations.root?.custom_reference_2_required) rows.push({ id: crypto.randomUUID(), key: customizations.root.custom_reference_2, value: null, required: true})
    if (customizations.root?.custom_reference_3_required) rows.push({ id: crypto.randomUUID(), key: customizations.root.custom_reference_3, value: null, required: true})
    // if not required
    if (!customizations.root?.custom_reference_1_required && customizations.root.custom_reference_1 != "") rows.push({ id: crypto.randomUUID(), key: customizations.root.custom_reference_1, value: null})
    if (!customizations.root?.custom_reference_2_required && customizations.root.custom_reference_2 != "") rows.push({ id: crypto.randomUUID(), key: customizations.root.custom_reference_2, value: null})
    if (!customizations.root?.custom_reference_3_required && customizations.root.custom_reference_3 != "") rows.push({ id: crypto.randomUUID(), key: customizations.root.custom_reference_3, value: null})
    rows.push({ id: crypto.randomUUID(), key: null, value: null })
    console.log(rows)
    updateForm({
      ...form,
      keyValues: rows,
    });
  }
  const rows: KeyValue[] = form.keyValues ?? [
    { id: crypto.randomUUID(), key: null, value: null, required: null },
  ];

  const fetchKeys = async () => {
    try {

      const new_keys: Set<string> = new Set();

      if(!customizations?.root?.custom_reference_1_required){
        new_keys.add(customizations?.root?.custom_reference_1);
      }

      if(!customizations?.root?.custom_reference_2_required){
        new_keys.add(customizations?.root?.custom_reference_2);
      }

      if(!customizations?.root?.custom_reference_3_required){
        new_keys.add(customizations?.root?.custom_reference_3);
      }

      const keysData = await api!.get_all_container_keys();
      for(const key of keysData){
        new_keys.add(key)
      }
      setAllKeys(Array.from(new_keys).filter(x => x).sort());
      
    } catch (error) {
      //TODO add snack bar
      console.error("Error fetching tags:", error);
    }
  };

  const fetchCustomizations = async() => {
    const customizations = await api!.get_org_customizations();
    setCustomizations(customizations);
  }

  useEffect(() => {
    if (!api) return;
    fetchCustomizations();
  }, [api]); 

  useEffect(() => {
    if (!api || !customizations) return;
    fetchKeys();
    get_default_rows();
  }, [api, customizations]);

  const updateRows = () => {
    updateForm({
      ...form,
      keyValues: rows,
    });
  };

  function ensureBlank() {
    while (
      rows.length > 1 &&
      rows[rows.length - 1].value == null &&
      rows[rows.length - 1].key == null
    ) {
      handleRemoveRow(rows.length - 1);
    }
    if (
      rows[rows.length - 1].value != null ||
      rows[rows.length - 1].key != null
    ) {
      handleAddRow();
    }
  }

  const handleAddRow = () => {
    rows.push({ id: crypto.randomUUID() });
    updateRows();
  };

  const handleRemoveRow = (indexToRemove: number) => {
    rows.splice(indexToRemove, 1);
    updateRows();
  };

  function errorKey(kv: KeyValue) {
    return !kv.key && kv.value ? "Key needs to be populated" : null;
  }

  function errorValue(kv: KeyValue) {
    return kv.required && !kv.value ? "Value needs to be populated" : null
  }

  const classes = useStyles();

  return (
    <Box className={classes.FormContainer}>
      <Card
        style={{
          width: "100%",
          borderColor: "#2B5592",
          borderWidth: "2px",
          borderStyle: "solid",
        }}
      >
        <CardContent>
          <Grid2 container>
            {rows.map((row, index) => (
              <Fragment key={row.id}>
                <Grid2 xs={12} sm={2}>
                  {index === rows.length - 1 ? (
                    <IconButton onClick={handleAddRow}>
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleRemoveRow(index)} disabled={!!row.required}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                </Grid2>
                <Grid2 xs={12} sm={5}>
                  <Autocomplete
                    id="tags-filled"
                    style={{ maxWidth: "200px", width: "100%" }}
                    options={allKeys}
                    value={row.key}
                    disabled={!!row.required}
                    onChange={(e, v) => {
                      if (v) {
                        row.key = v;
                      } else {
                        delete row.key;
                      }
                      updateRows();
                      ensureBlank();
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        error={errorKey(row) != null}
                        helperText={errorKey(row)}
                        {...params}
                        variant="standard"
                        placeholder="Reference"
                        onChange={(e) => {
                          if (e.target.value) {
                            row.key = e.target.value;
                          } else {
                            delete row.key;
                          }
                          updateRows();
                          ensureBlank();
                        }}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 xs={12} sm={5}>
                  <TextField
                    error={errorValue(row) != null}
                    variant="standard"
                    value={row.value ?? ""}
                    helperText={errorValue(row)}
                    style={{ maxWidth: "200px", width: "100%" }}
                    placeholder="Reference Value"
                    onChange={(e) => {
                      if (e.target.value) {
                        row.value = e.target.value;
                      } else {
                        delete row.value;
                      }
                      updateRows();
                      ensureBlank();
                    }}
                  />
                </Grid2>
              </Fragment>
            ))}
          </Grid2>
        </CardContent>
      </Card>
    </Box>
  );
};

export default KeyValues;
