import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Autocomplete,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useContext } from "react";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";
import { JSONFormsContext } from "../../context/JSONFormsContext";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./index.css";
import CardContent from "../general/CardContentNoPadding";
import CardHeader from "../general/CardHeaderNoPadding"
import { stringify } from "querystring";
import React from "react";
import { OrgContext } from "../../context/OrgContext";
import { isOutlook } from "../../utils/office";

type Recipient = {
  id: string;
  name?: string;
  email: string;
};

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
  },
});

const Recipients = (props: any) => {
  const { org, api } = React.useContext(OrgContext);

  const classes = useStyles();
  const { form, updateForm } = useContext(
    JSONFormsContext
  ) as JSONFormsContextType;

  let recipients: Recipient[] = form.recipients ?? [{ id: crypto.randomUUID(), email: '' }];
  const [addresses, set_addresses] = React.useState<any[]>([]);
  const [users, set_users] = React.useState<any[]>([]);
  // console.log("Recipients: ", recipients);

  const backgroundColor = props.backgroundColor;

  function updateEmail(index: number, email: string) {
    recipients[index].email = email;
    updateForm({ ...form, recipients });
    ensureBlank();
  }

  const load_addresses = async () => {
    const new_addresses = await api!.get_org_addresses();
    // console.log(new_addresses)
    // const new_users = [
    //     {id: "1", email: "jdk@gmail.com", name: "Jonathan"},
    //     {id: "2", email: "akadado@gmail.com", name: "Ahmad"}
    // ]
    set_addresses(new_addresses)
  }
  const load_users = async () => {
    const new_users = (await api!.get_org_chart()).orgs.filter(org => org.email);
    // const new_users = [
    //     {id: "1", email: "jdk@gmail.com", name: "Jonathan"},
    //     {id: "2", email: "akadado@gmail.com", name: "Ahmad"}
    // ]
    set_users(new_users)
  }

  React.useEffect(() => {
    if (!api) return;
    load_addresses();
    load_users();
  }, [api]);

  function ensureBlank() {
    while (recipients.length > 1 && recipients[recipients.length - 1].email === "") {
      deleteRecipeint(recipients.length - 1);
    }
    if (recipients[recipients.length - 1].email !== "") {
      addRecipient();
    }
  }

  function addRecipient() {
    recipients.push({
      id: crypto.randomUUID(),
      email: "",
    });
    updateForm({ ...form, recipients });
  }

  function deleteRecipeint(index: number) {
    recipients.splice(index, 1);
    updateForm({ ...form, recipients });
  }

  function emailError(email: string) {
    return ((email == "") || /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:\*|(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)/i.test(email)) ? null : "Invalid email format";
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (!recipients.some(({ email }) => email === '*@*')) {
        recipients.unshift({ id: crypto.randomUUID(), email: '*@*' });
      }
    } else {
      recipients = recipients.filter(({ email }) => email !== '*@*');
    }
    updateForm({ ...form, recipients });
  };

  function uniq(values: string[]){
    return Array.from(new Set(values).values())
  }

  return (
    <Card style={{ width: "100%", backgroundColor }}>
      <CardHeader title="Authorized Viewer(s)" />
      <CardContent>
        <div className="recipient-form">
          {!isOutlook() && <div className="recipient-form-grid">
            {recipients.map((recipient, i) => (recipient.email !== '*@*' &&
              <div className="recipient-form-row" key={recipient.id}>
                <div>
                  {i === recipients.length - 1 ? (
                    <IconButton onClick={() => addRecipient()}>
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => deleteRecipeint(i)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                </div>
                <div className="recipient-form-col">
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={uniq(addresses.map((option) => option.email).concat(users.map(user => user.email)).sort())}
                    onInputChange={(e, value) => {
                      updateEmail(i, value)
                    }}
                    value={recipient.email}
                    renderInput={(params) => (
                      <TextField
                        error={emailError(recipient.email) != null}
                        {...params}
                        placeholder="Email"
                        variant="standard"
                        className="recipient-form-field"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                        helperText={emailError(recipient.email)}
                      // onChange={(e) =>
                      //   updateEmail(i, (e.target as HTMLInputElement).value)
                      // }
                      />
                    )}
                  />
                  {/* <TextField
                    error={emailError(recipient.email) != null}
                    placeholder="Email"
                    variant="standard"
                    fullWidth
                    className="recipient-form-field"
                    value={recipient.email}
                    helperText={emailError(recipient.email)}
                    onInput={(e) =>
                      updateEmail(i, (e.target as HTMLInputElement).value)
                    }
                  ></TextField> */}
                </div>
              </div>
            ))}
          </div>}
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={recipients.some(({ email }) => email === '*@*')} onChange={handleChange} />} label="Allow anyone with link to access" />
          </FormGroup>
        </div>
      </CardContent>
    </Card>
  );
};

export default Recipients;
