import PUBLIC_URL from './PUBLIC_URL';
import { call } from './office';

const alnum = `[0-9a-f]`;
const uuid = `${alnum}{8}-${alnum}{4}-${alnum}{4}-${alnum}{4}-${alnum}{12}`;
const tera_file_re = new RegExp(`(https://.*?locktera\\.com)/auth/view/(${uuid})/(${uuid})`, 'i');

async function show_dialog (message: string, options: Office.DialogOptions = {}) {
	// Wait for the dialog to open
	const dialog = await call(Office.context.ui, 'displayDialogAsync', `${PUBLIC_URL}/OutlookErrorDialog.html`, options);

	// It will message us when it's ready; when we receive that message, send back the error
	dialog.addEventHandler(
		Office.EventType.DialogMessageReceived,
		() => dialog.messageChild(message)
	);

	// Wait for the dialog to close
	await new Promise<void>(resolve => {
		dialog.addEventHandler(
			Office.EventType.DialogEventReceived,
			() => resolve()
		)
	});
}

export async function open_analytics (event: any) {
	try {
		const body = await call(Office.context.mailbox.item?.body!, 'getAsync', 'html');
		// console.log('body:', body);

		const match = body.match(tera_file_re);
		if (!match) {
			await show_dialog('This email contains no Locktera secure attachments.', { width: 25, height: 25 });
			return;
		}

		const url = `${match[1]}/#/files/analytics/${match[3]}`;

		if (Office.context.ui.openBrowserWindow) {
			Office.context.ui.openBrowserWindow(url);
		} else {
			window.open(url, '_blank');
		}

		event.completed();
	} catch (err: any) {
		await show_dialog(`${err}\n${err.stack}`, { width: 50, height: 50 });
	} finally {
		event.completed();
	}
}

export function register_commands () {
	Office.actions.associate('open_analytics', open_analytics);
}
