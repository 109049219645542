import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IFile } from '../../context/@types/filesContext';
import { createBlankForm } from '../../context/JSONFormsContext';
import EncoderContext from '../../context/EncoderContext';

export default function ClearFormDialog () {
  const { clear_form } = React.useContext(EncoderContext)!;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        variant='outlined'
        sx={{ padding: '14px', width: '100%' }}
        onClick={handleClickOpen}>
        Clear All Form Fields
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='clear-all-fields-title'
        aria-describedby='clear-all-fields-description'>
        <DialogTitle id='clear-all-fields-title'>
          Clear All Fields Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='clear-all-fields-description'>
            Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            sx={{ width: '100%' }}
            onClick={handleClose}
            autoFocus>
            Close Dialog
          </Button>
          <Button
            variant='contained'
            color='error'
            sx={{ width: '100%' }}
            onClick={(event) => {
              event.preventDefault();
              clear_form();
              handleClose();
            }}
            autoFocus>
            Clear Fields
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
