import LockOpen from '@mui/icons-material/LockOpen';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { OrgContext } from '../../context/OrgContext';

export interface Props {
	container_id: string;
	open: boolean;
	onClose: () => void;
}

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

export default function AttemptsDialog (props: Props) {
	const { org, api } = React.useContext(OrgContext);
	const { container_id, open, onClose } = props;

	const [attempts, set_attempts] = React.useState<any[]>([]);

	async function load_attempts () {
		set_attempts(await api!.get_container_attempts(container_id));
	}

	React.useEffect(() => {
		if (api && open) {
			load_attempts();
		}
	}, [api, open]);

	async function unlock (email: string) {
		await api!.reset_container_attempts(container_id, email);
		await api?.addEvent(container_id, "viewer.unlock", {viewer: email})
		await load_attempts();
	}

	return (<Modal open={open} onClose={onClose}>
		<Box sx={style}>
			<h2>Unlock</h2>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Email</TableCell>
						<TableCell>Attempts</TableCell>
						<TableCell>Unlock</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{attempts.map(attempt => (<TableRow>
						<TableCell>{attempt.email}</TableCell>
						<TableCell>{attempt.attempts}</TableCell>
						<TableCell>
							<IconButton onClick={() => unlock(attempt.email)}>
								<LockOpen />
							</IconButton>
						</TableCell>
					</TableRow>))}
				</TableBody>
			</Table>
		</Box>
	</Modal>);
}
