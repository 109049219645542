import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDropzone } from "react-dropzone";

import { FilesContext } from "../../context/FilesContext";
import { IFile, FileContextType } from "../../context/@types/filesContext";
import styled from "styled-components";
import FileList from "./FileList";

import { JSONFormsContext } from "../../context/JSONFormsContext";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";

import FileViewerDialog from "../general/FileViewerDialog";
import DeleteFilesDialog from "./DeleteFilesDialog";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
} from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
// @ts-ignore
import Grid2 from "@mui/material/Unstable_Grid2";
import { OrgContext } from "../../context/OrgContext";
import { encode as base64Encode } from "../../utils/base64";
import { createMD5 } from "hash-wasm";
import CustomSnackbar, { Severity } from "../general/snackbar";
import CircularProgressWithLabel from "../encoder/CircularProgressWithLabel";
import { ExpandMore } from "@mui/icons-material";

const useStyles = makeStyles({
  Main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    // height: '100%',
  },
  ContentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
    marginTop: "10px",
  },
  FileContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  FileTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "20px 0px",
  },
  ListItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "10px 0px",
  },
  dataContent: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "0.25em",
    backgroundColor: "#cecece",
    marginBottom: "1rem",
    width: "800px",
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#716d6d";
};

/**
  border-width: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: solid;
 */

const Container = styled.div`
  /* flex: 1; */
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 4px;
  background-color: #c4d6ed;
  color: ${(props) => getColor(props)};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

interface Props {
  refreshUI: () => void;
}

const RecoverFilesUI = (props: Props) => {
  const [files, updateFiles] = React.useState<IFile[]>([]);

  const { api } = React.useContext(OrgContext);
  const [snackBarSeverity, setSnackBarSeverity] = useState<Severity>("success");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [modal_open, set_modal_open] = useState(false);
  const [modal_body, set_modal_text] = useState("");
  const [progress, set_progress] = useState<number | undefined>();
  const [dialog_open, set_dialog_open] = React.useState(false);

  // console.log('form', form);

  const setFiles = (files: any) => {
    let newList: IFile[] = [];
    let checkNameExistsObject: any = {
      // name: count
    };
    // console.log('formCopy1', formCopy);

    for (let filesIndex = 0; filesIndex < files.length; filesIndex++) {
      const file = files[filesIndex];
      // console.log("file", file);
      if (checkNameExistsObject.hasOwnProperty(file.name)) {
        let currentCount: number = checkNameExistsObject[file.name];
        let newCount: number = currentCount + 1;
        checkNameExistsObject[file.name] = newCount;
        const splitArray = file.name.split(".");
        splitArray[0] = `${splitArray[0]}_${newCount}`;
        const modifiedName = splitArray.join(".");
        newList.push({
          file: file,
          name: modifiedName,
          type: file.type,
          size: file.size,
        });
      } else {
        newList.push({
          file: file,
          name: file.name,
          type: file.type,
          size: file.size,
        });
        checkNameExistsObject[file.name] = 1;
      }
    }
    // console.log("newFormList ======>", newFormList);
    // do not use update form will cause issue with rerendering
    // updateForm(formCopy);
    updateFiles(newList);
  };

  const existingFiles = () => {
    let fileArray = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      fileArray.push(file.file);
    }
    return fileArray;
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles.length === 0) {
        showSnackbar(
          "error",
          "File type not allowed. Please upload tera file."
        );
      } else {
        // console.log('form1', form);
        // console.log('here acceptedFiles', acceptedFiles);
        let extFiles = existingFiles();
        let files = [...extFiles, ...acceptedFiles];
        // console.log('files', files);
        setFiles(files);
      }
    },
    [files]
  );

  const removeFile = (file: any) => () => {
    let fileArray = [];
    let name = file.name;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (name !== file.name) {
        fileArray.push(file.file);
      }
    }
    setFiles(fileArray);
  };

  const removeAll = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "application/octet-stream": [".tera"],
      },
    });

  useEffect(() => {
    let extFiles = existingFiles();
    setFiles(extFiles);
  }, []);

  //Calculate digest of file, call API to get uuid
  async function checkFile(fileInfo: IFile) {
    const buffer = await fileInfo.file.arrayBuffer();
    //const digestBuffer = await crypto.subtle.digest("SHA-256", buffer);
    const md5 = await createMD5();
    md5.update(new Uint8Array(buffer));
    const digest = base64Encode(md5.digest("binary"));

    // console.log("FileInfo: ", fileInfo, "FileDigest: ", digest);

    return await api?.lookup_file_digest(digest);
  }

  async function uploadFile(
    uuid: string,
    file: IFile,
    onProgress: (progressEvent: any) => void
  ) {
    let res = await api?.upload_file_url(uuid, file.file, onProgress);
    //console.log("upload method called");
  }

  const showSnackbar = (
    severity: Severity,
    message: string,
    ms = severity === "success" ? 3000 : 15000
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);
    setSnackBarOpen(true);
    if (ms > 0) setTimeout(() => setSnackBarOpen(false), ms);
  };

  async function handleRecover() {
    let restoreResp;

    set_modal_text("Restoring file");
    set_modal_open(true);

    for (const fileInfo of files) {
      try {
        let uuid = await checkFile(fileInfo);
        await uploadFile(uuid, fileInfo, onProgress);
        restoreResp = await api?.restore_file(uuid);
      } catch (e: any) {
        console.log(e);
        if (e.response?.status === 404) {
          showSnackbar(
            "error",
            "Error. Selected Tera File is not from this library."
          );
        } else {
          showSnackbar("error", "Error. Please load .tera file");
        }
      }
    }

    set_modal_open(false);

    if (restoreResp) {
      showSnackbar("success", "File restored");
    }

    props.refreshUI();
    setFiles([]);
  }

  function onProgress(progressEvent: any) {
    set_progress((100 * progressEvent.loaded) / progressEvent.total);
    // console.log(
    //   "Upload progress:",
    //   Math.round((progressEvent.loaded / progressEvent.total) * 100) + "%"
    // );
  }

  return (
    <>
      <Box>
        <Box>
          <Typography
            variant="h5"
            align="left"
            sx={{ margin: "10px", width: "100%" }}
          ></Typography>
          <Button variant="contained" onClick={() => { set_dialog_open(true) }}>
            Upload .tera File to Library
          </Button>
          <Dialog
            open={dialog_open}
            onClose={() => set_dialog_open(false)}
            maxWidth='md'
            fullWidth
          >
            <DialogTitle variant="h4" sx={{ marginTop: 1, marginBottom: 1, color: "#2B5592" }}>
              <Typography variant="h5">Upload .tera File to Library</Typography>
            </DialogTitle>

            <DialogContent >
              <Grid2 container spacing={1}>
                <Grid2 md={files.length === 0 ? 12 : 6} xs={12} sm={6}>

                  <Container
                    {...getRootProps({
                      isFocused,
                      isDragAccept,
                      isDragReject,
                      onDrop: (event) => event.preventDefault(),
                      onClick: (event) => event.preventDefault(),
                    })}
                    style={
                      files.length === 0
                        ? {
                          borderColor: "#2B5592",
                          color: "#58585B",
                        }
                        : {}
                    }
                  >
                    <input {...getInputProps()} />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CloudUploadOutlinedIcon
                        style={{ marginRight: "10px", fontSize: "100px" }}
                      />
                      <Typography
                        variant="body1"
                        style={{ marginRight: "20px" }}
                      >
                        Restore TERA file to Library
                      </Typography>
                      <Button
                        variant="contained"
                        component="label"
                        style={{
                          backgroundColor: "#58585B",
                          padding: "2px 16px",
                          borderRadius: "8px",
                        }}
                      >
                        Browse
                        <input hidden accept="image/*" multiple type="file" />
                      </Button>
                    </div>
                  </Container>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRecover}
                    sx={{ marginTop: "20px" }}
                  >
                    Restore
                  </Button>

                </Grid2>
                {files.length == 0 ? (
                  <></>
                ) : (
                  <Grid2 md={6} xs={12} sm={6}>
                    <FileList files={files} removeFile={removeFile} />
                  </Grid2>
                )}
              </Grid2>
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
      <CustomSnackbar
        severity={snackBarSeverity}
        message={snackBarMessage}
        state={snackBarOpen}
        changeState={setSnackBarOpen}
      />
      <Modal open={modal_open}>
        <Box sx={modalStyle}>
          <h2>Restoring File</h2>
          <p>{modal_body}</p>
          <div>
            {progress == null ? (
              <CircularProgress variant="indeterminate" />
            ) : (
              <CircularProgressWithLabel value={progress} />
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default RecoverFilesUI;
