import { Card, CardContent, CardHeader, FormControlLabel, FormGroup, Switch, Checkbox, TextField, Button, MenuItem, Select, Container } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import React, { Fragment } from "react";
import { OrgContext } from "../../context/OrgContext";
import CustomSnackbar, { Severity } from "../../components/general/snackbar";
import EnableDialog from "../../components/2FA/EnableDialog";
import DisableDialog from "../../components/2FA/DisableDialog";

const TwoFA: React.FC = () => {
    const { org, api } = React.useContext(OrgContext);

    const [show_enable_2FA, set_show_enable_2FA] = React.useState(false)
    const [show_disable_2FA, set_show_disable_2FA] = React.useState(false)
    return <>

        <h2 style={{ color: "#2B5592" }}>Enable and disable Two-Factor Authentication</h2>

        

        <Card
            sx={{
                borderColor: "#2B5592",
                borderWidth: "2px",
                borderStyle: "solid",
                width: "100%",
                marginBottom: 2,
            }}
        >
            <CardHeader title="Two-Factor Authentication" />
            <CardContent>
                {org?.totp_key ?
                    <Fragment>
                        <div style={{display: "flex", paddingTop: "1em", alignItems: "center"}}>
                            <Button variant="contained" style={{marginRight: 10, height: "3.5em"}} onClick={() => set_show_disable_2FA(true)}>Disable 2FA</Button>
                            <p style={{fontSize: "1.25em"}}>Two-Factor Authentication is currently enabled</p>
                            <DisableDialog open={show_disable_2FA} onClose={() => set_show_disable_2FA(false)}></DisableDialog>
                        </div>
                    </Fragment>

                    :
                    <Fragment>
                        <div style={{display: "flex", paddingTop: "1em", alignItems: "center"}}>
                            <Button variant="contained" style={{marginRight: 10, height: "3.5em"}} onClick={() => set_show_enable_2FA(true)}>Enable 2FA</Button>
                            <p style={{fontSize: "1.25em"}}>Two-Factor Authentication is currently disabled</p>
                            <EnableDialog open={show_enable_2FA} onClose={() => set_show_enable_2FA(false)}></EnableDialog>
                        </div>
                    </Fragment>
                }
            </CardContent>
        </Card>
        
        <p style={{fontSize: '16px'}}>To Enable Two-Factor Authentication:</p>
        <ol style={{fontSize: '16px'}}>
        <li>Click “ENABLE 2FA” above.</li>
        <li>Install an authenticator app such as Microsoft Authenticator.</li>
        <li>Open your authenticator app and tap Add Account or click the  Add icon in the top right corner. If this is your first time setting up an account on the app, you might be prompted straight away to set up an account.</li>
        <li>Scan the Locktera QR Code provided</li>
        <li>Find “Locktera” and enter the 6-digit verification code generated by your authenticator app to verify your device. </li>
        <li>Be sure to record the removal number provided. This number is necessary to remove 2FA.</li>
        <li>Now your device is ready for two-step authentication. Whenever you log in from a new device, open your Authenticator app and enter the code when prompted.</li>
        </ol>

        <p style={{fontSize: '16px'}}>To Disable Two-Factor Authentication:</p>
        <ol style={{fontSize: '16px'}}>
        <li>Click “DISABLE 2FA” above.</li>
        <li>Enter the removal code provided when you enabled 2FA.</li>
        <li>Confirm removal.</li>
        </ol>
       </>
}

export default TwoFA
