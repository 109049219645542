import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { FormControl } from "@mui/material";
import React from "react";
import HorizontalBarGraph from "../../components/analytics/HorizontalBarGraph";
import QueryComponent from "../../components/analytics/QueryComponent";
import ReactTable from "../../components/analytics/ReactTable";
import { ViewersFilter } from "../../components/analytics/filters";
import { lt_analytics_client_main_v2, quote_string, tableToObjects } from "../../components/analytics/lt_analytics_client";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import { OrgContext } from "../../context/OrgContext";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const schema = 'VIEWER_AUDIT';

const ViewerAuditTab: React.FC = () => {
	const { org, api } = React.useContext(OrgContext);

	// Allocate the DuckDB connection
	const [conn, setConn] = React.useState<AsyncDuckDBConnection>();
	// Filter states
	const [dateRange, setDateRange] = React.useState(DateRange.Last_14_Days);
	const [allViewers, setAllViewers] = React.useState<string[]>([]);
	const [selectedViewers, setSelectedViewers] = React.useState<string[]>([]);

	React.useEffect(() => {
		if (!org) return;
		(async () => {
			try {
				// Clear the old connection
				setConn(undefined);

				// Calculate the filters
				const [start_time, end_time] = range_to_dates(dateRange);

				// Time and execute the query
				const start = performance.now();
				const conn = await lt_analytics_client_main_v2([org.id], null, schema, null, start_time?.toISOString(), end_time?.toISOString());
				const end = performance.now();
				if (end - start > 1000) api?.report_error({
					module: "analytics",
					message: `Slow Analytics Query: ${schema}`,
					duration_ms: end - start,
					level: "warning",
				});

				// Make the data available to components
				setConn(conn);

				// Get the list of viewers for the filter
				let viewers: any = await conn.query(`select distinct viewer from ${schema}.EVENTS_VW where viewer is not null order by viewer`);
				viewers = tableToObjects(viewers).map((row: any) => row.viewer);
				setAllViewers(viewers);
				setSelectedViewers(viewers);
			} catch (error) {
				api?.report_error({
					module: "analytics",
					user: org.email,
					message: `Error calculating analytics: ${error}`,
					stack: (error as any).stack,
				});
				throw error;
			}
		})();
	}, [org, dateRange]);

	return <>
		<h3>
			Filters:
		</h3>
		<FormControl>
			<DateRangeSelect value={dateRange} onChange={setDateRange} />
		</FormControl>
		<ViewersFilter options={allViewers} values={selectedViewers} set_values={setSelectedViewers} />
		{conn && <div>
			<h1>VIEWER AUDIT</h1>
			<Grid2 container>
				<QueryComponent
					conn={conn}
					query={`
						with cte_prep as (
							select "VIEWER", "CONTENT FILE", min("FIRST ACCESSED") as "FIRST ACCESSED"
							from ${schema}.RPT_VIEWER_AUDIT_VW c
							${selectedViewers.length > 0 ? `WHERE c."VIEWER" IN (${selectedViewers.map(s => quote_string(s)).join(",")})` : ''}
							group by "VIEWER", "CONTENT FILE"
						), cte_main as (
							select "VIEWER", case when "FIRST ACCESSED" is null then 0 else 1 end as "OPENED"
								from cte_prep
						), cte_old as (
							select "VIEWER", count(distinct("CONTENT FILE"))::int as "OPENED"
								from ${schema}.RPT_VIEWER_AUDIT_VW c
							WHERE "FIRST ACCESSED" is not null
							${selectedViewers.length > 0 ? `AND c."VIEWER" IN (${selectedViewers.map(s => quote_string(s)).join(",")})` : ''}
							group by "VIEWER"
							order by 2 desc
						)
						select "VIEWER",
									coalesce(sum("OPENED")::int,0)::int as "CONTENT FILES OPENED"
							from cte_main
							group by "VIEWER"
							order by 2 desc
						`}
					render={(data) => {
						return <Grid2 xs={12} sm={6}>
							<HorizontalBarGraph
								style={{ height: '400px' }}
								title='CONTENT FILES OPENED'
								color='#C7D5EB'
								data={data}
							/>
						</Grid2>;
					}}
				/>
				<QueryComponent
					conn={conn}
					query={`
						with cte_prep as (
							select distinct "VIEWER", "CONTENT FILE", case when "DOWNLOADS" == 'N/A' then 0 else "DOWNLOADS"::int end as "DOWNLOAD"
							-- max(coalesce("DOWNLOADS",0)) as "DOWNLOAD"
							from ${schema}.RPT_VIEWER_AUDIT_VW c
							WHERE "DOWNLOADS" != 'N/A' -- skip files that are not downloadable
							${selectedViewers.length > 0 ? `AND c."VIEWER" IN (${selectedViewers.map(s => quote_string(s)).join(",")})` : ''}
							-- group by "VIEWER", "CONTENT FILE"
						), cte_main as (
							select "VIEWER", "CONTENT FILE", max("DOWNLOAD") as "DOWNLOAD"
								from cte_prep
							group by "VIEWER", "CONTENT FILE"
						), cte_old as (
						select "VIEWER", count(distinct("CONTENT FILE"))::int as "DOWNLOADED"
							from ${schema}.RPT_VIEWER_AUDIT_VW c
							WHERE "DOWNLOADS" is not null
							${selectedViewers.length > 0 ? `AND c."VIEWER" IN (${selectedViewers.map(s => quote_string(s)).join(",")})` : ''}
						group by "VIEWER"
						order by 2 desc
						)
						select "VIEWER", sum("DOWNLOAD")::int as "DOWNLOADS PER VIEWER"
							from cte_main
							group by "VIEWER"
							order by 2 desc
						`}
					render={(data) => {
						return <Grid2 xs={12} sm={6}>
							<HorizontalBarGraph
								style={{ height: '400px' }}
								title='DOWNLOADS PER VIEWER'
								data={data}
							/>
						</Grid2>;
					}}
					/>
				<QueryComponent
					conn={conn}
					query={`
					select "VIEWER", coalesce(sum(coalesce(duration_seconds,0))::int/60.0,0)::int as "DURATION VIEWING FILES"
						from ${schema}.RPT_VIEWER_AUDIT_VW c
						${selectedViewers.length > 0 ? `WHERE c."VIEWER" IN (${selectedViewers.map(s => quote_string(s)).join(",")})` : ''}
					group by "VIEWER"
					order by 2 desc
					`}
					render={(data) => {
						return <Grid2 xs={12} sm={6}>
							<HorizontalBarGraph
								style={{ height: '400px' }}
								title='DURATION VIEWING FILES'
								color='#58585B'
								data={data}
							/>
						</Grid2>;
					}}
				/>
				<QueryComponent
					conn={conn}
					query={`
					with cte_prep as (
						select "VIEWER", "CONTENT FILE", min("FIRST ACCESSED") as "FIRST ACCESSED"
						from ${schema}.RPT_VIEWER_AUDIT_VW c
						${selectedViewers.length > 0 ? `WHERE c."VIEWER" IN (${selectedViewers.map(s => quote_string(s)).join(",")})` : ''}
						group by "VIEWER", "CONTENT FILE"
					), cte_main as (
						select "VIEWER", case when "FIRST ACCESSED" is null then 1 else 0 end as "NOT OPENED"
							from cte_prep
					), cte_old as (
						select "VIEWER", count(distinct("CONTENT FILE"))::int as "NOT OPENED"
						from ${schema}.RPT_VIEWER_AUDIT_VW c
						WHERE "FIRST ACCESSED" is null
						${selectedViewers.length > 0 ? `AND c."VIEWER" IN (${selectedViewers.map(s => quote_string(s)).join(",")})` : ''}
						group by "VIEWER"
						order by 2 desc
					)
					select "VIEWER", coalesce(sum("NOT OPENED")::int,0)::int as "FILES NOT OPENED"
						from cte_main
						group by "VIEWER"
						order by 2 desc
					`}
					render={(data) => {
						return <Grid2 xs={12} sm={6}>
							<HorizontalBarGraph
								style={{ height: '400px' }}
								title='FILES NOT OPENED'
								color='#7F1F10'
								data={data}
							/>
						</Grid2>;
					}}
				/>
			</Grid2>


			{selectedViewers.map(viewer => {
				return <>
					<QueryComponent
						conn={conn}
						query={`
						select
						"CONTENT FILE","DATE SENT","% COMPLETION","PAGES VIEWED","DOWNLOADS","DURATION","FIRST ACCESSED",
						"LAST ACCESSED","CITY","STATE","COUNTRY"
						from ${schema}.RPT_VIEWER_AUDIT_VW c
						where c."VIEWER" = ${quote_string(viewer)}
						-- ${selectedViewers.length > 0 ? `WHERE c."VIEWER" IN (${selectedViewers.map(s => quote_string(s)).join(",")})` : ''}
							order by lower(c."VIEWER"),lower(c."CONTENT FILE"),c."DATE SENT",c."FIRST ACCESSED"
						`}
						render={(data) => {
							return <ReactTable data={data} title={viewer} />;
						}}
					/>

				</>;
			})}
		</div>}
	</>
};

export default ViewerAuditTab;
