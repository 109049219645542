import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDropzone } from "react-dropzone";

import { FilesContext } from "../../context/FilesContext";
import { IFile, FileContextType } from "../../context/@types/filesContext";
import styled from "styled-components";
import FileList from "./FileList";

import { JSONFormsContext } from "../../context/JSONFormsContext";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";

import FileViewerDialog from "../general/FileViewerDialog";
import DeleteFilesDialog from "./DeleteFilesDialog";
import { Grid } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
// @ts-ignore
import Grid2 from "@mui/material/Unstable_Grid2";
import { OrgContext } from "../../context/OrgContext";

const useStyles = makeStyles({
  Main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    // height: '100%',
  },
  ContentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
    marginTop: "10px",
  },
  FileContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  FileTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "20px 0px",
  },
  ListItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "10px 0px",
  },
  dataContent: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "0.25em",
    backgroundColor: "#cecece",
    marginBottom: "1rem",
    width: "800px",
  },
});

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#716d6d";
};

/**
  border-width: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: solid;
 */

const Container = styled.div`
  /* flex: 1; */
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 4px;
  color: ${(props) => getColor(props)};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

interface Props {
  limit?: number,
}

const Files = (props: Props) => {
  const { org } = useContext(OrgContext);

  const classes = useStyles();

  const [downloadableChecked, setDownloadableChecked] = useState(false);

  const { form, updateForm } = useContext(
    JSONFormsContext
  ) as JSONFormsContextType;

  const { files, updateFiles } = useContext(FilesContext) as FileContextType;

  const over_limit = props.limit && files.reduce((sum, file) => sum + file.size, 0) > props.limit;

  // console.log('form', form);

  const setFiles = (files: any) => {
    let newList: IFile[] = [];
    let newFormList: any = [];
    let checkNameExistsObject: any = {
      // name: count
    };
    let formCopy: any = form;
    // console.log('formCopy1', formCopy);
    if (!formCopy.files) {
      formCopy.files = {};
    }
    const existingParts = formCopy.files.parts ?? [];
    for (let filesIndex = 0; filesIndex < files.length; filesIndex++) {
      const file = files[filesIndex];
      let newFormPartObject: any = {};
      for (
        let existingPartsIndex = 0;
        existingPartsIndex < existingParts.length;
        existingPartsIndex++
      ) {
        const existingPart = existingParts[existingPartsIndex];
        // console.log("existingPart", existingPart);
        // formCopy.files.parts
        if (existingPart.name === file.name) {
          const hasData = existingPart.hasOwnProperty("data");
          const hasDiacom = existingPart.hasOwnProperty("diacom");
          const hasTags = existingPart.hasOwnProperty("tags");
          // console.log("hasData ===>", hasData);
          // console.log("hasDiacom ===>", hasDiacom);
          if (hasData) {
            newFormPartObject.data = existingPart.data;
          }
          if (hasDiacom) {
            newFormPartObject.diacom = existingPart.diacom;
          }
          if (hasTags) {
            newFormPartObject.tags = existingPart.tags;
          }
        }
      }
      // console.log("file", file);
      if (checkNameExistsObject.hasOwnProperty(file.name)) {
        let currentCount: number = checkNameExistsObject[file.name];
        let newCount: number = currentCount + 1;
        checkNameExistsObject[file.name] = newCount;
        const splitArray = file.name.split(".");
        splitArray[0] = `${splitArray[0]}_${newCount}`;
        const modifiedName = splitArray.join(".");
        newList.push({
          file: file,
          name: modifiedName,
          type: file.type,
          size: file.size,
        });
        newFormPartObject.name = modifiedName;
        newFormPartObject.fileName = modifiedName;
        newFormPartObject.type = file.type;
        newFormList.push(newFormPartObject);
      } else {
        newList.push({
          file: file,
          name: file.name,
          type: file.type,
          size: file.size,
        });
        newFormPartObject.name = file.name;
        newFormPartObject.fileName = file.name;
        newFormPartObject.type = file.type;
        newFormList.push(newFormPartObject);
        checkNameExistsObject[file.name] = 1;
      }
    }
    // console.log("newFormList ======>", newFormList);
    formCopy.files.parts = newFormList;
    // do not use update form will cause issue with rerendering
    // updateForm(formCopy);
    updateFiles(newList);
  };

  const existingFiles = () => {
    let fileArray = [];
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      fileArray.push(file.file);
    }
    return fileArray;
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      // console.log('form1', form);
      // console.log('here acceptedFiles', acceptedFiles);
      let extFiles = existingFiles();
      let files = [...extFiles, ...acceptedFiles];
      // console.log('files', files);
      setFiles(files);
    },
    [files]
  );

  const removeFile = (file: any) => () => {
    let fileArray = [];
    let name = file.name;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      if (name !== file.name) {
        fileArray.push(file.file);
      }
    }
    setFiles(fileArray);
  };

  const removeAll = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
    });

  useEffect(() => {
    let extFiles = existingFiles();
    setFiles(extFiles);
  }, []);

  return (
    <Box>
      <Box>
        {/* <Typography
          variant="h5"
          align="left"
          sx={{ margin: "10px", width: "100%" }}
        ></Typography> */}
        <Grid2 container spacing={1}>
          <Grid2 xs={12} sm={6}>
            <Container
              {...getRootProps({
                isFocused,
                isDragAccept,
                isDragReject,
                onDrop: (event) => event.preventDefault(),
                onClick: (event) => event.preventDefault(),
              })}
              style={{
                backgroundColor: over_limit ? (org.large_file ? '#80be5a' : '#8b1001') : '#c7d5eb',
                ...(files.length === 0 && {
                  borderColor: "#2B5592",
                  color: "#58585B",
                }),
              }}
            >
              <input {...getInputProps()} />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CloudUploadOutlinedIcon
                  style={{ marginRight: "10px", fontSize: "100px" }}
                />
                <Typography variant="body1" style={{ marginRight: "20px" }}>
                  Drag and drop here or
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    backgroundColor: "#58585B",
                    padding: "2px 16px",
                    borderRadius: "8px",
                  }}
                >
                  Browse
                  <input hidden accept="image/*" multiple type="file" />
                </Button>
              </div>
            </Container>
          </Grid2>
          <Grid2 xs={12} sm={6}>
            {files.length > 0 && (
              <>
                <DeleteFilesDialog removeAll={removeAll} />
              </>
            )}
            <FileList files={files} removeFile={removeFile} limit={props.limit} />
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
};

export default Files;
