declare global {
	namespace JSX {
		interface IntrinsicElements {
			'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
		}
	}
}

export default function Subscribe (props: any) {
	return (<>
		<stripe-pricing-table pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
			publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
		</stripe-pricing-table>
	</>)
}
