import { Card, CardContent, CardHeader, FormControlLabel, FormGroup, Switch, Checkbox, TextField, Button, MenuItem, Select, Container } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import React, { Fragment } from "react";
import { OrgContext } from "../../context/OrgContext";
import CustomSnackbar, { Severity } from "../../components/general/snackbar";
import EnableDialog from "../../components/2FA/EnableDialog";
import DisableDialog from "../../components/2FA/DisableDialog";

const CloudStorage: React.FC = () => {
    const { org, api } = React.useContext(OrgContext);

    const [cloud_storage, set_cloud_storage] = React.useState<any>('default');
    const [storage_connection_string, set_storage_connection_string] = React.useState<any>('');
    const [storage_host, set_storage_host] = React.useState<any>('');
    const [storage_region, set_storage_region] = React.useState<any>('');
    const [storage_bucket, set_storage_bucket] = React.useState<any>('');
    const [storage_access_key, set_storage_access_key] = React.useState<any>('');
    const [storage_secret_key, set_storage_secret_key] = React.useState<any>('');

    async function load_storage_config() {
        if (!api) return;
        const config = await api.get_storage_config()
        if (!config) return set_cloud_storage('default')
        set_cloud_storage(config.type)
        set_storage_connection_string(config.connection_string);
        set_storage_host(config.host)
        set_storage_region(config.region)
        set_storage_bucket(config.bucket)
        set_storage_access_key(config.access_key)
        set_storage_secret_key(config.secret_key)
    }

    async function save_customizations() {
        try {
            await api?.save_storage_config({
                type: cloud_storage,
                connection_string: storage_connection_string,
                host: storage_host,
                bucket: storage_bucket,
                region: storage_region,
                access_key: storage_access_key,
                secret_key: storage_secret_key
            })
            show_snack_bar("success", "Configuration Saved")
        } catch (error) {
            show_snack_bar("error", `Error Saving Configuration: ${error}`)
        }
    }

    React.useEffect(() => {
        load_storage_config()
    }, [org])

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarSeverity, setSnackBarSeverity] =
        React.useState<Severity>("success");
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    function show_snack_bar(severity: Severity, message: string) {
        setSnackBarSeverity(severity);
        setSnackBarMessage(message);
        setSnackBarOpen(true);
    }

    return <>
    <Container disableGutters>
        <h2 style={{color: "#2B5592"}}>Cloud Storage</h2>
        <Card
            sx={{
                borderColor: "#2B5592",
                borderWidth: "2px",
                borderStyle: "solid",
                width: "100%",
                marginBottom: 1,
            }}
        >
            <CardHeader title="Cloud Storage" />
            <CardContent>
                <FormGroup >
                    <Select
                        value={cloud_storage}
                        onChange={(e) => set_cloud_storage(e.target.value)}
                    >
                        <MenuItem value='default'>Locktera Storage</MenuItem>
                        <MenuItem value='azure'>Azure Blob Storage</MenuItem>
                        <MenuItem value='s3'>S3-Compatible Storage</MenuItem>
                    </Select>
                    {cloud_storage === 'azure' &&
                        <TextField label="Connection String" value={storage_connection_string} onChange={(e) => set_storage_connection_string(e.target.value)} />
                    }
                    {cloud_storage === 's3' &&

                        (
                            <>
                                <TextField label="Host" value={storage_host} onChange={(e) => set_storage_host(e.target.value)} variant="outlined" fullWidth />
                                <TextField label="Region" value={storage_region} onChange={(e) => set_storage_region(e.target.value)} variant="outlined" fullWidth />
                                <TextField label="Bucket" value={storage_bucket} onChange={(e) => set_storage_bucket(e.target.value)} variant="outlined" fullWidth />
                                <TextField label="Access Key" value={storage_access_key} onChange={(e) => set_storage_access_key(e.target.value)} variant="outlined" fullWidth />
                                <TextField label="Secret Key" value={storage_secret_key} onChange={(e) => set_storage_secret_key(e.target.value)} variant="outlined" fullWidth />

                            </>
                        )
                    }
                </FormGroup>
            </CardContent>
        </Card>
        <Button variant="outlined" onClick={(e) => { load_storage_config() }} sx={{ width: "10%", marginRight: 2 }}>
            Cancel
        </Button>
        <Button variant="contained" onClick={(e) => { save_customizations() }} sx={{ width: "10%", marginRight: 2 }}>
            Save
        </Button>


        <CustomSnackbar
            severity={snackBarSeverity}
            message={snackBarMessage}
            state={snackBarOpen}
            changeState={setSnackBarOpen}
        />
        </Container>

        <p style={{fontSize: '16px'}}><b>Configure Your Cloud Storage</b></p>

        <p style={{fontSize: '16px'}}>Azure Blob Storage</p>

        <ol style={{fontSize: '16px'}}>
        <li>Enter connection string</li>
        <li>Click Save</li>
        </ol>

        <p style={{fontSize: '16px'}}>S3-Compatible Storage</p>
        <ol style={{fontSize: '16px'}}>
        <li>Enter Host Name</li>
        <li>Enter Region</li>
        <li>Enter Bucket</li>
        <li>Enter Access Key</li>
        <li>Enter Secret Key</li>
        <li>Click Save</li>
        </ol>
    </>
}

export default CloudStorage
