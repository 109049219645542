import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { FormControl } from "@mui/material";
import React from "react";
import HorizontalBarGraph from "../../components/analytics/HorizontalBarGraph";
import QueryComponent from "../../components/analytics/QueryComponent";
import ReactTable from "../../components/analytics/ReactTable";
import { ViewersFilter } from "../../components/analytics/filters";
import { lt_analytics_client_main_v2, quote_string, tableToObjects } from "../../components/analytics/lt_analytics_client";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import { OrgContext } from "../../context/OrgContext";
import GroupVerticalBarGraph from "../../components/analytics/GroupVerticalBarGraph";

const schema = 'ACCESS_DENIED';

const AccessDeniedTab: React.FC = () => {
	const { org, api } = React.useContext(OrgContext);

	// Allocate the DuckDB connection
	const [conn, setConn] = React.useState<AsyncDuckDBConnection>();
	// Filter states
	const [dateRange, setDateRange] = React.useState(DateRange.Last_14_Days);

	React.useEffect(() => {
		if (!org) return;
		(async () => {
			try {
				// Clear the old connection
				setConn(undefined);

				// Calculate the filters
				const [start_time, end_time] = range_to_dates(dateRange);

				// Time and execute the query
				const start = performance.now();
				const conn = await lt_analytics_client_main_v2([org.id], null, schema, null, start_time?.toISOString(), end_time?.toISOString());
				const end = performance.now();
				if (end - start > 1000) api?.report_error({
					module: "analytics",
					message: `Slow Analytics Query: ${schema}`,
					duration_ms: end - start,
					level: "warning",
				});

				// Make the data available to components
				setConn(conn);

			} catch (error) {
				api?.report_error({
					module: "analytics",
					user: org.email,
					message: `Error calculating analytics: ${error}`,
					stack: (error as any).stack,
				});
				throw error;
			}
		})();
	}, [org, dateRange]);

	return <>
		<h3>
			Filters:
		</h3>
		<FormControl>
			<DateRangeSelect value={dateRange} onChange={setDateRange} />
		</FormControl>
		{conn && <div>
			<h1>ACCESS DENIED</h1>
			<QueryComponent
				conn={conn}
				query={`
						with cte_main as (
							select substr(v.sent_date::text,1,7) as dt, count(*) as cnt
						  from ${schema}.RPT_ACCESS_DENIED_VW v
						  group by substr(v.sent_date::text,1,7)
						)
							select 'ACCESS DENIED' as "group",
								   v.dt as x,
								   v.cnt::int as y
							  from cte_main v
						 	order by v.dt
						`}
				render={(data) => {
					return <GroupVerticalBarGraph data={data as any} layout={{ xaxis: { title: 'MONTH', type: 'category' }, yaxis: { title: 'COUNT' } }} />
				}}
			/>

			<QueryComponent
				conn={conn}
				query={`
						with cte_main as (
							select	v.container_name,
									v.viewer,
									CASE WHEN upper(v.reason) = upper('Exceeded open limit') then 'exceeded open limit'
     									 WHEN upper(v.reason) = upper('Tera file blocked') then 'blocked'
     									 WHEN upper(v.reason) = upper('geo DRM failed') THEN 'Location Rule'
     									 WHEN upper(v.reason) = upper('ip DRM failed') THEN 'IP Rule'
     									 WHEN upper(v.reason) = upper('time DRM failed') THEN 'expired'
     									 WHEN upper(v.reason) = upper('viewer DRM failed') THEN 'not authorized'
     									 ELSE v.reason
									END as reason,
									v.ip,v.city,v.state,v.country,
									v.attempts,
									v.first_attempt,
									v.last_attempt,
									v.sent_date
						  from ${schema}.RPT_ACCESS_DENIED_VW v
						)
							select distinct
								v.container_name as "CONTAINER NAME",
								v.viewer as "VIEWER",
								v.reason as "REASON",
								v.ip as "IP ADDRESS",v.city as "CITY",v.state as "STATE",v.country as "COUNTRY",
								--v.attempts as "ATTEMPTS",
								v.first_attempt as "TIMESTAMP", --"FIRST ATTEMPT",
								--v.last_attempt as "LAST ATTEMPT",
								v.sent_date as "DATE SENT"
							  from cte_main v
						 	order by last_attempt desc,v.sent_date, v.container_name
						`}
				render={(data) => {
					return <ReactTable data={data} />;
				}}
			/>
		</div>}
	</>
};

export default AccessDeniedTab;
