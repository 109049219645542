import { BoxProps } from "@mui/material";
import Plot from "react-plotly.js";
import { Data, Layout, LayoutAxis } from "plotly.js";
import * as d3 from "d3";
import deepMerge from "../../utils/deepMerge";
import React from "react";

type Props = {
  data: Record<string, any>[];
  style?: Partial<React.CSSProperties>
  layout?: Partial<Layout>,
  title?: string;
  color?: string;
} & BoxProps;

const HorizontalBarGraph: React.FC<Props> = (props) => {
  if (!props.data?.length) {
    return <h3>No Data Found</h3>;
  }

  console.log("DATA: ", props.data);

  const columns = Object.keys(props.data[0]);

  //console.log("HB DATA: ", console.log(props.data));

  console.log("Columns: ", columns);

  const max_char = 30;

  const valuesX = props.data.map((row) => row[columns[1]]).reverse();
  const valuesY = props.data.map(row=>row[columns[0]].length > max_char ? `${row[columns[0]].slice(0,max_char)}...` : row[columns[0]]).reverse();

  console.log("X: ", valuesX);
  console.log("Y: ", valuesY);

  const plot: Data[] = [
    {
      type: "bar",
      x: valuesX,
      y: valuesY,
      orientation: "h",
      marker: {
        color: props.color || '#35548E',
      },
    },
  ];

  var leftMargin = (d3.max(valuesY, d => d.length)) * 8 + 10;
  var xAxis: Partial<LayoutAxis> = {side:'top',tick0:0};

  if (valuesX.every(value=>!value)) {
		xAxis.range = [0,10]
  }

  let layout: Partial<Layout> = {
    autosize: true,
    barmode: "group",
    margin:{l:leftMargin},
    xaxis: xAxis,
    title: props.title,
  };

  return <Plot
    style={{ width: '100%', height: '100%', ...props.style }}
    data={plot}
    layout={deepMerge(layout, props.layout)}
    useResizeHandler
  />;
};

export default HorizontalBarGraph;
