import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { FormControl } from "@mui/material";
import React from "react";
import HorizontalBarGraph from "../../components/analytics/HorizontalBarGraph";
import QueryComponent from "../../components/analytics/QueryComponent";
import ReactTable from "../../components/analytics/ReactTable";
import { ContentFilesFilter, ViewersFilter } from "../../components/analytics/filters";
import { lt_analytics_client_main_v2, quote_string, tableToObjects } from "../../components/analytics/lt_analytics_client";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import { OrgContext } from "../../context/OrgContext";

const schema = 'CONTENT_FILE_AUDIT';

const ViewerAuditTab: React.FC = () => {
	const { org, api } = React.useContext(OrgContext);

	// Allocate the DuckDB connection
	const [conn, setConn] = React.useState<AsyncDuckDBConnection>();
	// Filter states
	const [dateRange, setDateRange] = React.useState(DateRange.Last_14_Days);

	const [allContentFiles, setAllContentFiles] = React.useState<string[]>([]);
	const [selectedContentFiles, setSelectedContentFiles] = React.useState<string[]>([]);

	React.useEffect(() => {
		if (!org) return;
		(async () => {
			try {
				// Clear the old connection
				setConn(undefined);

				// Calculate the filters
				const [start_time, end_time] = range_to_dates(dateRange);

				// Time and execute the query
				const start = performance.now();
				const conn = await lt_analytics_client_main_v2([org.id], null, schema, null, start_time?.toISOString(), end_time?.toISOString());
				const end = performance.now();
				if (end - start > 1000) api?.report_error({
					module: "analytics",
					message: `Slow Analytics Query: ${schema}`,
					duration_ms: end - start,
					level: "warning",
				});

				// Make the data available to components
				setConn(conn);

				// Get the list of Contents for the filter
				let contentFiles: any = await conn.query(`select distinct object from ${schema}.EVENTS WHERE object IS NOT NULL order by object`);
				contentFiles = tableToObjects(contentFiles).map((row: any) => row.object);
				setAllContentFiles(contentFiles);
				setSelectedContentFiles(contentFiles);

			} catch (error) {
				api?.report_error({
					module: "analytics",
					user: org.email,
					message: `Error calculating analytics: ${error}`,
					stack: (error as any).stack,
				});
				throw error;
			}
		})();
	}, [org, dateRange]);

	return <>
		<h3>
			Filters:
		</h3>
		<FormControl>
			<DateRangeSelect value={dateRange} onChange={setDateRange} />
		</FormControl>
		<ContentFilesFilter options={allContentFiles} values={selectedContentFiles} set_values={setSelectedContentFiles} />
		{conn && <div>
			{selectedContentFiles.map(object => {
				return <>
					<QueryComponent
						conn={conn}
						query={`
						with cte_main as (
							SELECT v.session
								,v.viewer
								,v.object
								,v.doc_type
								,vw.city,vw.state,vw.country
								,sum(v.object_download) as download
								,count(distinct(coalesce(v.page,1))) as page
								,sum(coalesce(v.duration,0))::int as duration_seconds
								,sum(coalesce(v.media_duration,0)) as media_duration
								,min(v.ts) as START_TIMESTAMP
								,coalesce(max(v.next_ts),max(v.ts)) as END_TIMESTAMP
								,h.downloadable
								-- session,orgId,uuid,ts,viewer,container_name,object,coalesce(page,0) as page
							FROM ${schema}.OBJECT_EVENTS_STAT v --EVENTS_VW v
							     join ${schema}.EVENTS_HEADER h on v.orgId = h.orgId and v.uuid = h.uuid
								 left join ${schema}.OBJECTS o on v.orgId = o.orgId and v.uuid = o.uuid and v.object = o.object_file
								 left join ${schema}.viewer vw  on v.orgId = vw.orgId and v.uuid = vw.uuid and v.viewer = vw.viewer
							where 1 = 1 -- session is not null and
							      -- anchor_stat_flag = 1
									${(object != null)
											? `and v.object = ${quote_string(object)}`
											: (selectedContentFiles.length > 0 ? `AND v.object IN (${selectedContentFiles.map(s => quote_string(s)).join(",")})` : '')
									}
							GROUP
								BY v.session,v.viewer,v.object,v.doc_type,vw.city,vw.state,vw.country,h.downloadable
						), cte_final as (
						select  session,
								viewer,
								object,
								page,
								case when upper(doc_type) = 'MEDIA' then media_duration::int else duration_seconds::int end as duration_seconds,
								--least((DATE_PART('EPOCH',coalesce(END_TIMESTAMP::timestamp,current_timestamp::timestamp)) - DATE_PART('EPOCH',START_TIMESTAMP::timestamp)),300)::int as duration_seconds,
								START_TIMESTAMP,
								END_TIMESTAMP,
								download,
								downloadable,
								city,state,country
						from cte_main
						)
						select
							--session as "SESSION",
							--object as "CONTENT FILE",
							${schema !== 'CONTENT_FILE_AUDIT'?`object as "CONTENT FILE",`:''}
							${schema === 'CONTENT_FILE_AUDIT'?`viewer as "VIEWER",`:''}
							START_TIMESTAMP as "START TIMESTAMP",
							END_TIMESTAMP as "END TIMESTAMP",
							page as "PAGES VIEWED",
							--container_name as "CONTAINER NAME",
							(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as "DURATION",
							case when downloadable = 'no' then 'N/A' else download::text end as "DOWNLOADS",
							city as "CITY",
							state as "STATE",
							country as "COUNTRY"
						from cte_final
						where object is not null
						--order by viewer,START_TIMESTAMP
						order by ${schema === 'CONTENT_FILE_AUDIT'
										? 'lower(object),START_TIMESTAMP,lower(viewer)'
										: 'lower(viewer),START_TIMESTAMP'
								}
							`}
						render={(data) => {
							return <ReactTable data={data} title={object} />;
						}}
					/>

				</>;
			})}
		</div>}
	</>
};

export default ViewerAuditTab;
