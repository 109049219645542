import { BoxProps } from "@mui/material";
import Plot from "react-plotly.js";
import { findColumns } from "./lt_analytics_client";
import { Data, Layout, LayoutAxis } from "plotly.js";
import deepMerge from "../../utils/deepMerge";

type Props = {
  data: Record<string, any>[];
  style?: Partial<React.CSSProperties>;
  layout?: Partial<Layout>;
  title?: string;
  maxX?: number;
  maxY?: number;
} & BoxProps;

const VerticalBarGraph: React.FC<Props> = (props) => {
  if (!props.data?.length) {
    return <h3>No Data Found</h3>;
  }

  const columns = findColumns(props.data);

  //const values = columns.map((c) => props.data[0][c]);
  const valuesX = props.data.map((row) => row[columns[0]]);
  const valuesY = props.data.map((row) => row[columns[1]]);

  const plot: Data[] = [
    {
      type: "bar",
      x: valuesX,
      y: valuesY,
      orientation: "v",
      marker: {
        color: '#35548E',
      },
    },
  ];

  const yaxis: Partial<LayoutAxis> = {
    side: "top",
    title: columns[1],
    tick0: 0,
  };

  if (valuesY.every(value => !value)) {
    yaxis.range = [0, 10];
  }

  let layout: Partial<Layout> = {
    autosize: true,
    title: props.title,
    barmode: "group",
    xaxis: {
      title: columns[0],
      tick0: 0,
      dtick: 1,
      ...(props.maxX && { range: [0.25, props.maxX + 0.75] }),
    },
    yaxis: yaxis,
  };

  return <Plot
    style={{ width: '100%', height: '100%', ...props.style }}
    data={plot}
    layout={deepMerge(layout, props.layout)}
    useResizeHandler
  />;
};

export default VerticalBarGraph;
