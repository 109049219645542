import * as React from 'react';
import { JSONFormsContextType } from './@types/jsonFormsContext';
import { FileContextType } from './@types/filesContext';
import { DownloadableContextType } from './@types/downloadableContext';
import { JSONFormsContext, createBlankForm } from './JSONFormsContext';
import { FilesContext } from './FilesContext';
import { DownloadableContext } from './DownloadableContext';

export type EncoderContextType = {
	form: JSONFormsContextType,
	tags: { tags: string[], set_tags: (value: string[]) => void },
	files: FileContextType,
	downloadable: DownloadableContextType,
	result: any,
	set_result: (result: any) => void,
	clear_form: () => void,
	request_result: any,
	set_request_result: (result: any) => void,
};

export const EncoderContext = React.createContext<EncoderContextType | null>(null);

export const EncoderContextProvider: React.FC<any> = ({ children }) => {
	const form = React.useContext(JSONFormsContext)!;
	const [tags, set_tags] = React.useState<string[]>([]);
	const files = React.useContext(FilesContext)!;
	const downloadable = React.useContext(DownloadableContext)!;
	const [result, set_result] = React.useState<any>(null);
	const [request_result, set_request_result] = React.useState<any>(null);

	return <EncoderContext.Provider value={{
		form,
		tags: { tags, set_tags },
		files,
		downloadable,
		result, set_result,
		request_result, set_request_result,
		clear_form: () => {
			form.updateForm(createBlankForm);
			set_tags([]);
			files.updateFiles([]);
			downloadable.setDownloadable(false);
			set_result(null);
			set_request_result(null);
		},
	}}>
		{children}
	</EncoderContext.Provider>;
};

export default EncoderContext;
