import IconButton from '@mui/material/IconButton';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { Link } from 'react-router-dom';
import PUBLIC_URL from '../../utils/PUBLIC_URL';

interface Props {
  file?: any;
}

export default function AnalyticsButton(props: Props) {
  let { file } = props;
  return (
    <>
      {/* {file && file.public_url ? ( */}
        <IconButton
          aria-label='delete'
          size='large'
          component={Link}
          to={`/files/analytics/${file.uuid}`}>
          <AnalyticsIcon />
        </IconButton>
      {/* ) : (
        <></>
      )} */}
    </>
  );
}
