import {
  AddCircle,
  AlternateEmail,
  ContactMail,
  CurrencyExchange,
  Dashboard,
  Folder,
  Groups,
  Help,
  Home,
  Key,
  Leaderboard,
  Logout,
  ManageSearch,
  Notifications,
  PeopleAlt,
  QuestionMark,
  Settings,
} from "@mui/icons-material";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import FolderIcon from "@mui/icons-material/Folder";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Collapse,
  Container,
  Fab,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Modal,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import ApiClient from "./api/ApiClient";
import EncoderContext from "./context/EncoderContext";
import { OrgContext } from "./context/OrgContext";
import PUBLIC_URL from "./utils/PUBLIC_URL";
import theme from "./theme";
import { styled, Drawer as MuiDrawer } from "@mui/material";
import { Paper } from "@mui/material";
import site_map, { get_url, Tab, Page, get_current_location } from "./site_map";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { isOutlook } from "./utils/office";
import EncoderStep from "./components/encoder";

const Drawer = styled(MuiDrawer)({
  position: "relative", //imp
  width: 240, //drawer width
  "& .MuiDrawer-paper": {
    width: 240, //drawer width
    position: "absolute", //imp
    transition: "none !important",
  },
});

type Props = {
  children: React.ReactElement<any, any>;
};

export default function Authenticated (props: Props) {
  const { clear_form } = React.useContext(EncoderContext)!;
  const location = useLocation();
  const [current_page, current_tab] = get_current_location(location);

  React.useEffect(() => console.log('current_page:', current_page, 'current_tab:', current_tab), [current_page, current_tab]);

  const logout_url = `${
    process.env.REACT_APP_AUTH_URL
  }/logout?redirect=${encodeURIComponent(window.location.toString())}`;

  const useStyles = makeStyles({
    Main: {
      width: "100%",
      height: "100%",
    },
    main: {
      width: "100%",
      height: "100%",
      //marginTop: "100px",
      padding: "20px",
      [theme.breakpoints.down("sm")]: {
        padding: "8px",
      },
    },
    NavButtonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      width: "160px",
    },
  });

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const classes = useStyles();

  const [missingConsents, setMissingConsents] = React.useState<any[]>([]);
  const [requiredConsents, setRequiredConsents] = React.useState<any[]>([]);
  const [optionalConsents, setOptionalConsents] = React.useState<any[]>([]);
  const [isRequiredChecked, setRequiredIsChecked] = React.useState(false);
  const [isOptionalChecked, setOptionalIsChecked] = React.useState<boolean[]>(
    []
  );
  const [visibleConsent, setVisibleConsent] = React.useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [helpOpen, setHelpOpen] = React.useState(false);

  const { org, set_org, api, stop_session } = React.useContext(OrgContext)!;

  React.useEffect(() => {
    (async () => {
      if (!org) {
        let api = new ApiClient();
        try {
          const new_org = await api.getOrgInfo();
          if (new_org.domain) {
            console.log("redirecting to", new_org.domain);
            // This is actually a redirect to a different domain - construct and follow the redirect
            const cookies = document.cookie
              .split(/; */)
              .map((cookie) => cookie.split("="))
              .reduce((cookies, [name, value]) => {
                cookies[name] = decodeURIComponent(value);
                return cookies;
              }, {} as Record<string, string>);

            window.location.href = `https://${
              new_org.domain
            }.locktera.com/auth/?locktera-token=${encodeURIComponent(
              cookies["locktera-token"]
            )}&redirect=${encodeURIComponent(
              `https://${new_org.domain}.locktera.com/`
            )}`;
            return;
          }
          set_org(new_org);
          api = new ApiClient(new_org.id);
          // console.log("API: ", api);
        } catch (err: any) {
          console.log("error getting org:", err);
          switch (err.response?.status) {
            case 401:
              // Unauthorized - need to log in
              break;
            case 403:
              if (!isOutlook()) {
                alert(
                  "That email has been deactivated. Please try again or contact your administrator."
                );
              }
              break;
            case 404:
              if (!isOutlook()) {
                alert(
                  "That email is not subscribed. Please try again or contact your administrator."
                );
              }
              break;
          }

          if (isOutlook()) {
            console.log("trying Office login");
            try {
              const token = await Office.auth.getAccessToken({
                allowSignInPrompt: true,
              });
              console.log("Office token:", token);
              window.location.href = `${
                process.env.REACT_APP_AUTH_URL
              }/login/microsoft/jwt?token=${encodeURIComponent(
                token
              )}&redirect=${encodeURIComponent(window.location.toString())}`;
              return;
            } catch (err) {
              console.error("error getting Outlook access token:", err);
            }
          }

          window.location.href = logout_url;
          return;
        }

        await loadConsents(api);
      }
    })();
  }, [org]);

  async function loadConsents(api_ = api) {
    const missingConsents = await api_?.get_required_consents();
    console.log("Missing consents: ", missingConsents);
    setMissingConsents(missingConsents);
    const requiredConsents = missingConsents.filter(
      (consentObj: any) => !consentObj.consent_optional
    );
    const optionalConsents = missingConsents.filter(
      (consentObj: any) => consentObj.consent_optional
    );
    setRequiredConsents(requiredConsents);
    setRequiredIsChecked(false);
    setOptionalConsents(optionalConsents);
    setOptionalIsChecked(optionalConsents.map(() => false));
  }

  const getRequiredCheckboxLinks = () => {
    const links = requiredConsents.map((consent) =>
      consent.consent_url ? (
        <a href={consent.consent_url} target="_blank">
          {consent.consent_checkbox}
        </a>
      ) : (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            openConsentTextModal(consent);
          }}
        >
          {consent.consent_checkbox}
        </a>
      )
    );

    function join<T>(array: T[], separator: T) {
      const joined: T[] = [];
      for (let i = 0; i < array.length; i++) {
        if (i > 0) joined.push(separator);
        joined.push(array[i]);
      }
      return joined;
    }

    if (links.length <= 1) {
      return links;
    } else if (links.length === 2) {
      // Add an ' and ' between the two links
      return join(links, <> and </>);
    } else {
      // Add commas between all but the last two links, then add ', and ' between the last two
      // Oxford (or serial terminal) commas are absolutely correct, and anyone who thinks otherwise is wrong.
      return join(links.slice(0, -1), <>, </>).concat(
        <>, and </>,
        links[links.length - 1]
      );
    }
  };

  const handleRequiredCheckboxChange = () => {
    setRequiredIsChecked(!isRequiredChecked);
  };

  const handleOptionalCheckboxChange = (index: number) => {
    isOptionalChecked[index] = !isOptionalChecked[index];
    setOptionalIsChecked(isOptionalChecked.slice()); // .slice() to get a copy, otherwise React won't know it changed
  };

  const handleSubmit = async () => {
    for (const consent of requiredConsents) {
      await api?.save_consent(consent.id, true);
    }

    for (let i = 0; i < optionalConsents.length; i++) {
      await api?.save_consent(optionalConsents[i].id, isOptionalChecked[i]);
    }

    loadConsents();
  };

  function openConsentTextModal(consent: any) {
    setVisibleConsent(consent);
  }

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const [hoveringMenu, setHoveringMenu] = React.useState(false);
  const [hoveringDrawer, setHoveringDrawer] = React.useState(false);

  function handleMenuMouseEnter() {
    setHoveringMenu(true);
  };

  function handleMenuMouseLeave() {
    setHoveringMenu(false);
  };

  function handleDrawerEnter() {
    setHoveringDrawer(true);
  };

  function handleDrawerLeave() {
    setHoveringDrawer(false);
  };

  function handleDrawer() {
    if ((hoveringMenu == true || hoveringDrawer == true) && (current_page?.tabs)) {
      return true;
    } else {
      return false;
    }
  }

  const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 1280,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    maxHeight: "80%",
    display: "flex",
    flexDirection: "column",
  };

  return (
    <>
      {!isOutlook()
        ? <Box
          id="site-container"
          className={classes.Main}
          style={{
            display: "grid",
            gridTemplateRows: "min-content auto",
            gridTemplateColumns: "min-content auto",
          }}
        >
          <Box
            id="logo"
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              backgroundColor: "#2B5592",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "8px",
              }}
            >
              <img
                src={`${PUBLIC_URL}/LOCKTERA_Logo_new.png`}
                alt="Locktera header"
                height="32px"
                style={{
                  maxWidth: "100%",
                  // maxHeight: "32px",
                  height: "auto",
                }}
              ></img>
            </div>
          </Box>
          <Box
            id="page-title"
            style={{
              borderBottom: "1px solid #333",
              marginLeft: "8px",
              padding: "8px 8px 8px 0",
              display: "flex",
              alignItems: "center",
              gap: '8px'
            }}
          >
            <Typography variant="h2" style={{ flexGrow: 1, fontSize: '32px' }}>{current_page?.name ?? 'Locktera'}</Typography>
            {org && <Typography variant="body1" style={{ textAlign: "right" }}>{org.first_name} {org.last_name}<br />{org.email}</Typography>}
            <Tooltip title={<Typography variant="body1">Help</Typography>}>
              <Fab color="success" size="small">
                <QuestionMark fontSize="large" onClick={() => setHelpOpen(!helpOpen)} />
              </Fab>
            </Tooltip>
          </Box>
          <Box
            onMouseEnter={handleMenuMouseEnter}
            onMouseLeave={handleMenuMouseLeave}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#2B5592",
            }}
            id="nav-icons"
          >
            {site_map.authenticated.map((list, i) => {
              return (
                <List key={i.toString()}>
                  {list.map((page) => (
                    <ListItem
                      key={page.name}
                      sx={{
                        backgroundColor: page === current_page ? '#aabbd3' : undefined,
                        // borderLeft: page === current_page ? '4px solid #65bbe3' : undefined,
                      }}
                    >
                      <Tooltip title={<Typography variant="body1">{page.name}</Typography>} placement="right">
                        <IconButton
                          href={page.href ?? (page.tabs ? `#${get_url(page)}${get_url(page.tabs![0])}` : `#${get_url(page)}`)}
                          sx={{ color: "white" }}
                        >
                          {page.icon()}
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                  ))}
                </List>
              );
            })}
          </Box>
          <Box
            id="site-body"
            sx={{
              overflow: "scroll",
            }}
          >
            <Collapse
              id="nav-drawer"
              onMouseEnter={handleDrawerEnter}
              onMouseLeave={handleDrawerLeave}
              in={handleDrawer()}
              orientation="horizontal"
              sx={{
                height: "100vh",
                zIndex: 100,
                position: "fixed",
              }}
            >
              <Paper
                sx={{ width: 250, height: "100%", backgroundColor: "#C6D6EB" }}
                role="presentation"
              >
                <List>
                  {current_page?.tabs?.map((tab, index) => (
                    <ListItem key={tab.name} disablePadding sx={{
                      color: tab === current_tab ? '#2f5995' : undefined,
                    }}>
                      <ListItemButton
                        href={`#${get_url(current_page!)}${get_url(tab)}`}
                      >
                        <ListItemText
                          primary={tab.name}
                          style={{ fontSize: "2.2em", textTransform: 'uppercase', padding: '10px', fontWeight: 'bold' }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Collapse>
            <Box id="page-viewport" sx={{ display: "flex" }}>
              <Box id="page-body" sx={{ flexGrow: 1, padding: 1 }}>
                {props.children}
              </Box>
              {(current_tab?.help_url ?? current_page?.help_url) && <>
                <Collapse orientation="horizontal" in={helpOpen} sx={{
                  height: "100vh",
                  zIndex: 100,
                  position: "fixed",
                  right: 0,
                }}>
                  <iframe style={{ width: '20vw', minWidth: "320px", border: 0, borderLeft: '1px solid #000', height: "100%" }} src={current_page?.help_url || ''}></iframe>
                </Collapse>
              </>}
            </Box>
          </Box>
        </Box>
        : <Box sx={{ overflow: 'auto' }}>
          <Toolbar sx={{ backgroundColor: '#2b5592', color: '#fff', justifyContent: 'flex-end' }}>
            {org && <Typography variant="body1" style={{ textAlign: "right" }}>{org.first_name} {org.last_name}</Typography>}
            <Tooltip title={<Typography variant="body1">Dashboard</Typography>}>
              <IconButton href={`${PUBLIC_URL}`}
                target="_blank"
                sx={{color: '#fff'}}
                size="large"
              >
                <Dashboard fontSize="large" />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Typography variant="body1">Help</Typography>}>
              <Fab color="success" size="small" href="https://locktera.com/locktera-outlookadd-in/" target="_blank" >
                <QuestionMark fontSize="large" />
              </Fab>
            </Tooltip>
          </Toolbar>
          <Container>
            <EncoderStep mode="default" />
          </Container>
        </Box>}
      {missingConsents.length !== 0 && (
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={`${PUBLIC_URL}/LOCKTERA_Logo_new.png`}
                alt="Logo"
                style={{
                  width: "100px",
                  height: "auto",
                  marginLeft: "-20px",
                  marginTop: "-20px",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
              />
            </Box>

            {process.env.REACT_APP_TRIAL && (
              <Typography
                variant="body1"
                component="div"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                This trial license is intended for internal testing
                (non-production) and evaluation use purposes only and for no
                other purposes. Licensee should use test data only.
              </Typography>
            )}

            {requiredConsents.length > 0 && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRequiredChecked}
                    onChange={handleRequiredCheckboxChange}
                  />
                }
                label={
                  <>
                    I have read and agree to the {getRequiredCheckboxLinks()}.
                  </>
                }
              />
            )}

            {optionalConsents.map((consent, index) => (
              <FormControlLabel
                key={consent.id}
                control={
                  <Checkbox
                    checked={isOptionalChecked[index]}
                    onChange={() => handleOptionalCheckboxChange(index)}
                  />
                }
                label={consent.consent_checkbox}
              />
            ))}

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={requiredConsents.length > 0 && !isRequiredChecked}
            >
              Submit
            </Button>
            {visibleConsent != null && (
              <Modal
                open={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={() => {
                  setVisibleConsent(null);
                }}
              >
                <Box sx={modalStyle}>
                  <h2>{visibleConsent.consent_name}</h2>
                  <p style={{ whiteSpace: "pre-line", overflow: "scroll" }}>
                    {visibleConsent.consent_text}
                  </p>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setVisibleConsent(null);
                    }}
                  >
                    Done
                  </Button>
                </Box>
              </Modal>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
}
