import * as React from 'react';

import { QueueContextType } from './@types/queueContext';

export const QueueContext = React.createContext<QueueContextType | null>(null);

const JSONQueueProvider: React.FC<any> = ({ children }) => {
  const [queue, setQueue] = React.useState<any>({});

  const updateQueue = (queue: any) => {
    setQueue(queue);
  };

  return (
    <QueueContext.Provider value={{ queue, updateQueue }}>
      {children}
    </QueueContext.Provider>
  );
};

export default JSONQueueProvider;
