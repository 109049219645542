import axios, { AxiosInstance, AxiosError } from 'axios';
import { getEmail } from './index';

export type OrgRole = 'org' | 'group' | 'org_admin' | 'user_admin' | 'user';

export type Org = {
  id?: string,
  parent_org_id?: string,
  is_user_flag?: boolean,
  role?: OrgRole,
  org_name?: string,
  first_name?: string,
  last_name?: string,
  email?: string,
  mobile_phone?: string,
  totp_key?: string,
  totp_removal_code?: string,
  large_file?: boolean,
};

export type ChartOrg = Org & { token: string };

export default class ApiClient {
  org_id?: string;
  user?: string;
  api_key?: string;
  url: string;
  client: AxiosInstance;

  constructor(
    org_id?: string,
    user?: string,
    api_key?: string,
    url: string = process.env.REACT_APP_API_URL!
  ) {
    this.org_id = org_id; //ex. e52098c9-1768-4728-af1b-727cc28f7d63
    this.user = user;
    this.api_key = api_key; //ex. c4b9a13e-9ab1-473a-916a-7f50825d05b4
    this.url = url;

    // Pre-fill useful defaults for all API calls
    this.client = axios.create({
      baseURL: this.url,
      headers: api_key ? {
        Authorization: `Bearer ${this.api_key}`,
      } : undefined,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      withCredentials: true,
    });
  }

  async fetch(resource: RequestInfo | URL, options: RequestInit = {}){
    options.credentials = "include"
    if(this.api_key){
      ((options.headers ||= {}) as Record<string, string>).authorization = `Bearer ${this.api_key}`
    }
    return await fetch(`${this.url}${resource}`, options)
  }

  async report_error (body: ErrorBody) {
    await this.client.post(`/report_error`, {
      ...body,
      org_id: this.org_id,
      user: this.user,
      level: body.level || "error"
    } as ErrorBody)
  }

  async encode(manifest: any, files: any, fileName: string) {
    let filesObjects: any = {};
    // console.log('manifest', manifest);
    // console.log('files', files);
    // console.log('fileName', fileName);
    const form = new FormData();

    if (fileName) form.append('fileName', fileName);

    for (let index = 0; index < files.length; index++) {
      // key equal to id (name uuid) and value is equal to file object
      filesObjects[files[index].name] = files[index].file;
    }

    // console.log('filesObjects', filesObjects);

    for (const [name, part] of Object.entries(manifest.files.parts)) {
      const { file, filename } = part as any;
      // console.log('name', name);
      // console.log('part', part);
      // console.log('file', file);
      // console.log('filename', filename);
      //   if (content) {
      //     // Decode inline content
      //     form.append(name, new Blob([decodeBase64(content)]), filename);
      //   } else if (file) {
      //     form.append(name, file, filename);
      //   }
      form.append(name, filesObjects[name], filename);
    }

    // console.log('manifest:', manifest);
    form.append('manifest', new Blob([JSON.stringify(manifest)]));

    // console.log('form:', form);

    const response = await this.client.post(
      `/encoder/org_id/${this.org_id}`,
      form
    );
    return response.data;
  }

  async getStatus(uuid: string) {
    const response = await this.client.get(
      `/check_files/org_id/${this.org_id}/task_ids/${uuid}`
    );
    return response.data.shift();
  }

  async check_service_status() {
    const response = await this.client.get(
      `/check_service_status`
    )
    return response.data;
  }

  async downloadFile(uuid: string) {
    const response = await this.client.get(
      `/download_file/org_id/${this.org_id}/file_entry_id/${uuid}`,
      { responseType: 'blob' }
    );
    return response.data;
  }

  async getFiles() {
    try {
      const response = await this.client.get(
        `/get_file_entry/org_id/${this.org_id}`
      );
      return response.data;
    } catch (error) {
      console.log('getFiles error:', error);
      throw error;
    }
  }
  // publish tera
  async publish(uuid: string) {
    const response = await this.client.post(
      `/publish_file/org_id/${this.org_id}/file_entry_id/${uuid}`
    );
    return response.data.url;
  }
  // unpublish tera
  async unpublish(uuid: string) {
    const response = await this.client.delete(
      `/unpublish_file/org_id/${this.org_id}/file_entry_id/${uuid}`
    );
    return response.data;
  }

  // block tera from rendering
  async block(uuid: string, reason_msg: string) {
    const data = { reason: reason_msg };
    const response = await this.client.put(
      `/block_file/org_id/${this.org_id}/uuid/${uuid}`,
      data
    );
    return response.data;
  }

  // unblock tera from rendering
  async unblock(uuid: string) {
    const response = await this.client.delete(
      `/unblock_file/org_id/${this.org_id}/uuid/${uuid}`
    );
    return response.data;
  }
  // get tera block exceptions
  async getExceptions(uuid: string) {
    const response = await this.client.get(
      `/exception_file/org_id/${this.org_id}/uuid/${uuid}`
    );
    // console.log('exc response', response);
    return response.data;
  }

  // delete file
  async delete(uuid: string) {
    const response = await this.client.delete(
      `/delete_file/org_id/${this.org_id}/file_entry_id/${uuid}`
    );
    return response.data;
  }

  // get event data
  async getEvents(uuid: string) {
    const response = await this.client.get(
      `/file_events/org_id/${this.org_id}/uuid/${uuid}`
    );
    // console.log("response getEvents", response);
    return response.data;
  }

  // get user event access data
  async getUserAccess(uuid: string) {
    const response = await this.client.get(
      `/file_user_access/org_id/${this.org_id}/uuid/${uuid}`
    );
    // console.log("response getEvents", response);
    return response.data;
  }

  // search for file
  async searchForFiles(body: any) {
    try {
      // console.log('body', body);
      const response = await this.client.post(`/search_files/org_id/${this.org_id}`, body);
      // console.log('response searchForFiles', response);
      return response.data;
    } catch (error) {
      console.log('searchForFiles error', error);
    }
  }

  // get org info/data
  async getOrgInfo() {
    const response = await this.client.get(`/org_info${this.org_id ? `/org_id/${this.org_id}` : ''}`);
    // console.log('response getOrgInfo', response);
    return response.data;
  }

  // get admin get org tera counts
  async getOrgTeraCounts() {
    const response = await this.client.get(
      `/admin/get_org_tera_counts/org_id/${this.org_id}`
    );
    // console.log('response getOrgTeraCounts', response);
    return response.data;
  }

  // admin get event counts
  async getOrgEventCounts() {
    const response = await this.client.get(
      `/admin/get_event_counts/org_id/${this.org_id}`
    );
    // console.log('response getOrgEventCounts', response);
    return response.data;
  }

  // get all manifest files associated with org
  async getTemplates() {
    const response = await this.client.get(
      `/get_manifests/org_id/${this.org_id}`
    );
    // console.log('response getTemplates', response);
    return response.data;
  }
  // post manifest file associated with org
  async postTemplate(template: any) {
    const response = await this.client.post(`/post_manifest`, template);
    // console.log('response postTemplate', response);
    return response.data;
  }
  // update manifest file by id associated with org
  async updateTemplate(id: string, template: any) {
    const response = await this.client.patch(
      `/update_manifest/id/${id}`,
      template
    );
    // console.log('response updateTemplate', response);
    return response.data;
  }
  // delete manifest file by id associated with org
  async deleteTemplate(id: string) {
    const response = await this.client.delete(
      `/delete_manifest/org_id/${this.org_id}/id/${id}`
    );
    // console.log('response deleteTemplate', response);
    return response.data;
  }

  async addEvent (uuid: string, action: string, detail?: any) {
    const org = await this.getOrgInfo();

    await this.client.post(`/add_event/org_id/${this.org_id}/uuid/${uuid}`, {
      user: org?.email,
      action,
      detail,
      created_date: new Date().toISOString(),
    });
  }

  async provisionOrg(org: Org, restore = false) {
    try {
      const url = org.parent_org_id
        ? `/provision_org/org_id/${org.parent_org_id}${restore ? `?restore=true` : ''}`
        : `/provision_org`
      const rsp = await this.client.post(url, org);
      return rsp.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        throw err.response?.data;
      } else {
        throw err;
      }
    }
  }

  async update_org(org: Org) {
    const rsp = await this.client.patch(`/update_org/org_id/${org.id}`, org);
    return rsp.data;
  }

  async delete_org(org: Org) {
    const rsp = await this.client.delete(`/delete_org/org_id/${org.id}`);
    return rsp.data;
  }

  async import_orgs(org_id: string, data: string) {
    const rsp = await this.client.post(`/import_orgs/org_id/${org_id}`, data, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
    return rsp.data;
  }

  async save_user(email: string, mobile_phone: string, org_id = this.org_id, token = this.api_key) {
    const data = { org_id, email, mobile_phone };
    const rsp = await this.client.post(
      `/save_org_user/org_id/${org_id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return rsp.data;
  }

  async get_org_chart (org_id: string | null | undefined = this.org_id, include_deleted = false): Promise<{ orgs: ChartOrg[] }> {
    const rsp = await this.client.get(`/org_chart/org_id/${org_id}${include_deleted ? '?include_deleted=true' : ''}`);
    return rsp.data;
  }

  async get_org_addresses() {
    const rsp = await this.client.get(`/get_org_addresses/org_id/${this.org_id}`);
    return await rsp.data;
  }

  async get_org_errors(org_id = this.org_id, start: Date | null, end: Date | null) {
    const rsp = await this.client.get(`/get_errors/org_id/${org_id}?start=${start ? start.toISOString() : ""}&end=${end ? end.toISOString() : ""}`);
    return await rsp.data;
  }

  async get_email_audit (start: Date | null, end: Date | null){
    const rsp = await this.client.get(`/get_email_audit?start=${start ? start.toISOString() : ""}&end=${end ? end.toISOString() : ""}`);
    return await rsp.data;
  }

  async get_address_audit (start: Date | null, end: Date | null, org_ids = [this.org_id]) {
    const rsp = await this.client.get(`/get_address_audit/org_ids/${org_ids.join(',')}?start=${start ? start.toISOString() : ""}&end=${end ? end.toISOString() : ""}`);
    return await rsp.data;
  }

  async get_storage_config (){
    const rsp = await this.client.get(`/get_storage_config/org_id/${this.org_id}`);
    return await rsp.data;
  }

  async save_storage_config(config: any){
    const response = await this.client.put(
      `/save_storage_config/org_id/${this.org_id}`,
      config
    );
    return response.data;
  }

  async get_org_audit (start: Date | null, end: Date | null, org_ids = [this.org_id]) {
    const rsp = await this.client.get(`/get_org_audit/org_ids/${org_ids.join(',')}?start=${start ? start.toISOString() : ""}&end=${end ? end.toISOString() : ""}`);
    return await rsp.data;
  }

  async get_org_access (start: Date | null, end: Date | null, org_ids = [this.org_id]) {
    const rsp = await this.client.get(`/get_org_access/org_ids/${org_ids.join(',')}?start=${start ? start.toISOString() : ""}&end=${end ? end.toISOString() : ""}`);
    return await rsp.data;
  }

  async get_container_tags_audit (start: Date | null, end: Date | null, org_ids = [this.org_id]) {
    const rsp = await this.client.get(`/get_container_tags_audit/org_ids/${org_ids.join(',')}?start=${start ? start.toISOString() : ""}&end=${end ? end.toISOString() : ""}`);
    return await rsp.data;
  }

  async get_org_customizations(org_id = this.org_id){
    const rsp = await this.client.get(`/get_org_customizations/org_id/${org_id}`);
    return await rsp.data;
  }

  async set_org_customizations(customizations: any, org_id = this.org_id){
    const rsp = await this.client.post(`/set_org_customizations/org_id/${org_id}`, customizations);
    return rsp.data;
  }

  async save_address (email: string, first_name?: string, last_name?: string, mobile_phone?: string, company?: string) {
    const data = { org_id: this.org_id, email, first_name, last_name, mobile_phone, company };
    const rsp = await this.client.post(
      `/save_org_address/org_id/${this.org_id}`,
      data,
    );
    return rsp.data;
  }

  async delete_address(email: string) {
    const data = { org_id: this.org_id, email };
    const rsp = await this.client.delete(
      `/delete_org_address/org_id/${this.org_id}/email/${encodeURIComponent(email)}`,
    );
    return rsp.data;
  }

  async import_addresses(data: string) {
    const rsp = await this.client.post(`/import_addresses/org_id/${this.org_id}`, data, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
    return rsp.data;
  }

  async get_containers_by_name(...container_names: string[]) {
    const rsp = await this.client.get(`/get_file_entry/org_id/${this.org_id}/file_names/${encodeURIComponent(container_names.join(','))}`);
    return rsp.data;
  }

  async add_container_tags(uuid: string, tags: string[]) {
    const rsp = await this.client.put(`/add_container_tags/org_id/${this.org_id}/uuid/${uuid}?tags=${encodeURIComponent(tags.join(","))}`)
    return rsp.data;
  }

  async delete_container_tags(uuid: string, tags: string[]) {
    const rsp = await this.client.delete(`/delete_container_tags/org_id/${this.org_id}/uuid/${uuid}?tags=${encodeURIComponent(tags.join(","))}`)
    return rsp.data;
  }

  async get_all_container_tags() {
    const rsp = await this.client.get(`/get_all_tags/org_id/${this.org_id}`)
    return rsp.data;
  }

  async get_all_container_keys() {
    const rsp = await this.client.get(`/get_all_keys/org_id/${this.org_id}`)
    return rsp.data;
  }

  async get_root_orgs() {
    const rsp = await this.client.get(`/super/get_root_orgs`);
    return rsp.data;
  }

  async get_required_consents() {
    const rsp = await this.client.get(`/get_required_consents/org_id/${this.org_id}`);
    return rsp.data;
  }

  async save_consent(consent_id: string, isChecked: boolean) {
    await this.client.post(`/save_consent/org_id/${this.org_id}/consent_id/${consent_id}`, {
      consent_id, is_checked: isChecked,
    });

  }

  /**
  async save_address (email: string, first_name: string, last_name: string, mobile_phone: string, company: string) {
    const data = { org_id: this.org_id, email, first_name, last_name, mobile_phone, company };
    const rsp = await this.client.post(
      `/save_org_address/org_id/${this.org_id}`,
      data,
    );
    return rsp.data;
  }
  */

  async create_request(request_data: any) {
    await this.client.post(`/create_request/org_id/${this.org_id}`,
      request_data
    );
  }

  async get_request(org_id: string, request_id: string) {
    const rsp = await this.client.get(`/get_request/org_id/${org_id}/uuid/${request_id}`)
    return rsp.data;
  }

  async get_container_attempts (container_id?: string) {
    const rsp = await this.client.get(`/get_container_attempts/org_id/${this.org_id}${container_id ? `/uuid/${container_id}` : ''}`);
    return rsp.data;
  }

  async reset_container_attempts(container_id: string, email: string) {
    await this.client.post(`/reset_container_attempts/org_id/${this.org_id}/uuid/${container_id}`, { email });
  }

  async start_session () {
    const rsp = await this.client.get(`/start_session/org_id/${this.org_id}`);
    return rsp.data;
  }

  async stop_session (session_id: string) {
    const rsp = await this.fetch(`/stop_session/org_id/${this.org_id}/session_id/${session_id}`
      , { keepalive: true });
  }

  async upload_file_url (uuid: string, file: File, onProgress: (progressEvent: any) => void) {

    const urlResponse = await this.client.get(`/upload_file_url/org_id/${this.org_id}/uuid/${uuid}`);

    await axios.put(urlResponse.data, file, {
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
      },
      onUploadProgress: onProgress,
    });

  }

  async restore_file(uuid: string) {
    const rsp = await this.client.put(`/restore_file/org_id/${this.org_id}/file_entry_id/${uuid}`);
    return rsp.data;
  }

  async lookup_file_digest(digest: string) {
    const rsp = await this.client.get(`/lookup_file_digest/org_id/${this.org_id}?digest=${encodeURIComponent(digest)}`)
    return rsp.data;
  }

  async get_org_limits(root_org_id: string) {
    const rsp = await this.client.get(`/get_org_limits/root_org_id/${root_org_id}`)
    return rsp.data;
  }

  async get_org_consumption(root_org_id: string) {
    const rsp = await this.client.get(`/get_org_consumption/root_org_id/${root_org_id}`)
    return rsp.data;
  }

  async query_cosmos (container: string, query: string) {
    const rsp = await this.client.post(`/super/query_cosmos`, { container, query }, {responseType: 'blob'});
    return rsp.data;
  }

  async get_container (id: string) {
    const rsp = await this.client.get(`/get_file_entry/org_id/${this.org_id}/file_entry_id/${id}`);
    return rsp.data;
  }
}

type ErrorBody = {
  org_id?: string
  container_id?: string
  user?: string
  module?: string
  message?: string
  stack?: string
  level?: "debug" | "info" | "warning" | "error"
} & Record<string, any>
