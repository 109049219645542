import * as React from "react";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DataGrid from "../../components/DataGrid";
import FileViewerDialog from "../general/FileViewerDialog";
import Box from "@mui/material/Box";
import FileMenu from "./FileMenu";
import PublishButton from "./PublishedButton";
import AnalyticsButton from "./AnalyticsButton";
import moment from "moment";
import formatSize from "../../utils/formatsize";
import { Api, StayCurrentLandscapeTwoTone } from "@mui/icons-material";
import { Autocomplete, Chip, Stack, TextField } from "@mui/material";
import { fileURLToPath } from "url";
import { OrgContext } from "../../context/OrgContext";

interface Props {
  files?: any;
  refreshUI: () => void;
  tags: string[];
}

function TagsList({
  file,
  tags,
  refreshUI: refreshUI,
}: {
  file: any;
  tags: string[];
  refreshUI: () => void;
}) {
  const { api } = React.useContext(OrgContext)!;
  //console.log("Props", props);
  async function updateTags(newTags: string[]) {
    const addedItems = newTags.filter((item) => !file.tags?.includes(item));
    const removedItems = file.tags
      ? file.tags.filter((item: string) => !newTags.includes(item))
      : [];

    if (addedItems.length > 0) {
      await api!.add_container_tags(file.id, addedItems);
    }

    if (removedItems.length > 0) {
      await api!.delete_container_tags(file.id, removedItems);
    }
    refreshUI();
  }

  return (
    <>
      <Stack spacing={3} sx={{ width: 500 }}>
        <Autocomplete
          multiple
          id="tags-filled"
          options={tags}
          onChange={(e, v) => updateTags(v)}
          value={file.tags ?? []}
          freeSolo
          limitTags={3}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          onKeyDown={(e) => {
            // Prevent the DataGrid from handling this and blurring the autocomplete
            e.stopPropagation();
          }}
          renderInput={(params) => (
            <TextField
              sx={{ width: "100%" }}
              {...params}
              variant="standard"
              label="Tags"
              placeholder="Tags"
            />
          )}
        />
      </Stack>
    </>
  );
}

export default function FilesList(props: Props) {
  const { api } = React.useContext(OrgContext)!;
  let { files, refreshUI: refreshUI, tags: allTags } = props;
  const [open, setOpen] = React.useState(false);
  //const [allTags, setAllTags] = React.useState<string[]>([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCellClick = (
    param: any,
    event: { stopPropagation: () => void }
  ) => {
    // event.stopPropagation();
  };

  const handleRowClick = (
    param: any,
    event: { stopPropagation: () => void }
  ) => {
    // event.stopPropagation();
  };

  function showStatus(cellValue: any) {
    var currentDate = moment();
    var endDate = moment(cellValue.row.file.container_end_ts);
    var startDate = moment(cellValue.row.file.container_start_ts);
    // console.log("File: ", files);
    if (cellValue.row.file.blocked) {
      return "BLOCKED";
    }
    if (
      cellValue.row.file.container_end_ts &&
      cellValue.row.file.container_start_ts
    ) {
      if (currentDate.isBetween(startDate, endDate)) {
        return "ACTIVE";
      } else {
        return "EXPIRED";
      }
    } else if (cellValue.row.file.container_end_ts) {
      if (currentDate.isAfter(endDate)) {
        return "EXPIRED";
      }
    }
    return "ACTIVE";
  }

  const columns: GridColDef[] = [
    // {
    //   field: 'id',
    //   headerName: 'ID',
    //   width: 60,
    // },
    {
      field: "container_name",
      headerName: "CONTAINER NAME",
      width: 350,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>{params.value}</div>
      ),
    },
    {
      field: "uuid",
      headerName: "UUID",
      width: 300,
    },
    {
      field: "created_date",
      headerName: "CREATED",
      width: 150,
    },
    {
      field: "size",
      headerName: "SIZE",
      width: 110,
      renderCell: (cellValues: any) => {
        return formatSize(cellValues.row.size);
      },
    },
    {
      field: "published",
      headerName: "VIEW",
      disableExport: true,
      width: 100,
      renderCell: (cellValues: any) => {
        return <PublishButton file={cellValues.row} />;
      },
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 100,
      renderCell: (cellValues: any) => {
        return showStatus(cellValues);
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      width: 100,
      disableExport: true,
      renderCell: (cellValues: any) => {
        return <FileMenu file={cellValues.row.file} refreshUI={refreshUI} />;
      },
    },
    {
      field: "analytics",
      headerName: "ANALYTICS",
      width: 100,
      disableExport: true,
      renderCell: (cellValues: any) => {
        return <AnalyticsButton file={cellValues.row} />;
      },
    },
    {
      field: "tags",
      headerName: "TAGS",
      width: 550,
      renderCell: (cellValues: any) => {
        return (
          <TagsList
            file={cellValues.row.file}
            tags={allTags}
            key={cellValues.row.file.id}
            refreshUI={refreshUI}
          />
        );
      },
      // filterOperators: {

      // },
    },
  ];

  const rows = files.map((file: any, index: number) => {
    let createdDate = moment(file.created_date).local().format("lll");
    return {
      file,
      id: index,
      container_name: file.container_name,
      uuid: file.id,
      created_date: createdDate,
      size: file.container_size,
      public_url: file.public_url,
      org_id: file.org_id,
      // size: file.file.size,
      // file: file,
    };
  });
  return (
    <Box sx={{ height: 1000, width: "100%" }}>
      <DataGrid
        //rowHeight={65}
        initialState={{
          columns: {
            columnVisibilityModel: {
              uuid: false,
            },
          },
        }}
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        onCellClick={handleCellClick}
        onRowClick={handleRowClick}
      />
    </Box>
  );
}
