import React from "react";
import { Assignment, Drafts, Inventory, Share, Visibility, SignalCellularAlt, LockOpen } from "@mui/icons-material";
import { Box, Button, Container, FormControl, IconButton, InputLabel, TextField, Typography } from "@mui/material";
import DataGrid from "../components/DataGrid";
import PUBLIC_URL from "../utils/PUBLIC_URL";
import QueryComponent from "../components/analytics/QueryComponent";
import { OrgContext } from "../context/OrgContext";
import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { lt_analytics_client_main_v2 } from "../components/analytics/lt_analytics_client";
import DateRangeSelect, { DateRange, range_to_dates } from "../components/date_range_select/DateRangeSelect";
import Grid2 from "@mui/material/Unstable_Grid2";

export default function LandingPage(props: any) {
    const { org, api } = React.useContext(OrgContext);
    const [conn, setConn] = React.useState<AsyncDuckDBConnection>();
    const [date_range, set_date_range] = React.useState(DateRange.Last_14_Days)
    const schema = `LANDING_PAGE`;

    async function load_data () {
        if (!api) return;
        try {
            const start = performance.now();
            const [start_date, end_date] = range_to_dates(date_range)
            const conn = await lt_analytics_client_main_v2(
                [org.id],
                null,
                schema,
                null,
                start_date?.toISOString(),
                end_date?.toISOString()
            );
            const end = performance.now();
            if (end - start > 1000)
                api?.report_error({
                    module: "analytics",
                    message: "Slow Analytics Query",
                    duration_ms: end - start,
                    level: "warning",
                });
            setConn(conn);
        } catch (error) {
            api?.report_error({
                module: "analytics",
                message: `Error calculating analytics: ${error}`,
                stack: (error as any).stack,
            });
            throw error;
        }
    }

    const [attempts, setAttempts] = React.useState<any[]>([]);

    async function load_attempts () {
        if (!api) return;
        const newAttempts = await api!.get_container_attempts();
        const containerIds: string[] = Array.from(new Set(newAttempts.map((attempt: any) => attempt.container_id)));
        const containers = await Promise.all(containerIds.map(async id => await api!.get_container(id)));
        const containersById = containers.reduce((map, container) => {
            map[container.id] = container;
            return map;
        }, {});

        console.log('attempts:', newAttempts, 'containersById:', containersById);

        for (const attempt of newAttempts) {
            attempt.id = crypto.randomUUID();
            attempt.objects = containersById[attempt.container_id].objects.join('; ');
        }

        setAttempts(newAttempts);
    }

    React.useEffect(() => {
        load_data();
        load_attempts();
    }, [org, date_range]);

    async function unlock_viewer (container_id: string, email: string) {
        await api!.reset_container_attempts(container_id, email);
        await api?.addEvent(container_id, "viewer.unlock", { viewer: email })
        load_attempts();
    }

    const columns = [
        {
            field: "uuid",
            headerName: "UUID",
            width: 300,
        },
        {
            field: "ts",
            headerName: "DATE/TIME",
            width: 150,
            valueFormatter(params: any) { return new Date(params.value).toLocaleString() }
        },
        {
            field: "viewer",
            headerName: "VIEWERS",
            width: 150,
        },
        {
            field: "objects",
            headerName: "CONTENT FILES",
            width: 250,
        },
        {
            field: "view",
            headerName: "VIEW",
            width: 80,
            disableExport: true,
            renderCell: (cellValues: any) => (
                <IconButton aria-label='delete' size='large' onClick={() => {
                    window.open(
                        `${PUBLIC_URL}/auth/view/${cellValues.row.orgId}/${cellValues.row.uuid}`
                    );
                }}>
                    <Visibility />
                </IconButton>
            )
        },
        {
            field: "analytics",
            headerName: "ANALYTICS",
            width: 80,
            disableExport: true,
            renderCell: (cellValues: any) => (
                <IconButton aria-label='delete' size='large' onClick={() => {
                    window.open(
                        `${PUBLIC_URL}/#/files/analytics/${cellValues.row.uuid}`
                    );
                }}>
                    <SignalCellularAlt />
                </IconButton>
            )
        },
    ];

    const attemptColumns = [
        {
            field: "container_id",
            headerName: "UUID",
            width: 300,
        },
        {
            field: "update_timestamp",
            headerName: "DATE/TIME",
            width: 150,
            valueFormatter (params: any) { return new Date(params.value).toLocaleString() }
        },
        {
            field: "email",
            headerName: "VIEWERS",
            width: 150,
        },
        {
            field: "objects",
            headerName: "CONTENT FILES",
            width: 250,
        },
        // {
        //     field: "view",
        //     headerName: "VIEW",
        //     width: 80,
        //     disableExport: true,
        //     renderCell: (cellValues: any) => (
        //         <IconButton aria-label='delete' size='large' onClick={() => {
        //             window.open(
        //                 `${PUBLIC_URL}/auth/view/${cellValues.row.orgId}/${cellValues.row.uuid}`
        //             );
        //         }}>
        //             <Visibility />
        //         </IconButton>
        //     )
        // },
        // {
        //     field: "analytics",
        //     headerName: "ANALYTICS",
        //     width: 80,
        //     disableExport: true,
        //     renderCell: (cellValues: any) => (
        //         <IconButton aria-label='delete' size='large' onClick={() => {
        //             window.open(
        //                 `${PUBLIC_URL}/#/files/analytics/${cellValues.row.uuid}`
        //             );
        //         }}>
        //             <SignalCellularAlt />
        //         </IconButton>
        //     )
        // },
        {
            field: "unlock",
            headerName: "UNLOCK",
            width: 80,
            disableExport: true,
            renderCell: (cellValues: any) => (
                <IconButton aria-label='unlock' size='large' onClick={() => unlock_viewer(cellValues.row.container_id, cellValues.row.email)}>
                    <LockOpen />
                </IconButton>
            )
        }
    ];

    return (<>
        <Box style={{ display: "flex", flexDirection: 'column', padding: '0 1em' }}>
            {/* <Box style={{ display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "center", marginRight: "2em", gap: "2em", height: "37vh" }}>
            </Box> */}
            <FormControl sx={{ maxWidth: 200, paddingLeft: 1 }}>
                            <InputLabel id="demo-simple-select-label">Select Date Range</InputLabel>
                            <DateRangeSelect value={date_range} onChange={(range) => { set_date_range(range); }} />
                        </FormControl>
            <Grid2 container>
                <Grid2 xs={12} sm={6}>
                    <Box style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "center", marginRight: "2em", gap: "2em" }}>
                        <Typography style={{ fontSize: "1.8em", color: "#2B5592" }} >READ NOTIFICATIONS:</Typography>
                        <Box sx={{ marginBottom: 1, width: "100%" }}>
                            {conn && <QueryComponent
                                conn={conn}
                                query={`
				   SELECT *, gen_random_uuid() as id FROM ${schema}.LANDING_VW WHERE action_type = 'Read'
				`}
                                render={(data) => {
                                    return <DataGrid
                                        rows={data}
                                        columns={columns}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    uuid: false,
                                                },
                                            },
                                        }} />
                                }}
                            ></QueryComponent>}

                        </Box>
                    </Box>
                </Grid2>
                {/* <Grid2 xs={12} sm={6}>
                    <Box style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "center", marginRight: "2em", gap: "2em" }}>
                        <Typography style={{ fontSize: "1.8em", color: "#2B5592" }} >SECURE FILES REQUESTED:</Typography>
                        <Box sx={{ marginBottom: 1, width: "100%" }}>
                            {conn && <QueryComponent
                                conn={conn}
                                query={`
                                SELECT *, gen_random_uuid() as id FROM ${schema}.LANDING_VW WHERE action_type = 'Read'
				`}
                                render={(data) => {
                                    return <DataGrid
                                        rows={data}
                                        columns={columns}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    uuid: false,
                                                },
                                            },
                                        }} />
                                }}
                            ></QueryComponent>}

                        </Box>
                    </Box>
                </Grid2> */}
                <Grid2 xs={12} sm={6}>
                    <Box style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "center", marginRight: "2em", gap: "2em" }}>
                        <Typography style={{ fontSize: "1.8em", color: "#2B5592" }} >VIEWERS LOCKED OUT:</Typography>
                        <Box sx={{ marginBottom: 1, width: "100%" }}>
                            {conn && <QueryComponent
                                conn={conn}
                                query={`
                                SELECT *, gen_random_uuid() as id FROM ${schema}.LANDING_VW WHERE action_type = 'Locked out'
				`}
                                render={(data) => {
                                    return <DataGrid
                                        rows={attempts}
                                        columns={attemptColumns}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    container_id: false,
                                                },
                                            },
                                        }} />
                                }}
                            ></QueryComponent>}

                        </Box>
                    </Box>
                </Grid2>
                <Grid2 xs={12} sm={6}>
                    <Box style={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "stretch", justifyContent: "center", marginRight: "2em", gap: "2em" }}>
                        <Typography style={{ fontSize: "1.8em", color: "#2B5592" }} >SENT NOT ACCESSED:</Typography>
                        <Box sx={{ marginBottom: 1, width: "100%" }}>
                            {conn && <QueryComponent
                                conn={conn}
                                query={`
                                SELECT *, gen_random_uuid() as id FROM ${schema}.LANDING_VW WHERE action_type = 'Sent' OR action_type = 'Not Read'
				`}
                                render={(data) => {
                                    return <DataGrid
                                        rows={data}
                                        columns={columns}
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel: {
                                                    uuid: false,
                                                },
                                            },
                                        }} />
                                }}
                            ></QueryComponent>}

                        </Box>
                    </Box>
                </Grid2>
            </Grid2>
        </Box>
    </>)
}
