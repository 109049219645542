import { DuckDBConnection } from "@duckdb/duckdb-wasm/dist/types/src/bindings";
import { useAsyncEffect } from "@react-hook/async";
import { tableToObjects } from "./lt_analytics_client";
import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";

type Props = {
  conn: AsyncDuckDBConnection;
  query: string;
  render: (data: Record<string, any>[]) => React.ReactNode;
};

const QueryComponent: React.FC<Props> = ((props: Props) => {
  const {
    value: data,
    status,
    error,
  } = useAsyncEffect(async () => {
    if (!props.conn) {
      return null;
    }

    console.log("Query: ", props.query);
    const table = await props.conn.query(props.query);
    const objects = tableToObjects(table);
    console.log("Objects: ", objects);
    return tableToObjects(table);

  }, [props.conn, props.query]);

  if (status === "loading") {
    return <>Loading...</>;
  } else if (error) {
    return <>{error.stack}</>;
  }
  return props.render(data ?? []);
}) as any;

export default QueryComponent;
