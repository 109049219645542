import { createTheme, responsiveFontSizes } from "@mui/material";

let theme = createTheme({
    palette: {
      primary: {
        main: '#2B5592',
      }
    },
    typography: {
      fontSize: 8.75,
    },
    components: {
      MuiCardHeader: {
        styleOverrides: {
          root: {
            paddingBottom: 0,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            paddingBottom: 0,
            paddingTop: 0,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: "0.8em 0",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            height: 48
          }
        }
      },
      // MuiAccordionSummary: {
      //   styleOverrides: {
      //     root: {
      //       fontSize: "0.8em"
      //     }
      //   }
      // },
    },
  });

  // theme = createTheme(theme, {
  //   typography: {
  //     [theme.breakpoints.down('sm')]: {
	// 			fontSize: 8
	// 		}
  //   }
  // })

  // theme = responsiveFontSizes(theme, {
  //   // breakpoints: []
  //   factor: 16,
  //   disableAlign: true
  // })

  export default theme;