// for PCT completion
// add PCT completion in the container table
// for PCT object level, add pie graphs:  for each object add pie for each user
//    add a section under the content stats

import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import * as React from "react";
import {
  lt_analytics_client_main_v2,
  tableToObjects,
  d3CreateText,
  d3CreateTable,
  d3ClusterBargraph_v2,
  d3ClusterHorizontalBargraph,
  d3DividerText,
  pltClusterHorizontalBargraph,
  pltClusterVerticalBargraph,
  pltPieGraph,
  pltDonutGraph,
  d3Header,
  fillMissingDataZero,
  quote_string,
} from "./lt_analytics_client";
import "./ContainerStats.css";
import { OrgContext } from "../../context/OrgContext";
import * as d3 from "d3";
import { useAsync, useAsyncEffect } from "@react-hook/async";
import { Fragment } from "react";
import Grid2 from "@mui/material/Unstable_Grid2";
import DonutGraph from "./DonutGraph";
import ClusterBarGraph from "./HorizontalBarGraph";
import ClusterVerticalBarGraph from "./VerticalBarGraph";
import ReactTable from "./ReactTable";
import DataGrid from "../DataGrid";
import QueryComponent from "./QueryComponent";
import { FormLabel, MenuItem, Select } from "@mui/material";
import HorizontalBarGraph from "./HorizontalBarGraph";
//import { PieChart } from '@mui/x-charts/PieChart';

let vDebug = +(process.env.REACT_APP_DEBUG ?? 0);

let content_level = 1;
let object_level = 3;
let object_plot_level = 4;
let viewer_level = 5;

const ContainerStats: React.FC<{ uuid: string }> = ({ uuid }) => {
  // Get the orgId & token
  const { org, api } = React.useContext(OrgContext);

  // Find the body element
  const body = React.createRef<HTMLDivElement>();

  // Allocate the DuckDB connection
  const [conn, setConn] = React.useState<AsyncDuckDBConnection>();

  // Open the DuckDB connection
  React.useEffect(() => {
    if (!org) return;
    (async () => {
      try {
        const start = performance.now();
        const conn = await lt_analytics_client_main_v2(
          [org.id],
          null,
          `CONTAINER_OBJECT`,
          uuid
        );
        const end = performance.now();
        if (end - start > 1000)
          api?.report_error({
            module: "analytics",
            container_id: uuid,
            message: "Slow Analytics Query",
            duration_ms: end - start,
            level: "warning",
          });
        setConn(conn);
      } catch (error) {
        api?.report_error({
          module: "analytics",
          container_id: uuid,
          message: `Error calculating analytics: ${error}`,
          stack: (error as any).stack,
        });
        throw error;
      }
    })();
  }, [org, uuid]);

  const { value: container_header_info } = useAsyncEffect(async () => {
    if (!conn) {
      return null;
    }

    let container_header_info: any = await conn!.query(
      `select * from CONTAINER_OBJECT.rpt_container_header_info_vw`
    );

    return tableToObjects(container_header_info)[0];
  }, [conn]);

  React.useEffect(() => {
    (async () => {
      console.log("Container header info: ", await container_header_info);
    })();
  }, [container_header_info]);

  // Run the main analytics code
  React.useEffect(() => {
    // Don't run if DuckDB isn't open or the body element doesn't exist yet
    if (!conn || !body.current) {
      return;
    }

    //*****************************************************************************************
    //*****************************************************************************************
    //*****************************************************************************************
    // horizontal bar graph example https://d3-graph-gallery.com/graph/barplot_horizontal.html
    // index.js is here:
    // Schema is passed in from the bottom of this function
    async function main_v2(schema: string) {
      try {
        body.current!.innerHTML = "";
        if (schema === `CONTAINER_OBJECT`) {
          //   let container_header_info: any = await conn!.query(
          //     `select * from ${schema}.rpt_container_header_info_vw`
          //   );
          //   container_header_info = tableToObjects(container_header_info);
          //   //console.log("container_header_info:",container_header_info);
          //   d3CreateText(
          //     container_header_info,
          //     body.current,
          //     "Container Stats Summary:"
          //   );
          //d3CreateStatsSections(container_header_info, body.current);
          //ex: var junk_data = [{'completed':90,'not completed':10}];
          //ex: pltDonutGraph (junk_data,  body.current, null, 'test pie hskhfjkewjk hbkwekj');
          //

          // container_donut_stat
          // START THE VIEWER LOOP
          let viewer_loop: any = await conn!.query(`
				select distinct c.viewer from ${schema}.RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW c
				order by 1
				`);
          viewer_loop = tableToObjects(viewer_loop);
          //console.log('viewer_loop:',viewer_loop);
          for (let viewer_loop_row of viewer_loop) {
            let { viewer } = viewer_loop_row;
            let container_donut_stat: any = await conn!.query(`
					with cte_main as(
								select  v.viewer --as "AUTHORIZED VIEWERS"
								,(round(least(coalesce(c.percent_viewership,0),100),2))::double as complete --::text||'%' as "% COMPLETE"
							from ${schema}.rpt_container_stat_vw v left join
								(select orgId,uuid,viewer,avg(coalesce(percent_viewership,0)) as percent_viewership
								from ${schema}.RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW --RPT_ALL_COMPLETION_STAT_VW
								group
									by orgId,uuid,viewer
								) c
								on v.orgId = c.orgId and v.uuid = c.uuid and v.viewer = c.viewer
								where v.viewer = ${quote_string(viewer)}
						)
						select --viewer as "VIEWER",
						       coalesce(complete,0.0) as "COMPLETE",
							   (100.00 - coalesce(complete,0.0)) as "NOT COMPLETE"
						  from cte_main
					`);
            container_donut_stat = tableToObjects(container_donut_stat);
            //d3CreateTable(container_donut_stat, body.current, viewer, ['LAST ACCESSED']);
            pltDonutGraph(container_donut_stat, body.current, viewer, true);
          }

          let container_stat_1_vw: any = await conn!.query(`
				   select  v.viewer as "AUTHORIZED VIEWERS"
				          ,v.opens as "OPENS"
						  ,v.duration as "DURATION"
						  -- tk20230708, added least (x,100) on completion to cap at 100
						  ,(round(least(coalesce(c.percent_viewership,0),100),2))::text||'%' as "% COMPLETE"
						  ,v.last_accessed as "LAST ACCESSED"
						  ,v.ip as "IP ADDRESS"
						  ,v.city as "CITY"
						  ,v.state as "STATE"
						  ,v.country as "COUNTRY"
					 from ${schema}.rpt_container_stat_vw v left join
					  	  (select orgId,uuid,viewer,avg(coalesce(percent_viewership,0)) as percent_viewership
							 from ${schema}.RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW --RPT_ALL_COMPLETION_STAT_VW
							group
							   by orgId,uuid,viewer
						   ) c
					   on v.orgId = c.orgId and v.uuid = c.uuid and v.viewer = c.viewer
					order by 1
				`);

          // let container_stat_1_vw: any = await conn!.query(`
          // 	-- include ip in view
          // 	select r.viewer as "AUTHORIZED VIEWERS",v.container_opens as "OPENS",
          // 	(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as "DURATION",
          // 		   v.last_accessed as "LAST ACCESSED", r.ip as "IP ADDRESS", r.city as "CITY",
          // 		   r.state as "STATE", r.country as "COUNTRY"
          // 	 from ${schema}.container_stat_1_vw v
          // 	 	right join (select rr.* from ${schema}.viewer rr inner join ${schema}.AUTHORIZED_VIEWER av on rr.viewer = av.viewer) r
          // 		  on v.viewer = r.viewer
          // 	order
          // 	   by 1
          // `);
          container_stat_1_vw = tableToObjects(container_stat_1_vw);
          // Create the table from the container_stat_1_vw
          //d3CreateTable(container_stat_1_vw, body.current, 'Container Stats:', ['LAST ACCESSED']);
          d3CreateTable(container_stat_1_vw, body.current, null, [
            "LAST ACCESSED",
          ]);

          // present the content stats
          let content_stat: any = await conn!.query(`
					select   v.object as "CONTENT FILE"
							,v.viewers as "NUMBER OF VIEWERS"
							,v.object_open as "OPENS"
							,v.duration as "DURATION"
							,coalesce(d.download,0) as "DOWNLOADS"
							,v.last_accessed as "LAST ACCESSED"
					  from ${schema}.rpt_object_stat_vw v left join ${schema}.RPT_DOWNLOADED_CONTENT_SUMMARY_VW d
					    on v.orgId = d.orgId and v.uuid = d.uuid and v.object = d.object
					  order by 1,2
				`);
          // let content_stat: any = await conn!.query(`
          // select
          // q.object as "CONTENT FILE",
          // q.viewer as "NUMBER OF VIEWERS",
          // q.opens as "OPENS",
          // (duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as "DURATION",
          // q.last_accessed as "LAST ACCESSED"
          // from (
          // select
          //  v.object,
          //  count(distinct(v.viewer)) as viewer,
          //  --sum(v.cnt) as opens, need how many time content file is opened
          //  --tk count(distinct(v.page)) as opens,
          //  sum(v.object_file_open) as opens,
          //  sum(duration_seconds) as duration_seconds,
          //  max(v.last_accessed) as last_accessed
          //  from ${schema}.object_all_stat_vw v --join ${schema}.viewer r on v.viewer = r.viewer
          // group by v.object --, r.viewer
          // ) q
          // order by 1,2
          // `);
          content_stat = tableToObjects(content_stat);
          // Create the table from the container_stat_1_vw
          d3Header(body.current, content_level, "CONTENT STATS SUMMARY:");
          d3CreateTable(content_stat, body.current, null, ["LAST ACCESSED"]);

          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // Drop down menu for each object_file
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          let object_files_loop: any = await conn!.query(
            `select distinct object,doc_type from ${schema}.rpt_object_stat_vw where object is not null order by 1`
          );
          object_files_loop = tableToObjects(object_files_loop);
          if (vDebug > 0) {
            console.log("object_files_loop:", object_files_loop);
          }
          var label = document.createElement("label");
          label.className = "analytics";
          label.append("CONTENT FILE STAT DETAILS:");
          var select = document.createElement("select");
          label.appendChild(select);
          var object_details = document.createElement("div");

          d3.select(select)
            .on("change", (e: any) =>
              fnc_detailed_object_stats(e.target.value, object_details)
            )
            .selectAll("option")
            .data(object_files_loop)
            .enter()
            .append("option")
            .attr("value", (d: any) => `${d.object};${d.doc_type}`)
            .text((d: any) => d.object);

          body.current?.appendChild(label);
          body.current?.appendChild(object_details);

          fnc_detailed_object_stats(
            `${object_files_loop[0].object};${object_files_loop[0].doc_type}`,
            object_details
          );
        }
      } catch (error) {
        api?.report_error({
          module: "analytics",
          container_id: uuid,
          message: `Error calculating analytics: ${error}`,
          stack: (error as any).stack,
        });
        throw error;
      }

      async function fnc_detailed_object_stats(
        object_file_info: string,
        selector: any
      ) {
        if (vDebug > 0) {
          console.log("object_file_info:", object_file_info);
        }
        selector.innerHTML = "";
        //tk let { object,doc_type } = object_files_loop_row;
        let [object, doc_type] = object_file_info.split(";");
        //d3DividerText(selector,object);
        //tk d3Header(selector, object_level,object);
        let rpt_stat_vw: any;
        if (doc_type === "MEDIA") {
          rpt_stat_vw = await conn!.query(`
					select "VIEWER","OPENS","DURATION","FIRST ACCESSED","LAST ACCESSED" from ${schema}.rpt_media_stat_vw where "CONTENT FILE" = ${quote_string(
            object
          )} order by 1
					`);
        } else {
          rpt_stat_vw = await conn!.query(`
					select "VIEWER","PAGE","OPENS","DURATION","FIRST ACCESSED","LAST ACCESSED" from ${schema}.rpt_other_stat_vw where "CONTENT FILE" = ${quote_string(
            object
          )} order by 1,2
					`);
        }
        rpt_stat_vw = tableToObjects(rpt_stat_vw);
        if (vDebug > 0) {
          console.log("rpt_stat_vw:", rpt_stat_vw);
        }
        d3CreateTable(rpt_stat_vw, selector, null, [
          "FIRST ACCESSED",
          "LAST ACCESSED",
        ]);

        //
        // plot pages viewed for non MEDIA, total duration for MEDIA
        //
        let plt_object_vw: any;

        if (doc_type !== "MEDIA") {
          plt_object_vw = await conn!.query(`
					select "VIEWER", "PAGES VIEWED"
					from (
					select av.viewer as "VIEWER",count(v."PAGE")::int as "PAGES VIEWED"
					 from ${schema}.rpt_other_stat_vw  v right join ${schema}.VIEWER av on v.orgId = av.orgId and v.uuid = av.uuid and v."VIEWER" = av.viewer
					 and  "CONTENT FILE" = ${quote_string(object)}
					 group by av.viewer
					 ) q
					 order by 1
					`);
          plt_object_vw = tableToObjects(plt_object_vw);
          if (vDebug > 0) {
            console.log("plt_object_vw:", plt_object_vw);
          }
          //tk d3Header(selector, object_plot_level,'PAGES VIEWED');
          pltClusterHorizontalBargraph(
            plt_object_vw,
            selector,
            "PAGES VIEWED BY VIEWER",
            null
          );
        }
        plt_object_vw = await conn!.query(`
				select av.viewer as "VIEWER",
						(sum(v.duration_seconds)::double/60.0)::double as "DURATION IN MINUTES"
					from ${schema}.rpt_all_stat_vw v right join ${schema}.VIEWER av on v.orgId = av.orgId and v.uuid = av.uuid and v."VIEWER" = av.viewer
					and  "CONTENT FILE" = ${quote_string(object)}
					group by  av.viewer
					order by 1
				`);
        plt_object_vw = tableToObjects(plt_object_vw);
        if (vDebug > 0) {
          console.log("plt_object_vw:", plt_object_vw);
        }
        //tk d3Header(selector, object_plot_level,'DURATION IN MINUTES');
        pltClusterHorizontalBargraph(plt_object_vw, selector, "DURATION", null);

        // Find the max X (number of pages) & Y (duration) for all viewers for non-media
        let axes_max: any = await conn!.query(`
					with cte_main as (
							select  (case when coalesce(c.page,0) = 0 then 1::int else c.page::int end) as page
									,sum(duration)::float/60.0 as duration_minutes
							from ${schema}.OBJECT_EVENTS_STAT c
							where object = ${quote_string(object)}
							and page is not null
							group by  (case when coalesce(c.page,0) = 0 then 1::int else c.page::int end)
							order by 1
						 )
					select max(page) as max_page, max(duration_minutes) as max_duration_minutes
					  from cte_main
				`);
        axes_max = tableToObjects(axes_max);
        if (vDebug > 0) {
          console.log("axes_max:", axes_max);
        }

        //////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////
        let viewer_loop: any = await conn!.query(`
					select viewer from  ${schema}.VIEWER order by 1
				`);
        viewer_loop = tableToObjects(viewer_loop);
        for (let viewer_loop_row of viewer_loop) {
          let { viewer } = viewer_loop_row;
          // Loop for the current object thru for every viewer
          let plt_object_viewer_completion: any = await conn!.query(`
						with cte_completed as (
							select round(coalesce(v.percent_viewership,0),2) as completed
							from ${schema}.RPT_ALL_COMPLETION_STAT_VW v
							where v.object = ${quote_string(object)}
							  and v.viewer = ${quote_string(viewer)}
						), cte_not_completed as (
							select round((100 - completed),2) as not_completed
							from cte_completed
						)
						select case when c.completed = 0 then null else c.completed end as "COMPLETED"
							,case when n.not_completed = 0 then null else n.not_completed end as "NOT COMPLETED"
						from cte_completed c, cte_not_completed n
					`);
          plt_object_viewer_completion = tableToObjects(
            plt_object_viewer_completion
          );
          if (vDebug > 0) {
            console.log(
              "plt_object_viewer_completion:",
              plt_object_viewer_completion
            );
          }

          // Create a parent div to contain the graphs. Lay out the graphs in a row inside this parent
          let parent = document.createElement("div");
          parent.style.display = "inline-flex";
          parent.style.gap = "1em";

          // Create a section header per viewer for non-media (so completion & pages sit side-by-side)
          if (doc_type !== "MEDIA") {
            d3Header(selector, viewer_level, viewer);
            parent.style.width = "100%";
          }

          selector.append(parent);

          pltDonutGraph(plt_object_viewer_completion, parent, viewer);

          // Don't show pages for media
          if (doc_type !== "MEDIA") {
            let plt_viewer_page_duration: any = await conn!.query(`
						with cte_main as (
							select  (case when coalesce(c.page,0) = 0 then 1::int else c.page::int end) as page
									,sum(duration)::float/60.0 as duration_minutes
							from ${schema}.OBJECT_EVENTS_STAT c
							where viewer = ${quote_string(viewer)}
							and object = ${quote_string(object)}
							and page is not null
							group by  (case when coalesce(c.page,0) = 0 then 1::int else c.page::int end)
							order by 1
						 )
						 select page, coalesce(duration_minutes,0)::float as duration_minutes
						   from cte_main
						-- union all select 30 as page, 1.2 as duration_minutes
					`);
            plt_viewer_page_duration = tableToObjects(plt_viewer_page_duration);
            if (vDebug > 0) {
              console.log(
                "plt_viewer_page_duration:",
                plt_viewer_page_duration
              );
            }
            //d3ClusterBargraph_v2(viewer_pdf_page_duration, selector, 'page', viewer);
            // fill the data with 0's for missing values
            fillMissingDataZero(
              plt_viewer_page_duration,
              "page",
              "DURATION MINUTES"
              //"page",
              //"duration_minutes"
            );
            // Pass axes_max[...] so all the graphs have the same scales
            pltClusterVerticalBargraph(
              plt_viewer_page_duration,
              parent,
              "page",
              null,
              axes_max[0].max_page,
              axes_max[0].max_duration_minutes
            );
          }
        }
        //
        // plot views per viewer, also plot PCT completion
        //
      }
    }
    //*****************************************************************************************
    //*****************************************************************************************
    //*****************************************************************************************
    //main_v2("CONTAINER_OBJECT");
  }, [conn, body]);

  const gridStyle = (backgroundColor: string) => ({
    borderRadius: "10px",
    backgroundColor,
    margin: "4px",
    padding: "14px",
    width: "100%",
    height: "100%",
  });

  const tableData = [
    { authorizedviewers: "Ahmad", age: 30, city: "Dallas" },
    { authorizedviewers: "Taha", age: 31, city: "Dallas" },
    { authorizedviewers: "Jonathan", age: 32, city: "Dallas" },
  ];

  const mockHorizontalData = [
    { category: "Category 1", value: 30 },
    { category: "Category 2", value: 20 },
    { category: "Category 3", value: 35 },
    { category: "Category 4", value: 40 },
    { category: "Category 5", value: 50 },
  ];

  const schema = "CONTAINER_OBJECT";

  const [selectedObjectInfo, setSelectedObjectInfo] =
    React.useState<string>("");

  // index.html is here:
  return (
    <>
      {conn && (
        <>
          <h1 className="analytics">CONTAINER ANALYTICS:</h1>
          <div>
            <Grid2 style={{ marginBottom: "16px" }} container spacing={1}>
              <Grid2 xs={12} md={3}>
                <div style={{ ...gridStyle("#7AB9DF") }}>
                  <h3 style={{ marginTop: "2px" }}>Authorized viewers: </h3>
                  <p>
                    {container_header_info?.recipients
                      .split(";")
                      .map((recepient: string) => {
                        return (
                          <Fragment key={recepient}>
                            {recepient}
                            <br />
                          </Fragment>
                        );
                      })}
                  </p>
                </div>
              </Grid2>
              <Grid2 xs={12} md={3}>
                <div style={{ ...gridStyle("#C5D2E9") }}>
                  <h3 style={{ marginTop: "2px" }}>Content Files: </h3>
                  <p>
                    {container_header_info?.content_files
                      .split(",")
                      .map((files: string) => {
                        return (
                          <Fragment key={files}>
                            {files}
                            <br />
                          </Fragment>
                        );
                      })}
                  </p>
                </div>
              </Grid2>
              <Grid2 container md={6} xs={12}>
                <Grid2 xs={12} sm={6}>
                  <div style={{ ...gridStyle("#80BE5A") }}>
                    <h3 style={{ marginTop: "2px" }}>Status: </h3>
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      {container_header_info?.status}
                    </p>
                  </div>
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <div style={{ ...gridStyle("#ADD5EB") }}>
                    <h3 style={{ marginTop: "2px" }}>Access Dates: </h3>
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      {container_header_info?.files_expire ? (
                        <>
                          Expires at:{" "}
                          {new Date(
                            container_header_info.files_expire
                          ).toLocaleString()}{" "}
                        </>
                      ) : (
                        "Unlimited"
                      )}
                    </p>
                  </div>
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <div style={{ ...gridStyle("#ACD5EB") }}>
                    <h3 style={{ marginTop: "2px" }}>Access Permissions: </h3>
                    <p>
                      Download Permission: {container_header_info?.downloadable}{" "}
                      <br />
                    </p>
                  </div>
                </Grid2>
                <Grid2 xs={12} sm={6}>
                  <div style={{ ...gridStyle("#8498BB") }}>
                    <h3 style={{ marginTop: "2px" }}>Properties: </h3>
                    <p>
                      Container Name: {container_header_info?.container_name}{" "}
                      <br />
                      Date Sent:{" "}
                      {new Date(
                        container_header_info?.sent_date
                      ).toLocaleDateString()}{" "}
                      <br />
                      ID: {container_header_info?.uuid} <br />
                      Ref: {container_header_info?.ref} <br />
                      Sender: {container_header_info?.sender}
                    </p>
                  </div>
                </Grid2>
              </Grid2>
            </Grid2>
            <QueryComponent
              conn={conn}
              query={`
				select distinct c.viewer from ${schema}.RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW c
				order by 1
				`}
              render={(viewers) => {
                return <Grid2 container>
                  {viewers.map((row) => {
                    return (
                      <QueryComponent
                        key={row.viewer}
                        conn={conn}
                        query={`
          with cte_main as(
                select  v.viewer --as "AUTHORIZED VIEWERS"
                ,(round(least(coalesce(c.percent_viewership,0),100),2))::double as complete --::text||'%' as "% COMPLETE"
              from ${schema}.rpt_container_stat_vw v left join
                (select orgId,uuid,viewer,avg(coalesce(percent_viewership,0)) as percent_viewership
                from ${schema}.RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW --RPT_ALL_COMPLETION_STAT_VW
                group
                  by orgId,uuid,viewer
                ) c
                on v.orgId = c.orgId and v.uuid = c.uuid and v.viewer = c.viewer
                where v.viewer = ${quote_string(row.viewer)}
            )
            select --viewer as "VIEWER",
                coalesce(complete,0.0) as "COMPLETE",
                (100.00 - coalesce(complete,0.0)) as "NOT COMPLETE"
              from cte_main
          `}
                        render={(data) => {
                          return (
                            <Grid2 xs={12} sm={6} md={4}>
                              <DonutGraph
                                style={{ height: '400px' }}
                                data={data}
                                title={row.viewer}
                              />
                            </Grid2>
                          );
                        }}
                      />
                    );
                  })}
                </Grid2>;
              }}
            />
            <QueryComponent
              conn={conn}
              query={`
				   select  v.viewer as "AUTHORIZED VIEWERS"
				          ,v.opens as "OPENS"
						  ,v.duration as "DURATION"
						  -- tk20230708, added least (x,100) on completion to cap at 100
						  ,(round(least(coalesce(c.percent_viewership,0),100),2))::text||'%' as "% COMPLETE"
						  ,v.last_accessed as "LAST ACCESSED"
						  ,v.ip as "IP ADDRESS"
						  ,v.city as "CITY"
						  ,v.state as "STATE"
						  ,v.country as "COUNTRY"
					 from ${schema}.rpt_container_stat_vw v left join
					  	  (select orgId,uuid,viewer,avg(coalesce(percent_viewership,0)) as percent_viewership
							 from ${schema}.RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW --RPT_ALL_COMPLETION_STAT_VW
							group
							   by orgId,uuid,viewer
						   ) c
					   on v.orgId = c.orgId and v.uuid = c.uuid and v.viewer = c.viewer
					order by 1
				`}
              render={(data) => {
                return <ReactTable data={data} title="VIEWER SUMMARY" />;
              }}
            />
            <QueryComponent
              conn={conn}
              query={`
			  select   v.object as "CONTENT FILE"
			  ,v.viewers as "NUMBER OF VIEWERS"
			  ,v.object_open as "OPENS"
			  ,v.duration as "DURATION"
			  ,coalesce(d.download,0) as "DOWNLOADS"
			  ,v.last_accessed as "LAST ACCESSED"
		from ${schema}.rpt_object_stat_vw v left join ${schema}.RPT_DOWNLOADED_CONTENT_SUMMARY_VW d
		  on v.orgId = d.orgId and v.uuid = d.uuid and v.object = d.object
		order by 1,2
				`}
              render={(data) => {
                return (
                  <ReactTable data={data} title="CONTENT SUMMARY" />
                );
              }}
            />
            <FormLabel className="analytics">
              CONTENT FILE DETAIL: &nbsp;
              <QueryComponent
                conn={conn}
                query={`
				select distinct object,doc_type from ${schema}.rpt_object_stat_vw where object is not null order by 1
				`}
                render={(data) => {
                  if (!selectedObjectInfo && data[0]) {
                    setSelectedObjectInfo(
                      `${data[0].object};${data[0].doc_type}`
                    );
                  }
                  return (
                    <Select
                      value={selectedObjectInfo}
                      onChange={(e) => {
                        setSelectedObjectInfo(e.target.value);
                      }}
                    >
                      {data.map((row) => {
                        return (
                          <MenuItem
                            key={row.object}
                            value={`${row.object};${row.doc_type}`}
                          >
                            {row.object}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  );
                }}
              />
            </FormLabel>
            {selectedObjectInfo && (
              <>
                <QueryComponent
                  conn={conn}
                  query={
                    selectedObjectInfo.split(";")[1] === "MEDIA"
                      ? `
			  select "VIEWER","OPENS","DURATION","FIRST ACCESSED","LAST ACCESSED" from ${schema}.rpt_media_stat_vw where "CONTENT FILE" = ${quote_string(
                          selectedObjectInfo.split(";")[0]
                        )} order by 1
				`
                      : `
				select "VIEWER","PAGE","OPENS","DURATION","FIRST ACCESSED","LAST ACCESSED" from ${schema}.rpt_other_stat_vw where "CONTENT FILE" = ${quote_string(
                          selectedObjectInfo.split(";")[0]
                        )} order by 1,2
				`
                  }
                  render={(data) => {
                    return <ReactTable data={data} />;
                  }}
                />              </>
            )}
            {selectedObjectInfo &&
              selectedObjectInfo?.split(";")[0] !== "MEDIA" && (
                <>
                  <QueryComponent
                    conn={conn}
                    query={`
					select "VIEWER", "PAGES VIEWED"
					from (
					select av.viewer as "VIEWER",count(v."PAGE")::int as "PAGES VIEWED"
					 from ${schema}.rpt_other_stat_vw  v right join ${schema}.VIEWER av on v.orgId = av.orgId and v.uuid = av.uuid and v."VIEWER" = av.viewer
					 and  "CONTENT FILE" = ${quote_string(selectedObjectInfo?.split(";")[0])}
					 group by av.viewer
					 ) q
					 order by 1
				  `}
                    render={(data) => {
                      return (
                        <HorizontalBarGraph
                          style={{ height: '400px' }}
                          data={data}
                          title="PAGES VIEWED"
                        />
                      );
                    }}
                  />
                </>
              )}

            {selectedObjectInfo && (
              <>
                <QueryComponent
                  conn={conn}
                  query={`
			  		select av.viewer as "VIEWER",
			  				(sum(v.duration_seconds)::double/60.0)::double as "DURATION IN MINUTES"
		  			  from ${schema}.rpt_all_stat_vw v right join ${schema}.VIEWER av on v.orgId = av.orgId and v.uuid = av.uuid and v."VIEWER" = av.viewer
		  		  		and  "CONTENT FILE" = ${quote_string(selectedObjectInfo?.split(";")[0])}
		  			group by  av.viewer
		  			order by 1
				`}
                  render={(data) => {
                    return (
                      <HorizontalBarGraph
                        style={{ height: '400px' }}
                        data={data}
                        title="DURATION IN MINUTES"
                      />
                    );
                  }}
                />

                <QueryComponent
                  conn={conn}
                  query={`
					select viewer from  ${schema}.VIEWER order by 1
					`}
                  render={(viewers) => {
                    return viewers.map((row) => {
                      return (
                        <>
                          {/* <h3 className="analytics">{row.viewer}</h3> */}
                          <h3
                            className="analytics"
                            style={{ fontSize: "0.25em" }}
                          >
                            &nbsp;
                          </h3>
                          <Grid2 container>
                            <QueryComponent
                              key={row.viewer}
                              conn={conn}
                              query={`
              with cte_completed as (
                select round(coalesce(v.percent_viewership,0),2) as completed
                from ${schema}.RPT_ALL_COMPLETION_STAT_VW v
                where v.object = ${quote_string(selectedObjectInfo?.split(";")[0])}
                  and v.viewer = ${quote_string(row.viewer)}
              ), cte_not_completed as (
                select round((100.0 - completed),2) as not_completed
                from cte_completed
              )
              select case when c.completed = 0 then null else c.completed end as "COMPLETED"
                ,case when n.not_completed = 0 then null else n.not_completed end as "NOT COMPLETED"
              from cte_completed c, cte_not_completed n
                  `}
                              render={(data) => {
                                return (
                                  <Grid2 xs={12} md={4}>
                                    <DonutGraph
                                      style={{ height: '400px' }}
                                      data={data}
                                      title={row.viewer}
                                    />
                                  </Grid2>
                                );
                              }}
                            />
                            {selectedObjectInfo?.split(";")[0] !== "MEDIA" && (
                              <QueryComponent
                                key={row.viewer}
                                conn={conn}
                                query={`
              with cte_main as (
                select  (case when coalesce(c.page,0) = 0 then 1::int else c.page::int end) as page
                    ,sum(duration)::float/60.0 as duration_minutes
                from ${schema}.OBJECT_EVENTS_STAT c
                where viewer = ${quote_string(row.viewer)}
                and object = ${quote_string(selectedObjectInfo?.split(";")[0])}
                and page is not null
                group by  (case when coalesce(c.page,0) = 0 then 1::int else c.page::int end)
                order by 1
              )
              select page AS "PAGE", coalesce(duration_minutes,0)::float as "DURATION MINUTES" --duration_minutes
                from cte_main
              -- union all select 30 as page, 1.2 as duration_minutes
                    `}
                                render={(data) => {
                                  return <Grid2 xs={12} md={8}>
                                    <ClusterVerticalBarGraph
                                      style={{ height: '400px' }}
                                      data={data}
                                    />;
                                  </Grid2>
                                }}
                              />
                            )}
                          </Grid2>
                        </>
                      );
                    });
                  }}
                />
              </>
            )}
          </div>
        </>
      )}
      <div ref={body} />
    </>
  );
};

export default ContainerStats;
