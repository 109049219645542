import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { FormControl } from "@mui/material";
import React from "react";
import HorizontalBarGraph from "../../components/analytics/HorizontalBarGraph";
import QueryComponent from "../../components/analytics/QueryComponent";
import ReactTable from "../../components/analytics/ReactTable";
import { ViewersFilter } from "../../components/analytics/filters";
import { lt_analytics_client_main_v2, quote_string, tableToObjects } from "../../components/analytics/lt_analytics_client";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import { OrgContext } from "../../context/OrgContext";

const schema = 'BLOCKED';

const ViewerAuditTab: React.FC = () => {
	const { org, api } = React.useContext(OrgContext);

	// Allocate the DuckDB connection
	const [conn, setConn] = React.useState<AsyncDuckDBConnection>();
	// Filter states
	const [dateRange, setDateRange] = React.useState(DateRange.All);

	React.useEffect(() => {
		if (!org) return;
		(async () => {
			try {
				// Clear the old connection
				setConn(undefined);

				// Calculate the filters
				const [start_time, end_time] = range_to_dates(dateRange);

				// Time and execute the query
				const start = performance.now();
				const conn = await lt_analytics_client_main_v2([org.id], null, schema, null, start_time?.toISOString(), end_time?.toISOString());
				const end = performance.now();
				if (end - start > 1000) api?.report_error({
					module: "analytics",
					message: `Slow Analytics Query: ${schema}`,
					duration_ms: end - start,
					level: "warning",
				});

				// Make the data available to components
				setConn(conn);

			} catch (error) {
				api?.report_error({
					module: "analytics",
					user: org.email,
					message: `Error calculating analytics: ${error}`,
					stack: (error as any).stack,
				});
				throw error;
			}
		})();
	}, [org, dateRange]);

	return <>
		<h3>
			Filters:
		</h3>
		<FormControl>
			<DateRangeSelect value={dateRange} onChange={setDateRange} />
		</FormControl>
		{conn && <div>
			<h1>Blocked</h1>
			<QueryComponent
				conn={conn}
				query={`
				select distinct
				b.container_name as "CONTAINER NAME",
				--b.sender as "SENDER",
				b.status as "STATUS",
				b.sent_date as "DATE SENT",
				b.recipients as "RECIPIENTS",
				b.action as "ACTION",
				b.reason as "REASON",
				b.ts as "ACTION DATE"
				from ${schema}.RPT_BLOCKED_VW b
				order by b.container_name,b.ts
				`}
				render={(data) => {
					return <ReactTable data={data} />;
				}}
			/>
		</div>}
	</>
};

export default ViewerAuditTab;
