import React, { useState } from "react";
import Box from "@mui/material/Box";

type TableRow = Record<string, any>;

type Props = {
  title: string;
  value: string;
  backGroundColor: string;
};

const ReactBox: React.FC<Props> = ({title, value, backGroundColor}) => {
  const boxStyle: React.CSSProperties = {
    display: "inline-block",
    width: "200px",
    height: "200px",
    margin: "10px",
    textAlign: "center",
    color: "black",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "200px",
    whiteSpace: 'nowrap',
    position: "relative",
    backgroundColor: backGroundColor,
  };

  const contentStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    lineHeight: "1.5",
  };

  return (
    <div style={boxStyle} >
      <div style={contentStyle} >
        {title}:
        <br />
        {value}
      </div>
    </div>
  );
};

export default ReactBox;
