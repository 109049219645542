import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createRoot } from 'react-dom/client';
import { HashRouter } from "react-router-dom";
import App from "./App";
import DownloadableContext from "./context/DownloadableContext";
import { EncoderContextProvider } from "./context/EncoderContext";
import FilesContext from "./context/FilesContext";
import JSONFormsContext from "./context/JSONFormsContext";
import OrgContext from './context/OrgContext';
import QueueContext from "./context/QueueContext";
import { register_commands } from "./utils/outlook_commands";
import theme from "./theme";

function start () {
  const root = createRoot(document.getElementById("root")!);
  root.render(
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CssBaseline />
        <HashRouter>
          <OrgContext>
            <FilesContext>
              <DownloadableContext>
                <JSONFormsContext>
                  <QueueContext>
                    <EncoderContextProvider>
                      <App />
                    </EncoderContextProvider>
                  </QueueContext>
                </JSONFormsContext>
              </DownloadableContext>
            </FilesContext>
          </OrgContext>
        </HashRouter>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

Office.onReady((info: any) => {
  console.log('Office info:', info);
  register_commands();
  start();
});
