import * as React from "react";
import { DataGrid, GridColDef, GridFooter, GridFooterContainer, GridPagination, GridValueGetterParams } from "@mui/x-data-grid";
import FileViewerDialog from "../general/FileViewerDialog";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import formatsize from "../../utils/formatsize";
import { Typography } from "@mui/material";
import { OrgContext } from "../../context/OrgContext";

interface Props {
  files?: any;
  removeFile?: any;
  limit?: number;
}
export default function FileList (props: Props) {
  const { org } = React.useContext(OrgContext);
  let { files, removeFile } = props;

  const over_limit = props.limit && files.reduce((sum: number, file: any) => sum + file.size, 0) > props.limit;

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCellClick = (
    param: any,
    event: { stopPropagation: () => void }
  ) => {
    // event.stopPropagation();
  };

  const handleRowClick = (
    param: any,
    event: { stopPropagation: () => void }
  ) => {
    // event.stopPropagation();
  };

  const columns: GridColDef[] = [
    {
      field: "removeFile",
      headerName: "REMOVE FILE",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <>
            <Button
              color='error'
              sx={{
                height: "56px",
                // padding: '8px',
              }}
              onClick={removeFile(cellValues.row.file)}>
              Delete
            </Button>
          </>
        );
      },
    },
    {
      field: "size",
      headerName: "SIZE",
      width: 70,
      renderCell: (cellValues) => {
        return formatsize(cellValues.row.size);
      },
    },
    {
      field: "filename",
      headerName: "DOCUMENTS LOADED",
      width: 200,
    },
  ];

  const rows = files.map((file: any, index: number) => {
    return {
      id: index,
      filename: file.name,
      type: file.type,
      size: file.file.size,
      file: file,
    };
  });

  const Footer = () => {
    return <GridFooterContainer style={{
      display: 'flex',
      justifyContent: 'space-between'
    }}>
      <Typography variant="body1" style={{
        marginLeft: '1em',
        color: over_limit ? (org.large_file ? '#80be5a' : '#8b1001') : 'inherit',
      }}>Total size: {formatsize(files.reduce((sum: number, file: any) => sum + file.size, 0))}{over_limit && <>
          <br />
          {org.large_file ? <b>Using your Large File plan.</b> : <b>This container exceeds your size limit of {formatsize(props.limit)}.</b>}
      </>}</Typography>
      <GridPagination/>
    </GridFooterContainer>
  }

  return (
    <Box sx={{ height: "200px", width: '100%' }}>
      <DataGrid
        rowHeight={30}
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        onCellClick={handleCellClick}
        onRowClick={handleRowClick}
        sx={{ borderWidth: "2px", borderStyle: "solid", borderRadius: "4px", borderColor: "#2B5592" }}
        components={{
          Footer,
        }}
      />
    </Box>
  );
}
