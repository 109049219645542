import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { FormControl } from "@mui/material";
import React from "react";
import HorizontalBarGraph from "../../components/analytics/HorizontalBarGraph";
import QueryComponent from "../../components/analytics/QueryComponent";
import ReactTable from "../../components/analytics/ReactTable";
import { ViewersFilter } from "../../components/analytics/filters";
import { lt_analytics_client_main_v2, quote_string, tableToObjects } from "../../components/analytics/lt_analytics_client";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import { OrgContext } from "../../context/OrgContext";
import ReactBox from "../../components/analytics/ReactBox";
import VerticalBarGraph from "../../components/analytics/VerticalBarGraph";
import Grid2 from "@mui/material/Unstable_Grid2";

const schema = 'CONTAINERS_ACCESSED';

const ViewerAuditTab: React.FC = () => {
	const { org, api } = React.useContext(OrgContext);

	// Allocate the DuckDB connection
	const [conn, setConn] = React.useState<AsyncDuckDBConnection>();
	// Filter states
	const [dateRange, setDateRange] = React.useState(DateRange.Last_14_Days);

	React.useEffect(() => {
		if (!org) return;
		(async () => {
			try {
				// Clear the old connection
				setConn(undefined);

				// Calculate the filters
				const [start_time, end_time] = range_to_dates(dateRange);

				// Time and execute the query
				const start = performance.now();
				const conn = await lt_analytics_client_main_v2([org.id], null, schema, null, start_time?.toISOString(), end_time?.toISOString());
				const end = performance.now();
				if (end - start > 1000) api?.report_error({
					module: "analytics",
					message: `Slow Analytics Query: ${schema}`,
					duration_ms: end - start,
					level: "warning",
				});

				// Make the data available to components
				setConn(conn);

			} catch (error) {
				api?.report_error({
					module: "analytics",
					user: org.email,
					message: `Error calculating analytics: ${error}`,
					stack: (error as any).stack,
				});
				throw error;
			}
		})();
	}, [org, dateRange]);

	return <>
		<h3>
			Filters:
		</h3>
		<FormControl>
			<DateRangeSelect value={dateRange} onChange={setDateRange} />
		</FormControl>
		{conn && <div>
			<h1>CONTAINER STATUS</h1>
			<Grid2 style={{ marginBottom: "16px" }} container spacing={1}>
			<Grid2 xs={12} md={6}>
			<QueryComponent
				conn={conn}
				query={`
				with cte_prep as (
				 select 'ACTIVE' as status union all
				 select 'EXPIRED' as status union all
				 select 'BLOCKED' as status union all
				 select 'LOCKED' as status
				), cte_all_status as (
				 select distinct status
				   from (select status from cte_prep union all
						 select status from ${schema}.RPT_CONTAINERS_ACCESSED_SUMMARY_VW
						) q
				), cte_final as (
				select s.status, count(v.uuid)::int as cnt
				   from ${schema}.RPT_CONTAINERS_ACCESSED_SUMMARY_VW v
						right join cte_all_status s on v.status = s.status
				 group by s.status
				)
				select status as "STATUS", cnt as "COUNT"
				   from cte_final
				 order by status
			 `}
				render={(data) => {
					return <VerticalBarGraph style={{ height: '400px' }} data={data}/>
				}}
			/>
			</Grid2>
			<Grid2 xs={12} md={6}>
			<QueryComponent
				conn={conn}
				query={`
				with cte_containers as (
				 select distinct uuid
				   from ${schema}.EVENTS_HEADER
				), cte_open as (
				 select uuid, max(container_open) as open
							 from ${schema}.CONTAINER_EVENTS_STAT
						 group by uuid
				), cte_main as (
				 select c.sent, o.open
				   from (select count(uuid)::int as sent from cte_containers) c, (select sum(open)::int as open from cte_open) o
				)
				select
					 sent as "CONTAINERS SENT",
					 open as "CONTAINERS OPENED",
					 case when sent > 0 then open*100/sent else 0 end as "% CONTAINERS OPENED"
				  from cte_main
			 `}
				render={(data) => {
					if (!data.length) return;
					return <>
						<ReactBox title={"CONTAINERS SENT"} value={data[0]["CONTAINERS SENT"]} backGroundColor="#7AB9E0"/>
						<ReactBox title={"CONTAINERS OPENED"} value={data[0]["CONTAINERS OPENED"]} backGroundColor="#C7D5EB"/>
						<ReactBox title={"% CONTAINERS OPENED"} value={data[0]["% CONTAINERS OPENED"]} backGroundColor="#58575B"/>
					</>
				}}
			/>
			</Grid2>
			</Grid2>

			<QueryComponent
				conn={conn}
				query={`
				with cte_prep as (
				select 	 v.orgId
						,v.uuid
						,v.container_name
						,v.status
						,v.authorized_viewers
						,v.viewers
						,case when v.authorized_viewers = 'ANYONE WITH LINK' then
								case when coalesce(v.viewers,0) = 0 then 0.0 else 100.0 end
							when v.authorized_viewers::int > 0 then (v.viewers::double/v.authorized_viewers::double)*100.0
							else 0.0
						end as pct_viewership
						,v.container_open
						,v.duration
						,v.sent_date
						,v.last_accessed
						,v.object_file_accessed_cnt
						,case when viewers > 0 then object_file_accessed_cnt::double/viewers::double else 0 end as avg_content_files_per_viewer
				from ${schema}.RPT_CONTAINERS_ACCESSED_SUMMARY_VW v
				 )
				 select
					 p.container_name as "CONTAINER NAME"
					,p.status as "STATUS"
					,p.sent_date as "DATE SENT"
					,p.authorized_viewers as "AUTHORIZED_VIEWERS"
					--,coalesce(p.viewers::int,0) as "# VIEWERS"
					,case when p.pct_viewership > 100 then 100 else round(p.pct_viewership,2) end as "% VIEWERSHIP"
					,o.content_files as "CONTENT FILES"
					,round(p.avg_content_files_per_viewer,2) as "AVG. CONTENT FILES VIEWED PER VIEWER"
					,round(p.avg_content_files_per_viewer*100/o.content_files,2) as "% CONTENT FILES VIEWED PER VIEWER"
					--,coalesce(p.container_open::int,0) as "OPENS"
					--,p.duration as "DURATION"
					--,p.last_accessed as "LAST ACCESSED"
				from cte_prep p join
					 (select orgId, uuid, count(*) as content_files from ${schema}.OBJECTS group by orgId, uuid) o
					 on p.orgId = o.orgId and p.uuid = o.uuid
			order by p.container_name,p.last_accessed desc
			`}
				render={(data) => {
					return <ReactTable data={data}/>
				}}
			/>




		</div>}
	</>
};

export default ViewerAuditTab;
