export default function deepMerge<T extends Record<string, any>> (base: T, overrides?: Partial<T>) {
	const clone = structuredClone(base) as Record<string, any>;
	if (overrides) {
		for (const [key, value] of Object.entries(overrides)) {
			if (value && typeof value === 'object') {
				clone[key] = deepMerge(clone[key] || {}, value);
			} else {
				clone[key] = value;
			}
		}
	}
	return clone as T;
}
