import React from "react";
import { OrgContext } from "../../context/OrgContext";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import DataGrid from "../../components/DataGrid";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import { ChartOrg } from "../../api/ApiClient";
import * as object_path from '../../utils/object_path';
import csvjson from "csvjson";
import downloadBlob from "../../utils/downloadBlob";
import { createTheme } from '@mui/material/styles';


const ChangeLogTab: React.FC = () => {
    const { org, api } = React.useContext(OrgContext);

    const [all_users, set_all_users] = React.useState<ChartOrg[]>([]);

    const [audit_date_range, set_audit_date_range] = React.useState(DateRange.All)
    const [audit_selected_org_id, set_audit_selected_org_id] = React.useState('All');
    const [audit_logs, set_audit_logs] = React.useState<any[]>([])

    async function load_audit_logs() {
        const [start, end] = range_to_dates(audit_date_range)

        const org_ids = audit_selected_org_id !== 'All'
            ? [audit_selected_org_id]
            : all_users.length > 0
                ? all_users.map(org => org.id)
                : [org?.id];

        const logs = await api!.get_org_audit(start, end, org_ids);
        set_audit_logs(map_audit_logs(logs));
    }


    async function load_org_chart() {
        const org_chart = await api?.get_org_chart(org.id, true);
        const users = org_chart!.orgs.filter(org => !['org', 'group'].includes(org.role!))
        users.sort((a, b) => a.email!.localeCompare(b.email!))
        set_all_users(users);
    }

    React.useEffect(() => {
        if (api) load_org_chart();
    }, [api])

    React.useEffect(() => {
        if (api) load_audit_logs()
    }, [api, all_users, audit_date_range, audit_selected_org_id])

    function map_audit_logs(logs: any[]) {
        const field_names: Record<string, string | undefined> = {
            first_name: 'First Name',
            last_name: 'Last Name',
            mobile_phone: 'Mobile Phone',
            role: 'Role',
            'parent_org.org_name': 'Group',
            deleted_flag: 'Deleted',
        };

        const role_to_name: Record<string, string> = {
            super: 'Customer Success Admin',
            org_admin: 'Super Admin',
            org_manager: 'Org Manager',
            user_admin: 'Group Manager',
            user: 'User',
        };

        return logs.map(log => {
            const id = log.id;
            const ts = new Date(log.ts).toLocaleString();
            const acting_org = log.acting_org.email;
            const last_name = log.acting_org.last_name;
            const first_name = log.acting_org.first_name;
            const email = log.new_data?.email ?? log.old_data?.email;
            const action = (!log.old_data?.deleted_flag && log.new_data?.deleted_flag)
                ? 'Deactivate'
                : (log.old_data?.deleted_flag && !log.new_data?.deleted_flag)
                    ? 'Reactivate'
                    : { i: 'Create', u: 'Update', d: 'Delete' }[log.action as 'i' | 'u' | 'd'];

            let changes = [];
            for (const [key, field] of Object.entries(field_names)) {
                let old_value = object_path.get(log.old_data, key);
                let new_value = object_path.get(log.new_data, key);

                if (key === 'role') {
                    old_value = role_to_name[old_value];
                    new_value = role_to_name[new_value];
                }

                if (new_value !== undefined && new_value != old_value) {
                    changes.push(`${field}: ${new_value}`);
                }
            }

            return {
                id,
                ts,
                acting_org,
                last_name,
                first_name,
                email,
                action,
                changes: action === 'Deactivate' ? 'User deactivated' : changes.join('; '),
            };
        }).filter(log => !(log.action === "Update" && log.changes === ''));
    }

    const audit_log_columns = [{
        field: 'ts',
        headerName: 'DATE/TIME',
        width: 150,
    }, {
        field: 'acting_org',
        headerName: 'CHANGES MADE BY',
        width: 200,
    },
    {
        field: 'last_name',
        headerName: 'LAST NAME',
        width: 200,
    },
    {
        field: 'first_name',
        headerName: 'FIRST NAME',
        width: 200,
    },
    {
        field: 'action',
        headerName: 'ACTION',
        width: 100,
    },
    {
        field: 'email',
        headerName: 'USER',
        width: 200,
    },
    {
        field: 'changes',
        headerName: 'CHANGES',
        width: 600,
    }]


    if (!['super', 'org_admin'].includes(org?.role)) {
        return <></>;
    }

    function downloadCSVAudit() {
        const csv = csvjson.toCSV(audit_logs, { headers: "key" })
        const blob = new Blob([csv], { type: "text/csv" })
        downloadBlob(blob, "AuditLogs.csv")
    }

    return <>
        <Typography variant="h4" style={{ color: "#2B5592", marginBottom: 10 }}>User Management Audit Log</Typography>
        <Box sx={{ width: '100%', paddingBottom: 8 }}>
            <FormControl sx={{ minWidth: 200, paddingLeft: 1 }}>
                <InputLabel>Select Date Range</InputLabel>
                <DateRangeSelect value={audit_date_range} onChange={(range) => { set_audit_date_range(range); }} />
            </FormControl>
            {/* <FormControl sx={{ minWidth: 200, paddingLeft: 1 }}>
                <InputLabel>Select User</InputLabel>
                <Select
                    value={audit_selected_org_id}
                    onChange={(e) => set_audit_selected_org_id(e.target.value)}
                >
                    <MenuItem key='all' value='All'>All</MenuItem>
                    {all_users.map(user =>
                        <MenuItem
                            key={user.id}
                            value={user.id}
                        >
                            {user.email}
                        </MenuItem>
                    )}
                </Select>
            </FormControl> */}
            <DataGrid rows={audit_logs} columns={audit_log_columns} />
        </Box>
        {/* <Button onClick={downloadCSVAudit}>Download CSV</Button> */}
    </>
}

export default ChangeLogTab
