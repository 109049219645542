const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
const inverse = Array.from(alphabet).reduce((map, char, index) => {
  map[char] = index
  return map
}, {});

// Encodes a Uint8Array to a base64 string.
export function encode(bytes, pad = false) {
  if (!(bytes instanceof Uint8Array)) bytes = new Uint8Array(bytes)
  let string = ''

  let state = 0, stateBits = 0;
  for (const bits of bytes) {
    state = (state << 8) | bits;
    stateBits += 8;

    while (stateBits >= 6) {
      stateBits -= 6;
      string += alphabet[(state >>> stateBits) & 0x3f];
    }
  }

  if (stateBits > 0) {
    string += alphabet[(state << (6 - stateBits)) & 0x3f];
  }

  while (pad && string.length % 4 !== 0) {
    string += '=';
  }

  return string;
}

// Decodes a base64 string to a Uint8Array.
export function decode(string) {
  const length = Math.ceil(string.length / 4 * 3);
  const bytes = new Uint8Array(length);

  let state = 0, stateBits = 0, pos = 0;
  for (const char of string) {
    if (char === '=') break;
    state = (state << 6) | inverse[char];
    stateBits += 6;

    while (stateBits >= 8) {
      stateBits -= 8;
      bytes[pos++] = (state >>> stateBits) & 0xff;
    }
  }

  return bytes.slice(0, pos);
}
