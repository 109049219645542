import IconButton from '@mui/material/IconButton';
import { Visibility } from '@mui/icons-material';

interface Props {
  file?: any;
}

export default function PublishButton(props: Props) {
  let { file } = props;
  function viewUrl() {
    window.open(
      `${process.env.REACT_APP_AUTH_URL}/view/${file.org_id}/${file.uuid}`
    );
  }
  return (
    <>
      {file && file.public_url ? (
        <IconButton aria-label='delete' size='large' onClick={viewUrl}>
          <Visibility />
        </IconButton>
      ) : (
        <></>
      )}
    </>
  );
}
