import { Box } from "@mui/material";
import { DataGrid as MuiDataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter } from "@mui/x-data-grid";
import React from "react";

const DataGrid: typeof MuiDataGrid = (props) => {
  const { columns, ...rest } = props;

  function boldHeader(params: any) {
    return <b>{params.colDef.headerName}</b>;
  }

  for (const column of columns) {
    column.headerAlign = "center";
    column.renderHeader = boldHeader;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Box sx={{ flex: 1 }} />
        <GridToolbarQuickFilter/>
      </GridToolbarContainer>
    );
  }

  const rowHeight = 65;
  const [pageSize, setPageSize] = React.useState(10);

  return (
    <MuiDataGrid
      autoHeight
      columns={columns}
      rowHeight={rowHeight}
      components={{
        Toolbar: CustomToolbar,
      }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          ...props.componentsProps?.toolbar
        },
      }}
      disableColumnMenu={true}
      {...rest}
      rowsPerPageOptions={[10, 50, 100]}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      disableSelectionOnClick
    ></MuiDataGrid>
  );
};

export default DataGrid;
