import * as React from "react";
import {
  Box,
  Paper,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Card,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { DownloadableContext } from "../../context/DownloadableContext";
import { DownloadableContextType } from "../../context/@types/downloadableContext";
import CardContent from "../general/CardContentNoPadding";
import CardHeader from "../general/CardHeaderNoPadding";

const useStyles = makeStyles({
  Main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },
  FormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    //margin: "10px 0px",
    //padding: "10px",
  },
  SwitchContainer: {
    width: "100%",
    padding: "2px 10px",
    //margin: "40px 0px 0px",
    background: "rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
});

const Downloadable = () => {
  const classes = useStyles();

  const { downloadable, setDownloadable } = React.useContext(
    DownloadableContext
  ) as DownloadableContextType;

  const handleChangeDownloadable = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    //event.preventDefault();
    setDownloadable(event.target.checked);
  };

  return (
    // <Card>
      //<CardHeader title="Attachment File Settings" />
      // <CardContent className={classes.FormContainer}>
        <FormGroup className={classes.SwitchContainer}>
          <FormControlLabel
            control={
              <Switch
                checked={downloadable}
                onChange={handleChangeDownloadable}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Allow Download"
          />
        </FormGroup>
      // </CardContent>
    //</Card>
  );
};

export default Downloadable;
