import { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { JSONFormsContext } from '../../context/JSONFormsContext';
import { JSONFormsContextType } from '../../context/@types/jsonFormsContext';
import IPAddressRules from '../ipaddressrules/ipaddressrules';
import LocationRules from '../locationrules/LocationRules';
import OpenRules from '../opencount/OpenCount';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1200px',
    marginBottom: '10px',
  },
});

const AdvancedForm = () => {
  const classes = useStyles();
  const [advancedDisabled, setAdvancedDisabled] = useState(false);
  const { form, updateForm } = useContext(
    JSONFormsContext
  ) as JSONFormsContextType;
  return (
      <Accordion disabled={advancedDisabled} sx={{ width: '100%', marginTop: '5px', backgroundColor: "#E0E0E0" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'>
          <Typography variant='h5'>Advanced Security Rules</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <IPAddressRules/>
          <LocationRules/>
          <OpenRules/>
        </AccordionDetails>
      </Accordion>
  );
};

export default AdvancedForm;
