import * as React from 'react';

import { JSONFormsContextType } from './@types/jsonFormsContext';

export function createBlankForm () {
  return {
    metadata: {
      api: process.env.REACT_APP_API_URL
    },
    files: {}
  };
}

export const JSONFormsContext =
  React.createContext<JSONFormsContextType | null>(null);

const JSONFormsProvider: React.FC<any> = ({ children }) => {
  const [form, setForm] = React.useState<any>(createBlankForm());

  const updateForm = (form: any) => {
    setForm(form);
  };

  return (
    <JSONFormsContext.Provider value={{ form, updateForm }}>
      {children}
    </JSONFormsContext.Provider>
  );
};

export default JSONFormsProvider;
