import * as React from 'react';
import ApiClient from '../api/ApiClient';

type OrgContextType = {
	org: any,
	set_org: (org: any) => void,
	// parent: any,
	// set_parent: (parent: any) => void,
	api: ApiClient | null,
	stop_session: () => Promise<void>
}

export const OrgContext = React.createContext<OrgContextType>({ org: null, set_org: () => { }, api: null, stop_session: () => Promise.resolve() });

function getState () {
	if (document.visibilityState === "hidden") {
		return "hidden";
	}
	if (document.hasFocus()) {
		return "active";
	}
	return "passive";
}

const OrgProvider: React.FC<any> = ({ children }) => {
	const [org, set_org] = React.useState<any>(null);

	const api = React.useMemo(() => org ? new ApiClient(org.id, org.email) : null, [org]);

	const [page_state, set_page_state] = React.useState(getState());
	const [session_id, set_session_id] = React.useState<string | null>(null);

	async function start_session () {
		if (!api) return;
		const session_id = await api?.start_session();
		// console.log('started new session:', session_id);
		set_session_id(session_id);
	}

	async function stop_session () {
		if (!api || !session_id) return;
		// console.log('stopping session:', session_id);
		await api.stop_session(session_id);
		set_session_id(null);
	}

	// Wire up the event handlers once
	React.useEffect(() => {
		const opts = { capture: true };

		for (const type of [
			"pageshow",
			"focus",
			"blur",
			"visibilitychange",
			"resume",
		]) {
			document.addEventListener(
				type,
				() => set_page_state(getState()),
				opts
			);
		}

		window.addEventListener(
			"freeze",
			() => set_page_state('frozen'),
			opts
		);

		window.addEventListener(
			"pagehide",
			(e) => set_page_state(e.persisted ? "frozen" : "terminated"),
			opts
		);
	}, []);

	React.useEffect(() => {
		if (page_state === 'active') {
			// Transitioning to active
			start_session();
		} else {
			stop_session();
		}
	}, [org, page_state]);

	return <OrgContext.Provider value={{ org, set_org, api, stop_session }}>
		{children}
	</OrgContext.Provider>;
}

export default OrgProvider;
