import { AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import { FormControl } from "@mui/material";
import React from "react";
import HorizontalBarGraph from "../../components/analytics/HorizontalBarGraph";
import QueryComponent from "../../components/analytics/QueryComponent";
import ReactTable from "../../components/analytics/ReactTable";
import { ContentFilesFilter, ViewersFilter } from "../../components/analytics/filters";
import { lt_analytics_client_main_v2, quote_string, tableToObjects } from "../../components/analytics/lt_analytics_client";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import { OrgContext } from "../../context/OrgContext";
import GroupVerticalBarGraph from "../../components/analytics/GroupVerticalBarGraph";
import Grid2 from "@mui/material/Unstable_Grid2";

const schema = 'CONTENT_FILES_ACCESSED';

const ViewerAuditTab: React.FC = () => {
	const { org, api } = React.useContext(OrgContext);

	// Allocate the DuckDB connection
	const [conn, setConn] = React.useState<AsyncDuckDBConnection>();
	// Filter states
	const [dateRange, setDateRange] = React.useState(DateRange.Last_14_Days);

	const [allContentFiles, setAllContentFiles] = React.useState<string[]>([]);
	const [selectedContentFiles, setSelectedContentFiles] = React.useState<string[]>([]);

	React.useEffect(() => {
		if (!org) return;
		(async () => {
			try {
				// Clear the old connection
				setConn(undefined);

				// Calculate the filters
				const [start_time, end_time] = range_to_dates(dateRange);

				// Time and execute the query
				const start = performance.now();
				const conn = await lt_analytics_client_main_v2([org.id], null, schema, null, start_time?.toISOString(), end_time?.toISOString());
				const end = performance.now();
				if (end - start > 1000) api?.report_error({
					module: "analytics",
					message: `Slow Analytics Query: ${schema}`,
					duration_ms: end - start,
					level: "warning",
				});

				// Make the data available to components
				setConn(conn);

				// Get the list of Contents for the filter
				let contentFiles: any = await conn.query(`select distinct object from ${schema}.EVENTS WHERE object IS NOT NULL order by object`);
				contentFiles = tableToObjects(contentFiles).map((row: any) => row.object);
				setAllContentFiles(contentFiles);
				setSelectedContentFiles(contentFiles);

			} catch (error) {
				api?.report_error({
					module: "analytics",
					user: org.email,
					message: `Error calculating analytics: ${error}`,
					stack: (error as any).stack,
				});
				throw error;
			}
		})();
	}, [org, dateRange]);

	return <>
		<h3>
			Filters:
		</h3>
		<FormControl>
			<DateRangeSelect value={dateRange} onChange={setDateRange} />
		</FormControl>
		<ContentFilesFilter options={allContentFiles} values={selectedContentFiles} set_values={setSelectedContentFiles} />
		{conn && <div>
				<Grid2 style={{ marginBottom: "16px" }} container spacing={1}>
					<Grid2 xs={12} md={8}>
						<QueryComponent
							conn={conn}
							query={`
							with cte_open as (
							select
							q.uuid,
							q.object,
							substr(q.sent_date::text,1,7) as sent_date,
							max(case when q.object_open::int > 0 then 1 else 0 end) as open
							--max(coalesce(q.object_open::int,0)) as open
							from ${schema}.RPT_CONTENT_FILES_ACCESSED_DETAIL_VW q
							group
								by q.uuid,q.object,substr(q.sent_date::text,1,7)
							), cte_download as (
								select substr(h.sent_date::text,1,7) as sent_date,
								1 as download
								from ${schema}.EVENTS e join ${schema}.EVENTS_HEADER h on e.orgid = h.orgid and e.uuid = h.uuid
								where e.object is not null
								and e.action = 'object.download'
							), cte_sent as (
								select substr(sent_date::text,1,7) as sent_date,
								1 as sent
								from ${schema}.OBJECTS
							), cte_not_opened as (
								select a.sent_date, (a.sent - b.open) as not_opened
								from (
										select s.sent_date, sum(sent) as sent
										from cte_sent s
										group by s.sent_date
									) a,
									(
										select s.sent_date, sum(open) as open
										from cte_open s
										group by s.sent_date
									) b
									where a.sent_date = b.sent_date
							), cte_final as (
							select
							'OPEN' as "LABEL",
							q.sent_date as "DATE",
							sum(q.open) as "VALUE"
							from cte_open q
							group by q.sent_date
							union all
							select
							'NOT OPENED' as "LABEL",
							q.sent_date as "DATE",
							sum(q.not_opened) as "VALUE"
							from cte_not_opened q
							group by q.sent_date
							union all
							select
							'DOWNLOAD' as "LABEL",
							q.sent_date as "DATE",
							sum(q.download) as "VALUE"
							from cte_download q
							group by q.sent_date
							union all
							select
							'SENT' as "LABEL",
							q.sent_date as "DATE",
							sum(q.sent) as "VALUE"
							from cte_sent q
							group by q.sent_date
							)
							select "LABEL" as "group", "DATE" as x, "VALUE"::int as y from cte_final order by "DATE", "LABEL"
					`}
							render={(data) => {
								return <GroupVerticalBarGraph style={{ height: '400px' }} data={data as any} layout={{ xaxis: { title: 'MONTH', type: 'category' }, yaxis: { title: 'COUNT' } }} title="CONTENT FILES ACCESSED" />;
							}}
						/>
					</Grid2>
					<Grid2 xs={12} md={4}>
						<QueryComponent
							conn={conn}
							query={`
							with cte_main as (
							select
							q.object,
							sum(coalesce(q.object_open::int,0)) as opens
							from ${schema}.RPT_CONTENT_FILES_ACCESSED_DETAIL_VW q
							group
								by q.object
							)
							select
							q.object as "FILE",
							q.opens::int as "OPENS"
							from cte_main q
							order by q.opens desc
							limit 5

					`}
							render={(data) => {
								return <HorizontalBarGraph style={{ height: '400px' }} data={data} title="CONTENT FILES MOST ACCESSED" />;
							}}
						/>
					</Grid2>
				</Grid2>

				<QueryComponent
					conn={conn}
					query={`
					with cte_main as (
						select -- c.orgId,c.uuid,
							-- c.sender,
							--h.container_name,
							c.object,
							h.ALL_VIEWER_FLAG,
							av.authorized_viewers as authorized_viewers,
							count(distinct(c.viewer)) as viewers,
							sum(object_open) as opens,
							sum(c.duration) as duration_seconds,
							sum(c.object_download) as downloads,
							min(c.ts) as first_accessed,
							max(c.ts) as last_accessed
						from ${schema}.OBJECT_EVENTS_STAT c
						left join
							(select orgId,uuid,count(distinct(viewer)) as authorized_viewers from ${schema}.AUTHORIZED_VIEWER group by orgId,uuid) av
							on c.orgId = av.orgId and c.uuid = av.uuid
						join ${schema}.EVENTS_HEADER h on c.orgId = h.orgId and c.uuid = h.uuid
						group by --c.orgId,c.uuid,
						-- c.sender, --h.container_name,
						c.object,h.ALL_VIEWER_FLAG,av.authorized_viewers
					)
					select object as "CONTENT FILE",
							case when ALL_VIEWER_FLAG = 1 then 'ANYONE WITH LINK' else authorized_viewers end as "AUTHORIZED VIEWERS",
							viewers as "# VIEWERS",
							OPENS as "OPENS",
							(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as "DURATION",
							downloads as "DOWNLOADS",
							first_accessed as "FIRST ACCESSED",
							last_accessed as "LAST ACCESSED"
						from cte_main
						where object is not null
						order by object,last_accessed desc
					`}
					render={(data) => {
						return <ReactTable data={data} title="CONTENT ACCESS SUMMARY"/>;
					}}
				/>

				<QueryComponent
					conn={conn}
					query={`
					with cte_main as (
						select
						q.object,
						q.container_name,
						q.viewer,
						coalesce(round(q.percent_viewership,2),0) as complete,
						coalesce(q.object_open::int,0) as opens,
						q.duration,
						q.last_accessed
						from ${schema}.RPT_CONTENT_FILES_ACCESSED_DETAIL_VW q
						${selectedContentFiles.length > 0 ? `WHERE q.object IN (${selectedContentFiles.map(s => quote_string(s)).join(",")})` : ''}
					)
					select
					q.object as "CONTENT FILE",
					q.container_name as "CONTAINER NAME",
					q.viewer as "VIEWER",
					case when q.complete > 100 then 100 else q.complete end as "% COMPLETE",
					q.opens as "OPENS",
					q.duration as "DURATION",
					q.last_accessed as "LAST ACCESSED"
						from cte_main q
					order
						by q.object,q.last_accessed desc
				`}
					render={(data) => {
						return <ReactTable data={data} title="CONTENT ACCESS DETAIL" />;
					}}
				/>
		</div>}
	</>
};

export default ViewerAuditTab;
