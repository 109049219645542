import React from "react";
import { OrgContext } from "../../context/OrgContext";
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import DataGrid from "../../components/DataGrid";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import { ChartOrg } from "../../api/ApiClient";
import * as object_path from '../../utils/object_path';
import csvjson from "csvjson";
import downloadBlob from "../../utils/downloadBlob";
import { createTheme } from '@mui/material/styles';


const AccessLogTab: React.FC = () => {
    const { org, api } = React.useContext(OrgContext);

    const [all_users, set_all_users] = React.useState<ChartOrg[]>([]);

    const [access_date_range, set_access_date_range] = React.useState(DateRange.All)
    const [access_selected_org_id, set_access_selected_org_id] = React.useState('All');
    const [access_logs, set_access_logs] = React.useState<any[]>([])

    async function load_access_logs() {
        const [start, end] = range_to_dates(access_date_range)

        const org_ids = access_selected_org_id !== 'All'
            ? [access_selected_org_id]
            : all_users.length > 0
                ? all_users.map(org => org.id)
                : [org?.id];
        const logs = await api!.get_org_access(start, end, org_ids)
        set_access_logs(logs.map((log: any) => {
            return {
                id: log.id,
                start_date: new Date(log.start_date).toLocaleString(),
                email: log.org?.email,
                first_name: log.org?.first_name,
                last_name: log.org?.last_name,
                end_date: log.end_date ? new Date(log.end_date).toLocaleString() : '',
                start_ip: log.start_ip,
                end_ip: log.end_ip,
                start_country: log.start_geo?.country?.iso_code,
                start_region: log.start_geo?.subdivisions?.[0]?.iso_code,
                start_city: log.start_geo?.city?.names?.en,
            }
        }));
    }

    async function load_org_chart() {
        const org_chart = await api?.get_org_chart(org.id, true);
        const users = org_chart!.orgs.filter(org => !['org', 'group'].includes(org.role!))
        users.sort((a, b) => a.email!.localeCompare(b.email!))
        set_all_users(users);
    }

    React.useEffect(() => {
        if (api) load_org_chart();
    }, [api])

    React.useEffect(() => {
        if (api) load_access_logs()
    }, [api, all_users, access_date_range, access_selected_org_id])

    function map_audit_logs(logs: any[]) {
        const field_names: Record<string, string | undefined> = {
            first_name: 'First Name',
            last_name: 'Last Name',
            mobile_phone: 'Mobile Phone',
            role: 'Role',
            'parent_org.org_name': 'Group',
            deleted_flag: 'Deleted',
        };

        const role_to_name: Record<string, string> = {
            super: 'Customer Success Admin',
            org_admin: 'Super Admin',
            org_manager: 'Org Manager',
            user_admin: 'Group Manager',
            user: 'User',
        };

        return logs.map(log => {
            const id = log.id;
            const ts = new Date(log.ts).toLocaleString();
            const acting_org = log.acting_org.email;
            const last_name = log.acting_org.last_name;
            const first_name = log.acting_org.first_name;
            const email = log.new_data?.email ?? log.old_data?.email;
            const action = (!log.old_data?.deleted_flag && log.new_data?.deleted_flag)
                ? 'Deactivate'
                : (log.old_data?.deleted_flag && !log.new_data?.deleted_flag)
                    ? 'Reactivate'
                    : { i: 'Create', u: 'Update', d: 'Delete' }[log.action as 'i' | 'u' | 'd'];

            let changes = [];
            for (const [key, field] of Object.entries(field_names)) {
                let old_value = object_path.get(log.old_data, key);
                let new_value = object_path.get(log.new_data, key);

                if (key === 'role') {
                    old_value = role_to_name[old_value];
                    new_value = role_to_name[new_value];
                }

                if (new_value !== undefined && new_value != old_value) {
                    changes.push(`${field}: ${new_value}`);
                }
            }

            return {
                id,
                ts,
                acting_org,
                last_name,
                first_name,
                email,
                action,
                changes: action === 'Deactivate' ? 'User deactivated' : changes.join('; '),
            };
        }).filter(log => !(log.action === "Update" && log.changes === ''));
    }

    const access_log_columns = [{
        field: 'start_date',
        headerName: 'START DATE',
        width: 150,
    }, {
        field: 'end_date',
        headerName: 'END DATE',
        width: 150,
    },
    {
        field: 'email',
        headerName: 'USER',
        width: 200,
    },
    {
        field: 'last_name',
        headerName: 'LAST NAME',
        width: 200,
    },
    {
        field: 'first_name',
        headerName: 'FIRST NAME',
        width: 200,
    },
    {
        field: 'start_ip',
        headerName: 'IP',
        width: 100,
    }, {
        field: 'start_country',
        headerName: 'COUNTRY',
        width: 100,
    }, {
        field: 'start_region',
        headerName: 'REGION/STATE',
        width: 100,
    }, {
        field: 'start_city',
        headerName: 'CITY',
        width: 100,
    }]

    if (!['super', 'org_admin'].includes(org?.role)) {
        return <></>;
    }

    function downloadCSVAccess() {
        const csv = csvjson.toCSV(access_logs, { headers: "key" })
        const blob = new Blob([csv], { type: "text/csv" })
        downloadBlob(blob, "AccessLogs.csv")
    }

    return <>
        <Typography variant="h4" style={{ color: "#2B5592", marginBottom: 10 }}>User Access Log</Typography>
        <Box sx={{ width: '100%', paddingBottom: 8 }}>
                    <FormControl sx={{ minWidth: 200, paddingLeft: 1 }}>
                        <InputLabel>Select Date Range</InputLabel>
                        <DateRangeSelect value={access_date_range} onChange={(range) => { set_access_date_range(range); }} />
                    </FormControl>
                    {/* <FormControl sx={{ minWidth: 200, paddingLeft: 1 }}>
                        <InputLabel>Select User</InputLabel>
                        <Select
                            value={access_selected_org_id}
                            onChange={(e) => set_access_selected_org_id(e.target.value)}
                        >
                            <MenuItem key='all' value='All'>All</MenuItem>
                            {all_users.map(user =>
                                <MenuItem
                                    key={user.id}
                                    value={user.id}
                                >
                                    {user.email}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl> */}
                    <DataGrid rows={access_logs} columns={access_log_columns} />
                </Box>
        {/* <Button onClick={downloadCSVAccess}>Download CSV</Button> */}
    </>
}

export default AccessLogTab
