import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDropzone } from "react-dropzone";

import { FilesContext } from "../../context/FilesContext";
import { IFile, FileContextType } from "../../context/@types/filesContext";
import styled from "styled-components";
import FileList from "./FileList";

import { JSONFormsContext } from "../../context/JSONFormsContext";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";

import FileViewerDialog from "../general/FileViewerDialog";
import DeleteFilesDialog from "./DeleteFilesDialog";
import { CircularProgress, Grid, Modal } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
// @ts-ignore
import Grid2 from "@mui/material/Unstable_Grid2";
import { OrgContext } from "../../context/OrgContext";
import { encode as base64Encode } from "../../utils/base64";
import { createMD5 } from "hash-wasm";
import CustomSnackbar, { Severity } from "../general/snackbar";
import CircularProgressWithLabel from "../encoder/CircularProgressWithLabel";

const useStyles = makeStyles({
  Main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    // height: '100%',
  },
  ContentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
    marginTop: "10px",
  },
  FileContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  FileTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "20px 0px",
  },
  ListItemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "10px 0px",
  },
  dataContent: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "0.25em",
    backgroundColor: "#cecece",
    marginBottom: "1rem",
    width: "800px",
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#716d6d";
};

/**
    border-width: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: solid;
   */

const Container = styled.div`
  /* flex: 1; */
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 4px;
  background-color: #c4d6ed;
  color: ${(props) => getColor(props)};
  outline: none;
  transition: border 0.24s ease-in-out;
`;

interface Props {
  importCSV: (csv_file: File) => void;
  onError: (error: Error) => void;
}

const RecoverFilesUI = (props: Props) => {
  const { api } = React.useContext(OrgContext);
  const [snackBarSeverity, setSnackBarSeverity] = useState<Severity>("success");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [modal_open, set_modal_open] = useState(false);
  const [modal_body, set_modal_text] = useState("");
  const [progress, set_progress] = useState<number | undefined>();

  const onDrop = (acceptedFiles: File[]) => {
    if(acceptedFiles.length === 0) {
      props.onError(new Error("File type not allowed. Please upload csv file."));
    } else {
      props.importCSV(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        'text/csv': ['.csv']
      }
    });

  const showSnackbar = (
    severity: Severity,
    message: string,
    ms = severity === "success" ? 3000 : 15000
  ) => {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);
    setSnackBarOpen(true);
    if (ms > 0) setTimeout(() => setSnackBarOpen(false), ms);
  };

  function onProgress(progressEvent: any) {
    set_progress((100 * progressEvent.loaded) / progressEvent.total);
    // console.log(
    //   "Upload progress:",
    //   Math.round((progressEvent.loaded / progressEvent.total) * 100) + "%"
    // );
  }

  return (
    <>
      <Box>
        <Box>
          <Typography
            variant="h5"
            align="left"
            sx={{ margin: "10px", width: "100%" }}
          ></Typography>
          <Grid2 container spacing={1}>
            <Grid2 md={12} xs={12} sm={6}>
              <Container
                {...getRootProps({
                  isFocused,
                  isDragAccept,
                  isDragReject,
                  onDrop: (event) => event.preventDefault(),
                  onClick: (event) => event.preventDefault(),
                })}
                style={{ borderColor: "#2B5592", color: "#58585B" }}
              >
                <input {...getInputProps()} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <UploadFileIcon
                    style={{ marginRight: "10px", fontSize: "100px" }}
                  />
                  <Typography variant="body1" style={{ marginRight: "20px" }}>
                    Select a CSV file to import
                  </Typography>
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      backgroundColor: "#58585B",
                      padding: "2px 16px",
                      borderRadius: "8px",
                    }}
                  >
                    Browse
                  </Button>
                </div>
              </Container>
            </Grid2>

            {/* <Grid2 md={6} xs={12} sm={6}>
              <FileList files={files} removeFile={removeFile} />
            </Grid2> */}
          </Grid2>
        </Box>
      </Box>
      <CustomSnackbar
        severity={snackBarSeverity}
        message={snackBarMessage}
        state={snackBarOpen}
        changeState={setSnackBarOpen}
      />
      <Modal open={modal_open}>
        <Box sx={modalStyle}>
          <h2>Restoring File</h2>
          <p>{modal_body}</p>
          <div>
            {progress == null ? (
              <CircularProgress variant="indeterminate" />
            ) : (
              <CircularProgressWithLabel value={progress} />
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default RecoverFilesUI;
