import { BoxProps } from "@mui/material";
import Plot from "react-plotly.js";
import { findColumns } from "./lt_analytics_client";
import { Data, Layout } from "plotly.js";
import deepMerge from "../../utils/deepMerge";

type Props = {
  data: Record<string, any>[];
  style?: Partial<React.CSSProperties>;
  layout?: Partial<Layout>;
  title?: string;
} & BoxProps;

const DonutGraph: React.FC<Props> = (props) => {

  if (!props.data?.length) {
    return <h3>No Data Found</h3>;
  }
  const columns = findColumns(props.data);
  const values = columns.map((c) => props.data[0][c]);

  const plot: Data[] = [
    {
      type: "pie",
      labels: columns,
      values: values,
      hole: 0.4,
      marker: {
        colors: ["#64BBE3", "#8B1001"],
      },
    },
  ];

  var layout: Partial<Layout> = {
    autosize: true,
		title: {
			text: props.title,
			font: {
				size: 13,
				color: '#000'
			}
		},
		legend: {
			font: {
				size: 10
			}
		}
  }

  return <Plot
    style={{ width: '100%', height: '100%', ...props.style }}
    data={plot}
    layout={deepMerge(layout, props.layout)}
    useResizeHandler
  />;
};

export default DonutGraph;
