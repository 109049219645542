import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Autocomplete,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useContext } from "react";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";
import { JSONFormsContext } from "../../context/JSONFormsContext";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./index.css";
import CardContent from "../general/CardContentNoPadding";
import CardHeader from "../general/CardHeaderNoPadding"
import { stringify } from "querystring";
import React from "react";
import { OrgContext } from "../../context/OrgContext";
import { isOutlook } from "../../utils/office";

type Uploader = {
  id: string;
  name?: string;
  email: string;
};

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
  },
});

const Uploaders = () => {
  const { org, api } = React.useContext(OrgContext);

  const classes = useStyles();
  const { form, updateForm } = useContext(
    JSONFormsContext
  ) as JSONFormsContextType;

  let uploaders: Uploader[] = form.uploaders ?? [{ id: crypto.randomUUID(), email: '' }];
  const [addresses, set_addresses] = React.useState<any[]>([]);
  // console.log("Recipients: ", recipients);

  function updateEmail(index: number, email: string) {
    uploaders[index].email = email;
    updateForm({ ...form, uploaders: uploaders });
    ensureBlank();
  }


  React.useEffect(() => {
    if (!api) return;
    //load_addresses();
  }, [api]);

  function ensureBlank() {
    while (uploaders.length > 1 && uploaders[uploaders.length - 1].email === "") {
      deleteRecipeint(uploaders.length - 1);
    }
    if (uploaders[uploaders.length - 1].email !== "") {
      addRecipient();
    }
  }

  function addRecipient() {
    uploaders.push({
      id: crypto.randomUUID(),
      email: "",
    });
    updateForm({ ...form, uploaders: uploaders });
  }

  function deleteRecipeint(index: number) {
    uploaders.splice(index, 1);
    updateForm({ ...form, uploaders: uploaders });
  }

  function emailError(email: string) {
    return ((email == "") || /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:\*|(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)/i.test(email)) ? null : "Invalid email format";
  }

  return (
    <Card style={{ width: "100%", backgroundColor: "#C4D6ED" }}>
      <CardHeader title="Authorized Uploader(s)" />
      <CardContent>
        <div className="recipient-form">
          {!isOutlook() && <div className="recipient-form-grid">
            {uploaders.map((uploader, i) => (uploader.email !== '*@*' &&
              <div className="recipient-form-row" key={uploader.id}>
                <div>
                  {i === uploaders.length - 1 ? (
                    <IconButton onClick={() => addRecipient()}>
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => deleteRecipeint(i)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                </div>
                <div className="recipient-form-col">
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={addresses.map((option) => option.email)}
                    onInputChange={(e, value) => {
                      updateEmail(i, value)
                    }}
                    value={uploader.email}
                    renderInput={(params) => (
                      <TextField
                        error={emailError(uploader.email) != null}
                        {...params}
                        placeholder="Email"
                        variant="standard"
                        className="recipient-form-field"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                        helperText={emailError(uploader.email)}
                      />
                    )}
                  />
                </div>
              </div>
            ))}
          </div>}
        </div>
      </CardContent>
    </Card>
  );
};

export default Uploaders;
