import { Card, CardContent, CardHeader, FormControlLabel, FormGroup, Switch, Checkbox, TextField, Button, MenuItem, Select, Container } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import React, { Fragment } from "react";
import { OrgContext } from "../../context/OrgContext";
import CustomSnackbar, { Severity } from "../../components/general/snackbar";
import EnableDialog from "../../components/2FA/EnableDialog";
import DisableDialog from "../../components/2FA/DisableDialog";

const ReferenceFields: React.FC = () => {
    const { org, api } = React.useContext(OrgContext);


    const [customizations, set_customizations] = React.useState<any>(null)

    async function load_customizations() {
        if (!api) return;
        set_customizations(await api.get_org_customizations())
    }

    async function save_customizations() {
        try {
            if (customizations.root) await api?.set_org_customizations(customizations.root, org.root_org_id)
            if (customizations.user) await api?.set_org_customizations(customizations.user, org.id)

            show_snack_bar("success", "Configuration Saved")
        } catch (error) {
            show_snack_bar("error", `Error Saving Configuration: ${error}`)
        }
    }

    function change_customizations(level: string, property: string, value: any) {
        const new_customizations = structuredClone(customizations)
        if (!new_customizations[level]) new_customizations[level] = {}
        new_customizations[level][property] = value
        set_customizations(new_customizations)
        return new_customizations
    }

    React.useEffect(() => {
        load_customizations()
    }, [org])

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarSeverity, setSnackBarSeverity] =
        React.useState<Severity>("success");
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    function show_snack_bar(severity: Severity, message: string) {
        setSnackBarSeverity(severity);
        setSnackBarMessage(message);
        setSnackBarOpen(true);
    }

    return <>
    <Container disableGutters>
        <h2 style={{color: "#2B5592"}}>Require Reference Fields</h2>
        <h3 style={{color: "black"}}><InfoIcon style={{color: "#64BBE3"}}/> &nbsp; Create custom Reference Field Labels to guide users to enter information. Reference field labels are encrypted and searchable. For example, Customer Name, Case #, Patient ID, etc.​​</h3>

        <Card
            sx={{
                borderColor: "#2B5592",
                borderWidth: "2px",
                borderStyle: "solid",
                width: "100%",
                marginBottom: 2,
            }}
        >
            <CardHeader title="Custom Reference Field Labels" />
            <CardContent>
                <FormGroup >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TextField
                        label="Reference Field 1"
                        value={customizations?.root?.custom_reference_1 ?? ""}
                        onChange={(e) => {
                            change_customizations("root", "custom_reference_1", e.target.value)
                        }}
                        onBlur={(e) => {
                            change_customizations("root", "custom_reference_1", e.target.value)
                        }}
                        fullWidth
                    />
                    <FormControlLabel
                        control={<Switch
                            checked={customizations?.root?.custom_reference_1_required ?? false}
                            onChange={(e) => {
                                change_customizations("root", "custom_reference_1_required", e.target.checked)
                            }}
                        />}
                        disabled={!customizations?.root?.custom_reference_1}
                        label={<span style={{ fontWeight: 'bold' }}>Required</span>}
                        labelPlacement="end"
                        style={{ marginLeft: 'auto' }}
                    />
                    </div>
                </FormGroup>
                <FormGroup >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TextField
                        label="Reference Field 2"
                        value={customizations?.root?.custom_reference_2 ?? ""}
                        onChange={(e) => {
                            change_customizations("root", "custom_reference_2", e.target.value)
                        }}
                        onBlur={(e) => {
                            change_customizations("root", "custom_reference_2", e.target.value)
                        }}
                        fullWidth
                    />
                    <FormControlLabel
                       control={<Switch
                        checked={customizations?.root?.custom_reference_2_required ?? false}
                        onChange={(e) => {
                                change_customizations("root", "custom_reference_2_required", e.target.checked)
                            }}
                        />}
                        disabled={!customizations?.root?.custom_reference_2}
                        label={<span style={{ fontWeight: 'bold' }}>Required</span>}
                        labelPlacement="end"
                        style={{ marginLeft: 'auto' }}
                    />
                </div>
                </FormGroup>
                <FormGroup >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <TextField
                        label="Reference Field 3"
                        value={customizations?.root?.custom_reference_3 ?? ""}
                        onChange={(e) => {
                            change_customizations("root", "custom_reference_3", e.target.value)
                        }}
                        onBlur={(e) => {
                            change_customizations("root", "custom_reference_3", e.target.value)
                        }}
                        fullWidth
                    />
                    <FormControlLabel
                       control={<Switch
                        checked={customizations?.root?.custom_reference_3_required ?? false}
                        onChange={(e) => {
                                change_customizations("root", "custom_reference_3_required", e.target.checked)
                            }}
                        />}
                        disabled={!customizations?.root?.custom_reference_3}
                        label={<span style={{ fontWeight: 'bold' }}>Required</span>}
                        labelPlacement="end"
                        style={{ marginLeft: 'auto' }}
                    />
                </div>
                </FormGroup>
            </CardContent>
        </Card>
       
        <Button variant="outlined" onClick={(e) => { load_customizations() }} sx={{ width: "10%", marginRight: 2 }}>
            Cancel
        </Button>
        <Button variant="contained" onClick={(e) => { save_customizations() }} sx={{ width: "10%", marginRight: 2 }}>
            Save
        </Button>


        <CustomSnackbar
            severity={snackBarSeverity}
            message={snackBarMessage}
            state={snackBarOpen}
            changeState={setSnackBarOpen}
        />
        </Container>

        <p style={{fontSize: '16px'}}>Use references to enhance search capabilities. For example, account #, Case #, Department, project name, etc</p>
        <p style={{fontSize: '16px'}}>Set Reference Field Labels</p>

        <ol style={{fontSize: '16px'}}>
        <li>Name reference field as it should appear when securing files.</li>
        <li>Toggle Required switch on if the field is always required by your users.</li>
        </ol>

        <p style={{fontSize: '16px'}}>For more information on using references click the help (?) section in the bottom right.</p>
    </>
}

export default ReferenceFields
