import * as React from "react";
import { Fragment, useState, useMemo, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";

import downloadBlob from "../utils/downloadBlob";
import { insertCSVEventData } from "../utils/alasql";
import Box from "@mui/material/Box";

import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";

import ContainerStats from "../components/analytics/ContainerStats";
import { OrgContext } from "../context/OrgContext";
import { Container } from "@mui/material";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // alignItems: "center",
    // padding: "10px 0px",
    // width: "100%",
    // height: '100%'
  },
  analyticsTopNav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0px",
  },
  analyticsControls: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // padding: '10px 0px',
  },
  downloadButton: {
    height: "56px",
  },
});

interface Props { }

const Analytics = (props: Props) => {
  const classes = useStyles();
  const { uuid } = useParams();
  const { api } = useContext(OrgContext);

  let [type, setType] = useState("Table");
  let [events, setEvents] = useState<any>(null);
  // let [userAccessData, setUserAccess] = useState<any>(null); // not nec
  let [formattedData, setFormattedData] = useState<any>(null); // // not nec
  let [fileIsBlocked, setFileIsBlocked] = useState<any>(false);
  let [fileBlockMessage, setFileBlockMessage] = useState<any>("");
  let [navOptions, setNavOptions] = useState<any>(null);

  const downloadCSV = () => {
    const blob = new Blob([events], { type: "text/csv" });
    downloadBlob(blob, `${uuid}.csv`);
  };

  function onChangeSelection(selected: any) {
    setType(selected);
  }

  async function checkBlock() {
    try {
      const response = await api?.getExceptions(uuid!);
      // console.log('response', response);
      if (!response.exception || response.exception === "") {
        setFileIsBlocked(false);
        setFileBlockMessage("");
      } else {
        setFileIsBlocked(true);
        setFileBlockMessage(response.exception);
      }
    } catch (error) {
      console.log("checkBlock error:", checkBlock);
    }
  }

  const getData = async () => {
    let eventsLocal;
    let formattedDataLocal;
    // let userAccessDataLocal;


    const getEvents = async () => {
      try {
        setFormattedData([]);
        setEvents([]);
        let events1 = await api?.getEvents(uuid!);
        const formatted = await insertCSVEventData(events1);
        console.log("formatted", formatted);
        console.log("events1", events1);
        setFormattedData(formatted);
        formattedDataLocal = formatted
        setEvents(events1);
        eventsLocal = events1
      } catch (error) {
        console.log("useEffect getEvents ERROR", error);
      }
    };
    // const getUserAccess = async () => {
    //   setUserAccess([])
    //   let res = await API?.getUserAccess(uuid);
    //   setUserAccess(res);
    //   userAccessDataLocal = res
    // };
    await getEvents();
    // await getUserAccess();
    await checkBlock();
  }

  // useEffect(() => { getData() }, []);

  return (
    // <Box className={classes.main}>
    //   <Box className={classes.analyticsTopNav}>
    //     <Box className={classes.analyticsControls}>
    //       {navOptions !== null ?
    //         <>
    //           <EventNav
    //             type={type}
    //             options={navOptions}
    //             onChangeSelection={onChangeSelection}
    //           />
    //           <Button
    //             variant="contained"
    //             startIcon={<RefreshIcon />}
    //             className={classes.downloadButton}
    //             onClick={getData}>
    //             Refresh
    //           </Button>
    //         </>
    //         : null}
    //     </Box>
    //     <Box className={classes.analyticsControls}>
    //       <EventControls
    //         uuid={uuid}
    //         fileIsBlocked={fileIsBlocked}
    //         fileBlockMessage={fileBlockMessage}
    //         checkBlock={checkBlock}
    //       />
    //       <Button
    //         variant='outlined'
    //         startIcon={<DownloadIcon />}
    //         className={classes.downloadButton}
    //         disabled={events === null ? true : false}
    //         onClick={() => {
    //           downloadCSV();
    //         }}>
    //         Download Analytics
    //       </Button>
    //     </Box>
    //   </Box>
    //   {formattedData && userAccessData && events && navOptions !== null ? (
    //     // navOptions.map((option: any) => {
    //     //   if (option.title === type) {
    //     //     return option.component;
    //     //   }
    //     // })
    //     <>
    //       {type === "User Access Table" ?
    //         <UserAccessTable data={userAccessData} />
    //         : <></>}
    //       {type === "Table" ?
    //        <EventTable data={formattedData} />
    //         : <></>}
    //       {type === "Tera File Views QTY vs Date - Bar Graph" ?
    //         <EventGBar events={events} data={formattedData} />
    //         : <></>}
    //       {type === "Tera File Views - Choropleth" ?
    //         <EventGChoropleth events={events} data={formattedData} />
    //         : <></>}
    //     </>
    //   ) : (
    //     <>Loading...</>
    //   )}
    // </Box>
    <Container>
      <ContainerStats uuid={uuid!} />
    </Container>
  );
};

export default Analytics;
