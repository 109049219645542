import { Box, Card, FormControlLabel, Switch, TextField } from "@mui/material";
import { useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { JSONFormsContext } from "../../context/JSONFormsContext";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";
import CardContent from "../general/CardContentNoPadding";
import CardHeader from "../general/CardHeaderNoPadding";
import { openDuckDB } from "../analytics/duckdb";
import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";

const dbPromise = openDuckDB(+process.env.REACT_DEBUG! > 0);

const useStyles = makeStyles({
  FormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "10px",
  },
  Main2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
});



const OpenRules = () => {

  const { form, updateForm } = useContext(
    JSONFormsContext
  ) as JSONFormsContextType;

  const [enabled, setEnabled] = React.useState(false);

  const handleToggle = () => {
    handleLimitChange(null);
    setEnabled(!enabled);
  };

  const rule = form.drmRules?.opens ?? {};

  const updateRule = () => updateForm({
    ...form,
    drmRules: {
      ...form.drmRules,
      opens: rule
    }
  });

  const handleLimitChange = (newValue: number | null) => {
    if (newValue && enabled) {
      rule.limit = newValue;
    } else {
      delete rule.limit;
    }
    updateRule();
  };

  const classes = useStyles();

  return (
    <Box className={classes.FormContainer}>
      <Card
        style={{
          width: "100%",
          borderColor: "#2B5592",
          borderWidth: "2px",
          borderStyle: "solid",
          overflow: "auto",
        }}
      >
        <CardHeader title="Open Rules" />
        <CardContent>
          <Grid2 container>
            <Grid2 xs={12} sm={3}>
            <FormControlLabel style={{marginLeft: "24px"}}
              control={
                <Switch
                  checked={enabled}
                  onChange={() => handleToggle()}
                />
              }
              label={enabled ? "Limited" : "Unlimited"}
            />
            </Grid2>
            <Grid2 xs={12} sm={9}>
            <TextField
              id="open-limt"
              label="Open Limit"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              disabled={!enabled}
              onChange={(e) => {handleLimitChange(+e.target.value)}}
              value={rule.limit ?? 0}
            />
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OpenRules;
