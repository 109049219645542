import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { OrgContext } from "../../context/OrgContext";
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

export type Properties = {
    open: boolean,
    onClose?: () => void
}

export default function DisableDialog(props: Properties) {
    const { org, api } = React.useContext(OrgContext);
    const [code, set_code] = React.useState<string>("")
    const [checked, set_checked] = React.useState(false);

    async function disable_2FA() {
        if (org.totp_removal_code === code) {
            org.totp_key = null
            org.totp_removal_code = null
            await api!.update_org(org)
            props.onClose?.()
        }
    }

    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                onClose={props.onClose}
            >
                <DialogTitle>Disable 2FA Warning</DialogTitle>
                <DialogContent>
                    <Typography variant="h6" sx={{ marginLeft: 1, marginBottom: 1 }}>You are about to disable two factor authentication</Typography>
                    <TextField
                        label="Enter removal code"
                        defaultValue="removal code"
                        sx={{ width: "100%", marginTop: 1 }}
                        value={code}
                        onChange={(e) => set_code(e.target.value)}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={event => set_checked(event.target.checked)}
                            />
                        }
                        label="Confirm Removal"
                        sx={{ marginLeft: 0.2 }}
                    />


                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disabled={!checked} onClick={(e) => { disable_2FA() }} sx={{ width: "30%", marginTop: 1 }}>
                        Disable 2FA
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}