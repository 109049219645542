import * as React from 'react';

import { DownloadableContextType } from './@types/downloadableContext';

export const DownloadableContext =
  React.createContext<DownloadableContextType | null>(null);

const DownloadableProvider: React.FC<any> = ({ children }) => {
  const [downloadable, setDownloadable] = React.useState(false);

  return (
    <DownloadableContext.Provider value={{ downloadable, setDownloadable }}>
      {children}
    </DownloadableContext.Provider>
  );
};

export default DownloadableProvider;

