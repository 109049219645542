import * as React from "react";
import {
  GridColDef,
  GridValueGetterParams,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import DataGrid from "../../components/DataGrid";
import {
  AccountCircle,
  Add,
  AdminPanelSettings,
  Edit,
  ExpandMore,
  Groups,
  Key,
  Delete,
  Email,
  PersonSearch,
  QuestionMark,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CustomSnackbar, { Severity } from "../../components/general/snackbar";
import { OrgContext } from "../../context/OrgContext";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ChartOrg } from "../../api/ApiClient";
import { MuiFileInput } from "mui-file-input";
import { encode as base64Encode } from "../../utils/base64";
import * as csvjson from "csvjson";
import { react } from "plotly.js";
import SearchIcon from "@mui/icons-material/Search";
import PUBLIC_URL from "../../utils/PUBLIC_URL";
import DateRangeSelect, {
  DateRange,
  range_to_dates,
} from "../../components/date_range_select/DateRangeSelect";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CSVFileDrop from "../../components/files/CSVFileDrop";
import downloadBlob from "../../utils/downloadBlob";
import { makeStyles } from "@mui/styles";

type TableRow = {
  id: string;
  parents: any[];
  is_user_flag: boolean;
  role: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  mobile_phone?: string;
  token: string;
  parent: any;
};

const icons: any = {
  "Customer Success Admin": () => <Key></Key>,
  "Super Admin": () => (
    <AdminPanelSettings sx={{ color: "#762010" }}></AdminPanelSettings>
  ),
  "Org Manager": () => <PersonSearch></PersonSearch>,
  "Group Manager": () => <Groups sx={{ color: "#78BAE3" }}></Groups>,
  "User": () => <AccountCircle sx={{ color: "#39537F" }}></AccountCircle>,
};

const sort_order: any = {
  org: 0,
  group: 1,
  org_admin: 2,
  user_admin: 3,
  user: 4,
};

type ChartOrgWithParents = ChartOrg & { parents?: ChartOrg[] };

const get_user_email_body = (org: any) =>
  encodeURIComponent(`You are now a LOCKTERA SHARE registered user. Please click on the following link to authenticate your email. You MUST authenticate as ${org.email}

${PUBLIC_URL}

LOCKTERA SHARE is a leading secure document sharing platform. LOCKTERA SHARE enables users to achieve quantum ready security when sharing files with partners, customers, prospects, and members of your team. This platform gives you the ability to secure, track, audit, and revoke access to your content at any time. The platform enables you to transfer and share very large files securely and efficiently.

To get started, please see the User Guide here: https://locktera.com/support`);

const Users: React.FC = () => {
  const { org, api } = React.useContext(OrgContext);

  const [root_orgs, set_root_orgs] = React.useState<any[]>([]);
  const [selected_root_org_id, set_selected_root_org_id] =
    React.useState<string>("");

  const [chart, set_chart] = React.useState<any>();
  const [org_by_id, set_org_by_id] = React.useState<any>();
  const [table_data, set_table_data] = React.useState<TableRow[]>([]);
  const [root, set_root] = React.useState<ChartOrg>();
  const [expanded, set_expanded] = React.useState(false);
  const [mode, set_mode] = React.useState<"add" | "edit">("add");
  const [org_id, set_org_id] = React.useState("");
  const [role, set_role] = React.useState<"org_admin" | "user_admin" | "user">(
    "user"
  );
  const [parent, set_parent] = React.useState<ChartOrg>();
  const [first_name, set_first_name] = React.useState("");
  const [last_name, set_last_name] = React.useState("");
  const [email, set_email] = React.useState("");
  const [mobile_phone, set_mobile_phone] = React.useState("");

  const [csv_file, set_csv_file] = React.useState<File | null>();
  const [csv_errors, set_csv_errors] = React.useState<string[]>([]);

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarSeverity, setSnackBarSeverity] =
    React.useState<Severity>("success");
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [omniSearch, setOmniSearch] = React.useState<string>("");
  const [userFilter, setUserFilter] = React.useState<(user: any) => boolean>(
    () => (user: any) => true
  );

  const [dialog_open, set_dialog_open] = React.useState(false);
  const filteredUsers = React.useMemo(() => {
    return table_data.filter(userFilter);
  }, [table_data, userFilter]);
  React.useEffect(() => {
    console.log("parent:", parent);
  }, [parent]);

  function show_snack_bar(severity: Severity, message: string) {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);
    setSnackBarOpen(true);
  }

  async function load_root_orgs() {
    if (org.role !== "super") return;
    set_root_orgs(await api!.get_root_orgs());
    console.log("Root orgs: ", root_orgs);
  }

  React.useEffect(() => {
    if (org) load_root_orgs();
  }, [org]);

  const load_chart = async () => {
    const new_chart: { orgs: ChartOrgWithParents[] } = await api!.get_org_chart(
      selected_root_org_id || undefined
    );
    const new_org_by_id = new_chart.orgs.reduce(
      (map: { [org_id: string]: any }, org: any) => {
        map[org.id] = org;
        return map;
      },
      {}
    );

    set_org_by_id(new_org_by_id);

    function find_parents(org_id: string, parents: any[] = []): any[] {
      const org = new_org_by_id[org_id];
      if (!org) return parents;
      parents.unshift(org);
      return find_parents(org.parent_org_id, parents);
    }

    // for (const org of new_chart.orgs) {
    //   org.parents = find_parents(org.id!);
    // }

    // function by_parents (a: any, b: any) {
    // 	const min_parent_count = Math.min(a.parents.length, b.parents.length);
    // 	let cmp: number;
    // 	for (let i = 0; i < min_parent_count; i++) {
    // 		if ((cmp = (a.parents[i].is_user_flag ? 0 : 1) - (b.parents[i].is_user_flag ? 0 : 1)) !== 0) return cmp;
    // 		if ((cmp = sort_order[a.parents[i].role] - sort_order[b.parents[i].role]) !== 0) return cmp;
    // 		if ((cmp = (a.parents[i].org_name ?? '').localeCompare(b.parents[i].org_name ?? '')) !== 0) return cmp;
    // 	}
    // 	return a.parents.length - b.parents.length;
    // }

    // new_chart.orgs.sort(by_parents);

    set_chart(new_chart);
    if (!root) {
      set_root(new_chart.orgs[0]);
      set_parent(new_chart.orgs[0]);
    }

    const new_table_data: TableRow[] = new_chart.orgs
      .filter((org: any) => org.is_user_flag)
      .map((org: any) => ({
        org: {
          ...org,
          parent: new_org_by_id[org.parent_org_id],
        },
        id: org.id,
        is_user_flag: org.is_user_flag,
        role:
          org.role === "super"
            ? "Customer Success Admin"
            : org.role === "org_admin"
            ? "Super Admin"
            : org.role === "org_manager"
            ? "Org Manager"
            : org.role === "user_admin"
            ? "Group Manager"
            : "User",
        parents: org.parents,
        first_name: org.first_name,
        last_name: org.last_name,
        email: org.email,
        mobile_phone: org.mobile_phone,
        parent:  `${new_org_by_id[org.parent_org_id].role === "org" ? "Organization" : "Group"}: ${new_org_by_id[org.parent_org_id].org_name}`,
        org_id: org.id,
        token: org.token,
      }));

    // new_table_data.sort((a, b) => {
    // 	const cmp = by_parents(a, b);
    // 	if (cmp != 0) return cmp;
    // 	return (a.email ?? '').localeCompare(b.email ?? '');
    // });

    new_table_data.sort((a: any, b: any) => {
      let cmp;
      if ((cmp = a.last_name.localeCompare(b.last_name)) !== 0) return cmp;
      return a.first_name.localeCompare(b.first_name);
    });

    // console.log(new_table_data);

    set_table_data(new_table_data);
  };

  function reset_form() {
    set_expanded(false);
    set_mode("add");
    set_org_id("");
    set_role("user");
    set_parent(root);
    set_first_name("");
    set_last_name("");
    set_email("");
    set_mobile_phone("");
    set_dialog_open(false)
  }

  async function change_group(id: string) {
    if (id === "new") {
      const name = prompt("Enter new group name:");
      if (!name) return;
      try {
        const group = await api?.provisionOrg({
          parent_org_id: root!.id!,
          org_name: name,
          is_user_flag: false,
          role: "group",
        });
        await load_chart();
        set_parent(group);
        show_snack_bar("success", "Group created successfully");
      } catch (error: any) {
        show_snack_bar("error", `Creating Group failed: ${error.message}`);
      }
    } else {
      const org = org_by_id[id];
      set_parent(org);
    }
  }

  async function change_root_org(id: string) {
    if (id === "new") {
      const name = prompt("Enter new organization name:");
      if (!name) return;
      try {
        const new_org = await api?.provisionOrg({
          //parent_org_id: root!.id!,
          org_name: name,
          is_user_flag: false,
          role: "org",
        });
        console.log(new_org);
        show_snack_bar("success", "Organization provisioned successfully");
        await load_root_orgs();
        set_selected_root_org_id(new_org.id);
      } catch (error: any) {
        show_snack_bar(
          "error",
          `Provisioning Organization failed: ${error.message}`
        );
      }
    } else {
      set_selected_root_org_id(id);
    }
  }

  async function save() {
    if (!first_name.trim()) {
      show_snack_bar("error", "First name is required");
      return;
    }

    if (!last_name.trim()) {
      show_snack_bar("error", "Last name is required");
      return;
    }

    if (!email.trim()) {
      show_snack_bar("error", "Email is required");
      return;
    }

    if (!mobile_phone.trim()) {
      show_snack_bar("error", "Mobile phone is required");
      return;
    }

    const new_org = {
      parent_org_id: parent!.id!,
      is_user_flag: true,
      role,
      first_name,
      last_name,
      email,
      mobile_phone,
    };

    try {
      if (org_id) {
        await api?.update_org({ id: org_id, ...new_org });
      } else {
        try {
          await api?.provisionOrg(new_org);
        } catch (err: any) {
          if (err.message === "Email is deactivated") {
            if (
              !window.confirm(
                "Email is deactivated. Do you want to restore it?"
              )
            )
              return;
            await api?.provisionOrg(new_org, true);
          } else {
            throw err;
          }
        }
      }
      reset_form();
      show_snack_bar("success", "User saved successfully");
      load_chart();
    } catch (err: any) {
      show_snack_bar("error", `${err.message}`);
    }
    set_dialog_open(false)
  }

  React.useEffect(() => {
    if (!api) return;
    load_chart();
  }, [api, selected_root_org_id]);

  const [template, set_template] = React.useState("");

  React.useEffect(() => {
    if (!root) return;
    set_template(
      `data:text/plain;base64,${base64Encode(
        new TextEncoder().encode(
          `Email,First Name,Last Name,Mobile Phone,Role,Group\r\norg.admin@example.com,Org,Admin,123-456-7890,Org Admin,${root.org_name}\r\nuser.admin@example.com,User,Admin,234-567-8901,User Admin,Group 1\r\nuser.name@example.com,User,Name,345-678-9012,User,Group 2`
        ),
        true
      )}`
    );
  }, [root]);

  async function change_csv_file(file?: File) {
    if (!file) {
      set_csv_file(undefined);
      set_csv_errors([]);
      return;
    }

    const errors = await get_csv_errors(file);
    if (errors.length) {
      set_csv_file(undefined);
      set_csv_errors(errors);
      return;
    }

    set_csv_file(file);
    set_csv_errors([]);
  }

  async function get_csv_errors(csv_file: File) {
    const csv_string = (await csv_file.text()).replace(/[,\r\n]+$/, "");
    const lines: Record<string, string>[] = csvjson.toObject(csv_string);

    const errors = [];
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      if (!["Org Admin", "User Admin", "User"].includes(line["Role"])) {
        errors.push(`Line ${i + 1}: Invalid role`);
      }

      for (const column of [
        "Group",
        "First Name",
        "Last Name",
        "Email",
        "Mobile Phone",
      ]) {
        if (!line[column]) {
          errors.push(`Line ${i + 1}: ${column} is required`);
        }
      }
    }

    return errors;
  }

  async function import_csv(csv_file: File) {
    try {
      const data = (await csv_file!.text()).replace(/[,\r\n]+$/, "");

      const lines = csvjson.toObject(data);
      const numberOfUsers = lines.length;

      for (const line of lines) {
        const requiredFields = [
          "Email",
          "First Name",
          "Last Name",
          "Mobile Phone",
          "Role",
          "Group",
        ];

        if (
          Object.values(line).some(
            (value: unknown) => (value as string).trim() === ""
          )
        ) {
          throw new Error("Missing data.");
        }

        if (requiredFields.some((field) => !line[field])) {
          throw new Error("Matching error. Please see sample file.");
        }
      }

      await api!.import_orgs(selected_root_org_id || org.id, data);
      show_snack_bar(
        "success",
        `${numberOfUsers} user(s) imported successfully`
      );
      set_csv_file(null);
      load_chart();
    } catch (err: any) {
      show_snack_bar("error", `${err.message}`);
    }
  }

  const useStyles = makeStyles({
    boldStyle: { fontWeight: "bold !important" },
  });

  const classes = useStyles();

  function boldHeader(params: any) {
    return <b>{params.colDef.headerName}</b>;
  }

  const columns: GridColDef[] = [
    {
      field: "icon",
      headerName: "TYPE",
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => {
        return icons[row.role]?.() ?? <QuestionMark></QuestionMark>;
        //return <Icon />;
      },
      renderHeader: boldHeader,
      width: 40,
      headerAlign: "center",
    },
    {
      field: "last_name",
      headerName: "LAST NAME",
      width: 100,
      renderHeader: boldHeader,
      headerAlign: "center",
    },
    {
      field: "first_name",
      headerName: "FIRST NAME",
      width: 100,
      renderHeader: boldHeader,
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "EMAIL",
      width: 150,
      headerAlign: "center",
      renderHeader: boldHeader,
    },
    {
      field: "mobile_phone",
      renderHeader: boldHeader,
      headerName: "MOBILE PHONE",
      headerAlign: "center",
    },
    {
      field: "parent",
      headerName: "DEPARTMENT",
      renderHeader: boldHeader,
      width: 200,
      headerAlign: "center",
    },
    {
      field: "role",
      headerName: "USER ROLE",
      width: 150,
      renderHeader: boldHeader,
      headerAlign: "center",
    },
    // { field: "token", headerName: "Token", width: 250 },
    {
      field: "notify",
      filterable: false,
      disableExport: true,
      headerName: "NOTIFY",
      width: 60,
      headerAlign: "center",
      renderHeader: boldHeader,
      renderCell: ({ row }) =>
        ["super", "org_admin"].includes(org?.role) && (
          <IconButton
            component="a"
            href={`mailto:${row.email}?subject=${encodeURIComponent(
              `LOCKTERA SHARE - ${root?.org_name} Invitation`
            )}&body=${get_user_email_body(row)}`}
          >
            <Email />
          </IconButton>
        ),
    },
    {
      field: "edit",
      headerName: "EDIT",
      width: 60,
      filterable: false,
      disableExport: true,
      headerAlign: "center",
      renderHeader: boldHeader,
      renderCell: ({ row }) =>
        (["super", "org_admin"].includes(org?.role) || row.id === org?.id) && (
          <IconButton
            onClick={(e) => {
              console.log("editing:", row);
              set_mode("edit");
              set_org_id(row.id);
              set_role(row.org.role ?? 'user');
              set_parent(row.org.parent);
              set_first_name(row.first_name);
              set_last_name(row.last_name);
              set_email(row.email);
              set_mobile_phone(row.mobile_phone ?? "");
              set_expanded(true);
              set_dialog_open(true)
            }}
          >
            <Edit />
          </IconButton>
        ),
    },
    {
      field: "delete",
      headerName: "DELETE",
      width: 60,
      filterable: false,
      disableExport: true,
      headerAlign: "center",
      renderHeader: boldHeader,
      renderCell: ({ row }) =>
        ["super", "org_admin"].includes(org?.role) &&
        row.id !== org.id && (
          <IconButton
            onClick={async (e) => {
              if (!window.confirm("Are you sure you want to delete this user?"))
                return;
              await api!.delete_org(row.org);
              load_chart();
            }}
          >
            <Delete />
          </IconButton>
        ),
    },
  ];

  function fileMatchesLastName(lastName: string) {
    return (user: any) => user.last_name.toLowerCase().includes(lastName);
  }

  function fileMatchesFirstName(firstName: string) {
    return (user: any) => user.first_name.toLowerCase().includes(firstName);
  }

  function fileMatchesEmail(email: string) {
    return (user: any) => user.email.toLowerCase().includes(email);
  }

  function fileMatchesMobilePhone(mobilePhone: string) {
    return (user: any) => user.mobile_phone.toLowerCase().includes(mobilePhone);
  }

  function fileMatchesRole(role: string) {
    return (user: any) =>
      user.role.replace("_", " ").toLowerCase().includes(role);
  }

  function fileMatchesGroup(group: string) {
    return (user: any) => user.parent.org_name.toLowerCase().includes(group);
  }

  async function doOmniSearch(newSearch: string) {
    setOmniSearch(newSearch);
    const keep = (file: any) => true;

    if (newSearch.length === 0) {
      setUserFilter(() => keep);
      return;
    }

    newSearch = newSearch.toLowerCase();

    //console.log("tabel_data", table_data);

    const lastNameFilter = fileMatchesLastName(newSearch);

    const firstNameFilter = fileMatchesFirstName(newSearch);

    const emailFilter = fileMatchesEmail(newSearch);

    const mobilePhoneFilter = fileMatchesMobilePhone(newSearch);

    const roleFilter = fileMatchesRole(newSearch);

    const groupFilter = fileMatchesGroup(newSearch);

    setUserFilter(
      () => (user: any) =>
        lastNameFilter(user) ||
        firstNameFilter(user) ||
        emailFilter(user) ||
        mobilePhoneFilter(user) ||
        roleFilter(user) ||
        groupFilter(user)
    );
  }

  const [date_range, set_date_range] = React.useState(DateRange.All);
  const [logs, setLogs] = React.useState<any[]>([]);

  async function load_logs() {
    const [start, end] = range_to_dates(date_range);
    setLogs(await api!.get_org_audit(start, end));
  }

  React.useEffect(() => {
    console.log(api);
    if (api) load_logs();
  }, [api, date_range]);

  const log_columns = [
    {
      field: "ts",
      headerName: "Time Stamp",
      width: 100,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      width: 100,
    },
  ];

  function downloadCSVUsers() {
    const formatted = filteredUsers.map((user) => {
      return {
        Email: user.email,
        "First Name": user.first_name,
        "Last Name": user.last_name,
        "Mobile Phone": user.mobile_phone,
        Role:
          user.role === "super"
            ? "Customer Success Admin"
            : user.role === "org_admin"
            ? "Super Admin"
            : user.role === "org_manager"
            ? "Org Manager"
            : user.role === "user_admin"
            ? "Group Manager"
            : "User",
        Group: user.parent.org_name,
      };
    });
    const csv = csvjson.toCSV(formatted, { headers: "key" });
    const blob = new Blob([csv], { type: "text/csv" });
    downloadBlob(blob, "Locktera Users.csv");
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <Box sx={{ flex: 1 }} />
        <GridToolbarQuickFilter/>
      </GridToolbarContainer>
    );
  }


  return (
    <>
      {org?.role == "super" ? (
        <Box sx={{ maxWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              SUPER: Select Organization
            </InputLabel>
            <Select
              id="select-root-orgs"
              value={selected_root_org_id}
              label="SUPER: Select Organization"
              onChange={(e) => {
                change_root_org((e.target as HTMLInputElement).value);
              }}
              sx={{ backgroundColor: "rgba(139, 16, 1, 0.20)" }}
            >
              {root_orgs.map((root_org) => (
                <MenuItem key={root_org.id} value={root_org.id}>
                  {root_org.org_name}
                </MenuItem>
              ))}
              <MenuItem key="new" value="new">
                <Add /> Provision New Organization
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      ) : (
        <></>
      )}

      <Button variant="contained" onClick={() => { reset_form(); set_dialog_open(true) }}>
        Add New User
      </Button>

      <Dialog
        open={dialog_open}
        onClose={() => set_dialog_open(false)}
        maxWidth={mode == "edit" ? 'sm' : 'md'}
      >
        <DialogTitle>
          {mode === "edit" ? "Edit User" : "Add New User"}
        </DialogTitle>
        <DialogContent>
          <Grid2 container spacing={1}>
            <Grid2 md={mode == "edit" ? 12 : 6} xs={12}>
              {(org?.role == "super" ||
                org?.role.endsWith("admin") ||
                mode === "edit") && (
                  <>
                    <FormControl fullWidth sx={{ margin: 0 }}>
                      <TextField
                        label="First Name"
                        variant="outlined"
                        required
                        value={first_name}
                        onChange={(e) => set_first_name(e.target.value)}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ margin: 0 }}>
                      <TextField
                        label="Last Name"
                        variant="outlined"
                        required
                        value={last_name}
                        onChange={(e) => set_last_name(e.target.value)}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ margin: 0 }}>
                      <TextField
                        label="Email"
                        variant="outlined"
                        required
                        value={email}
                        onChange={(e) => set_email(e.target.value)}
                        disabled={mode === "edit"}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ margin: 0 }}>
                      <TextField
                        label="Mobile Phone"
                        variant="outlined"
                        required
                        value={mobile_phone}
                        onChange={(e) => set_mobile_phone(e.target.value)}
                      />
                    </FormControl>
                    {org?.id !== org_id && (
                      <FormControl fullWidth>
                        <InputLabel id="role-label">Role</InputLabel>
                        <Select
                          labelId="role-label"
                          label="Role"
                          required
                          value={role}
                          onChange={(e) => {
                            set_role(e.target.value as any);
                          }}
                      >
                          {org?.role === 'super' && <MenuItem value="super">Customer Success Admin</MenuItem>}
                          {(org?.role == "super" || org?.role === "org_admin") && (
                            <MenuItem value="org_admin">Super Admin</MenuItem>
                          )}
                          {(org?.role == "super" ||
                            org?.role.endsWith("admin")) && (
                              <MenuItem value="org_manager">Org Manager</MenuItem>
                            )}
                          {(org?.role == "super" ||
                            org?.role.endsWith("admin")) && (
                              <MenuItem value="user_admin">Group Manager</MenuItem>
                            )}
                          <MenuItem value="user">User</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    {org?.id !== org_id && (
                      <FormControl fullWidth>
                        <InputLabel id="group-label">Group</InputLabel>
                        <Select
                          labelId="group-label"
                          label="Group"
                          required
                          value={parent?.id ?? ""}
                          onChange={(e) => {
                            change_group(e.target.value);
                          }}
                        >
                          {chart?.orgs
                            .filter(
                              (org: any) =>
                                org.role === "org" || org.role === "group"
                            )
                            .map((org: any) => (
                              <MenuItem key={org.id} value={org.id}>
                                {org.role === "org" ? "Organization" : "Group"}:{" "}
                                {org.org_name}
                              </MenuItem>
                            ))}
                          {(org?.role == "super" || org?.role === "org_admin") && (
                            <MenuItem key="new" value="new">
                              <Add /> Add New Group
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    )}
                    <div
                      style={{
                        display: "grid",
                        columnGap: "8px",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      <Button variant="outlined" onClick={(e) => reset_form()}>
                        Cancel
                      </Button>
                      <Button variant="contained" onClick={(e) => save()}>
                        Save
                      </Button>
                    </div>
                  </>
                )}
            </Grid2>
            {mode != "edit" &&
            <Grid2 md={6} xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              {(org?.role == "super" || org?.role.endsWith("admin")) && (
                <>

                    <CSVFileDrop importCSV={import_csv} onError={(error) => { show_snack_bar("error", error.message) }}></CSVFileDrop>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "20px" }}
                    download="locktera_users.csv"
                    href={template}
                  >
                    DOWNLOAD SAMPLE CSV
                  </Button>
                </>
              )}
            </Grid2>
}
          </Grid2>

        </DialogContent>
      </Dialog>
      <Box sx={{ marginBottom: 1, height: 600 }}>
        <DataGrid
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          columns={columns}
          rows={filteredUsers}
          disableSelectionOnClick
          disableColumnMenu={true}
          //autosizeOnMount={true}
          initialState={{
            columns: {
              columnVisibilityModel: {
                org_id: false,
                token: false,
              },
            },
          }}
        />
      </Box>

      {/* <Button onClick={downloadCSVUsers} sx={{ marginBottom: 1 }}>
        Download CSV
      </Button> */}
      <CustomSnackbar
        severity={snackBarSeverity}
        message={snackBarMessage}
        state={snackBarOpen}
        changeState={setSnackBarOpen}
      />
    </>
  );
};

export default Users;
