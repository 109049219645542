import * as duckdb from '@duckdb/duckdb-wasm';
import PUBLIC_URL from '../../utils/PUBLIC_URL.js';

const MANUAL_BUNDLES = {
	mvp: {
		mainModule: `${PUBLIC_URL}/duckdb-wasm/duckdb-mvp.wasm`,
		mainWorker: `${PUBLIC_URL}/duckdb-wasm/duckdb-browser-mpv.worker.js`,
	},
	eh: {
		mainModule: `${PUBLIC_URL}/duckdb-wasm/duckdb-eh.wasm`,
		mainWorker: `${PUBLIC_URL}/duckdb-wasm/duckdb-browser-eh.worker.js`,
	},
};

export async function openDuckDB (debug) {
	const bundle = await duckdb.selectBundle(MANUAL_BUNDLES);
	const worker = new Worker(bundle.mainWorker);
	const logger = debug ? new duckdb.ConsoleLogger() : new duckdb.VoidLogger();
	const db = new duckdb.AsyncDuckDB(logger, worker);
	await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
	return db;
}
