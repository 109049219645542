import { styled } from "@mui/material/styles";
import { CardContent } from "@mui/material";
const CardContentNoPadding = styled(CardContent)(`
 padding: 0 16px;
 &:last-child {
   padding-bottom: 0;
 }
`);

export default CardContentNoPadding;
