import {
  Box,
  Button,
  Card,
  TextField,
} from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import moment from "moment";
import { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { JSONFormsContext } from "../../context/JSONFormsContext";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";
import CardContent from "../general/CardContentNoPadding";
import CardHeader from "../general/CardHeaderNoPadding";

const useStyles = makeStyles({
  FormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
    marginTop: "10px",
  },
  Main2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
});

const DateRules = () => {
  const { form, updateForm } = useContext(JSONFormsContext) as JSONFormsContextType;

  const rule = form.drmRules?.time ?? {};

  const updateRule = () => updateForm({
    ...form,
    drmRules: {
      ...form.drmRules,
      time: rule
    }
  });

  const handleStartValueChange = (newValue: moment.Moment | null) => {
    if (newValue) {
      rule.start = newValue.toISOString();
    } else {
      delete rule.start;
    }
    updateRule();
  };

  const handleEndValueChange = (newValue: moment.Moment | null) => {
    if (newValue) {
      rule.end = newValue.toISOString();
    } else {
      delete rule.end;
    }
    updateRule();
  };

  const classes = useStyles();

  return (
    <Card
      style={{
        width: "100%",
        borderColor: "#2B5592",
        borderWidth: "2px",
        borderStyle: "solid",
      }}
    >
      <CardHeader title="Access Dates" />
      <CardContent style={{ display: "flex", gap: "8px", justifyItems: 'center' }}>
        <MobileDateTimePicker
          label="Start"
          value={rule.start ? moment(rule.start) : null}
          onChange={handleStartValueChange}
          sx={{ flexGrow: 1 }}
        />
        <Button
          variant='contained'
          sx={{ margin: '8px 0' }}
          onClick={() => { rule.start = new Date().toISOString(); updateRule(); }}
        >Now</Button>
        <MobileDateTimePicker
          label="End"
          value={rule.end ? moment(rule.end) : null}
          onChange={handleEndValueChange}
          sx={{ flexGrow: 1 }}
        />
      </CardContent>
    </Card>
  );
};

export default DateRules;
