// called from index.js

// Import D3 from JSDelivr
import * as d3 from './d3.js';
import plt from 'plotly.js';
import { openDuckDB } from './duckdb.js';
// import lt library
import { lt_api_client } from './lt_api_client.js';
import csvjson from 'csvjson';

// *****TO DO*****
// add the max pages for each pdf doc

//  **** analytics:
// container:
//  - do container open and remove email count
//  - failed attempt
//
// vDebug ===  0 ---> none
// vDebug ===  1 ---> console.log
// vDebug >= 11 ---> console.log and download file

let vDebug = +(process.env.REACT_APP_DEBUG ?? 0);
//let vDebug = 11

export function quote_string (str) {
	return `'${str.replace(/'/g, "''")}'`;
}

//
export function ConvertToCSV (objArray) {
	var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
	var str = '';

	for (var i = 0; i < array.length; i++) {
		var line = '';
		for (var index in array[i]) {
			if (line != '') line += ','

			line += array[i][index];
		}

		str += line + '\r\n';
	}

	return str;
}

// Load a CSV from a URL
async function fetchCSV (url) {
	const rsp = await fetch(url);
	return await rsp.text();
}

// Convert a DuckDB result table into an array of objects
export function tableToObjects (table) {
	return Array.from(table, item => item.toJSON());
}

export async function* iterateQuery (conn, query) {
	for await (const batch of await conn.send(query)) {
		for (const row of batch) {
			yield row.toJSON();
		}
	}
}

// Get the column names from the first row in an array of rows
export function findColumns (rows) {
	return Object.keys(rows[0]);
}

function fnc_extent (array,ignore) {
	let min, max;
	for (const object of array) {
		for (let [key,value] of Object.entries(object)) {
			if (key === ignore) continue;
			if (typeof value !== 'number') {
				value = parseFloat(value);
				if (isNaN(value)) continue;
			}
			if (min === undefined || value < min) min = value;
			if (max === undefined || value > max) max = value;
		}
	}
	return [min, max];
}


export function d3DividerText (selector, title) {
	if (!selector) return;
	const div = d3.select(selector).append('div');

	div.html(`
    <p class=analytics_divider><b>${title}</b> </p>
	`);
}

export function d3Header (selector, level, text) {
	if (!selector) return;
	const header = d3.select(selector).append(`h${level}`);
	header.attr('class','analytics');
	header.text(text);
}

export function d3CreateText (rows, selector, title) {
	if (!selector) return;
	d3Header(selector,1,`CONTAINER STATS SUMMARY: ${rows[0].container_name}`)
	const div = d3.select(selector).append('div');
	if (vDebug > 1) {
		console.log('rows:',rows);
	}

	div.html(`
    <!-- <p class=analytics_divider><b>CONTAINER STATS:</b> ${rows[0].container_name}</p> -->
	<p><b>ID:</b> ${rows[0].uuid}</p>
	<p><b>SENDER:</b> ${rows[0].sender}</p>
	<p><b>AUTHORIZED VIEWERS:</b> ${rows[0].recipients}</p>
    <p><b>DATE SENT:</b> ${new Date(rows[0].sent_date).toLocaleDateString()}</p>
	<p><b>STATUS:</b> ${rows[0].status}</p>
	<p><b>FILES EXPIRE:</b> ${rows[0].files_expire ? new Date(rows[0].files_expire).toLocaleString() : 'No Expiration'}</p>
    <p><b>CONTENT FILES:</b> ${rows[0].content_files}</p>
    <p><b>DOWNLOAD PERMISSION:</b> ${rows[0].downloadable}</p>
	`);
}

// Use D3 to create a table of data inside the provided selector
export function d3CreateTable (rows, selector, title, dates = []) {
	if (!selector) return;
	if (!rows?.length) {
		d3.select(selector).append('h3').text('No Data Found');
		return 0;
	};
	const columns = findColumns(rows);
	const div = d3.select(selector).append('div');
	if (title) {
			div.append('h2').attr('class','analytics').text(title);
	}

	// add export to csv button
	// div.append('span').text('Download Table:').attr('class','analytics-d3table-download');
	// var exportButton = div.append('span').attr('class','material-icons').on('click',function () {
	// 	exportD3TableToCSV('table_data.csv');
	//   }).text('cloud_download');
	var exportButton = div.append('button').on('click',function () {
			var filename = prompt('Enter Filename:');
			if (filename != null) exportD3TableToCSV(filename || 'table_data.csv');
		}).text('Download Table as CSV').attr('style','font-weight:bold');

	// Create the table
	const table = div.append('table').attr('class', 'analytics');

	// Create the header row
	table.append('thead').append('tr')
		.selectAll('th')
		.data(columns)
		.enter()
		.append('th').text(col => col).style('min-width', col=>dates.includes(col) ?'150px':'0');

	// Create the body rows
	const trs = table.append('tbody')
		.selectAll('tr')
		.data(rows)
		.enter()
		.append('tr');

	// Create each value cell
	const tds = trs.selectAll('td')
		.data(row => Object.entries(row))
		.enter()
		//.append('td').text(([col, value]) => (dates.includes(col) && value) ? new Date(value).toLocaleDateString() : value);
		.append('td').text(([col, value]) => (dates.includes(col) && value) ? new Date(value).toLocaleString() : value);

	function exportD3TableToCSV(filename) {
		var csv = [];
		//var rows = document.querySelectorAll('table tr');
		var rows = table.selectAll('tr').nodes();

		for (var i = 0; i < rows.length; i++) {
			var row = [];
			var cols = rows[i].querySelectorAll('td, th');

			for (var j = 0; j < cols.length; j++) {
			row.push(`"${cols[j].innerText.replace(/"/g,'""')}"`);
			}

			csv.push(row.join(','));
		}

		// Create a CSV file and download it
		var csvContent = csv.join('\n');
		var link = document.createElement('a');
		link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
		link.target = '_blank';
		link.download = filename;
		link.click();
		}

}

export function d3CreateBox (selector, title, value, color, font_size, font_color) {
// create svg element:
//const div = d3.select(selector).append('div').attr("width", 200).attr("height", 200);
const div = d3.select(selector).append('div').text(title+':\n'+value)
	.style('display','inline-flex').style("width", "200px")
	.style('white-space','pre-line')
	.style('text-align','center')
	.style("height", "200px").style('background-color',color)
	.style('align-items','center')
	.style('justify-content','center')
	.style('flex-direction','column')
	.style('font-size',font_size)
	.style('color',font_color)
	.style('font-weight','bold')
	.style('margin-right','16px');

}

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// call pltClusterHorizontalBargraph function
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function pltClusterHorizontalBargraph (data, selector, group_name, title, stack, bar_color) {
	if (!selector || !data?.length) return;
	const div = d3.select(selector).append('div');
	var w = 460;
	var h = 400;
	if (stack) {
		div.style('display', 'inline-block').style('width', w);
	}
	if (title) {
		div.append('h3').attr('class','analytics_divider').text(title);
	}

	data.columns = findColumns(data);
	var values = data.map(row=>row[data.columns[1]]);
	// if values are all 0's return
	// if (values.every(v=>v==0)) return;
	var max_char = 30;

	var plot = [{
		type: 'bar',
		x: data.map(row=>row[data.columns[1]]).reverse(),
		y: data.map(row=>row[data.columns[0]].length > max_char ? `${row[data.columns[0]].slice(0,max_char)}...` : row[data.columns[0]]).reverse(),
		orientation: 'h',
		marker: {
			color: bar_color // Set a single color for all bars
		  }
	  }];
	  if (vDebug >= 1) {
		console.log('plot:',plot);
	  }
	  //var leftMargin = (d3.max(data,d => String(d[data.columns[0]]).length) *8) + 10;
	  var leftMargin = (d3.max(data,row => (row[data.columns[0]].length > max_char ? `${row[data.columns[0]].slice(0,max_char)}...` : row[data.columns[0]]).length) *8) + 10;
	  var xaxis = {side:'top',title:data.columns[1],tick0:0};
	//   if (!data.columns[1].toUpperCase().includes('DURATION')) {
	// 	xaxis.dtick=1;
	//   }
	  if (values.every(value=>!value)) {
		xaxis.range = [0,10]
	  }
	  var layout = {
		margin:{l:leftMargin},
		xaxis:xaxis,
		}

	  plt.newPlot(div.append('div').attr('width',w).attr('height',h).node(), plot, layout);

}

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// call pltClusterVerticalBargraph_4_Col function
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function pltClusterVerticalBargraph_4_Col (data, selector, group_name, title, stack) {
	if (!selector || !data?.length) return;
	const div = d3.select(selector).append('div');
	var w = 460;
	var h = 400;
	if (stack) {
		div.style('display', 'inline-block').style('width', w);
	}
	if (title) {
		div.append('h3').attr('class','analytics_divider').text(title);
	}

	data.columns = findColumns(data);
	var values = data.map(row=>row[data.columns[1]]);
	// if values are all 0's return
	// if (values.every(v=>v==0)) return;

	var plot = [{
		type: 'bar',
		marker: {
			color: ['#6CC04A','#64BBE3','#8B1001','#58585B']
		},
		y: data.map(row=>row[data.columns[1]]),
		x: data.map(row=>row[data.columns[0]]),
		orientation: 'v'
	  }];
	  if (vDebug >= 1) {
		console.log('plot:',plot);
	  }
	  var leftMargin = (d3.max(data,d => String(d[data.columns[0]]).length) *8) + 10;
	  var yaxis = {side:'top',title:data.columns[1],tick0:0,dtick:1};
	//   if (!data.columns[1].toUpperCase().includes('DURATION')) {
	// 	xaxis.dtick=1;
	//   }
	  if (values.every(value=>!value)) {
		yaxis.range = [0,10]
	  }
	  var layout = {
		//margin:{l:leftMargin},
		yaxis:yaxis,
	  }

	  plt.newPlot(div.append('div').attr('width',w).attr('height',h).node(), plot, layout);

}


//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// call pltClusterVerticalBargraph_2_Col function
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function pltClusterVerticalBargraph_2_Col (data, selector, group_name, title, stack) {
	if (!selector || !data?.length) return;
	const div = d3.select(selector).append('div');
	var w = 460;
	var h = 400;
	if (stack) {
		div.style('display', 'inline-block').style('width', w);
	}
	if (title) {
		div.append('h3').attr('class','analytics_divider').text(title);
	}

	data.columns = findColumns(data);
	var values = data.map(row=>row[data.columns[1]]);
	// if values are all 0's return
	// if (values.every(v=>v==0)) return;

	var plot = [{
		type: 'bar',
		marker: {
			color: ['#64BBE3','#8B1001']
		},
		y: data.map(row=>row[data.columns[1]]),
		x: data.map(row=>row[data.columns[0]]),
		orientation: 'v'
	  }];
	  if (vDebug >= 1) {
		console.log('plot:',plot);
	  }
	  var leftMargin = (d3.max(data,d => String(d[data.columns[0]]).length) *8) + 10;
	  var yaxis = {side:'top',title:data.columns[1],tick0:0,dtick:1};
	//   if (!data.columns[1].toUpperCase().includes('DURATION')) {
	// 	xaxis.dtick=1;
	//   }
	  if (values.every(value=>!value)) {
		yaxis.range = [0,10]
	  }
	  var layout = {
		//margin:{l:leftMargin},
		yaxis:yaxis,
	  }

	  plt.newPlot(div.append('div').attr('width',w).attr('height',h).node(), plot, layout);

}

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// call pltClusterVerticalBargraph_v2 function
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function pltClusterVerticalBargraph_v2 (data, selector, group_name, title, stack) {
	if (!selector || !data?.length) return;
	const div = d3.select(selector).append('div');
	var w = 460;
	var h = 400;
	if (stack) {
		div.style('display', 'inline-block').style('width', w);
	}
	if (title) {
		div.append('h3').attr('class','analytics_divider').text(title);
	}

	data.columns = findColumns(data);
	var values = data.map(row=>row[data.columns[1]]);
	// if values are all 0's return
	// if (values.every(v=>v==0)) return;

	var plot = [{
		type: 'bar',
		y: data.map(row=>row[data.columns[1]]),
		x: data.map(row=>row[data.columns[0]]),
		orientation: 'v'
	  }];
	  if (vDebug >= 1) {
		console.log('plot:',plot);
	  }
	  var leftMargin = (d3.max(data,d => String(d[data.columns[0]]).length) *8) + 10;
	  var yaxis = {side:'top',title:data.columns[1],tick0:0};
	//   if (!data.columns[1].toUpperCase().includes('DURATION')) {
	// 	xaxis.dtick=1;
	//   }
	  if (values.every(value=>value==0)) {
		yaxis.range = [0,10]
	  }
	  var layout = {
		//margin:{l:leftMargin},
		yaxis:yaxis,
	  }

	  plt.newPlot(div.append('div').attr('width',w).attr('height',h).node(), plot, layout);

}


//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// call pltPieGraph function
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function pltPieGraph (data, selector, group_name, title) {
	if (!selector || !data?.length) return;
	const div = d3.select(selector).append('div');
	// if (title) {
	// 	div.append('h3').attr('class','analytics_divider').text(title);
	// }
	var w = 460;
	var h = 400;

	data.columns = findColumns(data);
	var values = data.columns.map(c=>data[0][c]);

	var plot = [{
		type: 'pie',
		labels: data.columns,
		values: values,
		marker:{
			colors: ['#64BBE3','#8B1001']
		}
	  }];

	  if (vDebug >= 1) {
		console.log('plot:',plot);
	  }

	  var layout = {
		height: h,
		width: w,
		title: title
	  }

	  plt.newPlot(div.append('div').attr('width',w).attr('height',h).node(), plot, layout);

}



// Medium Blue 	Hex: 64BBE3 RGB: 100,187,227
// Dark Blue 	Hex: 2B5592 RGB: 43, 85, 146
// Dark Grey 	Hex: 58585B RGB: 88, 88, 91
// Green 		Hex: 6CC04A	RGB: 108, 192, 74
// Light blue 	Hex: C4D6ED RGB: 196, 214, 237
// Red 			Hex: 8B1001 RGB 139, 16, 1
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// call pltDonutGraph function
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function pltDonutGraph (data, selector, title, stack) {
	if (!selector || !data?.length) return;
	const div = d3.select(selector).append('div');
	// if (title) {
	// 	div.append('h3').attr('class','analytics_divider').text(title);
	// }

	var w = 400;
	var h = 400;

	if (stack) {
		div.style('display', 'inline-block').style('width', w);
	}

	data.columns = findColumns(data);
	var values = data.columns.map(c=>data[0][c]);

	var plot = [{
		type: 'pie',
		labels: data.columns,
		values: values,
		hole: 0.4,
		marker:{
			colors: ['#64BBE3','#8B1001']
		}
	  }];

	  if (vDebug >= 1) {
		console.log('plot:',plot);
	  }

	  var layout = {
		height: h,
		width: w,
		title: {
			text: title,
			font: {
				size: 13,
				color: '#000'
			}
		},
		legend: {
			font: {
				size: 10
			}
		}
		// annotations: [
		// 	{
		// 	  font: {
		// 		size: 20
		// 	  },
		// 	  showarrow: false,
		// 	  text: title,
		// 	  x: 0.5,
		// 	  y: 0.5
		// 	},
		// ]
	  }

	  plt.newPlot(div.append('div').attr('width',w).attr('height',h).node(), plot, layout);

}

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// call fillMissingDataZero function
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function fillMissingDataZero (data,xaxis_name,yaxis_name) {
	const [min,max] = d3.extent(data,d=>d[xaxis_name]);
	for (let i = min+1;i<max;i++) {
		data.push({[xaxis_name]:i,[yaxis_name]:0})
	}
}

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// call pltClusterVerticalBargraph function
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function pltClusterVerticalBargraph (data, selector, group_name, title, max_x, max_y) {
	if (!selector || !data?.length) return;
	const div = d3.select(selector).append('div').style('display', 'flex').style('flex-grow', 1);
	if (title) {
		div.append('h3').attr('class','analytics_divider').text(title);
	}
	var w = 460;
	var h = 400;

	data.columns = findColumns(data);
	var values = data.map(row=>row[data.columns[1]]);
	// if values are all 0's return
	if (values.every(v => v == 0)) return;

	var plot = [{
		type: 'bar',
		x: data.map(row=>Number(row[data.columns[0]])),
		y: values,
		orientation: 'v'
	  }];
	  if (vDebug >= 1) {
		console.log('plot:',plot);
	  }
	var layout = {
			width: div.property('clientWidth'),
		xaxis:{
			title:data.columns[0],
			tick0: 0,
			dtick: 1,
			...(max_x && { range: [0.25, max_x + 0.75] })
		},
		yaxis:{
			title: data.columns[1],
			...(max_y && {range: [0, max_y] })
		}
	  }

	  plt.newPlot(div.append('div').node(), plot, layout);
}

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// call d3ClusterHorizontalBargraph function
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function d3ClusterHorizontalBargraph (data, selector, group_name, title) {
	if (!selector || !data?.length) return;
	const div = d3.select(selector).append('div');
	if (title) {
		div.append('h3').attr('class','analytics_divider').text(title);
	}
	data.columns = findColumns(data);

	// set the dimensions and margins of the graph
	var w = 460;
	var h = 400;
	var leftMargin = (d3.max(data,d => String(d[data.columns[0]]).length) *5) + 10;
	var margin = { top: 30, right: 30, bottom: 10, left: leftMargin },
		width = w - margin.left - margin.right,
		height = h - margin.top - margin.bottom;

	let y_range = fnc_extent(data,group_name);
	var svg = div.append("svg")
		.attr("width", width + margin.left + margin.right)
		.attr("height", height + margin.top + margin.bottom)
		.append("g")
		.attr("transform",
			"translate(" + margin.left + "," + margin.top + ")");

	// Add X axis
	var x = d3.scaleLinear()
		.domain([0,y_range[1]])
		.range([ 0, width]);
	svg.append("g")
		//.attr("transform", "translate(0," + height + ")")
		.call(d3.axisTop(x))
		.selectAll("text")
		.attr("transform", "translate(10,-25),rotate(-90)")
		.style("text-anchor", "end");

	// Y axis
	var y = d3.scaleBand()
		.range([ 0, height ])
		.domain(data.map(function(d) { return d[data.columns[0]]; }))
		.padding(.1);
	svg.append("g")
		.call(d3.axisLeft(y))

	//Bars
	svg.selectAll("myRect")
		.data(data)
		.enter()
		.append("rect")
		.attr("x", x(0) )
		.attr("y", function(d) { return y(d[data.columns[0]]); })
		.attr("width", function(d) { return x(d[data.columns[1]]); })
		.attr("height", y.bandwidth() )
		.attr("fill", "#64BBE3")


		// .attr("x", function(d) { return x(d.Country); })
		// .attr("y", function(d) { return y(d.Value); })
		// .attr("width", x.bandwidth())
		// .attr("height", function(d) { return height - y(d.Value); })
		// .attr("fill", "#69b3a2")

}
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
// call d3ClusterBargraph_v2 function
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
export function d3ClusterBargraph_v2 (data, selector, group_name, title) {
	if (!selector || !data?.length) return;
	const div = d3.select(selector).append('div');
	if (title) {
		// div.append('h3').attr('class','analytics_divider').text(title);
		div.append('h3').text(title);
	}
	//let group_name = 'page';
	//console.log(data);
	data.columns = findColumns(data);
	//console.log(data.columns);

	// set the dimensions and margins of the graph
	var w = 460;
	var h = 400;
	var margin = { top: 10, right: 30, bottom: 30, left: 70 },
		width = w - margin.left - margin.right,
		height = h - margin.top - margin.bottom;

	// trying to get min and max values from data json
	// console.log("range ...");
	let y_range = fnc_extent(data,group_name);
	//console.log(y_range);
	// var allValues = d3.merge(data[0]);
	// console.log(allValues);
	// var extent = d3.extent(allValues, function(d) { return d.vitality; });
	// console.log(d3.extent(extent));

	// append the svg object to the body of the page
	//var svg = d3.select("#my_dataviz")
	var svg = div.append("svg")
		.attr("width", width + margin.left + margin.right)
		.attr("height", height + margin.top + margin.bottom)
		.append("g")
		.attr("transform",
			"translate(" + margin.left + "," + margin.top + ")");

	// List of subgroups = header of the csv files = soil condition here
	var subgroups = data.columns.slice(1);
	//console.log('subgroups:', subgroups);

	// List of groups = species here = value of the first column called group -> I show them on the X axis
	var groups = Array.from(d3.map(data, function (d) { return (d[group_name]) }).values());
	//var groups = Array.from(d3.map(data, function(d){return(d.group)}).values());
	//console.log('groups:', groups);

	// Add X axis
	var x = d3.scaleBand()
		.domain(groups)
		.range([0, width])
		.padding([0.2]);
	svg.append("g")
		.attr("transform", "translate(0," + height + ")")
		.call(d3.axisBottom(x).tickSize(0));

	// Add Y axis
	var y = d3.scaleLinear()
		.domain([0,y_range[1]])
		.range([height, 0]);
	svg.append("g")
		.call(d3.axisLeft(y));

	// Another scale for subgroup position?
	var xSubgroup = d3.scaleBand()
		.domain(subgroups)
		.range([0, x.bandwidth()])
		.padding([0.05])

	// *** Need to dynamically assign color range
	// pre-compose only 8 colors
	// color palette = one color per subgroup
	// color picker https://www.w3schools.com/colors/colors_picker.asp
	// https://bottosson.github.io/misc/colorpicker/
	//
	// Medium Blue 	Hex: 64BBE3 RGB: 100,187,227
	// Dark Blue 	Hex: 2B5592 RGB: 43, 85, 146
	// Dark Grey 	Hex: 58585B RGB: 88, 88, 91
	// Green 		Hex: 6CC04A	RGB: 108, 192, 74
	// Light blue 	Hex: C4D6ED RGB: 196, 214, 237
	// Red 			Hex: 8B1001 RGB 139, 16, 1
	var color = d3.scaleOrdinal()
		.domain(subgroups)
		.range(['#64BBE3', '#2B5592', '#58585B', '#6CC04A', 'C4D6ED', '8B1001'])
	//.range(['#e41a1c','#377eb8','#4daf4a','#cccc00'])

	// Show the bars
	svg.append("g")
		.selectAll("g")
		// Enter in data = loop group per group
		.data(data)
		.enter()
		.append("g")
		.attr("transform", function (d) { return "translate(" + x(d[group_name]) + ",0)"; })
		.selectAll("rect")
		.data(function (d) { return subgroups.map(function (key) { return { key: key, value: d[key] }; }); })
		.enter().append("rect")
		.attr("x", function (d) { return xSubgroup(d.key); })
		//.append("text")
		//.text("x-axis")
		.attr("y", function (d) { return y(d.value); })
		.attr("width", xSubgroup.bandwidth())
		.attr("height", function (d) { return height - y(d.value); })
		.attr("fill", function (d) { return color(d.key); });

	// https://www.tomordonez.com/d3-bar-chart-title-and-labels/
	// Label for x Axis
	svg.append("text")
		.attr("transform", "translate(" + (w/2) + " ," + (h-15) + ")")
		.style("text-anchor", "middle")
		.text("page");
	// Label for y Axis
	svg.append("text")
		.attr("transform", "rotate(-90)")
		.attr("x", -(h/2))
		.attr("y", -40)
		.style("text-anchor", "middle")
		.text("view duration (min)");

}

//*******************************************************************************************
//*******************************************************************************************
//*******************************************************************************************
//*******************************************************************************************
//*******************************************************************************************
//*******************************************************************************************
//*******************************************************************************************
//*******************************************************************************************
//*******************************************************************************************
//*******************************************************************************************
//*******************************************************************************************
//*******************************************************************************************
export async function lt_analytics_client_main_v2 (org_ids, api_token, schema, uuid, start_time, end_time, groups, users) {
	// open in memory database
	const db = await openDuckDB(vDebug > 0);
	// Open a connection to the database
	const conn = await db.connect();
	//WIP: await conn.query(`pragma default_collation=nocase`);
	//drop and create schema if not main, main schema can't be dropped
	if (schema.toLowerCase() != 'main') {
		await conn.query(`drop schema if exists ${schema};`);
		await conn.query(`create schema if not exists ${schema};`);
	}
	//
	let api_client = new lt_api_client(org_ids.join(','), api_token);

	let csv;

	if (schema == 'CONTAINER_OBJECT') {
		csv = await api_client.fetch_events_by_orgid_uuid(uuid, null, null, null, null);
	} else if (['accessed_last_30','CONTENT_FILES_ACCESSED','VIEWER_ACTIVITY','VIEWER_COMPLETION','CONTENT_FILE_ACTIVITY','CONTENT_FILE_AUDIT'].includes(schema)) {
		csv = await api_client.fetch_events_by_orgid_access(start_time,end_time);
	} else if (schema == 'CONTAINERS_ACCESSED') {
		csv = await api_client.fetch_events_by_orgid_create(start_time,end_time);
	} else if (schema == 'CONTENT_FILES_SENT') {
		csv = await api_client.fetch_events_by_orgid_create(start_time,end_time);
	} else if (schema == 'CONTAINERS_EXPIRED') {
		csv = await api_client.fetch_events_by_orgid_expire(start_time,end_time);
	} else if (schema == 'SENT_NOT_ACCESSED') {
		csv = await api_client.fetch_events_by_orgid_create(start_time,end_time);
	} else if (schema == 'BLOCKED') {
		csv = await api_client.fetch_events_by_orgid_block(start_time,end_time);
	} else if (schema == 'ACCESS_DENIED') {
		csv = await api_client.fetch_events_by_orgid_fail(start_time,end_time);
	} else if (schema == 'VIEWER_INFORMATION') {
		csv = await api_client.fetch_events_by_orgid_create(start_time,end_time);
	} else if (schema == 'VIEWER_AUDIT') {
		csv = await api_client.fetch_events_by_orgid_create(start_time,end_time);
	} else if (schema == 'DOWNLOADED_CONTENT') {
		csv = await api_client.fetch_events_by_orgid_access(start_time,end_time);
	} else if (schema == 'CRM_EVENT_EXPORT') {
		csv = await api_client.fetch_events_by_orgid_access(start_time,end_time);
	} else if (schema == 'STORAGE_API_CALLS') {
		csv = await api_client.fetch_org_stats(start_time,end_time);
	} else if (schema == 'LANDING_PAGE') {
		csv = await api_client.fetch_events_for_landing_page(start_time,end_time);
	}

	await db.registerFileText('events.csv', csv);
	if (vDebug > 1) {
		//start download block
		let link = document.createElement('a');
		let url = URL.createObjectURL(new Blob([csv]));
		link.href = url;
		link.download = 'temp_events.csv';
		link.click();
		URL.revokeObjectURL(url);
		//end download block
	}

	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	//CREATE EVENTS_TEMP, EVENTS, RECIPIENTS, OBJECTS tables
	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	async function duckdb_load_tables (schema) {
		// import the groups and users
		async function import_groups_users () {
			if (!users?.length) {
				return;
			}
			await conn.query(`drop table if exists ${schema}.groups cascade;`);
			await conn.query(`drop table if exists ${schema}.users cascade;`);

			await db.registerFileText(
				'groups.csv',
				csvjson.toCSV(groups,{headers:'key'}),
			);
			await conn.query(`CREATE TABLE ${schema}.groups AS SELECT * FROM 'groups.csv';`);

			if (vDebug >= 1) {
				let groups = await conn.query(`SELECT * FROM ${schema}.groups`);
				console.log('groups:',tableToObjects(groups));
			}

			await db.registerFileText(
				'users.csv',
				csvjson.toCSV(users,{headers:'key'}),
			);
			await conn.query(`CREATE TABLE ${schema}.users AS SELECT * FROM 'users.csv';`);

			if (vDebug >= 1) {
				let users = await conn.query(`SELECT * FROM ${schema}.users`);
				console.log('users:',tableToObjects(users));
			}

		}

		// used by the org/performance reports
		async function org_stats () {
			// create org_stats table with data
			await conn.query(`drop table if exists ${schema}.org_stats cascade;`);
			await conn.query(`CREATE TABLE ${schema}.org_stats AS SELECT * FROM 'events.csv';`);
			if (vDebug >= 1) {
				let org_stats = await conn.query(`SELECT * FROM ${schema}.org_stats`);
				console.log('org_stats:',tableToObjects(org_stats));
			}
		}
		// build the tables for the stats
		async function other_stats () {
			// needed columns
			let needed_col_array = [
				'orgId', 'uuid', 'name', 'start', 'end', 'ts', 'action', 'src', 'ip',
				'country', 'region', 'city', 'latlong', 'event', 'from', 'to', 'user',
				'object', 'page', '[].detail.recipients[]', '[].detail.objects[]','[].detail.durations[]',
				'[].detail.contentTypes[]','[].detail.pages[]','reason','blocked_flag','time','downloadable',
				'container_start_ts','container_end_ts','session','container_state','media_state','[].detail.digests[]'
			];

			// create EVENTS_TEMP table with data
			await conn.query(`drop table if exists ${schema}.EVENTS_TEMP cascade;`);
			await conn.query(`CREATE TABLE ${schema}.EVENTS_TEMP AS SELECT * FROM read_csv('events.csv', AUTO_DETECT=TRUE, ALL_VARCHAR=TRUE);`);

			if (vDebug >= 1) {
				let EVENTS_TEMP = await conn.query(`SELECT * FROM ${schema}.EVENTS_TEMP`);
				console.log('EVENTS_TEMP:',tableToObjects(EVENTS_TEMP));
			}

			// add missing columns to EVENTS_TEMP table
			for (let col of needed_col_array) {
				let cnt = await conn.query(`select count(*)::int as cnt from information_schema.columns WHERE TABLE_SCHEMA = ${quote_string(schema)} and TABLE_NAME = 'EVENTS_TEMP' and COLUMN_NAME = ${quote_string(col)}`);
				cnt = tableToObjects(cnt);
				if (cnt[0].cnt == 0) {
					await conn.query(`alter table ${schema}.EVENTS_TEMP add "${col}" text`);
				}
			};


			//let temp = await conn.query(`SELECT * FROM ${schema}.EVENTS_TEMP`);
			//console.log('temp:',tableToObjects(temp));

			await conn.query(`drop table if exists ${schema}.EVENTS_HEADER cascade;`);
			await conn.query(`CREATE TABLE ${schema}.EVENTS_HEADER AS
				with cte_prep as (
				select   t.orgId,t.uuid
						,max(t.name) as container_name
						,max(t.user) as sender
						,min(t.ts) as sent_date
						,max(coalesce(t.container_start_ts,t.start)) as container_start_ts
						,max(coalesce(t.container_end_ts,t."end")) as container_end_ts
						,max(t."[].detail.recipients[]") as recipients
						,max(t."[].detail.objects[]") as objects
						,max(t."[].detail.contentTypes[]") as content_types
						,max(t."[].detail.durations[]") as media_durations
						,max(t."[].detail.pages[]") as total_pages
						,max(t."[].detail.digests[]") as digests
						,max(t."downloadable") as downloadable
						--,max(t.blocked_flag) as blocked_flag
						-- calculate the block state
						,(select last_value(t2.action) over (partition by t2.orgId,t2.uuid order by t2.orgId,t2.uuid,t2.ts)
						    from ${schema}.EVENTS_TEMP t2
						   where t2.action in ('container.block','container.unblock')
						     and t2.orgId = t.orgId
						     and t2.uuid = t.uuid
						 ) as last_block_action
				  from ${schema}.EVENTS_TEMP t
		  		 where t.action = 'container.create'
		   		 group
			  		by t.orgId,t.uuid
				), cte_final as (
				select distinct c.orgId,c.uuid,c.container_name,c.sender,c.sent_date,c.container_start_ts,c.container_end_ts,c.objects
					  ,c.content_types,c.recipients,c.media_durations,c.total_pages,c.digests
					  ,case when c.downloadable = 'true' then 'yes' else 'no' end as downloadable
					  --,case when trim(c.blocked_flag) = 'true' then 'BLOCKED'
					  ,case when coalesce(c.last_block_action,'!') = 'container.block' then 'BLOCKED'
					        else case when current_timestamp
							               between coalesce(c.container_start_ts,'1970-01-01'::timestamp)
										       and coalesce(c.container_end_ts,'9999-01-01'::timestamp)
									  then 'ACTIVE'
									  else 'EXPIRED'
								  end
					   end as status
				      ,case when c.recipients like '%*@*%'
							then 'ALL_VIEWER_FLAG'
							 when c.recipients like '%*@%'
							 then 'DOMAIN_ANY_USER_FLAG'
							 when c.recipients like '%@*%'
							 then 'USER_ANY_DOMAIN_FLAG'
							 else 'USER_FLAG'
					   end as classify_viewers
				  from cte_prep c
				)
				select c.*, case when classify_viewers = 'ALL_VIEWER_FLAG' then 1::int else 0::int end as ALL_VIEWER_FLAG
				  from cte_final c
			`);

			if (vDebug >= 1) {
				let EVENTS_HEADER = await conn.query(`SELECT * FROM ${schema}.EVENTS_HEADER`);
				console.log('EVENTS_HEADER:',tableToObjects(EVENTS_HEADER));
			}

			// create EVENTS table with data
			await conn.query(`drop table if exists ${schema}.EVENTS cascade;`);
			await conn.query(`CREATE TABLE ${schema}.EVENTS AS
			with cte_ip_user as (
			select orgId,uuid,ip,lower(max("user")) as viewer
				from ${schema}.EVENTS_TEMP
			where coalesce("user",'') <> ''
			group
				by orgId,uuid,ip
			), cte_container_name as (
				select * from ${schema}.EVENTS_HEADER
			), cte_main as (
				select
					 e.orgId
					,e.uuid
					,n.container_name
					,n.sent_date
					,n.sender
					,n.container_start_ts
					,n.container_end_ts
					,e.ts
					,e.action
					,e.src
					,e.ip
					,e.country
					,e.region
					,e.city
					,e.latlong
					,e.event
					,e.from
					,e.to
					,e.object
					,e.page::int as page
					,lower(e."[].detail.recipients[]") as detail_recipients
					,e."[].detail.objects[]" as detail_objects
					,e."[].detail.contentTypes[]" as content_types
					,e.reason
					,e.container_state
					,e.media_state
					,e.time::double as time
					,e.session
					-- get last user when missing due to initial container.check
					,lower(coalesce(e.user,last_value(e.user) over (partition by e.ip order by e.ts))) as viewer
				from ${schema}.EVENTS_TEMP e
					--left join cte_ip_user q on e.orgId = q.orgId and e.uuid = q.uuid and e.ip = q.ip
					 left join cte_container_name n on e.orgId = n.orgId and e.uuid = n.uuid
				order by e.orgId,e.uuid,e.ts
			), cte_final as (
				select (row_number() over ())::int as id,cm.*,
					lead(cm.ts) over (partition by cm.orgId,cm.uuid,cm.viewer order by cm.ts) as next_ts,
					lead(cm.time) over (partition by cm.orgId,cm.uuid,cm.viewer order by cm.ts) as next_time,
					lag(cm.session) over (partition by cm.orgId,cm.uuid,cm.viewer order by cm.ts) as prev_session,
				case when cm.action in ('object.show','container.show','object.load','pdf.loadPage','audio.play','video.play','object.download') then 1::int
				     when lower(cm.action) = 'container.changepagestate' and lower(cm.to) = 'active' then 1::int
					 -- seek events are not anchors
					 when lower(cm.action) in ('audio.seek', 'video.seek') then 0::int
					 when cm.media_state = 'playing' then 1::int
					 else 0::int
				end as anchor_stat_flag
				from cte_main cm
				order by cm.orgId,cm.uuid,cm.ts
			), cte_final2 as (
			select e.*,
			case when e.action = 'object.hide' then e.prev_session else e.session end as fixed_session,
			least((DATE_PART('EPOCH',coalesce(e.next_ts::timestamp,current_timestamp::timestamp)) - DATE_PART('EPOCH',e.ts::timestamp)),300)::int as duration,
			(e.next_time - e.time)::int as media_duration,
			case when lower(e.action) = 'pdf.loadpage' then 1::int else 0::int end as page_open,
			case when lower(e.action) = 'object.show' then 1::int else 0::int end as object_open,
			case when lower(e.action) = 'object.download' then 1::int else 0::int end as object_download,
			case when lower(e.action) = 'container.load' then 1::int else 0::int	end as container_open,
			case when lower(e.action) = 'container.block' then 'block' when lower(e.action) = 'container.unblock' then 'unblock' end as container_block_action,
			case when lower(e.action) = 'drm.fail' then 1::int else 0::int end as drm_fail,
			case when lower(e.action) = 'decrypt.fail' then 1::int else 0::int end as decrypt_fail,
			case when lower(e.action) = 'email.open' then 1::int else 0::int end as email_open
			from cte_final e
			)
				select
				e.id
				,e.orgId
				,e.uuid
				,e.container_name
				,e.sent_date
				,e.sender
				,e.container_start_ts
				,e.container_end_ts
				,e.ts
				,e.action
				,e.event
				,e.from
				,e.to
				,e.src
				,e.ip
				,e.country
				,e.region
				,e.region as state
				,e.city
				,e.latlong
				,e.object
				,e.page
				,e.detail_recipients
				,e.detail_objects
				,e.content_types
				,e.reason
				,e.container_state
				,e.media_state
				,e.time
				,e.fixed_session as session
				,e.viewer
				-- default viewership duration to 1 sec on pdf pages if viewed fast < 1 sec
				,case when e.page > 0 and e.page_open = 1 and coalesce(e.duration,0) < 1 then 1::int else e.duration end as duration
				,e.media_duration
				,e.page_open
				,e.object_open
				,e.object_download
				,e.container_open
				,e.container_block_action
				,e.drm_fail
				,e.decrypt_fail
				,e.email_open
				,e.next_ts
				,e.next_time
				,e.anchor_stat_flag
				from cte_final2 e
			`);

			//TK *** Delete records generated by sender, unless sender is specified in recipient list
			await conn.query(`
			   delete from ${schema}.EVENTS e where trim(upper(e.viewer)) = trim(upper(e.sender))
			   and not exists (
						  select 1
						  from ${schema}.EVENTS_HEADER h
						  where h.orgid = e.orgid
						  and h.uuid = e.uuid
						  and trim(upper(h.recipients)) like '%'||trim(upper(e.sender))||'%'
				   )
			 `
			 );

			if (vDebug >= 1) {
				let EVENTS = await conn.query(`SELECT * FROM ${schema}.EVENTS`);
				console.log('EVENTS:',tableToObjects(EVENTS));
			}

			//let events = await conn.query(`select * from ${schema}.EVENTS`);
			//console.log('events:',tableToObjects(events));

			// create table with AUTHORIZED_VIEWER Only
			await conn.query(`drop table if exists ${schema}.AUTHORIZED_VIEWER cascade;`);
			await conn.query(`CREATE TABLE ${schema}.AUTHORIZED_VIEWER as
				with cte_main as (
					select orgId,uuid,unnest(string_split(recipients,';')) as viewer
					from ${schema}.EVENTS_HEADER
				)
				select distinct q.orgId,q.uuid,lower(trim(q.viewer)) as viewer, 1::int as INCLUDED_IN_LIST_FLAG
				  from cte_main q
				 where q.viewer not like '%*%'
			`);

			if (vDebug >= 1) {
				let AUTHORIZED_VIEWER = await conn.query(`SELECT * FROM ${schema}.AUTHORIZED_VIEWER`);
				console.log('AUTHORIZED_VIEWER:',tableToObjects(AUTHORIZED_VIEWER));
			}

			// create table with ALL_VIEWER excluding *@* and sender (was excluded in EVENTS table build)
			await conn.query(`drop table if exists ${schema}.ALL_VIEWER cascade;`);
			await conn.query(`CREATE TABLE ${schema}.ALL_VIEWER as
				with cte_main as (
				select q.orgId,q.uuid,q.viewer,max(INCLUDED_IN_LIST_FLAG) as INCLUDED_IN_LIST_FLAG
				  from (
						select e.orgId,e.uuid,e.viewer, 0::int as INCLUDED_IN_LIST_FLAG
						  from ${schema}.EVENTS e
					     union all
						select v.orgId,v.uuid,v.viewer,v.INCLUDED_IN_LIST_FLAG
						  from ${schema}.AUTHORIZED_VIEWER v
						 --where v.viewer <> '*@*'
					   ) q
				  where q.viewer is not null
				  group
				     by q.orgId,q.uuid,q.viewer
				), cte_final as (
					select distinct c.*,
					       case when lower(trim(h.sender)) = lower(trim(c.viewer)) then 1 else 0 end as IS_SENDER_FLAG
					  from cte_main c join ${schema}.EVENTS_HEADER h on c.orgId = h.orgId and c.uuid = h.uuid
				)
				select * from cte_final
			`);

			if (vDebug >= 1) {
				let ALL_VIEWER = await conn.query(`SELECT * FROM ${schema}.ALL_VIEWER`);
				console.log('ALL_VIEWER:',tableToObjects(ALL_VIEWER));
			}
			//tk let ALL_VIEWER_FLAG = await conn.query(`SELECT ALL_VIEWER_FLAG FROM ${schema}.EVENTS_HEADER`);
			//tk ALL_VIEWER_FLAG = tableToObjects(ALL_VIEWER_FLAG)[0]?.ALL_VIEWER_FLAG;
			//tk console.log('ALL_VIEWER_FLAG:',ALL_VIEWER_FLAG);
			//***** DEPRECATED
			// await conn.query(`drop table if exists ${schema}.viewer cascade;`);
			// await conn.query(`CREATE TABLE ${schema}.viewer as
			// 		with cte_flag as (
			// 			SELECT orgId,uuid,ALL_VIEWER_FLAG FROM ${schema}.EVENTS_HEADER
			// 		),cte_main as (
			// 			select ALL_VIEWER_FLAG,qq.orgId, qq.uuid,
			// 				case when qq.viewer like '%*@*%' then 'ANYONE WITH LINK'::text else qq.viewer end as viewer,
			// 				ee.ip as ip, ee.city as city, ee.region as state, ee.country as country
			// 				from (
			// 					select q.orgId,q.uuid,q.viewer, max(e.id) as max_id,ALL_VIEWER_FLAG
			// 						from (
			// 							select v1.*,f1.ALL_VIEWER_FLAG  from ${schema}.ALL_VIEWER v1 join cte_flag f1 on v1.orgId = f1.orgId and v1.uuid = f1.uuid where f1.ALL_VIEWER_FLAG = 1
			// 							union all
			// 							select v2.*,f2.ALL_VIEWER_FLAG from ${schema}.AUTHORIZED_VIEWER v2 join cte_flag f2 on v2.orgId = f2.orgId and v2.uuid = f2.uuid where f2.ALL_VIEWER_FLAG = 0
			// 							) q left join ${schema}.EVENTS e on q.orgId = e.orgId and q.uuid = e.uuid and q.viewer = e.viewer
			// 						group by q.orgId,q.uuid,q.viewer,ALL_VIEWER_FLAG
			// 					) qq left join ${schema}.EVENTS ee on ee.orgId = qq.orgId and ee.uuid = qq.uuid and ee.id = qq.max_id
			// 		)
			// 		select * from cte_main order by uuid;
			// 		`);

			// if (vDebug >= 1) {
			// 	let VIEWER = await conn.query(`SELECT * FROM ${schema}.viewer`);
			// 	console.log('VIEWER:',tableToObjects(VIEWER));
			// }

			// this viewer_detail table is supposed to have all viewers along with classifications:
			//
			// INCLUDED_IN_LIST_FLAG
			// classify_viewers:
			//    ALL_VIEWER_FLAG
			//    DOMAIN_ANY_USER_FLAG
			//    USER_ANY_DOMAIN_FLAG
			//    USER_FLAG
			await conn.query(`drop table if exists ${schema}.viewer cascade;`);
			await conn.query(`CREATE TABLE ${schema}.viewer as
					with cte_main as (
					select v.*,h.classify_viewers
					  from ${schema}.ALL_VIEWER v join ${schema}.EVENTS_HEADER h on v.orgId = h.orgId and v.uuid = h.uuid
					), cte_prep as ( -- get max id for last location
					select q.orgId,q.uuid,q.viewer,q.classify_viewers,q.INCLUDED_IN_LIST_FLAG,q.IS_SENDER_FLAG,max(e.id) as max_id
					  from cte_main q left join ${schema}.EVENTS e on q.orgId = e.orgId and q.uuid = e.uuid and q.viewer = e.viewer
					group by q.orgId,q.uuid,q.viewer,q.classify_viewers,q.INCLUDED_IN_LIST_FLAG,q.IS_SENDER_FLAG
					), cte_final as (
					select qq.*,
					       ee.ip as ip, ee.city as city, ee.region as state, ee.country as country
					  from cte_prep qq left join ${schema}.EVENTS ee on ee.orgId = qq.orgId and ee.uuid = qq.uuid and ee.id = qq.max_id
					)
					select e.* from cte_final e
					 where (INCLUDED_IN_LIST_FLAG = 1) or (classify_viewers = 'ALL_VIEWER_FLAG') or (classify_viewers = 'DOMAIN_ANY_USER_FLAG')
					`);

			if (vDebug >= 1) {
				let viewer = await conn.query(`SELECT * FROM ${schema}.viewer`);
				console.log('viewer:',tableToObjects(viewer));
			}

			//let viewer = await conn.query(`select * from ${schema}.viewer`);
			//console.log('viewer:',tableToObjects(viewer));

			// create OBJECTS_PREP table with data
			await conn.query(`drop table if exists ${schema}.OBJECTS cascade;`);
			await conn.query(`CREATE TABLE ${schema}.OBJECTS as
				with cte_prep as (
				select orgId,uuid,container_name,sender,sent_date,
					unnest(string_split(objects,';')) as object_file,
					unnest(string_split(content_types,';')) as content_type,
					(unnest(string_split(media_durations,';')))::double as media_duration,
					(unnest(string_split(total_pages,';')))::int as total_pages,
					(unnest(string_split(digests,';'))) as digest
				from ${schema}.EVENTS_HEADER
				), cte_type as (
				select c.*
					--,case when lower(c.ext) in ('.doc','.docx','.xls','.xlsx','.ppt','.pptx') then 'MSDOC' else 'OTHER' end as doc_type
					,case when lower(c.content_type) like 'video%' then 'MEDIA'
							when lower(c.content_type) like 'audio%' then 'MEDIA'
							when lower(c.content_type) like '%openxmlformats%' then 'MSDOC'
							when lower(c.content_type) like '%pdf%' or lower(c.object_file) like '%.pdf%' then 'PDF'
							else 'OTHER'
						end as doc_type
				from cte_prep c
				)
				select c.*
					-- don't update EVENTS object file with .pdf extension if already has it
					--jk ,case when c.doc_type = 'MSDOC' and lower(c.object_file) not like '%.pdf%' then c.object_file||'.pdf' else c.object_file end as analytics_object_file
					,c.object_file as analytics_object_file
				from cte_type c;
			`);

			if (vDebug >= 1) {
				let OBJECTS = await conn.query(`SELECT * FROM ${schema}.OBJECTS`);
				console.log('OBJECTS:',tableToObjects(OBJECTS));
			}


			// // create OBJECTS table with data
			// await conn.query(`drop table if exists ${schema}.OBJECTS cascade;`);
			// await conn.query(`CREATE TABLE ${schema}.OBJECTS as
			// 	with cte_type as (
			// 	select * from  ${schema}.OBJECTS_PREP
			// 	), cte_max as (
			// 	select t.orgId,t.uuid,t.container_name,t.sender,t.sent_date,t.doc_type,t.object_file,t.media_duration,t.total_pages
			// 		,coalesce(max(e.page::int),1) as total_pages_calc
			// 		,coalesce(max(e.time::double),0) as media_duration_calc
			// 	from cte_type t left join ${schema}.EVENTS e on t.orgId = e.orgId and t.uuid = e.uuid and t.object_file = e.object
			// 	group
			// 		by t.orgId,t.uuid,t.container_name,t.sender,t.sent_date,t.doc_type,t.object_file,t.media_duration,t.total_pages
			// 	), cte_max_fix as (
			// 		select c.orgId,c.uuid,c.container_name,c.sender,c.sent_date,c.doc_type,c.object_file,
			// 			   case when coalesce(c.media_duration,0) = 0 then c.media_duration_calc else c.media_duration end as media_duration,
			// 			   case when coalesce(c.total_pages,0) = 0 then c.total_pages_calc else c.total_pages end as total_pages
			// 		  from cte_max c
			// 	)
			// 	select c.orgId,c.uuid,c.container_name,c.sender,c.sent_date,c.total_pages,c.media_duration::double as media_duration
			// 		--jk ,case when c.doc_type = 'MSDOC' then c.object_file||'.pdf' else c.object_file end as analytics_object_file
			// 		,c.object_file as analytics_object_file
			// 		,c.object_file
			// 		--,c.ext
			// 		,c.doc_type
			// 	from cte_max_fix c;
			// `);


			// EVENTS_VW
			await conn.query(`drop view if exists ${schema}.EVENTS_VW cascade;`);
			await conn.query(`CREATE view ${schema}.EVENTS_VW AS
			with cte_prep as (
				select e.*,
						o.doc_type,o.media_duration as object_media_duration,o.total_pages,
						v.classify_viewers,v.INCLUDED_IN_LIST_FLAG,v.IS_SENDER_FLAG
				 from ${schema}.EVENTS e
				 		left join ${schema}.OBJECTS o on e.orgId = o.orgId and e.uuid = o.uuid and e.object = o.object_file
						left join ${schema}.viewer v  on e.orgId = v.orgId and e.uuid = v.uuid and e.viewer = v.viewer
			)
			select * from cte_prep order by orgId,uuid,viewer,ts
			`
			);

			if (vDebug >= 1) {
				let EVENTS_VW = await conn.query(`SELECT * FROM ${schema}.EVENTS_VW`);
				console.log('EVENTS_VW:',tableToObjects(EVENTS_VW));
			}

			await conn.query(`
			      CREATE view ${schema}.LANDING_VW AS
					with cte_header as (
						select h.orgId, h.uuid, h.ts as sent_date, h.user as sender,h."[].detail.objects[]" as objects, h."[].detail.recipients[]" as recipients
						  from ${schema}.EVENTS_TEMP h
						 where trim(lower(h.action)) = 'container.create'
					), cte_prep as (
						select v.orgId, v.uuid, v.ts, h.sent_date, h.sender, v.action, v.user as viewer, v.object, h.objects, h.recipients,
						       case trim(lower(v.action))
							      when 'object.load' then 'Read'
                                  when 'decrypt.fail' then 'Access Denied'
							      when 'drm.fail' then 'Access Denied'
							      when 'viewer.lock' then 'Locked out'
								  when 'container.create' then 'Sent'
							   end as action_type
						from ${schema}.EVENTS_TEMP v inner join cte_header h on v.orgId = h.orgId and v.uuid = h.uuid
						where trim(lower(v.action)) in ('object.load','decrypt.fail','drm.fail','viewer.lock','container.create')
						  -- and (h.sent_date::date >= (current_date - 7)
						  --      or
						  --      v.ts::date >= (current_date - 14)
						  --     )
					), cte_recipient_list as (
						select distinct q.*
						from
						    (
							  select v.orgId, v.uuid, unnest(string_split(v.recipients,';')) as recipient_list from cte_header v
							  union all
							  select e.orgId, e.uuid, e.user as recipient_list from ${schema}.EVENTS_TEMP e
							) q
						where q.recipient_list not like '%*%'
					), cte_first_read as (
						select distinct c.orgId, c.uuid, c.object, c.action_type,
							first_value(c.ts) OVER (PARTITION BY c.orgId, c.uuid, c.object ORDER BY c.ts ASC) as first_read_ts,
							first_value(c.viewer) OVER (PARTITION BY c.orgId, c.uuid, c.object ORDER BY c.ts ASC) as first_read_viewer,
							h.sender
						from cte_prep c inner join cte_header h on c.orgId = h.orgId and c.uuid = h.uuid
						where c.action_type = 'Read' and c.viewer <> h.sender
					), cte_sent_not_read as (
						select distinct h.orgId, h.uuid, h.objects as object, 'Not Read' as action_type,
							h.sent_date as first_read_ts,
							null as first_read_viewer,
							h.sender
						 from cte_prep c right join cte_header h on c.orgId = h.orgId and c.uuid = h.uuid
						where -- c.viewer <> h.sender and
						     not exists (
							select 1
							  from cte_prep p
							 where p.action_type = 'Read'
							   and p.orgId = c.orgId and p.uuid = c.uuid -- shall we be specific to each content or take any view in container
						  )
					), cte_access_denied as (
						select distinct h.orgId, h.uuid, h.objects as object, c.action_type,
							h.sent_date as first_read_ts,
							c.viewer as first_read_viewer,
							h.sender
						 from cte_prep c right join cte_header h on c.orgId = h.orgId and c.uuid = h.uuid
						where c.action_type = 'Access Denied'
					), cte_locked_out as (
						select distinct h.orgId, h.uuid, h.objects as object, c.action_type,
							h.sent_date as first_read_ts,
							c.viewer as first_read_viewer,
							h.sender
						 from cte_prep c right join cte_header h on c.orgId = h.orgId and c.uuid = h.uuid
						where c.action_type = 'Locked out'
					), cte_all_action_minus_sent_not_read as (
						select v.orgId, v.uuid, v.ts, v.action_type, v.viewer, h.objects, h.recipients
						 from cte_prep v inner join cte_header h
						 on v.orgId = h.orgId
						 and v.uuid = h.uuid
						 and v.action_type <> 'Sent'
					), cte_final as (
						select v.orgId, v.uuid, v.ts, v.action_type, v.viewer, v.objects, v.recipients
						  from cte_all_action_minus_sent_not_read v
						union all
						select v.orgId, v.uuid, v.ts, v.action_type, v.viewer, v.objects, v.recipients
						  from cte_prep v join cte_recipient_list l on v.orgId = l.orgId and v.uuid = l.uuid
						  where v.action_type = 'Sent' and not exists (select 1 from cte_prep p
																 where p.orgId = v.orgId
																 and p.uuid = v.uuid
																 and p.viewer = l.recipient_list
																 and p.action = 'Read')
					), cte_all as (
						select v.orgId, v.uuid, v.first_read_ts as ts, v.action_type, v.first_read_viewer as viewer, v.object as objects from cte_first_read v
						union all
						select v.orgId, v.uuid, v.first_read_ts as ts, v.action_type, v.first_read_viewer as viewer, v.object as objects from cte_sent_not_read v
						union all
						select v.orgId, v.uuid, v.first_read_ts as ts, v.action_type, v.first_read_viewer as viewer, v.object as objects from cte_access_denied v
						union all
						select v.orgId, v.uuid, v.first_read_ts as ts, v.action_type, v.first_read_viewer as viewer, v.object as objects from cte_locked_out v
					)
					select * from cte_all order by ts desc
			 `
			);

			if (vDebug >= 1) {
				let LANDING_VW = await conn.query(`SELECT * FROM ${schema}.LANDING_VW`);
				console.log('LANDING_VW:',tableToObjects(LANDING_VW));
			}

			//completion views
			await conn.query(`drop view if exists ${schema}.COMPLETION_VW cascade;`);
			await conn.query(`CREATE view ${schema}.COMPLETION_VW AS
			with cte_prep as (
				select e.orgId,e.uuid,e.object,e.viewer,e.page,e.total_pages,e.time,e.next_time,e.object_media_duration,e.doc_type,e.ts
				 from ${schema}.EVENTS_VW e
				 where e.anchor_stat_flag = 1
			), cte_gen as (
				select 	q.orgid,q.uuid,q.object,q.viewer,q.doc_type,q.ts,
						case when upper(q.doc_type) = 'MEDIA' then generate_series(floor(q.time)::int,floor(q.next_time)::int,1)
							 else [q.page]
						end as coverage,
						case when upper(q.doc_type) = 'MEDIA' then (floor(q.object_media_duration)+1)::int
							 else coalesce(q.total_pages,1)::int
						end as coverage_max,
						-- add denominator_modifer, 1 for MEDIA, 0 for OTHER
						case when upper(q.doc_type) = 'MEDIA' then 1::int
							 else 0::int
						 end as denominator_modifer
				from cte_prep q
			)
			select q.orgid,q.uuid,q.object,q.viewer,q.doc_type,q.coverage,len(q.coverage)::int as coverage_length,q.coverage_max,q.denominator_modifer,q.ts,
			  q.coverage::text as coverage_text
			  from cte_gen q
			 order
			    by q.orgid,q.uuid,q.object,q.viewer
			`
			);

			if (vDebug >= 1) {
				let COMPLETION_VW = await conn.query(`SELECT q.* FROM ${schema}.COMPLETION_VW q`);
				console.log('COMPLETION_VW:',tableToObjects(COMPLETION_VW));
			}

			//COMPLETION_ORGID_UUID_OBJECT_VIEWER_VW
			await conn.query(`drop view if exists ${schema}.COMPLETION_ORGID_UUID_OBJECT_VIEWER_VW cascade;`);
			await conn.query(`CREATE view ${schema}.COMPLETION_ORGID_UUID_OBJECT_VIEWER_VW AS
			with cte_prep as (
				select c.orgId,c.uuid,c.object,c.viewer,c.coverage_max,c.denominator_modifer,list_distinct(flatten(list((c.coverage)))) as coverage
				 from ${schema}.COMPLETION_VW c
				 group by c.orgId,c.uuid,c.object,c.viewer,c.coverage_max,c.denominator_modifer
			)
			select c.orgId,c.uuid,c.object,c.viewer,len(c.coverage)::int as coverage_length, c.coverage_max,
				   round((len(c.coverage))*100/(floor(c.coverage_max))::double,2) as completion
				   --,array_to_string(c.coverage, ',') AS coverage_str
				   ,c.coverage::text as coverage_text
			  from cte_prep c
			  order by c.orgId,c.uuid,c.object,c.viewer
			`
			);

			if (vDebug >= 1) {
				let COMPLETION_ORGID_UUID_OBJECT_VIEWER_VW = await conn.query(`SELECT * FROM ${schema}.COMPLETION_ORGID_UUID_OBJECT_VIEWER_VW`);
				console.log('COMPLETION_ORGID_UUID_OBJECT_VIEWER_VW:',tableToObjects(COMPLETION_ORGID_UUID_OBJECT_VIEWER_VW));
			}

			//COMPLETION_ORGID_UUID_VIEWER_VW
			await conn.query(`drop view if exists ${schema}.COMPLETION_ORGID_UUID_VIEWER_VW cascade;`);
			await conn.query(`CREATE view ${schema}.COMPLETION_ORGID_UUID_VIEWER_VW AS
			with cte_coverage_max as (
				select c.orgId,c.uuid,c.viewer,sum(c.coverage_max) as coverage_max
				  from (select distinct orgId,uuid,viewer,object,coverage_max from ${schema}.COMPLETION_VW) c
				 group
				    by c.orgId,c.uuid,c.viewer
			), cte_prep as (
				select 	 c.orgId,c.uuid,c.viewer,c.coverage_max
						--,sum(c.denominator_modifer)::int as denominator_modifer
						,list_distinct(flatten(list((c.coverage)))) as coverage
			 	  from ${schema}.COMPLETION_VW c join cte_coverage_max m on c.orgId = m.orgId and c.uuid = m.uuid and c.viewer = m.viewer
				 group
				    by c.orgId,c.uuid,c.viewer,c.coverage_max
			)
			select c.orgId,c.uuid,c.viewer,len(c.coverage)::int as coverage_length, c.coverage_max,
				   round((len(c.coverage))*100/(floor(c.coverage_max))::double,2) as completion
			  from cte_prep c
			  order by c.orgId,c.uuid,c.viewer
			`
			);

			if (vDebug >= 1) {
				let COMPLETION_ORGID_UUID_VIEWER_VW = await conn.query(`SELECT * FROM ${schema}.COMPLETION_ORGID_UUID_VIEWER_VW`);
				console.log('COMPLETION_ORGID_UUID_VIEWER_VW:',tableToObjects(COMPLETION_ORGID_UUID_VIEWER_VW));
			}

			//$$$$$$$$$$$$$$$$$$$$$$$$$$
			//$$$$$$$$$$$$$$$$$$$$$$$$$$ CONTAINER_EVENTS_STAT
			//$$$$$$$$$$$$$$$$$$$$$$$$$$
			await conn.query(`drop table if exists ${schema}.CONTAINER_EVENTS_STAT cascade;`);
			await conn.query(`CREATE TABLE ${schema}.CONTAINER_EVENTS_STAT AS
				with cte_prep as (
					select e.*
						from ${schema}.EVENTS e
					where (lower(e.action) like '%container%' or
							lower(e.action) like '%fail%' or
							lower(e.action) like 'email%'
						)
					--and upper(o.doc_type) in ('MEDIA','PDF','MSDOC','OTHER')
					order
						by e.orgId,e.uuid,e.viewer,e.ts asc
				), cte_final as (
					select e.orgId,e.uuid,e.viewer,e.reason,e.ts,
						case when lower(e.action) like '%load%' then 1::int else 0::int
						end as container_open,
						case when lower(e.action) like '%block%' then 'block'
							 when lower(e.action) like '%unblock%' then 'unblock'
						end as block_action,
						case when lower(e.action) like '%drm.fail%' then 1::int else 0::int
						end as drm_fail,
						case when lower(e.action) like '%decrypt.fail%' then 1::int else 0::int
						end as decrypt_fail,
						case when lower(e.action) like '%email.open%' then 1::int else 0::int
						end as email_open
					from cte_prep e
					order
					   by e.orgId,e.uuid,e.viewer
				)
				-- join with viewer table to make sure only qualifed viewers are included
				select e.*
				  from cte_final e join ${schema}.viewer v on e.orgId = v.orgId and e.uuid = v.uuid and e.viewer = v.viewer
				 order by e.orgId,e.uuid,e.viewer;
			`
			);

			if (vDebug >= 1) {
				let CONTAINER_EVENTS_STAT = await conn.query(`SELECT * FROM ${schema}.CONTAINER_EVENTS_STAT`);
				console.log('CONTAINER_EVENTS_STAT:',tableToObjects(CONTAINER_EVENTS_STAT));
			}

			//$$$$$$$$$$$$$$$$$$$$$$$$$$
			//$$$$$$$$$$$$$$$$$$$$$$$$$$ OBJECT_EVENTS_STAT
			//$$$$$$$$$$$$$$$$$$$$$$$$$$
			await conn.query(`drop table if exists ${schema}.OBJECT_EVENTS_STAT cascade;`);
			await conn.query(`CREATE TABLE ${schema}.OBJECT_EVENTS_STAT AS
				with cte_prep as (
					select o.doc_type,o.media_duration,o.total_pages,e.*,
					       case when coalesce(e.page,0) > 0 then 1::int else 0::int end as page_open
				     from ${schema}.EVENTS e left join ${schema}.OBJECTS o on e.orgId = o.orgId and e.uuid = o.uuid and e.object = o.analytics_object_file
					where (	lower(e.action) like 'audio%' or
							lower(e.action) like 'video%' or
							lower(e.action) in ('object.show','object.hide') or
							lower(e.action) like 'pdf%' or
							(lower(e.action) like lower('container.changePageState') and (e.from = 'active' or e.to = 'active'))
						)
					--and upper(o.doc_type) in ('MEDIA','PDF','MSDOC','OTHER')
					order
						by e.orgId,e.uuid,e.viewer,e.ts asc
				), cte_next as (
					select e.*
						,(lead(e.ts) over (partition by e.orgId,e.uuid,e.viewer order by e.ts)) as next_ts
						,(lead(e.time) over (partition by e.orgId,e.uuid,e.viewer order by e.ts))::double as next_time
					from cte_prep e
					order
						by e.orgId,e.uuid,e.viewer,e.ts asc
				), cte_flag as (
				select e.*,
					case when upper(e.doc_type) in ('MEDIA') and lower(e.action) like '%play%' then 1::int
							when upper(e.doc_type) in ('PDF','MSDOC') and lower(e.action) like '%load%' then 1::int
							when upper(e.doc_type) in ('OTHER') and lower(e.action) like '%show%' then 1::int
							else 0::int
						end as anchor_stat_flag
				from cte_next e
				order
					by e.orgId,e.uuid,e.viewer,e.ts asc
				), cte_open as (
				select e.session,e.orgId,e.uuid,e.sender,e.viewer,e.object,e.doc_type,e.ts,0::int as page_open,1::int as object_open,
				0::int as object_download
				from cte_prep e
				where lower(e.action) like '%show%'
				), cte_final as (
				select e.session,e.orgId,e.uuid,e.sender,e.viewer,e.object,e.doc_type,e.page,e.time,e.next_time,e.ts,e.next_ts,
				    least((DATE_PART('EPOCH',coalesce(e.next_ts::timestamp,current_timestamp::timestamp)) - DATE_PART('EPOCH',e.ts::timestamp)),300)::int as duration,
					--least((DATE_PART('EPOCH',e.next_ts::timestamp) - DATE_PART('EPOCH',e.ts::timestamp)),300)::int as duration,
					(e.next_time::double - e.time::double)::double as media_duration,
					e.page_open,
					0::int as object_open, e.object_download
				from cte_flag e
				where e.anchor_stat_flag = 1
				union all
				select e.session,e.orgId,e.uuid,e.sender,e.viewer,e.object,e.doc_type,0::bigint as page,null::double as time, null::double as next_time,e.ts,null::timestamp as next_ts,
					0::bigint as duration, 0::double as media_duration, e.page_open, e.object_open, e.object_download
				from cte_open e
				)
				select	e.session,e.orgId,e.uuid,e.sender,e.viewer,e.object,e.doc_type,e.page,e.time, e.next_time,e.ts,e.next_ts,
						--tk20230708 if object_open and duration is null or 0 then default to 1 sec
						case when coalesce(e.object_open,0) > 0 and coalesce(e.duration,0) = 0 then 1::int else coalesce(e.duration,0)::int end as duration,
						--e.duration,
						e.media_duration,
						case when upper(e.doc_type) in ('OTHER') then e.object_open else e.page_open end as page_open,
						e.object_open, e.object_download
				  from cte_final e join ${schema}.viewer v on e.orgId = v.orgId and e.uuid = v.uuid and e.viewer = v.viewer
				 order by e.orgId,e.uuid,e.viewer,e.object,e.ts;
			`
			);

			if (vDebug >= 1) {
				let OBJECT_EVENTS_STAT = await conn.query(`SELECT * FROM ${schema}.OBJECT_EVENTS_STAT`);
				console.log('OBJECT_EVENTS_STAT:',tableToObjects(OBJECT_EVENTS_STAT));
			}

			// if ALL_VIEWER_FLAG = 0 then delete events not in AUTHORIZED_VIEWER
			//tk if (ALL_VIEWER_FLAG === 0 && ![`ACCESS_DENIED`].includes(schema)) {
			// if (![`ACCESS_DENIED`].includes(schema)) {
			// 	await conn.query(`
			// 	with cte_flag as (
			// 		SELECT h.orgId,h.uuid,h.ALL_VIEWER_FLAG, e.*
			// 		  FROM ${schema}.EVENTS_HEADER h join ${schema}.EVENTS e on h.orgId = e.orgId and h.uuid = e.uuid
			// 	), cte_delete as (
			// 		select e.*, v.viewer
			// 		  from cte_flag e left join ${schema}.AUTHORIZED_VIEWER v on e.orgId = v.orgId and e.uuid = v.uuid and e.viewer = v.viewer
			// 		 where e.ALL_VIEWER_FLAG = 0 and v.viewer is null
			// 	), cte_old_delete as (
			// 		select * from ${schema}.EVENTS e
			// 		 where not exists
			// 		 (select 1 from ${schema}.AUTHORIZED_VIEWER v
			// 		   where e.orgId = v.orgId and e.uuid = v.uuid and e.viewer = v.viewer
			// 		 )
			// 	)
			// 	delete from ${schema}.EVENTS e
			// 	 where exists
			// 	 (select 1 from ${schema}.cte_delete v
			// 	   where e.id = v.id
			// 	 )
			// 	`);
			// }

			// if (vDebug >= 1) {
			// 	let EVENTS = await conn.query(`SELECT * FROM ${schema}.EVENTS`);
			// 	console.log('EVENTS:',tableToObjects(EVENTS));
			// }

			//await conn.query(`CREATE TABLE OBJECTS as select distinct object as object_file from EVENTS where coalesce(object,'') <> '';`);
			//let res = await conn.query(`select * from OBJECTS`);
			//console.log(res)
			// if (vDebug >= 1) {
			// 	let OBJECTS = await conn.query(`SELECT * FROM ${schema}.OBJECTS`);
			// 	console.log('OBJECTS:',tableToObjects(OBJECTS));
			// }
		}
		await import_groups_users();
		if (schema === 'STORAGE_API_CALLS') {
			await org_stats();
		} else {
			await other_stats();
		}
	};

	await duckdb_load_tables(schema);

	// return conn;
	// don't go further if doing org stat
	if (schema === 'STORAGE_API_CALLS') {
		return conn;
	}

	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	// create RPT_CONTAINER_OBJECT for container and content REPORTS
	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	async function FNC_RPT_CONTAINER_OBJECT (schema,action) {
		var vSQL = ``;
		if (schema === `CONTAINER_OBJECT` || schema === `CONTENT_FILES_ACCESSED` ||
		    schema === `VIEWER_AUDIT` || schema === `CRM_EVENT_EXPORT` || schema === `VIEWER_ACTIVITY` ||
			schema === `CONTENT_FILE_AUDIT` || schema === `DOWNLOADED_CONTENT`) {
			//await conn.query(`drop view if exists ${schema}.rpt_container_header_info_vw cascade;`);
			vSQL = `create view ${schema}.rpt_container_header_info_vw as
				with cte_main as (
					select orgId,uuid,container_name,sender,sent_date,string_agg(distinct object_file,',  ') as content_files
					from ${schema}.OBJECTS
					group by orgId,uuid,container_name,sender,sent_date
					), cte_final as (
					select c.uuid,c.container_name,c.sender,h.recipients,c.sent_date,h.status,
						h.container_end_ts as files_expire,
						c.content_files, h.downloadable
					from cte_main c inner join ${schema}.EVENTS_HEADER h on c.orgId = h.orgId and c.uuid = h.uuid
					)
					select * from cte_final;
				`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.rpt_container_header_info_vw cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let rpt_container_header_info_vw = await conn.query(`select * from ${schema}.rpt_container_header_info_vw`);
						console.log(`rpt_container_header_info_vw:`,tableToObjects(rpt_container_header_info_vw));
					}
			}
			//await conn.query(`drop view if exists ${schema}.rpt_container_stat_vw cascade;`);
			vSQL = `create view ${schema}.rpt_container_stat_vw as
				with cte_container as (
					select c.orgId,c.uuid,c.viewer,sum(c.container_open) as container_open
					from ${schema}.CONTAINER_EVENTS_STAT c
					group
						by c.orgId,c.uuid,c.viewer
				), cte_object as (
					select c.orgId,c.uuid,c.viewer,sum(c.duration) as duration,max(c.ts) as last_accessed
					from ${schema}.OBJECT_EVENTS_STAT c
					group
						by c.orgId,c.uuid,c.viewer
				), cte_container_object as (
					select c.orgId,c.uuid,c.viewer,o.last_accessed,coalesce(c.container_open,0) as container_open,coalesce(o.duration,0) as duration_seconds
					from cte_container c join cte_object o on c.orgId = o.orgId and c.uuid = o.uuid and c.viewer = o.viewer
				), cte_container_object_viewer as (
					select v.orgId,v.uuid
					      ,v.viewer -- as "AUTHORIZED VIEWERS"
						  ,coalesce(c.container_open,0)::int as opens --"OPENS"
						  ,(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as duration --"DURATION",
						  ,c.last_accessed
						  ,v.ip --as "IP ADDRESS"
						  ,v.city --as "CITY",
						  ,v.state --as "STATE"
						  ,v.country --as "COUNTRY"
					from cte_container_object c right join ${schema}.viewer v on c.orgId = v.orgId and c.uuid = v.uuid and c.viewer = v.viewer
				)
				select * from cte_container_object_viewer;
				`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.rpt_container_stat_vw cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let rpt_container_stat_vw = await conn.query(`select * from ${schema}.rpt_container_stat_vw`);
						console.log(`rpt_container_stat_vw:`,tableToObjects(rpt_container_stat_vw));
					}
			}
			// rpt_object_stat_vw: "CONTENT FILE","NUMBER OF VIEWERS","OPENS","DURATION","LAST ACCESSED"
			//await conn.query(`drop view if exists ${schema}.rpt_object_stat_vw cascade;`);
			vSQL = `create view ${schema}.rpt_object_stat_vw as
				with cte_object as (
					select c.orgId,c.uuid,
						c.object,count(distinct(c.viewer)) as viewers,
						sum(object_open) as object_open,sum(c.duration) as duration_seconds,
						min(c.ts) as first_accessed,
						max(c.ts) as last_accessed
					from ${schema}.OBJECT_EVENTS_STAT c join ${schema}.viewer v on c.orgId = v.orgId and c.uuid = v.uuid and c.viewer = v.viewer
					group
						by c.orgId,c.uuid,c.object
					)
					select o.orgId,o.uuid
					     ,o.object_file as object --as "CONTENT FILE"
						 ,coalesce(c.viewers,0)::int as viewers --as "NUMBER OF VIEWERS"
						 ,coalesce(c.object_open,0)::int as object_open --as "OPENS"
						 ,(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as duration --as "DURATION"
						 ,duration_seconds
						 ,c.first_accessed
						 ,c.last_accessed --as "LAST ACCESSED"
						 ,o.doc_type
					from cte_object c right join ${schema}.OBJECTS o on c.orgId = o.orgId and c.uuid = o.uuid and c.object = o.object_file;
					`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
				await conn.query(`drop view if exists ${schema}.rpt_object_stat_vw cascade;`);
				await conn.query(vSQL);
				if (vDebug >= 1) {
					let rpt_object_stat_vw = await conn.query(`select * from ${schema}.rpt_object_stat_vw`);
					console.log(`rpt_object_stat_vw:`,tableToObjects(rpt_object_stat_vw));
				}
			}
			// rpt_pdf_stat_vw: "VIEWER","PAGE","OPENS","DURATION","FIRST ACCESSED","LAST ACCESSED"
			//await conn.query(`drop view if exists ${schema}.rpt_pdf_stat_vw cascade;`);
			vSQL = `create view ${schema}.rpt_other_stat_vw as
				with cte_object as (
					select c.orgId,c.uuid,c.object,c.viewer,
					    (case when coalesce(c.page,0) = 0 then 1::int else c.page::int end) as page,
						sum(case when (upper(c.doc_type) <> 'PDF' and upper(c.doc_type) <> 'MSDOC') then object_open else page_open end)::int as page_open,
						--sum(c.page_open) as page_open,
						sum(c.duration)::int as duration_seconds,
						min(c.ts) as first_accessed,
						max(c.ts) as last_accessed
					from ${schema}.OBJECT_EVENTS_STAT c join ${schema}.viewer v on c.orgId = v.orgId and c.uuid = v.uuid and c.viewer = v.viewer
					where upper(c.doc_type) <> 'MEDIA' --and c.page > 0
					group
						by c.orgId,c.uuid,c.object,c.viewer,(case when coalesce(c.page,0) = 0 then 1::int else c.page::int end)
					)
					select orgId, uuid,
						object as "CONTENT FILE",
						viewer as "VIEWER",
						page::int as "PAGE",
						page_open::int as "OPENS",
						(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as "DURATION",
						duration_seconds,
						first_accessed as "FIRST ACCESSED",
						last_accessed as "LAST ACCESSED"
					from cte_object;
					`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
				await conn.query(`drop view if exists ${schema}.rpt_other_stat_vw cascade;`);
				await conn.query(vSQL);
				if (vDebug >= 1) {
					let rpt_other_stat_vw = await conn.query(`select * from ${schema}.rpt_other_stat_vw`);
					console.log(`rpt_other_stat_vw:`,tableToObjects(rpt_other_stat_vw));
				}
			}
			// rpt_media_stat_vw: "VIEWER","PAGE","OPENS","DURATION","FIRST ACCESSED","LAST ACCESSED"
			//await conn.query(`drop view if exists ${schema}.rpt_media_stat_vw cascade;`);
			vSQL = `create view ${schema}.rpt_media_stat_vw as
				with cte_object as (
					select c.orgId,c.uuid,c.object,c.viewer, --c.page,
						sum(object_open) as object_open,
						--tk bug 20240210 sum(c.media_duration)::int as duration_seconds,
						sum(c.duration)::int as duration_seconds,
						min(c.ts) as first_accessed,
						max(c.ts) as last_accessed
					from ${schema}.OBJECT_EVENTS_STAT c join ${schema}.viewer v on c.orgId = v.orgId and c.uuid = v.uuid and c.viewer = v.viewer
					where upper(c.doc_type) = 'MEDIA' --tk and c.page > 0
					group
						by c.orgId,c.uuid,c.object,c.viewer --,c.page
					)
					select orgId, uuid,
						object as "CONTENT FILE",
						viewer as "VIEWER",
						0::int as "PAGE",
						object_open::int as "OPENS",
						(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as "DURATION",
						duration_seconds,
						first_accessed as "FIRST ACCESSED",
						last_accessed as "LAST ACCESSED"
					from cte_object;
					`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
				await conn.query(`drop view if exists ${schema}.rpt_media_stat_vw cascade;`);
				await conn.query(vSQL);
				if (vDebug >= 1) {
					let rpt_media_stat_vw = await conn.query(`select * from ${schema}.rpt_media_stat_vw`);
					console.log(`rpt_media_stat_vw:`,tableToObjects(rpt_media_stat_vw));
				}
			}

			vSQL = `
			create view ${schema}.rpt_all_stat_vw as
			select * from ${schema}.rpt_other_stat_vw union all
			select * from ${schema}.rpt_media_stat_vw
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
				await conn.query(`drop view if exists ${schema}.rpt_all_stat_vw cascade;`);
				await conn.query(vSQL);
				if (vDebug >= 1) {
					let rpt_all_stat_vw = await conn.query(`select * from ${schema}.rpt_all_stat_vw`);
					console.log(`rpt_all_stat_vw:`,tableToObjects(rpt_all_stat_vw));
				}
			}
			//$$$$$$$$$$$$$$$$$$$$$$$$$$ COMPLETION_STAT
			//MEDIA_COMPLETION_STAT
			vSQL = `CREATE view ${schema}.RPT_MEDIA_COMPLETION_STAT_VW AS
				with cte_main as (
					select vv.orgId,vv.uuid,vv.object,vv.viewer,
					vv.media_duration, m.time, m.next_time
					  from (select o.orgId,o.uuid,o.object_file as object,v.viewer, o.media_duration
						      from ${schema}.OBJECTS o join ${schema}.viewer v on o.orgId = v.orgId and o.uuid = v.uuid
							  where o.doc_type = 'MEDIA'
						   ) vv
					       left join ${schema}.OBJECT_EVENTS_STAT m on m.orgId = vv.orgId
						   and m.uuid = vv.uuid and m.object = vv.object and m.viewer = vv.viewer
				),cte_media_max as (
					select distinct c.orgId,c.uuid,c.object,c.viewer,floor(c.media_duration)::int as max_time
					from cte_main c --join ${schema}.OBJECTS o on c.orgId = o.orgId and c.uuid = o.uuid and c.object = o.analytics_object_file
					--where o.doc_type = 'MEDIA'
				),cte_media_series as (
					select m.*, unnest(generate_series(0,m.max_time, 1)) as t
					from cte_media_max m
				),cte_media_covered as (
					select distinct c.orgId,c.uuid,c.object,c.viewer,c.max_time,c.t
					from cte_media_series c, cte_main m
					where c.orgId = m.orgId and c.uuid = m.uuid and c.object = m.object and c.viewer = m.viewer
						and c.t between m.time and m.next_time
				),cte_media_coverage_duration as (
					select c.orgId,c.uuid,c.object,c.viewer,c.max_time,
					count(distinct(c.t))::double as view_duration
					from cte_media_covered c
					group
						by c.orgId,c.uuid,c.object,c.viewer,c.max_time
				),cte_media_completion as (
					select distinct c.orgId,c.uuid,c.object,c.viewer,c.max_time, c.view_duration,
						case when coalesce(c.max_time)::double > 0 then (c.view_duration*100/(c.max_time+1)::double)::double else null::double end as percent_viewership
					from cte_media_coverage_duration c
				), cte_object_viewer as (
					select o.orgId,o.uuid,o.object_file,v.viewer
					from ${schema}.OBJECTS o, ${schema}.viewer v
					where o.orgId = v.orgId and o.uuid = v.uuid
					  and o.doc_type = 'MEDIA'
				)
				select ov.orgId,ov.uuid,ov.object,ov.viewer,coalesce(c.percent_viewership,0)::double as percent_viewership,
				ov.max_time, c.view_duration
				from cte_media_completion c
				     right join cte_media_max ov on c.orgId = ov.orgId and c.uuid = ov.uuid and c.object = ov.object and c.viewer = ov.viewer
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
				await conn.query(`drop view if exists ${schema}.RPT_MEDIA_COMPLETION_STAT_VW cascade;`);
				await conn.query(vSQL);
				if (vDebug >= 1) {
					let RPT_MEDIA_COMPLETION_STAT_VW = await conn.query(`select * from ${schema}.RPT_MEDIA_COMPLETION_STAT_VW`);
					console.log(`RPT_MEDIA_COMPLETION_STAT_VW:`,tableToObjects(RPT_MEDIA_COMPLETION_STAT_VW));
				}
			}

			//OTHER_COMPLETION_STAT
			vSQL = `CREATE view ${schema}.RPT_OTHER_COMPLETION_STAT_VW AS
				with cte_main as (
					select m.orgId,m.uuid,m.object,m.viewer,
					case when coalesce(m.page,0)::int = 0::int then 1::int else m.page::int end as page
					  from ${schema}.OBJECT_EVENTS_STAT m
					 where m.doc_type <> 'MEDIA' and m.page_open = 1
				),cte_object_viewer as (
					select o.orgId,o.uuid,o.object_file,v.viewer,
					case when coalesce(o.total_pages,0)::int = 0::int then 1::int else o.total_pages::int end as total_pages
					from ${schema}.OBJECTS o, ${schema}.viewer v
					where o.orgId = v.orgId and o.uuid = v.uuid
					  and o.doc_type <> 'MEDIA'
				),cte_other_completion as (
					select ov.orgId,ov.uuid,ov.object_file as object,ov.viewer,ov.total_pages,count(distinct(c.page)) as viewed_pages,
					       (count(distinct(c.page))*100/ov.total_pages::double)::double as percent_viewership
					from cte_main c right join cte_object_viewer ov
					  on c.orgId = ov.orgId and c.uuid = ov.uuid and c.object = ov.object_file and c.viewer = ov.viewer
					group
						by ov.orgId,ov.uuid,ov.object_file,ov.viewer,ov.total_pages
				)
				select c.orgId,c.uuid,c.object,c.viewer,
					   coalesce(c.percent_viewership,0)::double as percent_viewership,
				       c.viewed_pages,c.total_pages
				from cte_other_completion c
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
				await conn.query(`drop view if exists ${schema}.RPT_OTHER_COMPLETION_STAT_VW cascade;`);
				await conn.query(vSQL);
				if (vDebug >= 1) {
					let RPT_OTHER_COMPLETION_STAT_VW = await conn.query(`select * from ${schema}.RPT_OTHER_COMPLETION_STAT_VW`);
					console.log(`RPT_OTHER_COMPLETION_STAT_VW:`,tableToObjects(RPT_OTHER_COMPLETION_STAT_VW));
				}
			}

		//ALL_COMPLETION_STAT
		vSQL = `CREATE view ${schema}.RPT_ALL_COMPLETION_STAT_VW AS
			select v1.orgId,v1.uuid,v1.object,v1.viewer,v1.percent_viewership from ${schema}.RPT_MEDIA_COMPLETION_STAT_VW v1
			union all
			select v2.orgId,v2.uuid,v2.object,v2.viewer,v2.percent_viewership from ${schema}.RPT_OTHER_COMPLETION_STAT_VW v2
		`;
		if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
		if (action === `execute`) {
			await conn.query(`drop view if exists ${schema}.RPT_ALL_COMPLETION_STAT_VW cascade;`);
			await conn.query(vSQL);
			if (vDebug >= 1) {
				let RPT_ALL_COMPLETION_STAT_VW = await conn.query(`select * from ${schema}.RPT_ALL_COMPLETION_STAT_VW`);
				console.log(`RPT_ALL_COMPLETION_STAT_VW:`,tableToObjects(RPT_ALL_COMPLETION_STAT_VW));
			}
		}

		//*********************************
		//*********************************
		//completion on viewer container level
		//*********************************
		//*********************************
		vSQL = `CREATE view ${schema}.RPT_VIEWER_CONTAINER_MEDIA_COMPLETION_STAT_VW_OLD AS
				with cte_main as (
					select * from ${schema}.OBJECT_EVENTS_STAT
				),cte_media_max as (
					select distinct c.orgId,c.uuid,c.object,c.viewer,floor(o.media_duration)::int as max_time
					from cte_main c join ${schema}.OBJECTS o on c.orgId = o.orgId and c.uuid = o.uuid and c.object = o.analytics_object_file
					where o.doc_type = 'MEDIA'
				),cte_media_series as (
					select m.*, unnest(generate_series(0,m.max_time, 1)) as t
					from cte_media_max m
				),cte_media_covered as (
					select distinct c.orgId,c.uuid,c.object,c.viewer,c.max_time,c.t
					from cte_media_series c, cte_main m
					where c.orgId = m.orgId and c.uuid = m.uuid and c.object = m.object and c.viewer = m.viewer
						and c.t between m.time and m.next_time
				),cte_media_coverage_duration as (
					select c.orgId,c.uuid,c.object,c.viewer,c.max_time,count(c.t)::double as view_duration
					from cte_media_covered c
					group
						by c.orgId,c.uuid,c.object,c.viewer,c.max_time
				), cte_sum_total_duration as (
					select orgId,uuid,sum(max_time)::double as max_time
					from (
							select distinct orgId,uuid,object,max_time
							  from cte_media_max
					  ) q
					group by orgId,uuid
				),cte_media_completion as (
					select c.orgId,c.uuid,c.viewer,
						case when coalesce(t.max_time)::double > 0 then ( (sum(c.view_duration)*100)::double/(t.max_time+1)::double )::double
						     else null::double
						 end as percent_viewership
					from cte_media_coverage_duration c join cte_sum_total_duration t  on c.orgId = t.orgId and c.uuid = t.uuid
					group by c.orgId,c.uuid,c.viewer,t.max_time
				), cte_viewer as (
					select v.orgId,v.uuid,v.viewer
					from ${schema}.viewer v
				)
				select ov.orgId,ov.uuid,ov.viewer,c.percent_viewership -- avg(coalesce(c.percent_viewership,0)::double)::double as percent_viewership
				from cte_media_completion c join cte_viewer ov on c.orgId = ov.orgId and c.uuid = ov.uuid and c.viewer = ov.viewer
				--group by ov.orgId,ov.uuid,ov.viewer
			`;

		vSQL = `CREATE view ${schema}.RPT_VIEWER_CONTAINER_MEDIA_COMPLETION_STAT_VW AS
				with cte_main as (
				select v.orgId,v.uuid,v.viewer,sum(v.max_time) as max_time, sum(v.view_duration) as view_duration
				from ${schema}.RPT_MEDIA_COMPLETION_STAT_VW v
				group by v.orgId,v.uuid,v.viewer
				)
				select v.orgId,v.uuid,v.viewer,
				case when coalesce(v.max_time,0)::double > 0 then ( ((v.view_duration)*100)::double/(v.max_time+1)::double )::double
						     else 0::double
						 end as percent_viewership
				from cte_main v
		`;
		if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
		if (action === `execute`) {
			await conn.query(`drop view if exists ${schema}.RPT_VIEWER_CONTAINER_MEDIA_COMPLETION_STAT_VW cascade;`);
			await conn.query(vSQL);
			if (vDebug >= 1) {
				let RPT_VIEWER_CONTAINER_MEDIA_COMPLETION_STAT_VW = await conn.query(`select * from ${schema}.RPT_VIEWER_CONTAINER_MEDIA_COMPLETION_STAT_VW`);
				console.log(`RPT_VIEWER_CONTAINER_MEDIA_COMPLETION_STAT_VW:`,tableToObjects(RPT_VIEWER_CONTAINER_MEDIA_COMPLETION_STAT_VW));
			}
		}

		vSQL = `CREATE view ${schema}.RPT_VIEWER_CONTAINER_OTHER_COMPLETION_STAT_VW_OLD AS
			with cte_main as (
				select o.orgId,o.uuid,oe.viewer, oe.object,
					-- set total pages to 1 for files that are one pagers like images
					case when coalesce(o.total_pages,0)::int = 0::int then 1::int else o.total_pages::int end as total_pages,
					-- set page to 1 for other files like images
					case when coalesce(oe.page,0)::int = 0::int then 1::int else oe.page::int end as page
				from ${schema}.OBJECT_EVENTS_STAT oe right join ${schema}.OBJECTS o on oe.orgId = o.orgId and oe.uuid = o.uuid and oe.object = o.object_file
				where o.doc_type <> 'MEDIA'
			), cte_sum_total_pages as (
					select orgId,uuid,sum(total_pages) as total_pages
					from (
							select distinct orgId,uuid,object,total_pages
							  from cte_main
					  ) q
					group by orgId,uuid
			),cte_other_completion as (
				select c.orgId,c.uuid,c.viewer,t.total_pages,((count(distinct(c.object||c.page::text))*100)::double/t.total_pages)::double as percent_viewership
				from cte_main c join cte_sum_total_pages t on c.orgId = t.orgId and c.uuid = t.uuid
				group
					by c.orgId,c.uuid,c.viewer,t.total_pages
			), cte_viewer as (
				select v.orgId,v.uuid,v.viewer
				from ${schema}.viewer v
			)
			select ov.orgId,ov.uuid,ov.viewer,coalesce(c.percent_viewership,0)::double as percent_viewership
			from cte_other_completion c
			join cte_viewer ov on c.orgId = ov.orgId and c.uuid = ov.uuid and c.viewer = ov.viewer
		`;

		vSQL = `CREATE view ${schema}.RPT_VIEWER_CONTAINER_OTHER_COMPLETION_STAT_VW AS
				with cte_main as (
				select v.orgId,v.uuid,v.viewer,
				sum(v.viewed_pages) as viewed_pages, sum(v.total_pages) as total_pages
				from ${schema}.RPT_OTHER_COMPLETION_STAT_VW v
				group by v.orgId,v.uuid,v.viewer
				)
				select v.orgId,v.uuid,v.viewer,
				case when coalesce(v.total_pages,0)::double > 0 then (viewed_pages*100)::double/v.total_pages::double
							else 0::double
						end as percent_viewership
				from cte_main v
		`;

		if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
		if (action === `execute`) {
			await conn.query(`drop view if exists ${schema}.RPT_VIEWER_CONTAINER_OTHER_COMPLETION_STAT_VW cascade;`);
			await conn.query(vSQL);
			if (vDebug >= 1) {
				let RPT_VIEWER_CONTAINER_OTHER_COMPLETION_STAT_VW = await conn.query(`select * from ${schema}.RPT_VIEWER_CONTAINER_OTHER_COMPLETION_STAT_VW`);
				console.log(`RPT_VIEWER_CONTAINER_OTHER_COMPLETION_STAT_VW:`,tableToObjects(RPT_VIEWER_CONTAINER_OTHER_COMPLETION_STAT_VW));
			}
		}
		//ALL_VIEWER_CONTAINER_COMPLETION_STAT
		vSQL = `CREATE view ${schema}.RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW AS
			select v1.orgId,v1.uuid,v1.viewer,coalesce(v1.percent_viewership,0)::double as percent_viewership from ${schema}.RPT_VIEWER_CONTAINER_MEDIA_COMPLETION_STAT_VW v1
			union all
			select v2.orgId,v2.uuid,v2.viewer,coalesce(v2.percent_viewership,0)::double as percent_viewership from ${schema}.RPT_VIEWER_CONTAINER_OTHER_COMPLETION_STAT_VW v2
		`;
		if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
		if (action === `execute`) {
			await conn.query(`drop view if exists ${schema}.RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW cascade;`);
			await conn.query(vSQL);
			if (vDebug >= 1) {
				let RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW = await conn.query(`select * from ${schema}.RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW`);
				console.log(`RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW:`,tableToObjects(RPT_ALL_VIEWER_CONTAINER_COMPLETION_STAT_VW));
			}
		}


		}

	}

	await FNC_RPT_CONTAINER_OBJECT(schema,`execute`);

	async function FNC_RPT_USER (schema,action) {
	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	// create RPT_USER for container and content REPORTS
	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	// CONTENT_FILES_ACCESSED
	// CONTAINERS_ACCESSED
	// VIEWER_ACTIVITY
	// CONTENT_FILE_ACTIVITY
	// CONTENT_FILES_SENT
	// CONTAINERS_EXPIRED
	// SENT_NOT_ACCESSED
	// BLOCKED
	// ACCESS_DENIED
	// VIEWER_INFORMATION
	// DOWNLOADED_CONTENT
	// STORAGE_API_CALLS
		var vSQL = ``;
		if (schema === `CONTENT_FILES_ACCESSED`) {
			//CONTENT FILE,# VIEWERS,OPENS,DURATION,LAST ACCESSED *** Rollup by object
			vSQL =`create view ${schema}.RPT_CONTENT_FILES_ACCESSED_SUMMARY_VW as
				with cte_object as (
					select -- c.orgId,c.uuid,
						c.sender,
						--h.container_name,
						c.object,
						count(distinct(c.viewer)) as viewers,
						coalesce(av.authorized_viewers::text,'ANYONE WITH LINK') as authorized_viewers,
						sum(object_open) as object_open,
						sum(c.duration) as duration_seconds,
						max(c.ts) as last_accessed
					from ${schema}.OBJECT_EVENTS_STAT c
					left join
					     (select orgId,uuid,count(distinct(viewer)) as authorized_viewers from ${schema}.AUTHORIZED_VIEWER group by orgId,uuid) av
					     on c.orgId = av.orgId and c.uuid = av.uuid
					join ${schema}.EVENTS_HEADER h on c.orgId = h.orgId and c.uuid = h.uuid
					group by --c.orgId,c.uuid,
					c.sender, --h.container_name,
					c.object,av.authorized_viewers
				)
				select --c.orgId,c.uuid,
					c.sender,
					c.object, --as "CONTENT FILE",
					--c.container_name, -- as "CONTAINER NAME",
					c.viewers::int as viewers, -- as "# VIEWERS",
					c.authorized_viewers,
					c.object_open::int as object_open, --as "OPENS",
					duration_seconds as duration_seconds,
					(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as duration, --as "DURATION",
					c.last_accessed --as "LAST ACCESSED"
				from cte_object c
				where c.object is not null
				order by --c.orgId,c.uuid,
				c.object
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_CONTENT_FILES_ACCESSED_SUMMARY_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_CONTENT_FILES_ACCESSED_SUMMARY_VW = await conn.query(`select * from ${schema}.RPT_CONTENT_FILES_ACCESSED_SUMMARY_VW`);
						console.log(`RPT_CONTENT_FILES_ACCESSED_SUMMARY_VW:`,tableToObjects(RPT_CONTENT_FILES_ACCESSED_SUMMARY_VW));
					}
			}

			//CONTENT FILE	VIEWER	OPENS	DURATION	LAST ACCESSED *** Rollup by object ,viewer
			vSQL = `create view ${schema}.RPT_CONTENT_FILES_ACCESSED_DETAIL_VW as
				with cte_object as (
					select --c.orgId,
					    c.uuid,
					    c.sender,
					    c.object,
						h.container_name, h.sent_date,
						c.viewer,
						v.percent_viewership,
						sum(object_open) as object_open,sum(c.duration) as duration_seconds,
						--min(c.ts) as first_accessed,
						max(c.ts) as last_accessed
					from ${schema}.OBJECT_EVENTS_STAT c
					     left join ${schema}.RPT_ALL_COMPLETION_STAT_VW v on c.orgId = v.orgId and c.uuid = v.uuid and c.object = v.object and c.viewer = v.viewer
						 join ${schema}.EVENTS_HEADER h on c.orgId = h.orgId and c.uuid = h.uuid
					group by --c.orgId,
					         c.uuid,
					         c.sender,c.object,h.container_name,h.sent_date,c.viewer
							 ,v.percent_viewership
				)
				select --c.orgId,
				    c.uuid,
					c.sender,
					c.object, --as "CONTENT FILE",
					c.container_name,c.sent_date,
					c.viewer, -- as "VIEWER",
					c.percent_viewership,
					c.object_open::int as object_open, --as "OPENS",
					(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as duration, --as "DURATION",
					c.last_accessed --as "LAST ACCESSED"
				from cte_object c
				where c.object is not null
				order by --c.orgId,c.uuid,
				c.object,c.viewer,c.last_accessed desc
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_CONTENT_FILES_ACCESSED_DETAIL_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_CONTENT_FILES_ACCESSED_DETAIL_VW = await conn.query(`select * from ${schema}.RPT_CONTENT_FILES_ACCESSED_DETAIL_VW`);
						console.log(`RPT_CONTENT_FILES_ACCESSED_DETAIL_VW:`,tableToObjects(RPT_CONTENT_FILES_ACCESSED_DETAIL_VW));
					}
			}
		}
		if (schema === `CONTAINERS_ACCESSED`) {
			//CONTAINER NAME,# VIEWERS,OPENS,DURATION,DATE SENT,LAST ACCESSED
			vSQL = `create view ${schema}.RPT_CONTAINERS_ACCESSED_SUMMARY_VW as
				with cte_container as (
					select c.orgId,c.uuid,c.viewer,sum(c.container_open) as container_open
					from ${schema}.CONTAINER_EVENTS_STAT c
					group
						by c.orgId,c.uuid,c.viewer
				), cte_object as (
					select c.orgId,c.uuid,c.viewer,sum(c.duration) as duration,max(c.ts) as last_accessed, count(distinct(c.object)) as object_file_accessed_cnt
					from ${schema}.OBJECT_EVENTS_STAT c
					group
					   by c.orgId,c.uuid,c.viewer
				), cte_container_object as (
					select c.orgId,c.uuid,c.viewer,o.last_accessed,coalesce(c.container_open,0) as container_open,coalesce(o.duration,0) as duration_seconds,
					       coalesce(o.object_file_accessed_cnt,0) as object_file_accessed_cnt
					from cte_container c join cte_object o on c.orgId = o.orgId and c.uuid = o.uuid and c.viewer = o.viewer
				), cte_container_object_viewer as (
					select c.orgId,c.uuid,v.viewer,c.last_accessed,coalesce(c.container_open,0)::int as container_open,duration_seconds, c.object_file_accessed_cnt
						--(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as "DURATION"
					from cte_container_object c right join ${schema}.viewer v on c.orgId = v.orgId and c.uuid = v.uuid and c.viewer = v.viewer
				), cte_container_object_summary as (
					select  c.orgId,c.uuid,
							count(distinct(c.viewer)) as viewers,
							sum(c.container_open) as container_open,
							sum(c.duration_seconds) as duration_seconds,
							max(c.last_accessed) as last_accessed,
							sum(c.object_file_accessed_cnt) as object_file_accessed_cnt
					  from cte_container_object_viewer c
					 group
						by c.orgId,c.uuid
				)
				select   h.orgId
				        ,h.uuid
						,h.sender
				        ,h.container_name --as "CONTAINER NAME"
						,h.status
						,coalesce(av.authorized_viewers::text,'ANYONE WITH LINK') as authorized_viewers
						,c.viewers::int as viewers --as "# VIEWERS"
						,c.container_open::int as container_open --as "OPENS"
						,(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as duration --as "DURATION"
						,h.sent_date -- as "DATE SENT"
						,c.last_accessed  --as "LAST ACCESSED"
						,c.object_file_accessed_cnt
				  from cte_container_object_summary c
				  -- consider adding right join
				   right join ${schema}.EVENTS_HEADER h on h.orgId = c.orgId and h.uuid = c.uuid
				  left join (select orgId,uuid,count(distinct(viewer)) as authorized_viewers from ${schema}.AUTHORIZED_VIEWER group by orgId,uuid) av
				    on h.orgId = av.orgId and h.uuid = av.uuid
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_CONTAINERS_ACCESSED_SUMMARY_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_CONTAINERS_ACCESSED_SUMMARY_VW = await conn.query(`select * from ${schema}.RPT_CONTAINERS_ACCESSED_SUMMARY_VW`);
						console.log(`RPT_CONTAINERS_ACCESSED_SUMMARY_VW:`,tableToObjects(RPT_CONTAINERS_ACCESSED_SUMMARY_VW));
					}
			}

			//CONTAINER NAME,VIEWER,OPENS,DURATION,DATE SENT,LAST ACCESSED
			vSQL = `create view ${schema}.RPT_CONTAINERS_ACCESSED_DETAIL_VW as
				with cte_container as (
					select c.orgId,c.uuid,c.viewer,sum(c.container_open) as container_open
					from ${schema}.CONTAINER_EVENTS_STAT c
					group
						by c.orgId,c.uuid,c.viewer
				), cte_object as (
					select c.orgId,c.uuid,c.viewer,sum(c.duration) as duration,max(c.ts) as last_accessed
					from ${schema}.OBJECT_EVENTS_STAT c
					group
					   by c.orgId,c.uuid,c.viewer
				), cte_container_object as (
					select c.orgId,c.uuid,c.viewer,o.last_accessed,coalesce(c.container_open,0) as container_open,coalesce(o.duration,0) as duration_seconds
					from cte_container c join cte_object o on c.orgId = o.orgId and c.uuid = o.uuid and c.viewer = o.viewer
				), cte_container_object_viewer as (
					select c.orgId,c.uuid,v.viewer,c.last_accessed,coalesce(c.container_open,0)::int as container_open,duration_seconds
						--(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as "DURATION"
					from cte_container_object c right join ${schema}.viewer v on c.orgId = v.orgId and c.uuid = v.uuid and c.viewer = v.viewer
				), cte_container_object_summary as (
					select  c.orgId,c.uuid,
							count(distinct(c.viewer)) as viewers,
							sum(c.container_open) as container_open,
							sum(c.duration_seconds) as duration_seconds,
							max(c.last_accessed) as last_accessed
					  from cte_container_object_viewer c
					 group
						by c.orgId,c.uuid
				)
				select   h.sender
				        ,h.container_name -- as "CONTAINER NAME"
						,c.viewer -- as "VIEWERS"
						,c.container_open::int as container_open -- as "OPENS"
						,(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as duration --as "DURATION"
						,h.sent_date --as "DATE SENT"
						,c.last_accessed -- as "LAST ACCESSED"
				  from cte_container_object_viewer c join ${schema}.EVENTS_HEADER h on c.orgId = h.orgId and c.uuid = h.uuid
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_CONTAINERS_ACCESSED_DETAIL_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_CONTAINERS_ACCESSED_DETAIL_VW = await conn.query(`select * from ${schema}.RPT_CONTAINERS_ACCESSED_DETAIL_VW`);
						console.log(`RPT_CONTAINERS_ACCESSED_DETAIL_VW:`,tableToObjects(RPT_CONTAINERS_ACCESSED_DETAIL_VW));
					}
			}
		}

		if (schema === `VIEWER_ACTIVITY`) {
			//VIEWER,CONTENT FILE,OPENS,DURATION,DATE SENT,FIRST ACCESSED,LAST ACCESSED *** rollup by viewer,object
			vSQL = `create view ${schema}.RPT_VIEWER_ACTIVITY_VW as
					with cte_main as (
						select
							--v.orgId,
							--v.uuid,
							v.viewer,
							v.object,
							h.sent_date,
							sum(v.object_open) as object_open,
							--tk count(distinct(v.page)) as pages_viewed,
							sum(v.duration) as duration_seconds,
							min(v.ts) as first_accessed,
							max(v.ts) as last_accessed
						from ${schema}.OBJECT_EVENTS_STAT v join ${schema}.EVENTS_HEADER h on v.orgId = h.orgId and v.uuid = h.uuid
						group by --v.orgId,v.uuid,
						   v.viewer,v.object,h.sent_date
					)
					SELECT
					 q.viewer --as "VIEWER"
					,q.object --as "CONTENT FILE"
					,q.object_open::int as object_open --as "OPENS"
					--tk ,q.pages_viewed as "PAGES VIEWED"
					,(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as duration --as "DURATION"
					,q.sent_date --as "DATE SENT"
					,q.first_accessed --as "FIRST ACCESSED"
					,q.last_accessed --as "LAST ACCESSED"
					from cte_main q
					order by q.viewer,q.object,q.last_accessed desc
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_VIEWER_ACTIVITY_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_VIEWER_ACTIVITY_VW = await conn.query(`select * from ${schema}.RPT_VIEWER_ACTIVITY_VW`);
						console.log(`RPT_VIEWER_ACTIVITY_VW:`,tableToObjects(RPT_VIEWER_ACTIVITY_VW));
					}
			}
		}

		if (schema === `CONTENT_FILE_ACTIVITY`) {
			//VIEWER,CONTAINER NAME,CONTENT FILE,TIMESTAMP,ACTION,EVENT,FROM,TO	DATE SENT,IP ADDRESS,CITY,STATE/REGION,COUNTRY
			vSQL = `create view ${schema}.RPT_CONTENT_FILE_ACTIVITY_VW as
				SELECT
				 q.viewer --as "VIEWER"
				,q.container_name --as "CONTAINER NAME"
				,q.object --as "CONTENT FILE"
				,q.ts --as "TIMESTAMP"
				,q.action --as "ACTION"
				,q.event --as "EVENT"
				,q.from --as "FROM"
				,q.to --as "TO"
				,q.sent_date --as "DATE SENT"
				,q.ip --as "IP ADDRESS"
				,q.city --as "CITY"
				,q.region --as "STATE/REGION"
				,q.country --as "COUNTRY"
				from (
					select
					v.viewer,
					v.container_name,
					v.object,
					v.ts,
					v.action,
					v.event,
					v.from,
					v.to,
					v.sent_date,
					v.ip,
					v.city,
					v.region,
					v.country
					from ${schema}.events v join ${schema}.viewer vv on v.orgId = vv.orgId and v.uuid = vv.uuid and v.viewer = vv.viewer
				where v.action not in ('container.create')
				and v.viewer is not null
				--and v.object is not null
				) q
				order by viewer, ts
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_CONTENT_FILE_ACTIVITY_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_CONTENT_FILE_ACTIVITY_VW = await conn.query(`select * from ${schema}.RPT_CONTENT_FILE_ACTIVITY_VW`);
						console.log(`RPT_CONTENT_FILE_ACTIVITY_VW:`,tableToObjects(RPT_CONTENT_FILE_ACTIVITY_VW));
					}
			}
		}


// 'ALL_VIEWER_FLAG','DOMAIN_ANY_USER_FLAG',
// classify_viewers: 'USER_FLAG'
// classify_viewers: ALL_VIEWER_FLAG

		if (schema === `CONTENT_FILES_SENT`) {
			//CONTENT FILE,DATE SENT,AUTHORIZED VIEWER,OPENS,DURATION *** rollup by viewer,object
			vSQL = `create view ${schema}.RPT_CONTENT_FILES_SENT_VW as
					with cte_main_prep as (
						select
							h.orgId,
							h.uuid,
							h.sender,
							case when h.classify_viewers = 'ALL_VIEWER_FLAG' then 'ANYONE WITH LINK'
								 when h.classify_viewers = 'DOMAIN_ANY_USER_FLAG' then h.recipients
								 else v.viewer
							end as viewer,
							v.object,
							h.sent_date,
							v.object_open,
							--tk count(distinct(v.page)) as pages_viewed,
							v.duration,
							v.ts
						from ${schema}.OBJECT_EVENTS_STAT v right join ${schema}.EVENTS_HEADER h on v.orgId = h.orgId and v.uuid = h.uuid
					), cte_main as (
						select h.orgId,h.uuid,h.sender,h.viewer,h.object,h.sent_date,
						sum(h.object_open) as object_open,
						--tk count(distinct(h.page)) as pages_viewed,
						sum(h.duration) as duration_seconds,
						min(h.ts) as first_accessed,
						max(h.ts) as last_accessed
						  from cte_main_prep h
						  group by h.orgId,h.uuid,h.sender,h.viewer,h.object,h.sent_date
					), cte_viewer_object as (
						select distinct
						      v.orgId,v.uuid,
							  case when h.classify_viewers = 'ALL_VIEWER_FLAG' then 'ANYONE WITH LINK'
							       when h.classify_viewers = 'DOMAIN_ANY_USER_FLAG' then h.recipients
							       else v.viewer
							  end as viewer,
							  o.object_file as object, h.sender, h.sent_date
						 from ${schema}.VIEWER v --AUTHORIZED_VIEWER v
						 join ${schema}.objects o on v.orgId = o.orgId and v.uuid = o.uuid
						 join ${schema}.EVENTS_HEADER h on v.orgId = h.orgId and v.uuid = h.uuid
					)
					SELECT
					 vv.sender
					 ,vv.object -- as "CONTENT FILE"
					 ,vv.sent_date -- as "DATE SENT"
					 ,vv.viewer -- as "AUTHORIZED VIEWER"
					,q.object_open::int as object_open -- as "OPENS"
					--tk ,q.pages_viewed as "PAGES VIEWED"
					,duration_seconds
					,(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as duration --as "DURATION"
					--,q.first_accessed as "FIRST ACCESSED"
					--,q.last_accessed as "LAST ACCESSED"
					--from cte_main q right join ${schema}.AUTHORIZED_VIEWER vv on q.orgId = vv.orgId and q.uuid = vv.uuid and q.viewer = vv.viewer
					from cte_main q right join cte_viewer_object vv on q.orgId = vv.orgId and q.uuid = vv.uuid and q.viewer = vv.viewer and q.object = vv.object
					where vv.object is not null
					and vv.sender is not null
					order by vv.sent_date desc,vv.object,vv.viewer
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_CONTENT_FILES_SENT_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_CONTENT_FILES_SENT_VW = await conn.query(`select * from ${schema}.RPT_CONTENT_FILES_SENT_VW`);
						console.log(`RPT_CONTENT_FILES_SENT_VW:`,tableToObjects(RPT_CONTENT_FILES_SENT_VW));
					}
			}
		}

		if (schema === `CONTAINERS_EXPIRED`) {
			//CONTAINER NAME,DATE SENT,VIEWER,OPENS,LAST ACCESSED,DATE EXPIRED
			vSQL = `create view ${schema}.RPT_CONTAINERS_EXPIRED_VW as
					with old_cte_main as (
					SELECT
					 sender
					,container_name -- as "CONTAINER NAME"
					,sent_date -- as "DATE SENT"
					,viewer -- as "VIEWER"
					,opens -- as "OPENS"
					,last_accessed -- as "LAST ACCESSED"
					,container_end_ts -- as "DATE EXPIRED"
					from (
						select
						h.sender,
						h.container_name,
						h.sent_date,
						av.viewer,
						sum(e.opens) as opens,
						max(e.last_accessed) as last_accessed,
						h.container_end_ts
						from ${schema}.EVENTS_HEADER h
							left join ${schema}.AUTHORIZED_VIEWER av on av.orgId = h.orgId and av.uuid = h.uuid
							left join (select orgId,uuid,viewer,count(*) as opens, max(ts) as last_accessed from ${schema}.events where action = 'container.load' group by orgId,uuid,viewer) e
							on av.orgId = e.OrgId and av.uuid = e.uuid
						group by h.sender,h.container_name,h.sent_date,av.viewer,h.container_end_ts
					) q
					where sender is not null
					order by container_end_ts desc,container_name, viewer
					), cte_header_viewer as (
						select h.orgId,h.uuid,h.sender,h.container_name,h.sent_date,h.container_end_ts, v.viewer
						  from ${schema}.EVENTS_HEADER h left join ${schema}.AUTHORIZED_VIEWER v
						  on h.orgId = v.orgId and h.uuid = v.uuid
					),cte_main as (
						SELECT
						 sender
						,container_name -- as "CONTAINER NAME"
						,sent_date -- as "DATE SENT"
						,coalesce(viewer::text,'ANYONE WITH LINK') as viewer -- as "VIEWER"
						,opens -- as "OPENS"
						,last_accessed -- as "LAST ACCESSED"
						,container_end_ts -- as "DATE EXPIRED"
						from (
							select
							h.sender,
							h.container_name,
							h.sent_date,
							h.viewer,
							sum(e.opens) as opens,
							max(e.last_accessed) as last_accessed,
							h.container_end_ts
							from cte_header_viewer h
							 left join (select orgId,uuid,viewer,count(*) as opens, max(ts) as last_accessed from ${schema}.events where action = 'container.load' group by orgId,uuid,viewer) e
								on h.orgId = e.OrgId and h.uuid = e.uuid and h.viewer = e.viewer
							group by h.sender,h.container_name,h.sent_date,h.viewer,h.container_end_ts
						) q
						where sender is not null
						order by container_end_ts desc,container_name, viewer
					)
					select * from cte_main
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_CONTAINERS_EXPIRED_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_CONTAINERS_EXPIRED_VW = await conn.query(`select * from ${schema}.RPT_CONTAINERS_EXPIRED_VW`);
						console.log(`RPT_CONTAINERS_EXPIRED_VW:`,tableToObjects(RPT_CONTAINERS_EXPIRED_VW));
					}
			}
		}

		if (schema === `SENT_NOT_ACCESSED`) {
			//CONTENT FILE,DATE SENT,AUTHORIZED VIEWER,OPENS,DURATION
			vSQL = `create view ${schema}.RPT_SENT_NOT_ACCESSED_VW as
					with cte_ov as (
						select e.orgId,e.uuid,e.container_name,e.object_file as object,e.sent_date,v.viewer
						from ${schema}.objects e, ${schema}.AUTHORIZED_VIEWER v -- ${schema}.viewer v
						where e.orgId = v.orgId and e.uuid = v.uuid
					), cte_os as (
						select e1.id,e1.orgId,e1.uuid,e1.object,e1.viewer
							from ${schema}.EVENTS e1
						where trim(e1.action) = 'object.show'
					), cte_final as (
						select ov.*, os.viewer as os_viewer
						from cte_ov as ov left join cte_os os
							on os.orgId = ov.orgId
						and os.uuid = ov.uuid
						and os.object = ov.object
						and os.viewer = ov.viewer
						where os.id is null
					)
					select
					 h.sender
					,q.uuid
					,q.container_name --as "CONTAINER NAME"
					,q.object --as "CONTENT FILE"
					,h.status --as "STATUS"
					,q.sent_date --as "DATE SENT"
					,q.viewer --as "VIEWER"
					from cte_final q, ${schema}.EVENTS_HEADER h where q.orgId = h.orgId and q.uuid = h.uuid
					order by q.sent_date desc, q.container_name, q.object, q.viewer
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_SENT_NOT_ACCESSED_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_SENT_NOT_ACCESSED_VW = await conn.query(`select * from ${schema}.RPT_SENT_NOT_ACCESSED_VW`);
						console.log(`RPT_SENT_NOT_ACCESSED_VW:`,tableToObjects(RPT_SENT_NOT_ACCESSED_VW));
					}
			}
		}

		if (schema === `BLOCKED`) {
			//CONTAINER NAME	STATUS	DATE SENT	RECIPIENTS	ACTION	REASON	ACTION DATE
			vSQL = `create view ${schema}.RPT_BLOCKED_VW as
					with cte_prep_blocked as (
						select row_number() over () as id, q.*
						from (
								select
								h.orgId, h.uuid, h.recipients, h.container_name, h.sender, h.sent_date
								,e.reason, e.ts, e.action, h.status
								from ${schema}.EVENTS_TEMP e join ${schema}.EVENTS_HEADER h on e.orgId = h.orgId and e.uuid = h.uuid
								where e.action in ('container.block','container.unblock')
								order by h.orgId, h.uuid,e.ts
							) q
					), cte_details as (
						select distinct
						b.container_name --as "CONTAINER NAME"
						--,b.sender as "SENDER"
						,b.status --as "STATUS"
						,b.sent_date-- as "DATE SENT"
						,b.recipients --as "RECIPIENTS"
						,b.action --as "ACTION"
						,b.reason --as "REASON"
						,b.ts --as "ACTION DATE"
						from cte_prep_blocked b
					)
					select * from cte_details order by container_name,ts --"CONTAINER NAME","ACTION DATE"
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_BLOCKED_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_BLOCKED_VW = await conn.query(`select * from ${schema}.RPT_BLOCKED_VW`);
						console.log(`RPT_BLOCKED_VW:`,tableToObjects(RPT_BLOCKED_VW));
					}
			}
		}

		if (schema === `ACCESS_DENIED`) {
			//CONTAINER NAME	VIEWER	IP ADDRESS	CITY	STATE	COUNTRY	ATTEMPTS	FIRST ATTEMPT	LAST ATTEMPT	DATE SENT
			vSQL = `create view ${schema}.RPT_ACCESS_DENIED_VW as
					select 	 v.container_name --as "CONTAINER NAME"
							,v.viewer --as "VIEWER"
							,v.ip --as "IP ADDRESS"
							,v.city --as "CITY"
							,v.region as state --as "STATE"
							,v.country --as "COUNTRY"
							,v.reason
							,count(*) as attempts --as"ATTEMPTS"
							,min(v.ts) as first_attempt --as "FIRST ATTEMPT"
							,max(v.ts) as last_attempt --as  "LAST ATTEMPT"
							,v.sent_date --as "DATE SENT"
						from ${schema}.EVENTS v
						where trim(lower(v.action)) in ('drm.fail', 'decrypt.fail')
						group
						by v.container_name,v.viewer,v.ip,v.city,v.region,v.country,v.reason,v.sent_date
						order
						by v.sent_date,max(v.ts) desc, v.container_name
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_ACCESS_DENIED_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_ACCESS_DENIED_VW = await conn.query(`select * from ${schema}.RPT_ACCESS_DENIED_VW`);
						console.log(`RPT_ACCESS_DENIED_VW:`,tableToObjects(RPT_ACCESS_DENIED_VW));
					}
			}
		}

		if (schema === `VIEWER_INFORMATION`) {
			//VIEWER	IP ADDRESS	CITY	STATE	COUNTRY	FIRST ACCESSED	LAST ACCESSED
			vSQL = `create view ${schema}.RPT_VIEWER_INFORMATION_VW as
					SELECT q.*
					from (
						select
						 vw.viewer --as "VIEWER"
						 ,vw.ip --as "IP ADDRESS"
						 ,vw.city --as "CITY"
						 ,vw.state --as "STATE"
						 ,vw.country --as "COUNTRY"
						--v.object as "CONTENT FILE",v.sent_date as "DATE SENT",sum(v.cnt) as "OPENS",count(v.page) as "PAGES VIEWED"
						--round(sum(v.duration_seconds)/60.0,2) as "DURATION(MIN)"
						,min(v.ts) as first_accessed --as "FIRST ACCESSED"
						,max(v.ts) as last_accessed --as "LAST ACCESSED"
						from ${schema}.viewer vw, ${schema}.OBJECT_EVENTS_STAT v
						where vw.orgId = v.orgId and vw.uuid = v.uuid and vw.viewer = v.viewer
						group by vw.viewer,vw.ip,vw.city,vw.state,vw.country
					) q
					where ip is not null --"IP ADDRESS" is not null -- tk
					order by  viewer, last_accessed desc --"VIEWER", "LAST ACCESSED" desc
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_VIEWER_INFORMATION_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_VIEWER_INFORMATION_VW = await conn.query(`select * from ${schema}.RPT_VIEWER_INFORMATION_VW`);
						console.log(`RPT_VIEWER_INFORMATION_VW:`,tableToObjects(RPT_VIEWER_INFORMATION_VW));
					}
			}
		}

		if (	schema === `DOWNLOADED_CONTENT`
				|| schema === `VIEWER_AUDIT`
				|| schema === `CRM_EVENT_EXPORT`
				|| schema === `CONTENT_FILE_AUDIT`
				|| schema === `VIEWER_ACTIVITY`
				|| schema === `CONTAINER_OBJECT`
				|| schema === `CONTENT_FILES_ACCESSED`
			)
		{
			//CONTENT FILE	VIEWERS	DOWNLOADS
			vSQL = `create view ${schema}.RPT_DOWNLOADED_CONTENT_SUMMARY_VW as
					SELECT q.*
					from (
						select
						 e.orgId
						,e.uuid
						,e.object -- as "CONTENT FILE"
						,count(distinct(e.viewer))::int as viewer --as "VIEWERS"
						,count(*)::int as download -- as "DOWNLOADS"
						from ${schema}.EVENTS e
						where e.object is not null
						and e.action = 'object.download'
						group by e.orgId,e.uuid,e.object
						order by e.object
					) q
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_DOWNLOADED_CONTENT_SUMMARY_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_DOWNLOADED_CONTENT_SUMMARY_VW = await conn.query(`select * from ${schema}.RPT_DOWNLOADED_CONTENT_SUMMARY_VW`);
						console.log(`RPT_DOWNLOADED_CONTENT_SUMMARY_VW:`,tableToObjects(RPT_DOWNLOADED_CONTENT_SUMMARY_VW));
					}
			}
			//CONTENT FILE	VIEWER	TIMESTAMP
			vSQL = `create view ${schema}.RPT_DOWNLOADED_CONTENT_DETAIL_VW as
					SELECT q.*
					from (
						select
						 e.orgId
						,e.uuid
						,e.object --as "CONTENT FILE"
						,e.sender
						,e.viewer --as "VIEWER"
						,e.ts --as "TIMESTAMP"
						from ${schema}.EVENTS e
						where e.object is not null
						and e.action = 'object.download'
						order by e.object, e.viewer, e.ts
					) q
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_DOWNLOADED_CONTENT_DETAIL_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_DOWNLOADED_CONTENT_DETAIL_VW = await conn.query(`select * from ${schema}.RPT_DOWNLOADED_CONTENT_DETAIL_VW`);
						console.log(`RPT_DOWNLOADED_CONTENT_DETAIL_VW:`,tableToObjects(RPT_DOWNLOADED_CONTENT_DETAIL_VW));
					}
			}


			vSQL = `CREATE view ${schema}.RPT_VIEWER_AUDIT_VW AS
			with cte_main as (
				select v.orgId, v.uuid,
				v."VIEWER",
				v."CONTENT FILE",
				h.sent_date,
				count(distinct(v."PAGE")) as "PAGES",
				sum(v.duration_seconds)::int as duration_seconds,
				min(v."FIRST ACCESSED") as "FIRST ACCESSED",
				max(v."LAST ACCESSED") as "LAST ACCESSED"
				from ${schema}.rpt_all_stat_vw v join ${schema}.EVENTS_HEADER h on v.orgId = h.orgId and v.uuid = h.uuid
				 group by v.orgId, v.uuid,v."VIEWER",v."CONTENT FILE",h.sent_date
			), cte_unopened_files as (
				select oo.*
				  from (
						select v.orgId, v.uuid,v.viewer as "VIEWER",o.object_file as "CONTENT FILE", o.sent_date as "DATE SENT"
							from ${schema}.viewer v join ${schema}.objects o on v.orgId = o.orgId and v.uuid = o.uuid
					   ) oo
				where not exists (select 1 from cte_main m where m.orgId = oo.orgId and m.uuid = oo.uuid and m."CONTENT FILE" = oo."CONTENT FILE" and m."VIEWER" = oo."VIEWER")
			), cte_viewer_info as (
				select distinct v.viewer,v.city,v.state,v.country
				  from (
						select viewer,max(ip) as ip
						from ${schema}.viewer
						group by viewer
						) q, ${schema}.viewer v
				where q.viewer = v.viewer and q.ip = v.ip
			), cte_download_cnt as (
				select orgId,uuid,object,viewer,count(object) as downloads
				from ${schema}.RPT_DOWNLOADED_CONTENT_DETAIL_VW
				group by orgId,uuid,object,viewer
			), cte_final as (
				select
				m.orgId,
				m.uuid,
				m."VIEWER",
				m."CONTENT FILE",
				m.sent_date as "DATE SENT",
				(round(c.percent_viewership,2))::text||'%' as "% COMPLETION",
				m."PAGES" as "PAGES VIEWED",
				coalesce(d.downloads,0) as "DOWNLOADS",
				duration_seconds::int as duration_seconds,
				(duration_seconds/3600)::text||':'||lpad((duration_seconds%3600/60)::text,2,'0')||':'||lpad((duration_seconds%3600%60)::text,2,'0') as "DURATION",
				m."FIRST ACCESSED",
				m."LAST ACCESSED"
			  from cte_main m join ${schema}.RPT_ALL_COMPLETION_STAT_VW c on m.orgId = c.orgId and m.uuid = c.uuid and m."CONTENT FILE" = c.object and m.viewer = c.viewer
				left join cte_download_cnt d on m.orgId = d.orgId and m.uuid = d.uuid and m."CONTENT FILE" = d.object and m.viewer = d.viewer
			  union all
			  select
				  u.orgId,
				  u.uuid,
				  u."VIEWER",
				u."CONTENT FILE",
				u."DATE SENT",
				null::text as "% COMPLETION",
				null::int "PAGES VIEWED",
				null::int as "DOWNLOADS",
				null::int as duration_seconds,
				null::text as "DURATION",
				null as "FIRST ACCESSED",
				null as "LAST ACCESSED"
				from cte_unopened_files u
			)
			select c."VIEWER",c."CONTENT FILE",c."DATE SENT",c."% COMPLETION",c."PAGES VIEWED",c.duration_seconds
				,case when h.downloadable = 'no' then 'N/A' else c."DOWNLOADS"::text end as "DOWNLOADS"
				,c."DURATION",c."FIRST ACCESSED",c."LAST ACCESSED"
				,v.city as "CITY", v.state as "STATE", v.country as "COUNTRY"
			from cte_final c left join cte_viewer_info v on --c.orgId = v.orgId and c.uuid = v.uuid and
				 c."VIEWER" = v.viewer
				 join ${schema}.EVENTS_HEADER h on c.orgId = h.orgId and c.uuid = h.uuid
			 order by lower(c."VIEWER"),lower(c."CONTENT FILE"),c."DATE SENT",c."FIRST ACCESSED"
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
				await conn.query(`drop view if exists ${schema}.RPT_VIEWER_AUDIT_VW cascade;`);
				await conn.query(vSQL);
				if (vDebug >= 1) {
					let RPT_VIEWER_AUDIT_VW = await conn.query(`select * from ${schema}.RPT_VIEWER_AUDIT_VW`);
					console.log(`RPT_VIEWER_AUDIT_VW:`,tableToObjects(RPT_VIEWER_AUDIT_VW));
				}
			}

		}

		if (schema === `STORAGE_API_CALLS`) {
			//COMPANY	Month	# CONTAINERS	Size in GB
			vSQL = `create view ${schema}.RPT_STORAGE_STAT_VW as
					SELECT   org_name -- as "COMPANY"
							,dt as month --as "Month"
							,cnt as containers --as "# CONTAINERS"
							,round(size_bytes/(1024.0*1024.0*1024.0),4) as size_in_gb --as "Size in GB"
					from ${schema}.org_stats
					where source = 'containers'
					order by 1,2
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_STORAGE_STAT_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_STORAGE_STAT_VW = await conn.query(`select * from ${schema}.RPT_STORAGE_STAT_VW`);
						console.log(`RPT_STORAGE_STAT_VW:`,tableToObjects(RPT_STORAGE_STAT_VW));
					}
			}
			//COMPANY	Month	# API CALLS	Size in GB
			vSQL = `create view ${schema}.RPT_API_CALLS_VW as
					SELECT  org_name as "COMPANY",
							dt as "Month",
							cnt as "# API CALLS",
							round(size_bytes/(1024.0*1024.0*1024.0),4) as "Size in GB"
					from ${schema}.org_stats
					where source = 'events'
					order by 1,2
			`;
			if (vDebug >= 1) {console.log(`vSQL:`,vSQL)}
			if (action === `execute`) {
					await conn.query(`drop view if exists ${schema}.RPT_API_CALLS_VW cascade;`);
					await conn.query(vSQL);
					if (vDebug >= 1) {
						let RPT_API_CALLS_VW = await conn.query(`select * from ${schema}.RPT_API_CALLS_VW`);
						console.log(`RPT_API_CALLS_VW:`,tableToObjects(RPT_API_CALLS_VW));
					}
			}

		}

	}
	await FNC_RPT_USER(schema,`execute`);
	// await FNC_RPT_USER(schema,`CONTENT_FILES_ACCESSED`,`execute`);
	// await FNC_RPT_USER(schema,`CONTAINERS_ACCESSED`,`execute`);
	// await FNC_RPT_USER(schema,`VIEWER_ACTIVITY`,`execute`);
	// await FNC_RPT_USER(schema,`CONTENT_FILE_ACTIVITY`,`execute`);
	// await FNC_RPT_USER(schema,`CONTENT_FILES_SENT`,`execute`);
	// await FNC_RPT_USER(schema,`CONTAINERS_EXPIRED`,`execute`);
	// await FNC_RPT_USER(schema,`SENT_NOT_ACCESSED`,`execute`);
	// await FNC_RPT_USER(schema,`BLOCKED`,`execute`);
	// await FNC_RPT_USER(schema,`ACCESS_DENIED`,`execute`);
	// await FNC_RPT_USER(schema,`VIEWER_INFORMATION`,`execute`);
	// await FNC_RPT_USER(schema,`DOWNLOADED_CONTENT`,`execute`);
	//await FNC_RPT_USER(schema,`STORAGE_API_CALLS`,`execute`);

	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	//write stat data into json
	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	async function write_json (schema) {
		// let container_stat_vw = await conn.query(`select * from ${schema}.container_stat_vw;`);
		// //await fs.writeFile("container_stat_vw.json", JSON.stringify(container_stat_vw));

		// let object_other_stat_vw = await conn.query(`select * from ${schema}.object_other_stat_vw;`);
		// //await fs.writeFile("object_other_stat_vw.json", JSON.stringify(object_other_stat_vw));

		// let object_all_stat_vw = await conn.query(`select * from ${schema}.object_all_stat_vw;`);
		// //await fs.writeFile("object_all_stat_vw.json", JSON.stringify(object_all_stat_vw));

		// let viewer_object_pdf_page_duration_vw = await conn.query(`select * from ${schema}.viewer_object_pdf_page_duration_vw;`);
		// //await fs.writeFile("viewer_object_pdf_page_duration_vw.json", JSON.stringify(viewer_object_pdf_page_duration_vw));

		// let object_pdf_page_duration_vw = await conn.query(`select * from ${schema}.object_pdf_page_duration_vw;`);
		// //await fs.writeFile("object_pdf_page_duration_vw.json", JSON.stringify(object_pdf_page_duration_vw));

		return null;

	};

	//await write_json(schema);

	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	//print counts
	//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	async function print_counts (schema) {

		// console.log(`########### Print Tables Counts ###########`);
		// let EVENTS_TEMP_CNT = await conn.query(`select count(*) as EVENTS_TEMP_CNT from ${schema}.EVENTS_TEMP;`);
		// console.log(EVENTS_TEMP_CNT);

		// let EVENTS_CNT = await conn.query(`select count(*) as EVENTS_CNT from ${schema}.EVENTS;`);
		// console.log(EVENTS_CNT);

		// let VIEWER_CNT = await conn.query(`select count(*) as viewer_CNT from ${schema}.viewer;`);
		// console.log(VIEWER_CNT);

		// let OBJECTS_CNT = await conn.query(`select count(*) as OBJECTS_CNT from ${schema}.OBJECTS;`);
		// console.log(OBJECTS_CNT);
		return null;
	};
	//await print_counts(schema);

	// end of lt_analytics_client_main
	return conn;

};
