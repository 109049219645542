// called from lt_analytics_client.js
export class lt_api_client {
	constructor(orgid, token) {
		this.orgid = orgid;
		this.token = token;
	}
	async fetch (url, request) {
		return await fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
			...request,
			headers: {
				...request?.headers,
				Authorization: `Bearer ${this.token}`
			},
			credentials: "include",
		})
	}
	query (object_values) {
		return Object.entries(object_values).map(
			([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
		).join('&')
	}
	//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
	//Fetch events for orgid,uuid from server, return csv
	//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
	async fetch_events_by_orgid_uuid (uuid, start_time, end_time, sort_order, rec_limit) {
		const args = {}
		if (start_time) args.start = start_time
		if (end_time) args.end = end_time
		if (sort_order) args.order = sort_order
		if (rec_limit) args.limit = rec_limit
		const url = `file_events/org_id/${this.orgid}/uuid/${uuid}?${this.query(args)}`;
		const response = await this.fetch(url);

		const csv = await response.text();
		//console.log(csv);
		//await fs.writeFile("events.csv", csv);
		return csv;
	};

	// // need an api function to fetch events for orgId on all files accessed last 30 days
	// select * from events where ts between current_date -30 + current Date;
	async fetch_events_by_orgid_access (start_time, end_time, rec_limit) {
		const url = `container_events_by_access/org_id/${this.orgid}?${this.query({
			start_time, end_time,
			limit: rec_limit
		})}`;
		const response = await this.fetch(url);

		const csv = await response.text();
		//console.log(csv);
		//await fs.writeFile("events.csv", csv);
		return csv;
	}

	async fetch_events_for_landing_page (start_time, end_time, rec_limit) {
		const url = `container_events_for_landing_page/org_id/${this.orgid}?${this.query({
			start_time, end_time,
			limit: rec_limit
		})}`;
		const response = await this.fetch(url);

		const csv = await response.text();
		//console.log(csv);
		//await fs.writeFile("events.csv", csv);
		return csv;
	}

	// // need an api function to fetch events for orgId on all files sent last 30 days
	// select * from events where uuid in
	// (select uuid from events where action = 'container.create' and ts between current_date -30 + current Date);
	async fetch_events_by_orgid_create (start_time, end_time, rec_limit) {
		const url = `container_events_by_create/org_id/${this.orgid}?${this.query({
			start_time, end_time,
			limit: rec_limit
		})}`;
		const response = await this.fetch(url);

		const csv = await response.text();
		//console.log(csv);
		//await fs.writeFile("events.csv", csv);
		return csv;
	};

	// // need an api function to fetch events for orgId on all files expired last 30 days
	// select * from events where uuid in
	// (select uuid from events where action = 'container.create' and end_date between current_date -30 + current Date);
	// // need an api function to fetch events for orgId on all files that will expire in 7 days
	// select * from events where uuid in
	// (select uuid from events where action = 'container.create' and end_date between current_date + current Date + 7);
	async fetch_events_by_orgid_expire (start_time, end_time, rec_limit) {
		const url = `container_events_by_expire/org_id/${this.orgid}?${this.query({
			start_time, end_time,
			limit: rec_limit
		})}`;
		const response = await this.fetch(url);

		const csv = await response.text();
		//console.log(csv);
		//await fs.writeFile("events.csv", csv);
		return csv;
	};

	// // need an api function to fetch events for orgId on all files blocked last 30 days
	// select * from events where uuid in
	// (select uuid from events where action = 'container.block' and ts between current_date -30 + current Date);
	async fetch_events_by_orgid_block (start_time, end_time, rec_limit) {
		const url = `container_events_by_block/org_id/${this.orgid}?${this.query({
			start_time, end_time,
			limit: rec_limit
		})}`;
		const response = await this.fetch(url);

		const csv = await response.text();
		//console.log(csv);
		//await fs.writeFile("events.csv", csv);
		return csv;
	};

	// // need an api function to fetch events for orgId on all failed access attempt last 30 days
	// select container_file,ip,count(*) as attempt, min(ts) as first_attempt_access, max(ts) as last_attempt_access
	//   from events
	//  where action = 'drm.fail' and ts between current_date -30 + current Date
	//  group by container_file, ip;
	async fetch_events_by_orgid_fail (start_time, end_time, rec_limit) {
		const url = `container_events_by_fail/org_id/${this.orgid}?${this.query({
			start_time, end_time,
			limit: rec_limit
		})}`;
		const response = await this.fetch(url);

		const csv = await response.text();
		//console.log(csv);
		//await fs.writeFile("events.csv", csv);
		return csv;
	};

	async fetch_org_stats (start_time, end_time) {
		const url = `org_stats/org_id/${this.orgid}`;
		const response = await this.fetch(url);

		const csv = await response.text();
		return csv;
	}
};

//  // think about the analytics summary to store into cosmos, then fetch or calculate if change
