import React, { useMemo } from "react";
import { getApi } from "../../api";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DataGrid from "../../components/DataGrid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  AccountCircle,
  AdminPanelSettings,
  Delete,
  Edit,
  ExpandMore,
  Face,
  Groups,
  GroupsOutlined,
  Label,
  Person,
  StraightenSharp,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomSnackbar, { Severity } from "../../components/general/snackbar";
import { OrgContext } from "../../context/OrgContext";
import { encode as base64Encode } from "../../utils/base64";
import SearchIcon from "@mui/icons-material/Search";
import * as csvjson from "csvjson";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CSVFileDrop from "../../components/files/CSVFileDrop";
import downloadBlob from "../../utils/downloadBlob";

const import_template = `data:text/plain;base64,${base64Encode(
  new TextEncoder().encode(
    `Email,First Name,Last Name,Mobile Phone,Company\r\njohndoe@example.com,John,Doe,555-555-5555,Locktera`
  ),
  true
)}`;

const AddressBook: React.FC = () => {
  const { org, api } = React.useContext(OrgContext);

  const [addresses, set_addresses] = React.useState<any[]>([]);
  const [mode, set_mode] = React.useState<"add" | "edit">("add");
  const [expanded, set_expanded] = React.useState(false);
  //const [name, set_name] = React.useState('');
  const [first_name, set_first_name] = React.useState("");
  const [last_name, set_last_name] = React.useState("");
  const [email, set_email] = React.useState("");
  const [mobile_phone, set_mobile_phone] = React.useState("");
  const [company, set_company] = React.useState("");
  const [csv_file, set_csv_file] = React.useState<File | null>();
  const [csv_errors, set_csv_errors] = React.useState<string[]>([]);
  const [addressFilter, setAddressFilter] = React.useState<
    (file: any) => boolean
  >(() => (file: any) => true);

  const [dialog_open, set_dialog_open] = React.useState(false);

  const filteredAddresses = useMemo(() => {
    //console.log("Addresses Filter: ", addressFilter);
    //return addresses.filter(addressFilter);
    return addresses.filter(addressFilter).sort((a, b) => a.email.localeCompare(b.email));
  }, [addresses, addressFilter]);
  const [omniSearch, setOmniSearch] = React.useState<string>("");

  const load_addresses = async () => {
    let new_addresses = await api!.get_org_addresses();
    // console.log(new_addresses);

    set_addresses(new_addresses);
  };

  const columns: GridColDef[] = [
    //{ field: 'id', headerName: 'ID', width: 90 },
    {
      field: "email",
      headerName: "EMAIL",
      width: 200,
    },
    {
      field: "first_name",
      headerName: "FIRST NAME",
      width: 150,
    },
    {
      field: "last_name",
      headerName: "LAST NAME",
      width: 150,
    },
    {
      field: "mobile_phone",
      headerName: "MOBILE PHONE",
      width: 150,
    },
    {
      field: "company",
      headerName: "COMPANY",
      width: 150,
    },
    {
      field: "id",
      headerName: "EDIT",
      disableExport: true,
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            set_mode("edit");
            set_email(params.row.email);
            set_first_name(params.row.first_name);
            set_last_name(params.row.last_name);
            set_mobile_phone(params.row.mobile_phone);
            set_company(params.row.company);
            set_expanded(true);
            set_dialog_open(true)
          }}
        >
          <Edit></Edit>
        </IconButton>
      ),
    },
    {
      field: "delete",
      headerName: "DELETE",
      disableExport: true,
      renderCell: (params) => (
        <IconButton
          onClick={async (e) => {
            if (
              !window.confirm("Are you sure you want to delete this contact?")
            )
              return;
            try {
              await api!.delete_address(params.row.email);
              show_snack_bar("success", "Address Deleted Successfully");
              load_addresses();
              load_logs();
            } catch (err: any) {
              show_snack_bar("error", `${err.message}`);
            }
          }}
        >
          <DeleteIcon></DeleteIcon>
        </IconButton>
      ),
    },
  ];

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarSeverity, setSnackBarSeverity] =
    React.useState<Severity>("success");
  const [snackBarMessage, setSnackBarMessage] = React.useState("");

  function show_snack_bar(severity: Severity, message: string) {
    setSnackBarSeverity(severity);
    setSnackBarMessage(message);
    setSnackBarOpen(true);
  }

  React.useEffect(() => {
    if (!api) return;
    load_addresses();
  }, [api]);

  function reset_form() {
    set_expanded(false);
    set_mode("add");
    //set_name('');
    set_first_name("");
    set_last_name("");
    set_mobile_phone("");
    set_email("");
    set_company("");
    set_dialog_open(false)
  }

  async function save() {
    if (!last_name?.trim()) {
      show_snack_bar("error", "Last name is required");
      return;
    }

    if (!first_name?.trim()) {
      show_snack_bar("error", "First name is required");
      return;
    }

    if (!email?.trim()) {
      show_snack_bar("error", "Email is required");
      return;
    }

    try {
      await api!.save_address(
        email,
        first_name,
        last_name,
        mobile_phone,
        company
      );
      reset_form();
      show_snack_bar("success", "Address Saved Successfully");
      load_addresses();
      load_logs();
    } catch (err: any) {
      show_snack_bar("error", `${err.message}`);
    }
    set_dialog_open(false)
  }

  async function import_csv(csv_file: File) {
    try {
      const data = await csv_file!.text();
      const lines = csvjson.toObject(data);
      const numberOfContacts = lines.length;

      for (const line of lines) {
        const requiredFields = [
          "Email",
          "First Name",
          "Last Name",
          "Mobile Phone",
          "Company",
        ];

        if (Object.values(line).some((value: unknown) => (value as string).trim() === "")) {
          throw new Error(
            "Missing data."
          );
        }

        if (requiredFields.some((field) => !line[field])) {
          throw new Error("Matching error. Please see sample file.");
        }

      }

      await api!.import_addresses(data);
      show_snack_bar("success", `${numberOfContacts} contact(s) successfully imported`);
      set_csv_file(null);
      load_addresses();
    } catch (err: any) {
      show_snack_bar("error", `${err.message}`);
    }
  }

  function fileMatchesEmail(email: string) {
    return (address: any) => address.email.toLowerCase().includes(email);
  }

  function fileMatchesFirstName(firstName: string) {
    return (address: any) =>
      address.first_name.toLowerCase().includes(firstName);
  }

  function fileMatchesLastName(lastName: string) {
    return (address: any) => address.last_name.toLowerCase().includes(lastName);
  }

  function fileMatchesMobilePhone(mobilePhone: string) {
    return (address: any) =>
      address.mobile_phone.toLowerCase().includes(mobilePhone);
  }

  function fileMatchesCompany(company: string) {
    return (address: any) => address.company.toLowerCase().includes(company);
  }

  async function doOmniSearch(newSearch: string) {
    setOmniSearch(newSearch);
    const keep = (file: any) => true;

    if (newSearch.length === 0) {
      setAddressFilter(() => keep);
      return;
    }

    newSearch = newSearch.toLowerCase();

    //console.log("addresses", addresses);

    const addressEmailFilter = fileMatchesEmail(newSearch);

    const firstNameFilter = fileMatchesFirstName(newSearch);

    const lastNameFilter = fileMatchesLastName(newSearch);

    const mobilePhoneFilter = fileMatchesMobilePhone(newSearch);

    const companyFilter = fileMatchesCompany(newSearch);

    setAddressFilter(
      () => (address: any) =>
        addressEmailFilter(address) ||
        firstNameFilter(address) ||
        lastNameFilter(address) ||
        mobilePhoneFilter(address) ||
        companyFilter(address)
    );
  }

  const [date_range, set_date_range] = React.useState(DateRange.All)
  const [logs, setLogs] = React.useState<any[]>([])

  async function load_logs() {
    const [start, end] = range_to_dates(date_range)
    const logs = await api!.get_address_audit(start, end);
    setLogs(map_logs(logs, {
      first_name: 'First Name',
      last_name: 'Last Name',
      mobile_phone: 'Mobile Phone',
      company: 'Company',
    }));
  }

  React.useEffect(() => {
    console.log(api)
    if (api) load_logs()
  }, [api, date_range]);

  function map_logs(logs: any[], field_names: Record<string, string | undefined>) {
    return logs.map(log => {
      const id = log.id;
      const ts = new Date(log.ts).toLocaleString();
      const email = log.new_data?.email ?? log.old_data?.email;
      const action = { i: 'Create', u: 'Update', d: 'Delete' }[log.action as 'i' | 'u' | 'd'];

      let changes = '';
      for (const [key, value] of Object.entries(log.new_data ?? {})) {
        const field = field_names[key];
        if (!field) continue;

        if (value != log.old_data?.[key]) {
          if (changes.length > 0) {
            changes += '; ';
          }
          changes += `${field}: ${value}`;
        }
      }

      return {
        id,
        ts,
        email,
        action,
        changes,
      };
    }).filter(log => !(log.action === "Update" && log.changes === ''));
  }

  function downloadCSVAddress() {
    const formatted = filteredAddresses.map((address) => {
      return {
        Email: address.email,
        "First Name": address.first_name,
        "Last Name": address.last_name,
        "Mobile Phone": address.mobile_phone,
        Company: address.company,
      }
    })
    const csv = csvjson.toCSV(formatted, { headers: "key" })
    const blob = new Blob([csv], { type: "text/csv" })
    downloadBlob(blob, "Addresses.csv")
  }

  //return <><pre><code>{JSON.stringify(users)}</code></pre></>
  return (
    <>

      <Button variant="contained" onClick={() => { reset_form(); set_dialog_open(true) }}>
        Add New Contact
      </Button>

      <Dialog
        open={dialog_open}
        onClose={() => set_dialog_open(false)}
        maxWidth={mode == "edit" ? 'sm' : 'md'}
      >
        {/* <Container disableGutters> */}
        <DialogTitle variant="h4" sx={{ marginTop: 1, marginBottom: 1, color: "#2B5592" }}>
          {mode === "add" ? "Add New Contact" : "Edit Contact"}
        </DialogTitle>
        <DialogContent>
          <Grid2 container spacing={1}>
            <Grid2 md={mode == "edit" ? 12 : 6} xs={12}>
              <>
                <FormControl fullWidth sx={{ margin: 0 }}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    required
                    disabled={mode === "edit"}
                    value={email}
                    onChange={(e) => set_email(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ margin: 0 }}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    required
                    value={first_name}
                    onChange={(e) => set_first_name(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ margin: 0 }}>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    required
                    value={last_name}
                    onChange={(e) => set_last_name(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ margin: 0 }}>
                  <TextField
                    label="Mobile Phone"
                    variant="outlined"
                    value={mobile_phone}
                    onChange={(e) => set_mobile_phone(e.target.value)}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ margin: 0 }}>
                  <TextField
                    label="Company"
                    variant="outlined"
                    value={company}
                    onChange={(e) => set_company(e.target.value)}
                  />
                </FormControl>
                <div
                  style={{
                    display: "grid",
                    columnGap: "8px",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  <Button variant="outlined" onClick={(e) => reset_form()}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={(e) => save()}>
                    Save
                  </Button>
                </div>
              </>
            </Grid2>
            {mode != "edit" &&
              <Grid2
                md={6}
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CSVFileDrop importCSV={import_csv} onError={(error) => { show_snack_bar("error", error.message) }}></CSVFileDrop>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: "20px" }}
                  download="locktera_addresses.csv"
                  href={import_template}
                >
                  DOWNLOAD SAMPLE CSV
                </Button>
              </Grid2>
            }
          </Grid2>
        </DialogContent>
      </Dialog>
      {/* <TextField
        value={omniSearch}
        onChange={(event) => {
          doOmniSearch(event.target.value);
        }}
        placeholder="Search..."
        variant="outlined"
        style={{ marginRight: "10px", width: "100%" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      /> */}
      <Box sx={{ height: 600, marginBottom: 1, width: "100%" }}>
        <DataGrid rows={filteredAddresses} columns={columns} />

      </Box>
      {/* <Button onClick={downloadCSVAddress} sx={{ marginBottom: 1 }}>Download CSV</Button> */}
      <CustomSnackbar
        severity={snackBarSeverity}
        message={snackBarMessage}
        state={snackBarOpen}
        changeState={setSnackBarOpen}
      />
      </>
  );
};

export default AddressBook;
