import Manifest, { Drm, FileInfo } from '../../lib/tera-codec-ts/src/Manifest';

export type IntermediateManifest = {
	crypto?: { passphrase: string };
	downloadable?: boolean;
	drmRules?: {
		geo?: {
			id: string;
			action: 'allow' | 'block';
			value?: { country?: string; subdivision?: string; city?: string; };
		}[];
		ip?: {
			id: string;
			action: 'allow' | 'block';
			value?: string;
		}[];
		time?: { start?: string; end?: string; };
		opens?: { limit?: number };
	};
	files: {
		parts: {
			file?: File;
			name: string;
			type: string;
		}[];
	};
	keyValues: {
		id: string;
		key?: string;
		value?: string;
	}[];
	metadata: {
		api: string;
		auth: string;
		fileName: string;
		orgId: string;
		uuid?: string;
	};
	recipients: {
		id: string;
		email?: string;
	}[];
	sender: string;
};

export function intermediateToEncoder (intermediate: IntermediateManifest): Manifest {
	const manifest: Manifest = {
		api: intermediate.metadata.api,
		container: {
			downloadable: !!intermediate.downloadable,
			name: intermediate.metadata.fileName,
			uuid: intermediate.metadata.uuid ?? '',
			sender: intermediate.sender,
		},
		org_id: intermediate.metadata.orgId,
		files: {},
	};

	const { crypto, drmRules, files, keyValues, recipients } = intermediate;

	if (crypto?.passphrase) {
		manifest.container.password = crypto.passphrase
	}

	if (drmRules) {
		let drm: Drm | undefined;

		let { geo, ip, opens, time } = drmRules;
		if (geo && (geo = geo.filter((rule) => rule.value)).length > 0) {
			if (!drm) drm = manifest.container.drm = {};
			drm.geo = groupBy(geo, (rule) => rule.action, (rule) => rule.value!);
		}
		if (ip && (ip = ip.filter((rule) => rule.value)).length > 0) {
			if (!drm) drm = manifest.container.drm = {};
			drm.ip = groupBy(ip, (rule) => rule.action, (rule) => rule.value!);
		}
		if (opens && opens.limit) {
			if (!drm) drm = manifest.container.drm = {};
			drm.opens = opens.limit;
		}
		if (time) {
			if (!drm) drm = manifest.container.drm = {};
			if (time.start) {
				if (!drm.time) drm.time = {};
				drm.time.start = new Date(time.start);
			}
			if (time.end) {
				if (!drm.time) drm.time = {};
				drm.time.end = new Date(time.end);
			}
		}
	}

	for (const file of files.parts) {
		const fileInfo: FileInfo = {
			file: file.file,
			name: file.name,
			size: file.file?.size ?? 0,
			type: file.type,
		};

		manifest.files[file.name] = fileInfo;
	}

	if (keyValues) {
		manifest.container.key_values = {};
		const key_values = manifest.container.key_values;

		for (const { key, value } of intermediate.keyValues) {
			if (key && value) key_values[key] = value;
		}
	}

	if (recipients) {
		for (const { email } of recipients) {
			if (email) {
				if (!manifest.container.recipients) manifest.container.recipients = [];
				manifest.container.recipients.push(email);
			}
		}
	}

	return manifest;
}

function groupBy<T, K extends string | number | symbol, V> (values: T[], keyFn: (value: T) => K, valFn: (value: T) => V): Record<K, V[]> {
	const obj = {} as Record<K, V[]>;
	for (const value of values) {
		const key = keyFn(value);
		obj[key] = [...(obj[key] ?? []), valFn(value)];
	}
	return obj;
}
