import React, { useState } from "react";
import * as csvjson from "csvjson";
import DataGrid from "../DataGrid";
import { GridValueFormatterParams } from "@mui/x-data-grid";

type TableRow = Record<string, any>;

type Props = {
  data: TableRow[];
  title?: string;
};

const isoDateRe = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)Z$/;

export function valueFormatter (params: GridValueFormatterParams) {
  return typeof params.value === 'string' && isoDateRe.test(params.value)
    ? new Date(params.value).toLocaleString()
    : params.value;
}

const ReactTable: React.FC<Props> = ({ data, title }) => {
  if (!data?.length) {
    return <h3>No Data Found</h3>;
  }

  const columns = Object.keys(data[0] || {}).map((key, index) => ({
    valueFormatter,
    field: key,
    headerName: key,
    width: 150,
  }));
  const rows = data.map((item, index) => ({
    id: index + 1,
    ...item,
  }));

  return (
    <div>
      {title && <h2 className="analytics">{title}</h2>}
      <DataGrid rows={rows} columns={columns} />
    </div>
  );
};

export default ReactTable;
