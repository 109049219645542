import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import QRcode from "qrcode";
import { totp, generateKey, getKeyUri, exportKey, SecretKey } from "otp-io"
import { hmac, randomBytes } from "otp-io/crypto-web"
import { OrgContext } from "../../context/OrgContext";
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

export type Properties = {
  open: boolean,
  onClose?: () => void
}

export default function EnableDialog(props: Properties) {
  const { org, api } = React.useContext(OrgContext);
  const [totpkey, set_totpkey] = React.useState<SecretKey | null>(null)
  const [totp_removal, set_totp_removal] = React.useState<SecretKey | null>(null)
  const [QRsrc, set_QRsrc] = React.useState("")
  const [code, set_code] = React.useState<string[]>(["", "", "", "", "", ""])
  const inputs = [
    React.useRef<HTMLInputElement>(), React.useRef<HTMLInputElement>(), React.useRef<HTMLInputElement>(),
    React.useRef<HTMLInputElement>(), React.useRef<HTMLInputElement>(), React.useRef<HTMLInputElement>()
  ]
  const [verified, set_verified] = React.useState(false)
  const [checked, set_checked] = React.useState(false);


  React.useEffect(() => {
    if (!org) return
    const secret = generateKey(randomBytes, 20);
    console.log(secret)
    console.log(exportKey(secret))
    set_totpkey(secret)
    const removal = generateKey(randomBytes, 20);
    set_totp_removal(removal)

    const URI = getKeyUri({ type: "totp", secret: secret, issuer: "Locktera", name: org.email })
    console.log(URI)
    QRcode.toDataURL(URI, (err: any, url: any) => { set_QRsrc(url) })
  }, [org])

  function digitChanged(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, i: number) {
    const target = e.target as HTMLInputElement
    console.log(target.value)
    if (target.value == "") {
      code[i] = ""
      set_code([...code])
      return
    }
    const num = +target.value % 10
    code[i] = num.toString()
    set_code([...code])
    const nextInput = inputs[i + 1]
    if (nextInput && nextInput.current) nextInput.current.focus()

  }

  function digitKeyDown(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, i: number) {
    const target = e.target as HTMLInputElement
    if (e.key == "Backspace" && !target.value && i > 0) {
      code[i - 1] = ""
      set_code([...code])
      inputs[i - 1]!.current!.focus()
    }
  }

  async function verify() {
    if (totpkey === null) throw new Error("TOTP key generation failed.");
    const totp_code = await totp(hmac, { secret: totpkey });
    if (totp_code === code.join('')) {
      set_verified(true);
      console.log('input matches generator');
    }
  }

  async function enable2FA() {
    org.totp_key = exportKey(totpkey!)
    org.totp_removal_code = exportKey(totp_removal!)
    await api!.update_org(org)
    props.onClose?.()
  }


  return (
    <React.Fragment>

      <Dialog
        open={props.open}
        onClose={props.onClose}
      >
        <DialogTitle>Scan QR Code with authenticator app</DialogTitle>
        {!verified && <>
          <DialogContent>
            <img src={QRsrc}></img>
            <Typography variant="h6" >Enter 6 digit verification code </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              {code.map((digit, i) => <TextField
                type="text"
                key={i}
                value={digit}
                onChange={(e) => digitChanged(e, i)}
                inputProps={{ onKeyDown: (e) => digitKeyDown(e, i) }}
                onFocus={(e) => { e.target.select(); }}
                inputRef={inputs[i]}
              />)}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={(e) => { verify() }} sx={{ width: "10%", marginTop: 1 }}>
              Verify
            </Button>
          </DialogActions>
        </>}

        {verified && <>
          <DialogContent>
            <TextField
              label="removal code"
              defaultValue="removal code"
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: "100%", marginTop: 1 }}
            value={exportKey(totp_removal!)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={event => set_checked(event.target.checked)}
                />
              }
              label="I have recorded the removal code somewhere"
              sx={{ marginLeft: 0.5, marginTop: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" disabled={!checked} onClick={(e) => { enable2FA() }} sx={{ width: "20%" }}>
              Enable 2FA
            </Button>
          </DialogActions>
        </>}
      </Dialog>
    </React.Fragment>
  );
}