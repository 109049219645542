import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import ApiClient from '../api/ApiClient';
import PUBLIC_URL from '../utils/PUBLIC_URL';
import './SignUp.css';

export default function SignUpForm () {
	const [organization, setOrganization] = React.useState('');
	const [firstName, setFirstName] = React.useState('');
	const [lastName, setLastName] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [phone, setPhone] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	const handleInputChange = (setter: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setter((event.target as any).value);
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const api = new ApiClient();
		try {
			setLoading(true);
			await api.provisionOrg({
				org_name: organization.trim(),
				first_name: firstName.trim(),
				last_name: lastName.trim(),
				email: email.trim().toLowerCase(),
				mobile_phone: phone.trim(),
			});

			window.location.href = 'https://locktera.com/welcome-to-trial/';
		} catch (err: any) {
			alert(err.message);
		} finally {
			setLoading(false);
		}
	}

	// All fields are required
	const isFormValid = ![organization, firstName, lastName, email, phone].some(s => !s.trim());

	return (
		<>
			<Container component='main' sx={{ marginTop: 2 }}>
				<Grid2 container gap={1}>
					<Grid2 xs={12} sm={3} md={4} sx={{ textAlign: 'right', color: '#2b6692' }}>
						<img src={`${PUBLIC_URL}/LOCKTERA_Logo_new.png`} style={{ width: '100%', maxWidth: 400 }} /><br />
						<Box style={{ fontSize: 36 }}>Sign Up</Box>
						<Box style={{ fontSize: 16 }}>Free 14 Day Trial</Box>
					</Grid2>
					<Grid2 xs={12} sm={6} md={4}>
						<form onSubmit={handleSubmit} style={{ width: '100%' }}>
							<TextField
								label="Company Name"
								name="company"
								value={organization}
								onChange={handleInputChange(setOrganization)}
								required
								fullWidth
								margin="normal"
							/>

							<TextField
								label="First Name"
								name="firstName"
								value={firstName}
								onChange={handleInputChange(setFirstName)}
								required
								fullWidth
								margin="normal"
							/>

							<TextField
								label="Last Name"
								name="lastName"
								value={lastName}
								onChange={handleInputChange(setLastName)}
								required
								fullWidth
								margin="normal"
							/>

							<TextField
								label="Email"
								type="email"
								name="email"
								value={email}
								onChange={handleInputChange(setEmail)}
								required
								fullWidth
								margin="normal"
							/>

							<TextField
								label="Phone"
								type="tel"
								name="phone"
								value={phone}
								onChange={handleInputChange(setPhone)}
								required
								fullWidth
								margin="normal"
							/>

							<Button
								type="submit"
								variant="contained"
								color="primary"
								fullWidth
								size="large"
								style={{ marginTop: 20 }}
								disabled={!isFormValid || loading}
							>
								{loading ? 'Processing, please wait' : 'Sign Up'}
							</Button>
						</form>
					</Grid2>
					<Grid2 xs={12} sm={3} md={4} />
				</Grid2>
			</Container>
			<Box className='footer'>
				<span>&copy; Copyright 2023 LOCKTERA, Inc.</span>
				<span>All rights reserved.</span>
				<span>Various trademarks held by LOCKTERA, Inc.</span>
				<a href="https://locktera.com/privacy-policy/" target="_blank">Privacy Policy</a>
				<span>|</span>
				<a href="https://locktera.com/cookies-policy-locktera-share/" target="_blank">Cookie Policy</a>
			</Box>
		</>
	);
}
