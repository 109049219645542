import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  removeAll?: any;
}

export default function DeleteFilesDialog(props: Props) {
  const { removeAll } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='delete-all-files-title'
        aria-describedby='delete-all-files-description'>
        <DialogTitle id='delete-all-files-title'>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-all-files-description'>
            Are you sure you want to delete all files?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            sx={{ width: '100%' }}
            onClick={handleClose}
            autoFocus>
            Close Dialog
          </Button>
          <Button
            variant='contained'
            color='error'
            sx={{ width: '100%' }}
            onClick={(event) => {
              event.preventDefault();
              removeAll();
              handleClose();
            }}
            autoFocus>
            Delete All Files
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
