import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RecoverFilesUI from "../files/RecoverFilesUI";

interface Props {
  refreshUI: () => void;
}

export default function RecoverFiles(props: Props) {
  return (
    <RecoverFilesUI refreshUI={props.refreshUI} />
  );
}
