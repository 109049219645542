export default function formatSize(n) {
    if (n == null) return '';
    n = +n;
    const suffixes = ['', 'ki', 'Mi', 'Gi', 'Ti'];
    let suffix = 0;
    while (n >= 1024) {
      n /= 1024;
      suffix++;
    }
    return `${n.toPrecision(4)} ${suffixes[suffix]}B`;
  }