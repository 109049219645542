// type CallbackResult<T> = T extends (arg: infer U) => any ? U : never;
// type Arguments<T> = T extends (...params: [...args: infer A, callback: CallbackResult<infer R>]) => any ? A : never;
// type CallbackMethod<T, K extends keyof T> = T[K] extends (this: T, ...params: [...args: infer A, callback: (result: infer R) => any]) => any ? [A, R] : never;

type OfficeAsyncMethod<T, K extends keyof T, A extends unknown[], R> = (this: T, ...params: [...args: A, callback: (result: Office.AsyncResult<R>) => any]) => any;
type OfficeAsyncMethodArgs<T, K extends keyof T> = T[K] extends OfficeAsyncMethod<T, K, infer A, infer R> ? A : never;
type OfficeAsyncMethodAsyncResult<T, K extends keyof T> = T[K] extends OfficeAsyncMethod<T, K, infer A, infer R> ? Office.AsyncResult<R> : never;
type OfficeAsyncMethodResult<T, K extends keyof T> = T[K] extends OfficeAsyncMethod<T, K, infer A, infer R> ? R : never;

export async function call<T, K extends keyof T>(obj: T, method: K, ...args: any[]): Promise<OfficeAsyncMethodResult<T, K>> {
	return new Promise((resolve, reject) => {
		(obj as any)[method](...args, (result: OfficeAsyncMethodAsyncResult<T, K>) => {
			if (result.status === Office.AsyncResultStatus.Succeeded) {
				resolve(result.value as OfficeAsyncMethodResult<T, K>);
			} else {
				reject(result.error);
			}
		});
	})
}

export function isOutlook () {
	return !!Office.context.mailbox;
}
