import {
  Box,
  Card,
  CardHeader,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Fragment, useContext, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import { JSONFormsContext } from "../../context/JSONFormsContext";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";
import CardContent from "../general/CardContentNoPadding";
import { getMatch } from "ip-matching";
import Grid2 from "@mui/material/Unstable_Grid2";

const useStyles = makeStyles({
  FormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "10px",
  },
});

type IpRule = {
  id: string;
  action: "allow" | "block";
  value?: string;
};

const IPAddressRules = () => {
  const { form, updateForm } = useContext(
    JSONFormsContext
  ) as JSONFormsContextType;

  const rows: IpRule[] = form.drmRules?.ip ?? [
    { id: crypto.randomUUID(), action: "allow" },
  ];

  const updateRows = () => {
    updateForm({
      ...form,
      drmRules: {
        ...form.drmRules,
        ip: rows,
      },
    });
  };

  function ensureBlank() {
    while (rows.length > 1 && rows[rows.length - 1].value == null) {
      handleRemoveRow(rows.length - 1);
    }
    if (rows[rows.length - 1].value != null) {
      handleAddRow();
    }
  }

  const handleAddRow = () => {
    rows.push({ id: crypto.randomUUID(), action: "allow" });
    updateRows();
  };

  const handleToggle = (index: number) => {
    rows[index].action = rows[index].action === "allow" ? "block" : "allow";
    updateRows();
  };

  const handleRemoveRow = (indexToRemove: number) => {
    rows.splice(indexToRemove, 1);
    updateRows();
  };

  function getError(pattern?: string) {
    if (!pattern) return null;
    try {
      getMatch(pattern);
      return null;
    } catch (err) {
      return (err as Error).message;
    }
  }

  const classes = useStyles();

  return (
    <Box className={classes.FormContainer}>
      <Card
        style={{
          width: "100%",
          borderColor: "#2B5592",
          borderWidth: "2px",
          borderStyle: "solid",
        }}
      >
        <CardHeader title="IP Address Rules" />
        <CardContent>
          <Grid2 container>
            {rows.map((row, index) => (
              <Fragment key={row.id}>
                <Grid2 xs={12} sm={3}>
                  {index === rows.length - 1 ? (
                    <IconButton onClick={handleAddRow}>
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleRemoveRow(index)}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={row.action === "allow"}
                        onChange={() => handleToggle(index)}
                      />
                    }
                    label={row.action === "allow" ? "Allow" : "Block"}
                  />
                </Grid2>
                <Grid2 xs={12} sm={9}>
                  <TextField
                    variant="standard"
                    value={row.value ?? ""}
                    error={getError(row.value) != null}
                    helperText={getError(row.value)}
                    placeholder="IP Address"
                    onChange={(e) => {
                      if (e.target.value) {
                        row.value = e.target.value;
                      } else {
                        delete row.value;
                      }
                      updateRows();
                      ensureBlank();
                    }}
                  />
                </Grid2>
              </Fragment>
            ))}
          </Grid2>
        </CardContent>
      </Card>
    </Box>
  );
};

export default IPAddressRules;
