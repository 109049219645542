import * as React from "react";
import { makeStyles } from "@mui/styles";
import downloadBlob from "../../utils/downloadBlob";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Divider from "@mui/material/Divider";

import DeleteFileDialog from "../general/DeleteFileDialog";

import { get_mailto_link, copy_html_to_clipboard } from "../../utils/clipBoard";
import { OrgContext } from "../../context/OrgContext";
import AttemptsDialog from "../AttemptsDialog/AttemptsDialog";

const useStyles = makeStyles({});

const ITEM_HEIGHT = 48;
interface Props {
  file: any;
  refreshUI: () => void;
}

const FilesMenu = (props: Props) => {
  const { file, refreshUI: refreshUI } = props;
  const classes = useStyles();
  const { org, api } = React.useContext(OrgContext);
  // console.log('file', file);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function downloadFile() {
    let blob = await api?.downloadFile(file.id);
    downloadBlob(blob, `${file.container_name}.tera`);
    await api?.addEvent(file.id, "container.download")
    handleClose();
  }

  async function copyHtml () {
    if (file.public_url) {
      await copy_html_to_clipboard(org, file);
    }
    handleClose();
  }

  async function copyFileLink() {
    if(file.public_url) {
      const url = file.public_url;
      navigator.clipboard.writeText(url);
    }
    handleClose();
  }

  async function publishFile() {
    const url = await api?.publish(file.id);
    file.public_url = url;
    refreshUI(); // Re-render
    handleClose();
  }

  async function unpublishFile() {
    await api?.unpublish(file.id);
    file.public_url = null;
    refreshUI(); // Re-render
    handleClose();
  }

  const [attempts_open, set_attempts_open] = React.useState(false);

  function unlockFile () {
    set_attempts_open(true);
    handleClose();
  }

  async function blockFile() {
    // Need code, reason
    const reason = prompt("What is the reason for blocking?");
    if (reason) {
      await api?.block(file.id, reason);
      refreshUI(); // Re-render
    }
    handleClose();
  }

  async function unblockFile() {
    await api?.unblock(file.id);
    refreshUI(); // Re-render
    handleClose();
  }

  async function deleteFile() {
    await api?.delete(file.id);
    refreshUI();
    await api?.addEvent(file.id, "container.delete")
    handleClose();
  }

  const handleCopy = (e: any, value: string) => {
    e.preventDefault();
    e.clipboardData.setData("text/plain", value);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {file.blocked ? <MenuItem key='unblock' onClick={unblockFile}>
          Unblock Tera File
        </MenuItem> : <MenuItem key='block' onClick={blockFile}>
          Block Tera File
        </MenuItem>}
        <MenuItem key='unlock' onClick={unlockFile}>
          Unlock Viewer
        </MenuItem>
        <Divider />
        {!file.public_url && <MenuItem key='publish' onClick={publishFile}>Publish</MenuItem>}
        {file.public_url && <MenuItem key='email' component='a' href={get_mailto_link(org, file)}>
          Resend Email
        </MenuItem>}
        {file.public_url && <MenuItem key='html' onClick={copyHtml}>
          Copy HTML
        </MenuItem>}
        {file.public_url && <MenuItem key='copy' onClick={copyFileLink}>
          Copy Link
        </MenuItem>}
        {file.public_url && <MenuItem key='download' onClick={downloadFile}>
          Download
        </MenuItem>}
        <Divider />
        <DeleteFileDialog deleteFile={deleteFile} />
      </Menu>
      <AttemptsDialog container_id={file.id} open={attempts_open} onClose={() => set_attempts_open(false)} />
    </div>
  );
};

export default FilesMenu;
