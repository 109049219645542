import {
  Autocomplete,
  Box,
  Card,
  Chip,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import { JSONFormsContext } from "../../context/JSONFormsContext";
import { JSONFormsContextType } from "../../context/@types/jsonFormsContext";
import CardContent from "../general/CardContentNoPadding";
import CardHeader from "../general/CardHeaderNoPadding";
import { iso31661, iso31662, ISO31661AssignedEntry } from "iso-3166";
import { Entity } from "draft-js";
import { Type } from "ajv/dist/compile/util";
import { openDuckDB } from "../analytics/duckdb";
import ApiClient from "../../api/ApiClient";
import PUBLIC_URL from "../../utils/PUBLIC_URL";
import Grid2 from "@mui/material/Unstable_Grid2";
import { tableToObjects } from "../analytics/lt_analytics_client_wip";
import { register_commands } from "../../utils/outlook_commands";

const dbPromise = openDuckDB(+process.env.REACT_DEBUG! > 0);

const useStyles = makeStyles({
  FormContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "10px",
  },
  Main2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
});

type GeoValue = {
  continent?: string;
  country?: string;
  subdivision?: string;
  city?: string;
};

type GeoRule = {
  id: string;
  action: "allow" | "block";
  value?: GeoValue;
};

type CountryInfo = {
  name: string;
  alpha2: string;
}

type SubdivisionInfo = {
  country_alpha2: string;
  name: string;
  subdivision_code: string;
}

type CityInfo = {
  country_alpha2: string;
  subdivision_code: string;
  name: string;
}

const LocationRules = () => {
  //const [selectedCountryCode, setSelectedCountryCode] = useState<string | null>(null);
  //const [regionOptions, setRegionOptions] = useState<any[]>([]);

  const [countries, setCountries] = useState<CountryInfo[]>([]);
  const [regions, setRegions] = useState<SubdivisionInfo[]>([]);
  const [cities, setCities] = useState<CityInfo[]>([]);

  useEffect(() => {
    (async () => {

      const db = await dbPromise;
      const conn = await db.connect();
      const countriesTable = await conn.query(`SELECT * from '${PUBLIC_URL}/data/locations/country.parquet?_=${Math.random().toString().slice(2)}' ORDER BY name`);
      setCountries(tableToObjects(countriesTable).sort((a, b) => {
        if (a.alpha2 === 'US') return -1;
        if (b.alpha2 === 'US') return 1;
        if (a.alpha2 === 'GB') return -1;
        if (b.alpha2 === 'GB') return 1;
        return a.name.localeCompare(b.name);
      }));

      const regionsTable = await conn.query(`SELECT * from '${PUBLIC_URL}/data/locations/subdivision.parquet?_=${Math.random().toString().slice(2)}' ORDER BY name`);
      setRegions(tableToObjects(regionsTable));

      const citiesTable = await conn.query(`SELECT * from '${PUBLIC_URL}/data/locations/city.parquet?_=${Math.random().toString().slice(2)}' ORDER BY name`);
      setCities(tableToObjects(citiesTable));

    })()
  }, [])

  function find_country (value?: GeoValue) {
    return countries.find(country => country.alpha2 === value?.country) ?? null;
  }

  function find_subdivision (value?: GeoValue) {
    return regions.find(region => region.country_alpha2 === value?.country && region.subdivision_code === value?.subdivision) ?? null;
  }

  function find_city (value?: GeoValue) {
    return cities.find(city => city.country_alpha2 === value?.country && city.subdivision_code === value?.subdivision && city.name === value?.city) ?? null;
  }

  // Function to fetch regions based on the selected country code
  function getRegionOptions(value?: GeoValue) {
    return regions.filter(region => region.country_alpha2 === value?.country);
  };

  // Function to fetch cities based on the selected country and subdivision code
  function getCityOptions (value?: GeoValue) {
    return cities.filter(city => city.country_alpha2 === value?.country && city.subdivision_code === value?.subdivision);
  }

  const { form, updateForm } = useContext(
    JSONFormsContext
  ) as JSONFormsContextType;

  const rows: GeoRule[] = form.drmRules?.geo ?? [
    { id: crypto.randomUUID(), action: "allow" },
  ];

  const updateRows = () =>
    updateForm({
      ...form,
      drmRules: {
        ...form.drmRules,
        geo: rows,
      },
    });

  function ensureBlank() {
    while (rows.length > 1 && rows[rows.length - 1].value == null) {
      handleRemoveRow(rows.length - 1);
    }
    if (rows[rows.length - 1].value != null) {
      handleAddRow();
    }
  }

  const handleAddRow = () => {
    rows.push({ id: crypto.randomUUID(), action: "allow" });
    updateRows();
  };

  const handleToggle = (index: number) => {
    rows[index].action = rows[index].action === "allow" ? "block" : "allow";
    updateRows();
  };

  const handleRemoveRow = (indexToRemove: number) => {
    rows.splice(indexToRemove, 1);
    updateRows();
  };

  const setValue = (
    row: GeoRule,
    key: "country" | "subdivision" | "city",
    newValue: any,
  ) => {
    if (newValue) {
      (row.value ||= {})[key] = newValue;
    } else if (row.value) {
      delete row.value[key];
    }
    if (row.value && Object.keys(row.value).length === 0) delete row.value;
    updateRows();
    ensureBlank();
  };

  const classes = useStyles();

  return (
    <Box className={classes.FormContainer}>
      <Card
        style={{
          width: "100%",
          borderColor: "#2B5592",
          borderWidth: "2px",
          borderStyle: "solid",
          overflow: "auto",
        }}
      >
        <CardHeader title="Location Rules" />
        <CardContent>
            <Grid2 container>
              {rows.map((row, index) => (
                <Fragment key={row.id}>
                  <Grid2 xs={12} sm={12} md={3}>
                    {index === rows.length - 1 ? (
                      <IconButton onClick={handleAddRow}>
                        <AddIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleRemoveRow(index)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={row.action === "allow"}
                          onChange={() => handleToggle(index)}
                        />
                      }
                      label={row.action === "allow" ? "Allow" : "Block"}
                    />
                  </Grid2>
                  <Grid2 xs={12} sm={4} md={3}>
                    <Autocomplete
                      id="country-select-demo"
                      sx={{ width: 200 }}
                      options={countries}
                      onChange={(event, newValue) => {
                        setValue(row, "country", newValue?.alpha2);
                        //when country is changed, remove subdivision and city
                        setValue(row, "subdivision", null);
                        setValue(row, "city", null);
                        // console.log("rows: ", rows);
                      }}
                      value={find_country(row.value)}
                      autoHighlight
                      getOptionLabel={(option: any) => option.name}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.alpha2.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.alpha2.toLowerCase()}.png 2x`}
                            alt=""
                          />
                          {option.name} ({option.alpha2})
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // value={row.value?.country}
                          variant="standard"
                          label="Choose a country"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    </Grid2>
                    <Grid2 xs={12} sm={4} md={3}>
                    <Autocomplete
                      id="region-select-demo"
                      sx={{ width: 200 }}
                      options={getRegionOptions(row.value)}
                      noOptionsText="Please choose a country"
                      autoHighlight
                      value={find_subdivision(row.value)}
                      onChange={(event, newValue) => {
                        setValue(row, "subdivision", newValue?.subdivision_code);
                        setValue(row, "city", null);
                        // setRegionValue(newValue);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Choose a region/state"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    </Grid2>
                    <Grid2 xs={12} sm={4} md={3}>
                    <Autocomplete
                      id="city-select-demo"
                      sx={{ width: 200 }}
                      options={getCityOptions(row.value)}
                      noOptionsText="Please choose a region/state"
                      value={find_city(row.value)}
                      autoHighlight
                      onChange={(event, newValue) => {
                        setValue(row, "city", newValue?.name);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label="Choose a city"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    </Grid2>
                    </Fragment>
              ))}
              </Grid2>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LocationRules;
