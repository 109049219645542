import { BoxProps } from "@mui/material";
import Plot from "react-plotly.js";
import { findColumns } from "./lt_analytics_client";
import { Data, Datum, Layout, LayoutAxis, PlotData } from "plotly.js";
import deepMerge from "../../utils/deepMerge";

type Props = {
  data: { group: string, x: any, y: any }[];
  style?: Partial<React.CSSProperties>;
  layout?: Partial<Layout>;
  title?: string;
  maxX?: number;
  maxY?: number;
} & BoxProps;

const colors = [
  '#35548E',
  '#7AB9DF',
  '#C7D5EB',
  '#80BE5A',
];

const GroupVerticalBarGraph: React.FC<Props> = (props) => {
  if (!props.data?.length) {
    return <h3>No Data Found</h3>;
  }

  const groups:Record<string, Partial<PlotData>> = {};

  function addToGroup(row: { group: string, x: any, y: any }) {
    let group = groups[row.group];
    if (!group) {
      group = groups[row.group] = {
        type: 'bar',
        x: [],
        y: [],
        orientation: 'v',
        name: row.group,
        marker: {
          color: colors[Object.keys(groups).length],
        },
      };
    }

    (group.x as Datum[]).push(row.x);
    (group.y as Datum[]).push(row.y);
  }

  for (const row of props.data) {
    addToGroup(row);
  }

  console.log("Groups: ", groups);

  const yaxis: Partial<LayoutAxis> = {
    side: "top",
    tick0: 0,
  };

  let layout: Partial<Layout> = {
    autosize: true,
    title: props.title,
    barmode: "group",
    xaxis: {
      tick0: 0,
      dtick: 1,
      ...(props.maxX && { range: [0.25, props.maxX + 0.75] }),
    },
    yaxis: yaxis,
  };

  return <Plot
    style={{ width: '100%', height: '100%', ...props.style }}
    data={Object.values(groups)}
    layout={deepMerge(layout, props.layout)}
    useResizeHandler
  />;
};

export default GroupVerticalBarGraph;
