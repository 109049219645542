import {
	AddCircle,
	Assignment,
	Block,
	CancelScheduleSend,
	CloudSync,
	ContactMail, Dashboard, Description, DiscFull, Drafts, FindInPage, Folder,
	Groups,
	Inventory,
	Leaderboard,
	List,
	Logout, NoteAlt,
	PeopleAlt,
	Person,
	PersonAddAlt,
	PersonSearch,
	ScreenLockPortrait,
	Settings, Subscriptions
} from "@mui/icons-material";
import { Location } from "react-router-dom";
import AddressBookTab from "./pages/AddressBook/AddressBookTab";
import AddressBookChangeLogTab from "./pages/AddressBook/ChangeLogTab";
import Encoder from "./components/encoder/index";
import AddressBookUpdates from "./pages/Confirguration/AddressBookUpdates";
import CloudStorage from "./pages/Confirguration/CloudStorage";
import ReferenceFields from "./pages/Confirguration/ReferenceFields";
import TwoFA from "./pages/Confirguration/TwoFA";
import FilesPage from "./pages/FilesPage";
import GroupAnalytics from "./pages/GroupAnalytics";
import LandingPage from "./pages/LandingPage";
import AccessLogTab from "./pages/People/AccessLogTab";
import PeopleChangeLogTab from "./pages/People/ChangeLogTab";
import PeopleTab from "./pages/People/PeopleTab";
import SignUp from "./pages/SignUp";
import Subscribe from "./pages/Subscribe";
import TabsPage from './pages/TabsPage';
import UserAnalytics from "./pages/UserAnalytics";
import ViewerAuditTab from "./pages/UserReports/ViewerAuditTab";
import ContentFileAuditTab from "./pages/UserReports/ContentFileAuditTab";
import BlockedTab from "./pages/UserReports/BlockedTab";
import SentNotAccessedTab from "./pages/UserReports/SentNotAccessedTab";
import ViewerInformationTab from "./pages/UserReports/ViewerInformationTab";
import AccessDeniedTab from "./pages/UserReports/AccessDeniedTab";
import ContainerStatusTab from "./pages/UserReports/ContainerStatusTab";
import ContentTab from "./pages/UserReports/ContentTab";

export type Tab = {
	name: string;
	id?: string;
	url?: string;
	icon: () => React.ReactElement;
	help_url?: string;
	body: () => React.ReactElement;
};

export type Page = {
	name: string;
	id?: string;
	url?: string;
	icon: () => React.ReactElement;
	help_url?: string;
	page?: (tab?: string) => React.ReactElement;
	tabs?: Tab[];
	href?: string;
};

type SiteMap = {
	authenticated: Page[][];
	public: Page[];
}

export function get_id (page: { name: string, id?: string }) {
	return page.id ?? page.name.toLowerCase().replace(/[^a-z]/g, '_').replace(/_+/g, '_');
}

export function get_url (page: { name: string, id?: string, url?: string }) {
	return page.url ?? `/${get_id(page)}`;
}

const site_map: SiteMap = {
	authenticated: [
		// Top
		[
			{
				name: 'Dashboard',
				url: '/',
				icon: () => <Dashboard fontSize="large" />,
				page: () => <LandingPage />,
				help_url: 'https://locktera.com/locktera-dashboard-help-cut-version/',
			},
			{
				name: 'Secure Files',
				icon: () => <AddCircle fontSize="large" />,
				page (tab) {
					return <TabsPage page={this} tab={tab} />
				},
				help_url: 'https://locktera.com/locktera-encode-help-cut-version/',
				tabs: [
					{
						name: 'Share Secure Files',
						url: '',
						icon: () => <Drafts fontSize="large" />,
						body: () => <Encoder mode='default' />,
					},
					// {
					// 	name: 'Secure Large Files',
					// 	url: '/large',
					// 	icon: () => <DiscFull fontSize="large" />,
					// 	body: () => <Encoder mode='large_file' />,
					// }
				],
			},
			{
				icon: () => <Folder fontSize="large" />,
				name: 'Library',
				url: '/files',
				page: () => <FilesPage />,
				help_url: 'https://locktera.com/locktera-library-cut-version/',
			},
			{
				name: 'Address Book',
				icon: () => <ContactMail fontSize="large" />,
				page (tab) {
					return <TabsPage page={this} tab={tab} container />
				},
				help_url: 'https://locktera.com/locktera-address-book-help-cut-version/',
				tabs: [
					{
						name: 'Address Book',
						url: '',
						icon: () => <ContactMail fontSize="large" />,
						body: () => <AddressBookTab />
					},
					{
						name: 'Change Log',
						icon: () => <Assignment fontSize="large" />,
						body: () => <AddressBookChangeLogTab />
					},
				],
			},
			{
				name: 'People',
				icon: () => <PeopleAlt fontSize="large" />,
				page (tab) {
					return <TabsPage page={this} tab={tab} container />
				},
				help_url: 'https://locktera.com/locktera-people-help-cut-version/',
				tabs: [
					{
						name: 'People',
						url: '',
						icon: () => <PeopleAlt fontSize="large" />,
						body: () => <PeopleTab />,
					},
					{
						name: 'Access Log',
						icon: () => <NoteAlt fontSize="large" />,
						body: () => <AccessLogTab />
					},
					{
						name: 'Change Log',
						icon: () => <Assignment fontSize="large" />,
						body: () => <PeopleChangeLogTab />
					},
					// {
					// 	name: 'Usage',
					// },
				],
			},
			{
				name: 'Reports',
				icon: () => <Leaderboard fontSize="large" />,
				page (tab) {
					return <TabsPage page={this} tab={tab} />
				},
				tabs: [
					// {
					// 	name: 'User Reports',
					// 	icon: () => <Person fontSize="large" />,
					// 	body: () => <UserAnalytics />,
					// },
					// {
					// 	name: 'Performance Reports',
					// 	icon: () => <Groups fontSize="large" />,
					// 	body: () => <GroupAnalytics />,
					// },
					{
						name: 'Content',
						icon: () => <Description fontSize="large" />,
						body: () => <ContentTab />,
					},
					{
						name: 'Container Status',
						icon: () => <Inventory fontSize="large" />,
						body: () => <ContainerStatusTab />
					},
					{
						name: 'Access Denied',
						icon: () => <Block fontSize="large" />,
						body: () => <AccessDeniedTab />
					},
					{
						name: 'Sent, Not Accessed',
						icon: () => <CancelScheduleSend fontSize="large" />,
						body: () => <SentNotAccessedTab />
					},
					{
						name: 'Viewer Information',
						icon: () => <Person fontSize="large" />,
						body: () => <ViewerInformationTab />
					},
					{
						name: 'Viewer Audit',
						icon: () => <PersonSearch fontSize="large" />,
						body: () => <ViewerAuditTab />
					},
					{
						name: 'Content File Audit',
						icon: () => <FindInPage fontSize="large" />,
						body: () => <ContentFileAuditTab />
					},
					{
						name: 'Blocked',
						icon: () => <Block fontSize="large" />,
						body: () => <BlockedTab />
					},
				],
			},
		],
		// Bottom
		[
			//Page is not made yet?
			// {
			//     icon: () => <Notifications/>,
			// 	name: 'Notifications',
			// 	page: () => <NotificationsPage />,
			// },
			{
				icon: () => <Settings fontSize="large" />,
				name: 'Configuration',
				page (tab) {
					return <TabsPage page={this} tab={tab} container />
				},
				help_url: 'https://locktera.com/locktera-org-config-help-cut-version/',
				tabs: [
					{
						name: 'Two-Factor Authentication',
						icon: () => <ScreenLockPortrait fontSize="large" />,
						body: () => <TwoFA />
					},
					{
						name: 'Address Book Updates',
						icon: () => <ContactMail fontSize="large" />,
						body: () => <AddressBookUpdates />
					},
					{
						name: 'Reference Fields',
						icon: () => <List fontSize="large" />,
						body: () => <ReferenceFields />
					},
					{
						name: 'Cloud Storage',
						url: '',
						icon: () => <CloudSync fontSize="large" />,
						body: () => <CloudStorage />,
					},
				],
			},
			{
				icon: () => <Logout fontSize="large" />,
				name: 'Log Out',
				href: `${process.env.REACT_APP_AUTH_URL}/logout?${new URLSearchParams({ redirect: window.location.href })}`,
			},
		],
	],
	public: [
		{
			icon: () => <PersonAddAlt fontSize="large" />,
			name: 'Sign Up',
			page: () => <SignUp />,
		},
		{
			icon: () => <Subscriptions fontSize="large" />,
			name: 'Subscribe',
			page: () => <Subscribe />,
		},
	],
};

export default site_map;

export function get_current_location (location: Location): [Page?, Tab?] {
	for (const page of site_map.authenticated.flat().sort((a, b) => get_url(b).length - get_url(a).length)) {
		const page_url = get_url(page);
		if (location.pathname.startsWith(page_url)) {
			if (!page.tabs) {
				return [page];
			}

			const pathname = location.pathname.slice(page_url.length);
			for (const tab of page.tabs.slice().sort((a, b) => get_url(b).length - get_url(a).length)) {
				if (pathname.startsWith(get_url(tab))) {
					return [page, tab];
				}
			}

			return [page];
		}
	}

	return [];
}
