import React, { useMemo } from "react";
import { getApi } from "../../api";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import DataGrid from "../../components/DataGrid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  AccountCircle,
  AdminPanelSettings,
  Delete,
  Edit,
  ExpandMore,
  Face,
  Groups,
  GroupsOutlined,
  Label,
  Person,
  StraightenSharp,
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomSnackbar, { Severity } from "../../components/general/snackbar";
import { OrgContext } from "../../context/OrgContext";
import { encode as base64Encode } from "../../utils/base64";
import SearchIcon from "@mui/icons-material/Search";
import * as csvjson from "csvjson";
import DateRangeSelect, { DateRange, range_to_dates } from "../../components/date_range_select/DateRangeSelect";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CSVFileDrop from "../../components/files/CSVFileDrop";
import downloadBlob from "../../utils/downloadBlob";

const import_template = `data:text/plain;base64,${base64Encode(
  new TextEncoder().encode(
    `Email,First Name,Last Name,Mobile Phone,Company\r\njohndoe@example.com,John,Doe,555-555-5555,Locktera`
  ),
  true
)}`;

const AddressBook: React.FC = () => {
  const { org, api } = React.useContext(OrgContext);

  const [date_range, set_date_range] = React.useState(DateRange.All)
  const [logs, setLogs] = React.useState<any[]>([])

  async function load_logs() {
    const [start, end] = range_to_dates(date_range)
    const logs = await api!.get_address_audit(start, end);
    setLogs(map_logs(logs, {
      first_name: 'First Name',
      last_name: 'Last Name',
      mobile_phone: 'Mobile Phone',
      company: 'Company',
    }));
  }

  React.useEffect(() => {
    console.log(api)
    if (api) load_logs()
  }, [api, date_range]);

  function map_logs(logs: any[], field_names: Record<string, string | undefined>) {
    return logs.map(log => {
      const id = log.id;
      const ts = new Date(log.ts).toLocaleString();
      const email = log.new_data?.email ?? log.old_data?.email;
      const action = { i: 'Create', u: 'Update', d: 'Delete' }[log.action as 'i' | 'u' | 'd'];

      let changes = '';
      for (const [key, value] of Object.entries(log.new_data ?? {})) {
        const field = field_names[key];
        if (!field) continue;

        if (value != log.old_data?.[key]) {
          if (changes.length > 0) {
            changes += '; ';
          }
          changes += `${field}: ${value}`;
        }
      }

      return {
        id,
        ts,
        email,
        action,
        changes,
      };
    }).filter(log => !(log.action === "Update" && log.changes === ''));
  }

  const log_columns = [{
    field: 'ts',
    headerName: 'TIMESTAMP',
    width: 150,
  },
  {
    field: 'action',
    headerName: 'ACTION',
    width: 100,
  },
  {
    field: 'email',
    headerName: 'EMAIL',
    width: 150,
  },
  {
    field: 'changes',
    headerName: 'CHANGES',
    width: 600,
  }];

  function downloadCSVAccess() {
    const csv = csvjson.toCSV(logs, { headers: "key" })
    const blob = new Blob([csv], { type: "text/csv" })
    downloadBlob(blob, "AccessLogs.csv")
  }

  //return <><pre><code>{JSON.stringify(users)}</code></pre></>
  return (
    <>
      <Box sx={{ width: '100%', paddingBottom: 8 }}>
        <FormControl sx={{ minWidth: 200, paddingLeft: 1 }}>
          <InputLabel id="demo-simple-select-label">Select Date Range</InputLabel>
          <DateRangeSelect value={date_range} onChange={(range) => { set_date_range(range); }} />
        </FormControl>
        <DataGrid rows={logs} columns={log_columns} />
      </Box>
      {/* <Button onClick={downloadCSVAccess}>Download CSV</Button> */}
      {/* </Container> */}
    </>
  );
};

export default AddressBook;
