import { Card, CardContent, Container } from "@mui/material";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { withErrorBoundary } from "react-use-error-boundary";
import "./App.css";
import Authenticated from "./Authenticated-datagrid";
import { OrgContext } from "./context/OrgContext";

import site_map, { get_id, get_url } from './site_map';
import Analytics from "./pages/Analytics";
import { isOutlook } from "./utils/office";

interface Props { }

const App = withErrorBoundary((props: Props) => {
  // const authenticatedRoutes = [{
  //   path: '/',
  //   element: () => <LandingPage />
  // },{
  //   path: '/encode',
  //   element: () => <Builder />
  // }, {
  //   path: '/files',
  //   element: () => <FilesPage />
  // }, {
  //   path: '/user_reports',
  //   element: () => <UserAnalytics />
  // }, {
  //   path: '/performance_reports',
  //   element: () => <GroupAnalytics />
  // }, {
  //   path: '/address_book',
  //   element: () => <AddressBook />
  // }, {
  //   path: '/users',
  //   element: () => <Users />
  // }, {
  //   path: '/logs',
  //   element: () => <Logs />
  // }, {
  //   path: '/configuration',
  //   element: () => <Configuration />
  // }, {
  //   path: '/super_admin',
  //   element: () => org?.role === 'super' ? <SuperAdmin /> : <></>
  // }, {
  //   path: '/files/analytics/:uuid',
  //   element: () => <Analytics />
  // }];

  // const publicRoutes = [{
  //   path: '/signup',
  //   element: () => <SignUp />
  // }, {
  //   path: '/subscribe',
  //   element: () => <Subscribe />
  // }];

  // Show an error message in older (buggy) versions of Safari
  if (navigator.userAgent.match(/AppleWebKit\/605.1.15/) && !('fromAsync' in Array)) {
    return (
      <Container sx={{paddingTop: 1}}>
        <Card>
          <CardContent>
            <h2>Environment Not Supported</h2>
            Your current environment is not supported. Please ensure {isOutlook() ? 'Outlook' : 'your browser'} and {isOutlook() && 'your'} operating system are fully up-to-date.
          </CardContent>
        </Card>
      </Container>
    )
  }

  const routes = site_map.authenticated.flatMap(pages => pages.flatMap(page => {
    if (page.tabs) {
      return page.tabs.map(tab => {
        return <Route
          key={`${get_id(page)}__${get_id(tab)}`}
          path={`${get_url(page)}${get_url(tab)}`}
          element={<Authenticated>
            {page.page?.(get_id(tab)) || <></>}
          </Authenticated>}
        />
      });
    } else {
      return <Route
        key={get_id(page)}
        path={get_url(page)}
        element={<Authenticated>
          {page.page?.() || <></>}
        </Authenticated>}
      />
    }
  }));

  return (
    <Routes>
      {routes}
      <Route key="file__analytics" path="/files/analytics/:uuid" element={<Authenticated>
        <Analytics />
      </Authenticated>} />
    </Routes>
  );
});

export default App;
